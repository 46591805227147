"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const react_redux_1 = require("react-redux");
const URLParamUtil_1 = require("../util/URLParamUtil");
const LocalStorageUtil_1 = require("../util/LocalStorageUtil");
const StoreAPI_1 = require("../api/store/StoreAPI");
const BasketAPI_1 = require("../api/basket/BasketAPI");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const SessionActions_creators_1 = require("../redux/session/actions/SessionActions.creators");
const ShopNumberContainer = () => {
    const { defaultRequestParams, servicesEndpoint, shopNumber, isShopLanding } = (0, common_components_1.useAemConfig)();
    const [localShopNumber, setLocalShopNumber] = (0, react_1.useState)('');
    const dispatch = (0, react_redux_1.useDispatch)();
    const setShopLocationInCookie = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const storeInfo = yield StoreAPI_1.default.getShopLandingStoreInfo(servicesEndpoint, defaultRequestParams, shopNumber);
        if ((storeInfo === null || storeInfo === void 0 ? void 0 : storeInfo.latitude) && (storeInfo === null || storeInfo === void 0 ? void 0 : storeInfo.longitude)) {
            common_utils_1.CookieUtil.set(common_types_1.Cookie.BROWSER_GEOLOCATION, encodeURIComponent(JSON.stringify({ lat: storeInfo.latitude, lng: storeInfo.longitude })), {
                secure: true,
            });
        }
    });
    const setDeviceIdInLocalStorage = () => {
        const deviceIdParam = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, StorageKeysConstants_1.DEVICE_ID_STORAGE_KEY) ||
            (0, URLParamUtil_1.getQuerystringByName)(window.location.hash, StorageKeysConstants_1.DEVICE_ID_STORAGE_KEY);
        if (deviceIdParam) {
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.DEVICE_ID_STORAGE_KEY, deviceIdParam);
        }
    };
    const setPersonalShoppingAdviceInLocalStorage = () => {
        const personalShoppingAdviceParam = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, StorageKeysConstants_1.PERSONAL_SHOPPING_ADVICE_STORAGE_KEY) ||
            (0, URLParamUtil_1.getQuerystringByName)(window.location.hash, StorageKeysConstants_1.PERSONAL_SHOPPING_ADVICE_STORAGE_KEY);
        if (personalShoppingAdviceParam) {
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.PERSONAL_SHOPPING_ADVICE_STORAGE_KEY, personalShoppingAdviceParam.toLowerCase().trim());
        }
    };
    const checkForShopNumber = () => {
        const shopNumberValue = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY) ||
            (0, URLParamUtil_1.getQuerystringByName)(window.location.hash, StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY) ||
            LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY);
        if (shopNumberValue) {
            SITE.Config.shopNumber = shopNumberValue;
            setLocalShopNumber(shopNumberValue);
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY, shopNumberValue);
            BasketAPI_1.default.initiateBasketForShopLanding(servicesEndpoint, defaultRequestParams, shopNumberValue);
        }
    };
    (0, react_1.useEffect)(() => {
        checkForShopNumber();
        setDeviceIdInLocalStorage();
        setPersonalShoppingAdviceInLocalStorage();
        dispatch(SessionActions_creators_1.default.createInitAction());
    }, []);
    (0, react_1.useEffect)(() => {
        if (isShopLanding && localShopNumber) {
            setShopLocationInCookie();
        }
    }, [localShopNumber]);
    return null;
};
exports.default = ShopNumberContainer;
