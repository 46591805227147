"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryHierarchyTreeMobileHelper = void 0;
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const NavigationUtil_1 = require("../../../util/NavigationUtil");
const HtmlElementUtil_1 = require("../../../util/HtmlElementUtil");
const CategoryHierarchyTreeMobileHelper = ({ categoryIds, hierarchyTree, }) => {
    const { lang } = (0, common_components_1.useAemConfig)();
    const isClient = (0, cotopaxi_1.useIsClient)();
    const walkCategoryHierarchyTree = (hierchyTreeItem) => {
        var _a;
        // If Item has children, proceed down the tree, else search for a link in the menu and select its parent menu items.
        if (hierchyTreeItem.children && ((_a = hierchyTreeItem.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            hierchyTreeItem.children.map(hierchyTreeItemChild => walkCategoryHierarchyTree(hierchyTreeItemChild));
            return;
        }
        const isActive = categoryIds.includes(hierchyTreeItem.item.categoryId);
        if (!isActive)
            return;
        const link = (0, NavigationUtil_1.getHomepageUrl)(hierchyTreeItem.item.categoryTranslatedProperties[lang.toLocaleUpperCase()]
            .link);
        // Check corresponding mobile menu item
        const query = `.as-a-menu-item .as-a-link[href*="${link}"]`;
        const menuItem = document.querySelector(query);
        if (!menuItem)
            return;
        const menuItemCheckbox = menuItem.previousElementSibling;
        if (!menuItemCheckbox) {
            menuItem.classList.add('as-a-menu-item--mobile-only-active');
        }
        const parentMenuWrappers = (0, HtmlElementUtil_1.findParents)(menuItem, '[data-js-menu-has-flyout]');
        parentMenuWrappers.forEach(menuWrapper => {
            const menuWrapperCheckbox = menuWrapper.querySelector(':scope > input');
            if (menuWrapperCheckbox) {
                // use setAttribute so reset (on flyout close) will reset to the category menu by default.
                menuWrapperCheckbox.setAttribute('checked', 'checked');
            }
        });
    };
    (0, react_1.useEffect)(() => {
        if (!isClient) {
            return;
        }
        walkCategoryHierarchyTree(hierarchyTree);
    }, [hierarchyTree, isClient]);
    return null;
};
exports.CategoryHierarchyTreeMobileHelper = CategoryHierarchyTreeMobileHelper;
