"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenedStores = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ImageUtil_1 = require("../../util/ImageUtil");
const StoreInfoTag_1 = require("./StoreInfoTag");
const StoreAddress_1 = require("./StoreAddress");
const StoreActualInfoTag_1 = require("./StoreActualInfoTag");
const SliderConstants_1 = require("../../constants/SliderConstants");
const ScreenWidthConstants_1 = require("../../constants/ScreenWidthConstants");
const OpenedStores = ({ openedStores, copy }) => {
    const { root, imageCdnPrefix } = (0, common_components_1.useAemConfig)();
    if (!openedStores || openedStores.length === 0) {
        return null;
    }
    const carouselProps = {
        variant: cotopaxi_1.CarouselVariant.STORES,
        arrowsConfig: {
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
            color: cotopaxi_1.SliderNavColor.BASE,
            iconSize: cotopaxi_1.IconSize.MINOR,
        },
        configurationOverrides: {
            spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
            watchOverflow: true,
            shouldSwiperUpdate: true,
            breakpoints: {
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_MOBILE]: {
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
                    slidesPerView: openedStores.length > 1 ? 1.1 : 1,
                },
                [ScreenWidthConstants_1.MAX_SCREEN_WIDTH_MOBILE]: {
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
                    slidesPerView: openedStores.length > 1 ? 1.5 : 1,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET_SMALL]: {
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
                    slidesPerView: openedStores.length > 2 ? 2.5 : 2,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET]: {
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.DESKTOP,
                    slidesPerView: openedStores.length > 3 ? 3.2 : 3,
                },
                1100: {
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.DESKTOP,
                    slidesPerView: openedStores.length > 4 ? 4.2 : 4,
                },
            },
        },
    };
    const handleClick = (store) => () => (window.location.href = `${root}${store.relativeStorePagePath}.html`);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 1.5, paddingTop: 1.5 } }, tablet: { spacing: { paddingTop: 2.5, paddingBottom: 2.5 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.XL, children: copy.title }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, carouselProps, { children: openedStores.map(store => ((0, jsx_runtime_1.jsx)("div", { style: { height: 'auto' }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Card, { onClick: handleClick(store), fullWidth: true, children: [store.image && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Card.Media, { src: store.image.imagePath, srcset: (0, ImageUtil_1.getPictureSrcset)(store.image, imageCdnPrefix), alt: store.storeName, isPicture: true })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Card.Header, { title: store.storeName }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Card.Body, { children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.MEDIUM, children: [(0, jsx_runtime_1.jsx)(StoreActualInfoTag_1.StoreActualInfoTag, { scheduleEntries: store.scheduleEntries }), (0, jsx_runtime_1.jsx)(StoreInfoTag_1.StoreInfoTag, { icon: cotopaxi_1.IconName.MAP_MARKER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, children: (0, jsx_runtime_1.jsx)(StoreAddress_1.StoreAddress, { address: store.address }) }) })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Card.Footer, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: copy.button, variant: cotopaxi_1.ButtonVariant.OUTLINED, fill: true }) })] }) }, store.code))) }))] }));
};
exports.OpenedStores = OpenedStores;
