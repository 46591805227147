"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobileList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const FiltersEmptyResult_1 = require("../../empty-result/FiltersEmptyResult");
const common_components_1 = require("@as-react/common-components");
const FiltersMobileSort_1 = require("./FiltersMobileSort");
const FiltersMobileCollapsible_1 = require("./FiltersMobileCollapsible");
const andes_react_1 = require("@yonderland/andes-react");
const FiltersMobileList = ({ filters, itemCount, isContentTileLister, filterWizardConfig, fredhopperGlossary, onFilterItemChange, removeAllActiveFilters, urlFilters, }) => {
    const { mainWebShop, lister: listerConfig } = (0, common_components_1.useAemConfig)();
    return itemCount <= 0 ? ((0, jsx_runtime_1.jsx)(FiltersEmptyResult_1.FiltersEmptyResult, { removeAllActiveFilters: removeAllActiveFilters, mainWebShop: mainWebShop, showChangeFilterStep: false })) : ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [!isContentTileLister && listerConfig.sort.properties.length > 0 && ((0, jsx_runtime_1.jsx)(FiltersMobileSort_1.FiltersMobileSort, { sortConfiguration: listerConfig.sort }, "sort")), filters.map(filter => ((0, jsx_runtime_1.jsx)(FiltersMobileCollapsible_1.FiltersMobileCollapsible, { filter: filter, itemCount: itemCount, onFilterItemChange: onFilterItemChange, fredhopperGlossary: fredhopperGlossary, filterWizardConfig: filterWizardConfig, urlFilters: urlFilters }, filter.filterOn)))] }));
};
exports.FiltersMobileList = FiltersMobileList;
