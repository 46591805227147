"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.usedVersionReact = exports.renderingTools = exports.availableComponents = void 0;
const common_types_1 = require("@as-react/common-types");
const rootModules_1 = require("./redux/rootModules");
exports.availableComponents = {
    [common_types_1.AEMScenes.WISHLIST_MENU_ITEM]: {
        componentName: common_types_1.AEMScenes.WISHLIST_MENU_ITEM,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.WISHLIST_MENU_ITEM),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_WishlistMenuItem_Component" */ './containers/WishlistMenuItemContainer')),
    },
    [common_types_1.AEMScenes.GUEST_REDIRECT]: {
        componentName: common_types_1.AEMScenes.GUEST_REDIRECT,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.GUEST_REDIRECT),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_GuestRedirect_Component" */ './containers/GuestRedirectContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_DETAIL_V2]: {
        componentName: common_types_1.AEMScenes.PRODUCT_DETAIL_V2,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_DETAIL_V2),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductDetail_v2_Component" */ './containers/product/ProductDetailV2Container')),
    },
    [common_types_1.AEMScenes.COOKIE_MESSAGE]: {
        componentName: common_types_1.AEMScenes.COOKIE_MESSAGE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.COOKIE_MESSAGE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_CookieMessage_Component" */ './containers/CookieMessageContainer')),
    },
    [common_types_1.AEMScenes.MONETATE]: {
        componentName: common_types_1.AEMScenes.MONETATE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.MONETATE),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Monetate" */ './containers/MonetateContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_GROUP]: {
        componentName: common_types_1.AEMScenes.PRODUCT_GROUP,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_GROUP),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductGroup_Component" */ './containers/product/ProductGroupContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_LISTER]: {
        componentName: common_types_1.AEMScenes.PRODUCT_LISTER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_LISTER),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductLister_Component" */ './containers/product/ProductListerContainer')),
    },
    [common_types_1.AEMScenes.ESSENTIALS]: {
        componentName: common_types_1.AEMScenes.ESSENTIALS,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ESSENTIALS),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Essentials" */ './containers/EssentialsContainer')),
    },
    [common_types_1.AEMScenes.CAMPAIGN_GROUP]: {
        componentName: common_types_1.AEMScenes.CAMPAIGN_GROUP,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.CAMPAIGN_GROUP),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_CampaignGroup_Component" */ './components/campaign-group/CampaignGroup')),
    },
    [common_types_1.AEMScenes.SEARCH_MENU_ITEM]: {
        componentName: common_types_1.AEMScenes.SEARCH_MENU_ITEM,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.SEARCH_MENU_ITEM),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_SearchMenuItem_Component" */ './containers/MenuSearchContainer')),
    },
    [common_types_1.AEMScenes.SEO_AUTHOR_DATA]: {
        componentName: common_types_1.AEMScenes.SEO_AUTHOR_DATA,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.SEO_AUTHOR_DATA),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_SeoAuthorData" */ './containers/seo/AuthorDataContainer')),
    },
    [common_types_1.AEMScenes.BREADCRUMB]: {
        componentName: common_types_1.AEMScenes.BREADCRUMB,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BREADCRUMB),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_Breadcrumb_Component" */ './components/breadcrumb/Breadcrumb')),
    },
    [common_types_1.AEMScenes.CONTENT_TILE_LISTER]: {
        componentName: common_types_1.AEMScenes.CONTENT_TILE_LISTER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.CONTENT_TILE_LISTER),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ContentTileLister" */ './containers/ContentTileListerContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_RELEVANT_CONTENT]: {
        componentName: common_types_1.AEMScenes.PRODUCT_RELEVANT_CONTENT,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_RELEVANT_CONTENT),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductRelevantContent" */ './components/product-detail/product-relevant-content/ProductRelevantContent')),
    },
    [common_types_1.AEMScenes.CURRENCY_MENU_ITEM]: {
        componentName: common_types_1.AEMScenes.CURRENCY_MENU_ITEM,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.CURRENCY_MENU_ITEM),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_CurrencyMenuItem_Component" */ './components/currency-menu-item/CurrencyMenuItem')),
    },
    [common_types_1.AEMScenes.GIFT_VOUCHER]: {
        componentName: common_types_1.AEMScenes.GIFT_VOUCHER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.GIFT_VOUCHER),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_GiftVoucher_Component" */ './containers/GiftVoucherContainer')),
    },
    [common_types_1.AEMScenes.SINGLE_PRODUCT_TILE]: {
        componentName: common_types_1.AEMScenes.SINGLE_PRODUCT_TILE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.SINGLE_PRODUCT_TILE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_SingleProductTile_Component" */ './containers/SingleProductTileContainer')),
    },
    [common_types_1.AEMScenes.ABTESTING]: {
        componentName: common_types_1.AEMScenes.ABTESTING,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ABTESTING),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountActivation_Component" */ './containers/ab/ABTestingContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_ACTIVATION]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_ACTIVATION,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_ACTIVATION),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountActivation_Component" */ './containers/account/AccountActivationContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_CREDIT_CARD]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_CREDIT_CARD,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_CREDIT_CARD),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountCreditCard_Component" */ './containers/account/AccountCreditCardContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_MENU_ITEM]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_MENU_ITEM,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_MENU_ITEM),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountMenuItem_Component" */ './containers/account/AccountMenuItemContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_PAYBACK_ENRICHMENT]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_PAYBACK_ENRICHMENT,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_PAYBACK_ENRICHMENT),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountPaybackEnrichment_Component" */ './containers/account/AccountPaybackEnrichmentContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_PREFERENCES]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_PREFERENCES,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_PREFERENCES),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountPreferences_Component" */ './containers/account/AccountPreferencesContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_UNSUBSCRIBE]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_UNSUBSCRIBE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_UNSUBSCRIBE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountUnsubscribe_Component" */ './containers/account/AccountUnsubscribeContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_VOUCHERS]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_VOUCHERS,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_VOUCHERS),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountVouchers_Component" */ './containers/account/AccountVouchersContainer')),
    },
    [common_types_1.AEMScenes.ORDER_OVERVIEW]: {
        componentName: common_types_1.AEMScenes.ORDER_OVERVIEW,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ORDER_OVERVIEW),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_OrderOverview_Component" */ './containers/OrderOverviewContainer')),
    },
    [common_types_1.AEMScenes.BASKET_MENU_ITEM]: {
        componentName: common_types_1.AEMScenes.BASKET_MENU_ITEM,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BASKET_MENU_ITEM),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_BasketMenuItem_Component" */ './containers/BasketMenuItemContainer')),
    },
    [common_types_1.AEMScenes.LANGUAGE_MENU_ITEM]: {
        componentName: common_types_1.AEMScenes.LANGUAGE_MENU_ITEM,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.LANGUAGE_MENU_ITEM),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_LanguageMenuItem_Component" */ './containers/LanguageMenuItemContainer')),
    },
    [common_types_1.AEMScenes.RELATED_CATEGORIES]: {
        componentName: common_types_1.AEMScenes.RELATED_CATEGORIES,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.RELATED_CATEGORIES),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_RelatedCategories_Component" */ './containers/RelatedCategoriesContainer')),
    },
    [common_types_1.AEMScenes.WISHLIST]: {
        componentName: common_types_1.AEMScenes.WISHLIST,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.WISHLIST),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_Wishlist_Component" */ './containers/WishlistContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_WIZARD]: {
        componentName: common_types_1.AEMScenes.PRODUCT_WIZARD,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_WIZARD),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductWizard_Component" */ './containers/product/ProductWizardContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_GROUP_FREDHOPPER]: {
        componentName: common_types_1.AEMScenes.PRODUCT_GROUP_FREDHOPPER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_GROUP_FREDHOPPER),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductGroupFredhopper_Component" */ './containers/product/ProductGroupFredhopperContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_GROUP_MONETATE]: {
        componentName: common_types_1.AEMScenes.PRODUCT_GROUP_MONETATE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_GROUP_MONETATE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductGroupMonetate_Component" */ './containers/product/ProductGroupMonetateContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_GROUP_PDP_COMPARISON_FREDHOPPER]: {
        componentName: common_types_1.AEMScenes.PRODUCT_GROUP_PDP_COMPARISON_FREDHOPPER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_GROUP_PDP_COMPARISON_FREDHOPPER),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductGroupPdpComparisonFredhopper_Component" */ './containers/product/ProductGroupPdpComparisonFredhopperContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_GROUP_PDP_COMPARISON_MONETATE]: {
        componentName: common_types_1.AEMScenes.PRODUCT_GROUP_PDP_COMPARISON_MONETATE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_GROUP_PDP_COMPARISON_MONETATE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductGroupPdpComparisonMonetate_Component" */ './containers/product/ProductGroupPdpComparisonMonetateContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_REVIEW_LANDING]: {
        componentName: common_types_1.AEMScenes.PRODUCT_REVIEW_LANDING,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_REVIEW_LANDING),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductReviewLanding_Component" */ './containers/product/ProductReviewLandingContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_RECENTLY_VIEWED]: {
        componentName: common_types_1.AEMScenes.PRODUCT_RECENTLY_VIEWED,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_RECENTLY_VIEWED),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductRecentlyViewed_Component" */ './containers/product/ProductRecentlyViewedContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_REVIEW]: {
        componentName: common_types_1.AEMScenes.PRODUCT_REVIEW,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_REVIEW),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductReview_Component" */ './containers/product/ProductReviewContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_COMPARISON]: {
        componentName: common_types_1.AEMScenes.PRODUCT_COMPARISON,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_COMPARISON),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductComparison_Component" */ './containers/product/ProductComparisonContainer')),
    },
    [common_types_1.AEMScenes.BASKET_OVERVIEW]: {
        componentName: common_types_1.AEMScenes.BASKET_OVERVIEW,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BASKET_OVERVIEW),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_BasketOverview_Component" */ './containers/BasketOverviewContainer')),
    },
    [common_types_1.AEMScenes.BASKET_OVERVIEW_V2]: {
        componentName: common_types_1.AEMScenes.BASKET_OVERVIEW_V2,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BASKET_OVERVIEW_V2),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_BasketOverview_v2_Component" */ './containers/checkout/BasketOverviewContainer')),
    },
    [common_types_1.AEMScenes.BOXED_TITLE]: {
        componentName: common_types_1.AEMScenes.BOXED_TITLE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BOXED_TITLE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScene_BoxedTitle_Component" */ './components/boxed-title/BoxedTitle')),
    },
    [common_types_1.AEMScenes.COLLAPSIBLE_LIST]: {
        componentName: common_types_1.AEMScenes.COLLAPSIBLE_LIST,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.COLLAPSIBLE_LIST),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_CollapsibleList_Component" */ './components/collapsible-list/CollapsibleList')),
    },
    [common_types_1.AEMScenes.CHECKOUT]: {
        componentName: common_types_1.AEMScenes.CHECKOUT,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.CHECKOUT),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_Checkout_Component" */ './containers/checkout/CheckoutContainer')),
    },
    [common_types_1.AEMScenes.CHECKOUT_HEADER]: {
        componentName: common_types_1.AEMScenes.CHECKOUT_HEADER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.CHECKOUT_HEADER),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_CheckoutHeader_Component" */ './containers/checkout/CheckoutHeaderContainer')),
    },
    [common_types_1.AEMScenes.VIDEO]: {
        componentName: common_types_1.AEMScenes.VIDEO,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.VIDEO),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_Video_Component" */ './components/video-player/VideoPlayer')),
    },
    [common_types_1.AEMScenes.BUY]: {
        componentName: common_types_1.AEMScenes.BUY,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BUY),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScene_Buy_Component" */ './containers/BuyContainer')),
    },
    [common_types_1.AEMScenes.BUY_PAYMENT_REQUEST]: {
        componentName: common_types_1.AEMScenes.BUY_PAYMENT_REQUEST,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BUY_PAYMENT_REQUEST),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_BuyPaymentRequest_Component" */ './containers/BuyPaymentRequestContainer')),
    },
    [common_types_1.AEMScenes.BUY_CONFIRMATION]: {
        componentName: common_types_1.AEMScenes.BUY_CONFIRMATION,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BUY_CONFIRMATION),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScene_BuyConfirmation_Component" */ './containers/buy/BuyConfirmationContainer')),
    },
    [common_types_1.AEMScenes.BUY_PAYMENT_REQUEST_CONFIRMATION]: {
        componentName: common_types_1.AEMScenes.BUY_PAYMENT_REQUEST_CONFIRMATION,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BUY_PAYMENT_REQUEST_CONFIRMATION),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScene_BuyConfirmation_Component" */ './containers/buy/BuyPaymentRequestConfirmationContainer')),
    },
    [common_types_1.AEMScenes.BASKET_PERSONAL_SHOPPING_ADVICE]: {
        componentName: common_types_1.AEMScenes.BASKET_PERSONAL_SHOPPING_ADVICE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BASKET_PERSONAL_SHOPPING_ADVICE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScene_BasketPersonalShoppingAdvice_Component" */ './containers/BasketPersonalShoppingAdviceContainer')),
    },
    [common_types_1.AEMScenes.RENTAL]: {
        componentName: common_types_1.AEMScenes.RENTAL,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.RENTAL),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "Rental_Component" */ './containers/RentalContainer')),
    },
    [common_types_1.AEMScenes.COUNTDOWN]: {
        componentName: common_types_1.AEMScenes.COUNTDOWN,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.COUNTDOWN),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "Countdown_Component" */ './components/common/countdown/Countdown')),
    },
    [common_types_1.AEMScenes.DPE_ACTIVATOR]: {
        componentName: common_types_1.AEMScenes.DPE_ACTIVATOR,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.DPE_ACTIVATOR),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_DpeActivator_Component" */ './containers/DpeActivatorContainer')),
    },
    [common_types_1.AEMScenes.BRAND_TILE_LISTER]: {
        componentName: common_types_1.AEMScenes.BRAND_TILE_LISTER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.BRAND_TILE_LISTER),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_BrandTileLister_Component" */ './containers/BrandListerContainer')),
    },
    [common_types_1.AEMScenes.FOLDER_NAVIGATION]: {
        componentName: common_types_1.AEMScenes.FOLDER_NAVIGATION,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.FOLDER_NAVIGATION),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_FolderNavigation_Component" */ './components/folder/folder-navigation/FolderNavigation')),
    },
    [common_types_1.AEMScenes.PRODUCT_INSPIRATION]: {
        componentName: common_types_1.AEMScenes.PRODUCT_INSPIRATION,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_INSPIRATION),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductInspiration_Component" */ './components/product-inspiration/ProductInspiration')),
    },
    [common_types_1.AEMScenes.RETURN_ITEMS]: {
        componentName: common_types_1.AEMScenes.RETURN_ITEMS,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.RETURN_ITEMS),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ReturnItems_Component" */ './containers/ReturnItemsContainer')),
    },
    [common_types_1.AEMScenes.NEWSLETTER_SUBSCRIBE]: {
        componentName: common_types_1.AEMScenes.NEWSLETTER_SUBSCRIBE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.NEWSLETTER_SUBSCRIBE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_NewsletterSubscribe_Component" */ './containers/NewsletterSubscribeContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_PASSWORD_RESET]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_PASSWORD_RESET,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_PASSWORD_RESET),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountPasswordReset_Component" */ './containers/account/AccountPasswordResetContainer')),
    },
    [common_types_1.AEMScenes.CHANGE_PASSWORD]: {
        componentName: common_types_1.AEMScenes.CHANGE_PASSWORD,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.CHANGE_PASSWORD),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ChangePassword_Component" */ './containers/ChangePasswordContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_OVERVIEW_INFO]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_OVERVIEW_INFO,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_OVERVIEW_INFO),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountOverviewInfo_Component" */ './containers/account/AccountOverviewInfoContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_MENU]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_MENU,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_MENU),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountMenu_Component" */ './containers/account/AccountMenuProfileContainer')),
    },
    [common_types_1.AEMScenes.SHOP_THE_LOOK]: {
        componentName: common_types_1.AEMScenes.SHOP_THE_LOOK,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.SHOP_THE_LOOK),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ShopTheLook_Component" */ './containers/ShopTheLookContainer')),
    },
    [common_types_1.AEMScenes.RETURN_PORTAL_LOGIN]: {
        componentName: common_types_1.AEMScenes.RETURN_PORTAL_LOGIN,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.RETURN_PORTAL_LOGIN),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ReturnPortalLogin_Component" */ './containers/ReturnPortalLoginContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_REGISTRATION]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_REGISTRATION,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_REGISTRATION),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ReturnPortalLogin_Component" */ './containers/account/AccountRegistrationContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_DELIVERY_ADDRESSES]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_DELIVERY_ADDRESSES,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_DELIVERY_ADDRESSES),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_DeliveryAddresses_Component" */ './containers/account/AccountDeliveryAddressesContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_ORDER_HISTORY]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_ORDER_HISTORY,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_ORDER_HISTORY),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountOrderHistory_Component" */ './containers/account/AccountOrderHistoryContainer')),
    },
    [common_types_1.AEMScenes.ACCOUNT_PROFILE_DETAILS]: {
        componentName: common_types_1.AEMScenes.ACCOUNT_PROFILE_DETAILS,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ACCOUNT_PROFILE_DETAILS),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_AccountProfileDetails_Component" */ './containers/account/AccountProfileDetailsContainer')),
    },
    [common_types_1.AEMScenes.PRODUCT_CONTENT_VIDEO]: {
        componentName: common_types_1.AEMScenes.PRODUCT_CONTENT_VIDEO,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_CONTENT_VIDEO),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ProductContentVideo_Component" */ './containers/product/ProductContentVideoContainer')),
    },
    [common_types_1.AEMScenes.LOGIN]: {
        componentName: common_types_1.AEMScenes.LOGIN,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.LOGIN),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Login_Component" */ './containers/LoginContainer')),
    },
    [common_types_1.AEMScenes.REGISTRATION_COMPACT]: {
        componentName: common_types_1.AEMScenes.REGISTRATION_COMPACT,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.REGISTRATION_COMPACT),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_RegistrationCompact_Component" */ './containers/RegistrationCompactContainer')),
    },
    [common_types_1.AEMScenes.SLIDER]: {
        componentName: common_types_1.AEMScenes.SLIDER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.SLIDER),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Slider_Component" */ './containers/SliderContainer')),
    },
    [common_types_1.AEMScenes.CATEGORY_TILE]: {
        componentName: common_types_1.AEMScenes.CATEGORY_TILE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.CATEGORY_TILE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_CategoryTile_Component" */ './containers/CategoryTileContainer')),
    },
    [common_types_1.AEMScenes.TEASER]: {
        componentName: common_types_1.AEMScenes.TEASER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.TEASER),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "AEMScenes_Teaser_Component" */ './containers/TeaserContainer')),
    },
    [common_types_1.AEMScenes.FEEFO_BRAND_BADGE]: {
        componentName: common_types_1.AEMScenes.FEEFO_BRAND_BADGE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.FEEFO_BRAND_BADGE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_FeefoBrandBadge_Component" */ './components/feefo-brand-badge/FeefoBrandBadge')),
    },
    [common_types_1.AEMScenes.REEVOO_CUSTOMER_EXPERIENCE]: {
        componentName: common_types_1.AEMScenes.REEVOO_CUSTOMER_EXPERIENCE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.REEVOO_CUSTOMER_EXPERIENCE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ReevooCustomerExperience_Component" */ './components/reevoo-customer-experience/ReevooCustomerExperience')),
    },
    [common_types_1.AEMScenes.SOCIAL_LINKS]: {
        componentName: common_types_1.AEMScenes.SOCIAL_LINKS,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.SOCIAL_LINKS),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "SocialLinks_Component" */ './components/social-links/SocialLinks')),
    },
    [common_types_1.AEMScenes.LINE]: {
        componentName: common_types_1.AEMScenes.LINE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.LINE),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "Line_Component" */ './components/line/Line')),
    },
    [common_types_1.AEMScenes.LIST]: {
        componentName: common_types_1.AEMScenes.LIST,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.LIST),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "List_Component" */ './components/list/List')),
    },
    [common_types_1.AEMScenes.STORE_GENERAL]: {
        componentName: common_types_1.AEMScenes.STORE_GENERAL,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.STORE_GENERAL),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "StoreGeneral_Component" */ './components/stores/StoreGeneral')),
    },
    [common_types_1.AEMScenes.STORE_DEPARTMENTS]: {
        componentName: common_types_1.AEMScenes.STORE_DEPARTMENTS,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.STORE_DEPARTMENTS),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "StoreDepartments_Component" */ './components/stores/StoreDepartments')),
    },
    [common_types_1.AEMScenes.STORE_NEARBY_STORES]: {
        componentName: common_types_1.AEMScenes.STORE_NEARBY_STORES,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.STORE_NEARBY_STORES),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "StoreNearbyStores_Component" */ './components/stores/StoreNearbyStores')),
    },
    [common_types_1.AEMScenes.RECENTLY_OPENED_STORES]: {
        componentName: common_types_1.AEMScenes.RECENTLY_OPENED_STORES,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.RECENTLY_OPENED_STORES),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "RecentlyOpenedStores_Component" */ './components/stores/RecentlyOpenedStores')),
    },
    [common_types_1.AEMScenes.SERVICE_ORDER_TRACKING]: {
        componentName: common_types_1.AEMScenes.SERVICE_ORDER_TRACKING,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.SERVICE_ORDER_TRACKING),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_ServiceOrderTracking_Container" */ './containers/ServiceOrderTrackingContainer')),
    },
    [common_types_1.AEMScenes.STORE_LOCATOR]: {
        componentName: common_types_1.AEMScenes.STORE_LOCATOR,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.STORE_LOCATOR),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "StoreLocator_Component" */ './components/stores/store-locator/StoreLocator')),
    },
    [common_types_1.AEMScenes.PRODUCT_TOP_FIVE]: {
        componentName: common_types_1.AEMScenes.PRODUCT_TOP_FIVE,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.PRODUCT_TOP_FIVE),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "ProductTopFive_Component" */ './components/product-top-five/ProductTopFive')),
    },
    [common_types_1.AEMScenes.SHOP_NUMBER]: {
        componentName: common_types_1.AEMScenes.SHOP_NUMBER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.SHOP_NUMBER),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "ShopNumber_Component" */ './containers/ShopNumberContainer')),
    },
    [common_types_1.AEMScenes.GLOSSARY]: {
        componentName: common_types_1.AEMScenes.GLOSSARY,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.GLOSSARY),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "Glossary_Component" */ './containers/GlossaryContainer')),
    },
    [common_types_1.AEMScenes.ETICKET]: {
        componentName: common_types_1.AEMScenes.ETICKET,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.ETICKET),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "Eticket_Component" */ './containers/EticketContainer')),
    },
    [common_types_1.AEMScenes.DISTINCTIVE_PRODUCT_DETAILS]: {
        componentName: common_types_1.AEMScenes.DISTINCTIVE_PRODUCT_DETAILS,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.DISTINCTIVE_PRODUCT_DETAILS),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "DistinctiveProductDetails_Component" */ './components/distinctive-product-details/DistinctiveProductDetails')),
    },
    [common_types_1.AEMScenes.SHOPPING_BUDGET]: {
        componentName: common_types_1.AEMScenes.SHOPPING_BUDGET,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.SHOPPING_BUDGET),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "ShoppingBudget_Component" */ './containers/ShoppingBudgetContainer')),
    },
    [common_types_1.AEMScenes.VOUCHER]: {
        componentName: common_types_1.AEMScenes.VOUCHER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.VOUCHER),
        promisedComponent: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "Voucher_Component" */ './containers/VoucherContainer')),
    },
    [common_types_1.AEMScenes.DELIVERY_CONDITIONS_BANNER]: {
        componentName: common_types_1.AEMScenes.DELIVERY_CONDITIONS_BANNER,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.DELIVERY_CONDITIONS_BANNER),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "DeliveryConditionsBanner_Component" */ './containers/DeliveryConditionsBannerContainer')),
    },
    [common_types_1.AEMScenes.MOBILE_NAVIGATION]: {
        componentName: common_types_1.AEMScenes.MOBILE_NAVIGATION,
        hasReduxModule: (0, rootModules_1.checkModuleCollectionForComponent)(common_types_1.AEMScenes.MOBILE_NAVIGATION),
        promisedComponent: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "AEMScenes_MobileNavigation" */ './containers/MobileNavigationContainer')),
    },
};
exports.renderingTools = {
    redux: {
        configureStore: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "startup" */ './redux/storeConfigure/storeConfigure')),
    },
    i18n: {
        instanceCreator: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "startup" */ './config/i18n/instanceCreator.i18n')),
        instanceCreatorBackEnd: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "startup_backend" */ './config/i18n/instanceCreatorBackend.i18n')),
        frontEndInitOptions: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "startup" */ './config/i18n/i18nConfigFrontEnd')),
        backendEndInitOptions: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "startup_backend" */ './config/i18n/i18nConfigBackend')),
    },
    numeral: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "startup" */ './vendors/asNumeral')),
    cotopaxi: {
        version: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "startup_backend" */ './vendors/cotopaxi/usedVersionCotopaxi')),
        preloadAssetFileGenerator: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "startup_backend" */ './vendors/cotopaxi/preloadAssetFileGenerator')),
    },
    andes: {
        version: () => Promise.resolve().then(() => require(/* webpackMode: "lazy", webpackChunkName: "startup_backend" */ './vendors/andes/usedVersionAndes')),
        preloadAssetFileGenerator: () => Promise.resolve().then(() => require(
        /* webpackMode: "lazy", webpackChunkName: "startup_backend" */ './vendors/andes/preloadAssetFileGenerator')),
    },
};
exports.usedVersionReact = `${(_a = process.env.REACT_VERSION) !== null && _a !== void 0 ? _a : 'ECDEV-FE.0'}-${(_b = process.env.BUILD_TIMESTAMP) !== null && _b !== void 0 ? _b : '0'}`;
