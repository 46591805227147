"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnVoucherChanges = void 0;
const tslib_1 = require("tslib");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const CheckoutPaymentContext_1 = require("../context/CheckoutPaymentContext");
const EventEnum_1 = require("../enums/EventEnum");
const EventUtil_1 = require("../util/EventUtil");
const react_query_1 = require("@tanstack/react-query");
const useSelectPaymentOption_1 = require("./useSelectPaymentOption");
// should only be used inside PaymentContext
const useOnVoucherChanges = () => {
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const [{ isSetPaymentGroupSuccess, selectedPaymentGroup }] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const { selectPaymentGroup } = (0, useSelectPaymentOption_1.useSelectPaymentOption)();
    const { postSavePoint } = (0, common_queries_1.basketMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutateAsync: mutatePostSavePoint } = (0, react_query_1.useMutation)(postSavePoint);
    const onVoucherChanges = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            if (isSetPaymentGroupSuccess) {
                if (selectedPaymentGroup) {
                    yield selectPaymentGroup(selectedPaymentGroup);
                }
            }
            else {
                yield mutatePostSavePoint();
            }
        }
        catch (e) { }
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.VOUCHER_CHANGED, true);
    });
    return { onVoucherChanges };
};
exports.useOnVoucherChanges = useOnVoucherChanges;
