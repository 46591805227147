"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const Slider_1 = require("../slider/Slider");
const ImageUtil_1 = require("../../util/ImageUtil");
const SliderConstants_1 = require("../../constants/SliderConstants");
const i18nConstants_1 = require("../../constants/i18nConstants");
const IMAGE_HEIGHT = 200;
const SLIDES_PER_VIEW_MOBILE = 1;
const SLIDES_PER_VIEW_TABLET = 2;
const SLIDES_PER_VIEW_DESKTOP_MIN = 3;
const SLIDES_PER_VIEW_DESKTOP_MAX = 4;
const SLIDES_TO_SCROLL = 1;
const DistinctiveProductDetails = ({ distinctiveProductDetails, }) => {
    const { imageCdnPrefix } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    if (!distinctiveProductDetails.length)
        return null;
    const slidesPerViewMobile = distinctiveProductDetails.length > SLIDES_PER_VIEW_MOBILE
        ? SLIDES_PER_VIEW_MOBILE + SliderConstants_1.SlideCropped.MEDIUM
        : SLIDES_PER_VIEW_MOBILE;
    const slidesPerViewTablet = distinctiveProductDetails.length > SLIDES_PER_VIEW_TABLET
        ? SLIDES_PER_VIEW_TABLET + SliderConstants_1.SlideCropped.MEDIUM
        : SLIDES_PER_VIEW_TABLET;
    const slidesPerViewDesktop = distinctiveProductDetails.length > SLIDES_PER_VIEW_DESKTOP_MIN
        ? SLIDES_PER_VIEW_DESKTOP_MAX
        : SLIDES_PER_VIEW_DESKTOP_MIN;
    const sliderConfig = {
        displayCroppedSlides: false,
        hideOnViewports: false,
        infiniteSliding: false,
        innerSpacing: true,
        showArrowsForDesktop: false,
        showIndicators: false,
        slidesToScrollMobile: SLIDES_TO_SCROLL,
        slidesToScrollMobileLarge: SLIDES_TO_SCROLL,
        slidesToScrollTabletSmall: SLIDES_TO_SCROLL,
        slidesToScrollTablet: SLIDES_TO_SCROLL,
        slidesToScrollDesktop: SLIDES_TO_SCROLL,
        slidesToScrollDesktopLarge: SLIDES_TO_SCROLL,
        slidesToShowMobile: slidesPerViewMobile,
        slidesToShowMobileLarge: slidesPerViewMobile,
        slidesToShowTabletSmall: slidesPerViewTablet,
        slidesToShowTablet: slidesPerViewTablet,
        slidesToShowDesktop: slidesPerViewDesktop,
        slidesToShowDesktopLarge: slidesPerViewDesktop,
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: t('product.distinctive.details.title') }) }), (0, jsx_runtime_1.jsx)(Slider_1.Slider, { fullWidth: true, properties: sliderConfig, id: "distinctive-product-details", children: distinctiveProductDetails.map(details => ((0, jsx_runtime_1.jsx)("div", { style: { height: 'auto' }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Card, { fullWidth: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Card.Media, { height: IMAGE_HEIGHT, src: details.asset.imagePath, srcset: (0, ImageUtil_1.getPictureSrcset)(details.asset, imageCdnPrefix), alt: details.heading }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Card.Header, { title: details.heading }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Card.Body, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: details.description }) })] }) }, details.id))) })] }));
};
exports.default = DistinctiveProductDetails;
