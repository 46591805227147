"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const react_1 = require("react");
const formik_1 = require("formik");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const GeneralAPI_1 = require("../../../../api/general/GeneralAPI");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const BuyFormUtil_1 = require("../../../../util/BuyFormUtil");
const CollectionPointChangeHandler = ({ countryId }) => {
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const isFirstRender = (0, react_1.useRef)(true);
    const { values: { postCode, houseNumber }, setFieldError, setFieldValue, } = (0, formik_1.useFormikContext)();
    (0, react_1.useEffect)(() => {
        const validateAdrress = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            if ((0, BuyFormUtil_1.isValidAddress)(postCode, houseNumber, 'NL')) {
                try {
                    const addresses = yield GeneralAPI_1.default.getAddresses(servicesEndpoint, defaultRequestParams, countryId, postCode, houseNumber);
                    if (addresses === null || addresses === void 0 ? void 0 : addresses.length) {
                        const firstAddress = addresses[0];
                        setFieldValue('city', firstAddress.city);
                        setFieldValue('address', firstAddress.address);
                        if (firstAddress.houseNumberAddition) {
                            setFieldValue('houseNumberAddition', firstAddress.houseNumberAddition);
                        }
                    }
                }
                catch (_a) {
                    setFieldError('postCode', t('buy.error.no.results'));
                }
            }
        });
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        validateAdrress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postCode, houseNumber]);
    return null;
};
exports.default = CollectionPointChangeHandler;
