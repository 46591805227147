"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormFieldDateViews = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const React = require("react");
const react_1 = require("react");
const FormConstants_1 = require("../../../../constants/FormConstants");
const DateUtil_1 = require("../../../../util/DateUtil");
var FormFieldDateViews;
(function (FormFieldDateViews) {
    FormFieldDateViews["YEARS"] = "years";
    FormFieldDateViews["MONTHS"] = "months";
    FormFieldDateViews["DAYS"] = "days";
})(FormFieldDateViews || (exports.FormFieldDateViews = FormFieldDateViews = {}));
class DateField extends react_1.PureComponent {
    constructor(props) {
        super(props);
        this.handleChange = e => {
            const { field } = this.props;
            field.onChange(e);
        };
        this.initDatepicker = () => {
            this.datepicker = $(this.datepickerRef.current)
                .datepicker(Object.assign({ inline: false, view: FormFieldDateViews.YEARS, firstDay: 1, dateFormat: 'dd-mm-yyyy', startDate: new Date(1980, 0, 1), language: 'en', onShow: this.onShowDatepicker, onSelect: this.onSelectDatepicker, classes: 'm-datepicker', range: false, multipleDatesSeparator: FormConstants_1.FORM_FIELD_DATE_RANGE_SEPARATOR }, this.props.data))
                .data('datepicker');
        };
        this.onShowDatepicker = () => {
            const { field } = this.props;
            const inputValue = field.value;
            if (inputValue && inputValue !== '') {
                const date = this.formatToMomentDate(inputValue);
                this.datepicker.selectDate(date);
                this.datepicker.view = FormFieldDateViews.DAYS;
            }
            else {
                this.datepicker.view = FormFieldDateViews.YEARS;
            }
        };
        this.onSelectDatepicker = (formattedDate) => {
            const { field } = this.props;
            const eventData = {
                target: {
                    name: field.name,
                    value: formattedDate,
                },
            };
            this.handleChange(eventData);
        };
        this.formatToMomentDate = (inputValue) => {
            const momentDate = (0, DateUtil_1.convertToDayJsDate)(inputValue);
            if (!!momentDate && momentDate.isValid()) {
                return momentDate.toDate();
            }
            return '';
        };
        this.datepickerRef = React.createRef();
    }
    componentDidMount() {
        this.initDatepicker();
    }
    render() {
        const { field, className, data } = this.props;
        let type = 'text';
        if (data && data.inline) {
            type = 'hidden';
        }
        return ((0, jsx_runtime_1.jsx)("input", { type: type, ref: this.datepickerRef, className: className, name: field.name, placeholder: field.placeholder, value: field.value, onBlur: field.onBlur, onChange: this.handleChange }));
    }
}
exports.default = DateField;
