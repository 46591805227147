"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketReminderNotification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const react_1 = require("react");
const ImageUtil_1 = require("../../../util/ImageUtil");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const andes_react_1 = require("@yonderland/andes-react");
const SessionSelector_1 = require("../../../redux/session/selectors/SessionSelector");
const DOMElementsConstants_1 = require("../../../constants/DOMElementsConstants");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const AnalyticsEnums_1 = require("../../../enums/AnalyticsEnums");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const ScreenWidthConstants_1 = require("../../../constants/ScreenWidthConstants");
const BrowserUtil_1 = require("../../../util/BrowserUtil");
const MAX_PRODUCTS = 5;
const POPOVER_OFFSET_DEFAULT = 24;
const POPOVER_OFFSET_COLLAPSED_NAV = 64;
const DESKTOP_IMAGE_WIDTH = 90;
const DESKTOP_IMAGE_HEIGHT = 136;
const USER_AGENT_FIREFOX = 'Firefox';
const overflowOptions = (_floatingElement, overflowValues, translate) => {
    if (!overflowValues || !translate)
        return;
    const isFixedPosition = overflowValues.top >= -POPOVER_OFFSET_DEFAULT;
    return {
        position: 'fixed',
        transform: `translate(${translate.x}px, ${isFixedPosition ? POPOVER_OFFSET_DEFAULT : Math.abs(overflowValues.top)}px)`,
    };
};
const BasketReminderNotification = ({ hrefViewCart, children, }) => {
    const { productImageConfig } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.checkout);
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetIsLoggedIn)());
    const basketAmount = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const firstName = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetFirstName)());
    const [open, setOpen] = (0, react_1.useState)(false);
    const [popoverOffset, setPopoverOffset] = (0, react_1.useState)(POPOVER_OFFSET_DEFAULT);
    const [navigationEl, setNavigationEl] = (0, react_1.useState)(null);
    const dispatch = (0, react_redux_1.useDispatch)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isFirefox = BrowserUtil_1.default.detectBrowser(USER_AGENT_FIREFOX);
    const onViewBasketClick = (event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        dispatch(AnalyticsActions_creators_1.analyticsActions.basketReminderNotificationEvent(AnalyticsEnums_1.EventActions.BASKET_REMINDER_NOTIFICATION_CLICK));
        window.location.href = hrefViewCart;
    };
    (0, react_1.useLayoutEffect)(() => {
        setTimeout(() => {
            setOpen(true);
            dispatch(AnalyticsActions_creators_1.analyticsActions.basketReminderNotificationViewed());
        }, GeneralConstants_1.DELAY.DELAY_300);
    }, []);
    const popoverOffsetHandler = (navigation) => {
        navigation &&
            setPopoverOffset(navigation.classList.contains(DOMElementsConstants_1.NAVIGATION_HORIZONTAL_COLLAPSE)
                ? POPOVER_OFFSET_COLLAPSED_NAV
                : POPOVER_OFFSET_DEFAULT);
    };
    (0, react_1.useEffect)(() => {
        const navigation = document.querySelector(DOMElementsConstants_1.NAVIGATION_HORIZONTAL);
        setNavigationEl(navigation);
        popoverOffsetHandler(navigation);
    }, []);
    (0, cotopaxi_1.useScrollPosition)(() => {
        navigationEl && popoverOffsetHandler(navigationEl);
    }, [navigationEl]);
    const greetingText = () => `${isLoggedIn
        ? t(`${cotopaxi_1.I18N_NAMESPACES.checkout}:basket.reminder.greeting.loggedIn`, { firstName })
        : t(`${cotopaxi_1.I18N_NAMESPACES.checkout}:basket.reminder.greeting.notLoggedIn`)} 👋`;
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Popover, { isOpen: open, hasCloseBtn: true, animated: true, placement: "bottom-end", overflowOptions: isDesktop || isFirefox ? overflowOptions : undefined, offsetOptions: { mainAxis: popoverOffset }, disableTrigger: true, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Popover.Trigger, { children: children }), (0, jsx_runtime_1.jsx)(andes_react_1.Popover.Header, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 0.5, marginBottom: 0.5 } }, desktop: { spacing: { marginBottom: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.M, children: greetingText() }) }) }), (0, jsx_runtime_1.jsxs)(andes_react_1.Popover.Body, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.SUBTLE, children: t(`${cotopaxi_1.I18N_NAMESPACES.checkout}:basket.reminder.message`, { count: basketAmount }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, desktop: { spacing: { marginTop: 2, marginBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: hrefViewCart, variant: cotopaxi_1.LinkVariant.CONTAINER, onClick: onViewBasketClick, children: (0, jsx_runtime_1.jsx)(andes_react_1.Scrollable, { horizontal: true, clipped: true, children: (0, jsx_runtime_1.jsx)("div", { style: { height: isDesktop ? DESKTOP_IMAGE_HEIGHT : cotopaxi_1.ProductImageHeight.THUMB }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.SMALL, inline: true, children: products.slice(0, MAX_PRODUCTS).map(product => {
                                            const imageUrl = (0, ImageUtil_1.getImageSourceSetByProductCode)(product.productCode, product.image, productImageConfig);
                                            return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: imageUrl[common_types_1.ProductImageSizesPortraitEnum.thumb], srcset: {
                                                    [`${ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP}w`]: imageUrl[common_types_1.ProductImageSizesPortraitEnum.bundles],
                                                }, width: isDesktop ? DESKTOP_IMAGE_WIDTH : cotopaxi_1.ProductImageWidth.THUMB, height: isDesktop ? DESKTOP_IMAGE_HEIGHT : cotopaxi_1.ProductImageHeight.THUMB, alt: product.productCode }, product.sku));
                                        }) }) }) }) }) })] }), (0, jsx_runtime_1.jsx)(andes_react_1.Popover.Footer, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t(`${cotopaxi_1.I18N_NAMESPACES.checkout}:basket.reminder.ctaLabel`), iconRight: {
                        name: cotopaxi_1.IconName.ARROW_RIGHT,
                    }, size: isDesktop ? cotopaxi_1.ButtonSize.LARGE : undefined, fill: true, variant: cotopaxi_1.ButtonVariant.BRANDED, onClick: onViewBasketClick }) })] }));
};
exports.BasketReminderNotification = BasketReminderNotification;
