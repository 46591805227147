"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuSearch = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const react_barcode_reader_1 = require("react-barcode-reader");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ArrayUtil_1 = require("../../util/ArrayUtil");
const EnumUtil_1 = require("../../util/EnumUtil");
const SearchUtil_1 = require("../../util/SearchUtil");
const SessionStorageUtil_1 = require("../../util/SessionStorageUtil");
const common_types_1 = require("@as-react/common-types");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const SearchConstants_1 = require("../../constants/SearchConstants");
const MenuSearch = ({ createAdobeAnalyticsSetSearchTermAction, menuSearchItemGrouping, suggestionsParentLink, setSearchValue, searchValue, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.navigation);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const searchInputElementRef = (0, react_1.useRef)(null);
    const searchResultsNothingFoundTranslations = {
        noResults: t(`${i18nConstants_1.I18N_NAMESPACES.navigation}:flyout.no.search.results`),
    };
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const isPopoverOpen = !!anchorEl;
    const [selectedGroupAndItem, setSelectedGroupAndItem] = (0, react_1.useState)();
    const [mobileVirtualKeyboardOn, setMobileVirtualKeyboardOn] = (0, react_1.useState)(true);
    const [savedSearchValue, setSavedSearchValue] = (0, react_1.useState)(searchValue);
    const [suggestedSearchValue, setSuggestedSearchValue] = (0, react_1.useState)('');
    const [isSearchMenuActive, setIsSearchMenuActive] = (0, react_1.useState)(false);
    const hasSearchResults = !!menuSearchItemGrouping && (0, ArrayUtil_1.hasItems)(Object.keys(menuSearchItemGrouping));
    const { root, isShopLanding } = (0, common_components_1.useAemConfig)();
    const listerPageUrl = `${root}lister.html`;
    const onInputChangeHandler = (searchValueInput) => {
        setSelectedGroupAndItem(undefined);
        if (!!searchValueInput && !!(searchInputElementRef === null || searchInputElementRef === void 0 ? void 0 : searchInputElementRef.current)) {
            handlePopoverOpen(null, searchInputElementRef.current, false, true);
        }
        else if (isPopoverOpen) {
            setMobileVirtualKeyboardOn(true);
            handlePopoverClose();
        }
    };
    const onButtonBackClickHandler = () => {
        if (isPopoverOpen) {
            handlePopoverClose();
        }
        setIsSearchMenuActive(false);
    };
    const inputElementSetFocus = () => { var _a; return (_a = searchInputElementRef === null || searchInputElementRef === void 0 ? void 0 : searchInputElementRef.current) === null || _a === void 0 ? void 0 : _a.focus(); };
    const onInputClearClickHandler = () => {
        setSearchValue('');
        setSavedSearchValue('');
        setSelectedGroupAndItem(undefined);
        setSuggestedSearchValue('');
    };
    const getMenuSearchResultBlockVariantItemByValue = (value) => {
        const currentSelectedKey = EnumUtil_1.default.getItemBasedOnValue(common_types_1.MenuSearchResultBlockVariant, value);
        return common_types_1.MenuSearchResultBlockVariant[currentSelectedKey];
    };
    const onButtonSearchClickHandler = () => {
        if (searchValue.length === 0) {
            inputElementSetFocus();
            setIsSearchMenuActive(true);
            return;
        }
        else {
            setIsSearchMenuActive(false);
        }
        if (selectedGroupAndItem) {
            const selectedMenuSearchItem = menuSearchItemGrouping[selectedGroupAndItem.type].items[selectedGroupAndItem.index];
            if (selectedGroupAndItem.type === common_types_1.MenuSearchResultBlockVariant.SUGGESTIONS) {
                createAdobeAnalyticsSetSearchTermAction(selectedMenuSearchItem.name.trim());
                window.location.href = selectedMenuSearchItem.url;
            }
            else {
                window.location.href = (0, SearchUtil_1.appendSearchQueryToUrl)(selectedMenuSearchItem.url, searchValue);
            }
        }
        else {
            createAdobeAnalyticsSetSearchTermAction(searchValue);
            window.location.href = (0, SearchUtil_1.getKeywordsSuggestionsUrl)(suggestionsParentLink, searchValue);
        }
    };
    const updateSuggestedSearchValue = (menuSearchItem) => {
        if (menuSearchItem) {
            const { name, subtitle } = menuSearchItem;
            const _subtitle = subtitle && subtitle.length > 0 ? subtitle.slice(0, 2).join(', ') : '';
            setSuggestedSearchValue(`${name} ${_subtitle}`.trim());
        }
        else {
            setSuggestedSearchValue('');
        }
    };
    const handleArrowDown = () => {
        var _a, _b;
        const currentSelectedTypeAsString = ((_a = selectedGroupAndItem === null || selectedGroupAndItem === void 0 ? void 0 : selectedGroupAndItem.type) === null || _a === void 0 ? void 0 : _a.toString()) || Object.keys(menuSearchItemGrouping)[0];
        const currentSelectedType = getMenuSearchResultBlockVariantItemByValue(currentSelectedTypeAsString);
        const currentSelectedIndex = (selectedGroupAndItem === null || selectedGroupAndItem === void 0 ? void 0 : selectedGroupAndItem.index) !== undefined ? selectedGroupAndItem.index : -1;
        // Check if last within group
        if (currentSelectedIndex + 1 === ((_b = menuSearchItemGrouping[currentSelectedType]) === null || _b === void 0 ? void 0 : _b.items.length)) {
            const availableTypes = Object.keys(menuSearchItemGrouping);
            // Check if not last group
            if (availableTypes.indexOf(currentSelectedType) < availableTypes.length - 1) {
                const newTypeIndex = availableTypes.indexOf(currentSelectedType) + 1;
                const newSelectedType = getMenuSearchResultBlockVariantItemByValue(availableTypes[newTypeIndex]);
                // Move to new group
                setSelectedGroupAndItem({
                    type: newSelectedType,
                    index: 0,
                });
                updateSuggestedSearchValue(menuSearchItemGrouping[newSelectedType].items[0]);
            }
            else {
                const firstSelectedType = getMenuSearchResultBlockVariantItemByValue(Object.keys(menuSearchItemGrouping)[0]);
                setSelectedGroupAndItem({ type: firstSelectedType, index: 0 });
                updateSuggestedSearchValue(menuSearchItemGrouping[firstSelectedType].items[0]);
            }
        }
        else {
            setSelectedGroupAndItem({
                type: currentSelectedType,
                index: currentSelectedIndex + 1,
            });
            updateSuggestedSearchValue(menuSearchItemGrouping[currentSelectedType].items[currentSelectedIndex + 1]);
        }
    };
    const handleArrowUp = () => {
        const availableTypes = Object.keys(menuSearchItemGrouping);
        if (selectedGroupAndItem) {
            const type = (selectedGroupAndItem === null || selectedGroupAndItem === void 0 ? void 0 : selectedGroupAndItem.type) || common_types_1.MenuSearchResultBlockVariant[availableTypes[0]];
            const index = (selectedGroupAndItem === null || selectedGroupAndItem === void 0 ? void 0 : selectedGroupAndItem.index) || 0;
            // Check if first within group
            if (index === 0) {
                // Check if not first in group
                if (availableTypes.indexOf(type) > 0) {
                    const newTypeIndex = availableTypes.indexOf(type) - 1;
                    const newSelectedType = getMenuSearchResultBlockVariantItemByValue(availableTypes[newTypeIndex]);
                    // Move to new group
                    setSelectedGroupAndItem({
                        type: newSelectedType,
                        index: menuSearchItemGrouping[newSelectedType].items.length - 1,
                    });
                    updateSuggestedSearchValue(menuSearchItemGrouping[newSelectedType].items[menuSearchItemGrouping[newSelectedType].items.length - 1]);
                }
                else {
                    setSelectedGroupAndItem(undefined);
                    updateSuggestedSearchValue(undefined);
                }
            }
            else {
                setSelectedGroupAndItem({
                    type,
                    index: index - 1,
                });
                updateSuggestedSearchValue(menuSearchItemGrouping[type].items[index - 1]);
            }
        }
        else {
            // Move to the last group
            const newSelectedType = getMenuSearchResultBlockVariantItemByValue(availableTypes[availableTypes.length - 1]);
            const newSelectedIndex = menuSearchItemGrouping[newSelectedType].items.length - 1;
            setSelectedGroupAndItem({
                type: newSelectedType,
                index: newSelectedIndex,
            });
            updateSuggestedSearchValue(menuSearchItemGrouping[newSelectedType].items[newSelectedIndex]);
        }
    };
    const onInputKeyUpHandler = (e) => {
        const { key } = e;
        switch (key) {
            // ESCAPE
            case 'Esc': // IE/Edge specific value
            case 'Escape':
                !!e && e.preventDefault();
                onInputClearClickHandler();
                break;
            // ENTER
            case 'Enter':
                !!e && e.preventDefault();
                onButtonSearchClickHandler();
                break;
            // ARROW_DOWN
            case 'Down': // IE/Edge specific value
            case 'ArrowDown':
                !!e && e.preventDefault();
                handleArrowDown();
                break;
            // ARROW_UP
            case 'Up': // IE/Edge specific value
            case 'ArrowUp':
                !!e && e.preventDefault();
                handleArrowUp();
                break;
            default:
                break;
        }
    };
    const onFocusHandler = () => {
        setMobileVirtualKeyboardOn(true);
        if (!isSearchMenuActive)
            setIsSearchMenuActive(true);
        if (!!savedSearchValue && !!(searchInputElementRef === null || searchInputElementRef === void 0 ? void 0 : searchInputElementRef.current)) {
            setTimeout(() => {
                handlePopoverOpen(null, searchInputElementRef.current, false, true);
            }, GeneralConstants_1.DELAY.DELAY_300); // CSS animation delay
        }
        else if (isPopoverOpen) {
            handlePopoverClose();
        }
    };
    // On input blur, the popup should get full height because the keyboard is hidden.
    const onBlurHandler = () => setMobileVirtualKeyboardOn(false);
    const searchItemClickHandler = (value) => {
        setSearchValue(`${value} `);
        inputElementSetFocus();
    };
    const onSubmitCatcher = () => false;
    const onBarcodeScanHandler = (barcode) => {
        window.location.href = (0, SearchUtil_1.appendSearchQueryToUrl)(`${listerPageUrl}?input=scanner`, barcode);
    };
    const nothingFoundClick = () => {
        SessionStorageUtil_1.default.setItem(SearchConstants_1.NOTHING_FOUND_SESSION_KEY, '1');
    };
    (0, react_1.useEffect)(() => {
        if (savedSearchValue === '' && hasSearchResults) {
            inputElementSetFocus();
        }
    }, [savedSearchValue]);
    (0, react_1.useEffect)(() => setSavedSearchValue(searchValue), [searchValue]);
    (0, cotopaxi_1.useLockBodyScrollOnTablet)(isSearchMenuActive);
    return ((0, jsx_runtime_1.jsxs)("form", { method: "GET", action: listerPageUrl, onSubmit: onSubmitCatcher, suppressHydrationWarning: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Searchbox, { searchValue: selectedGroupAndItem && suggestedSearchValue ? suggestedSearchValue : searchValue, setSearchValue: setSearchValue, inputElRef: searchInputElementRef, onButtonBackClick: onButtonBackClickHandler, onButtonSearchClick: onButtonSearchClickHandler, onInputBlur: onBlurHandler, onInputChange: onInputChangeHandler, onInputClearClick: onInputClearClickHandler, onInputFocus: onFocusHandler, onInputKeyUp: onInputKeyUpHandler, placeholder: t('flyout.search.placeholder') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, type: cotopaxi_1.PopoverType.STICKY, onClose: handlePopoverClose, mobileVirtualKeyboardOn: mobileVirtualKeyboardOn, hideArrow: true, noAnimation: true, position: cotopaxi_1.PopoverPosition.BOTTOM, children: hasSearchResults ? ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: Object.values(menuSearchItemGrouping).map((menuSearchItemGroup) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.SearchResultBlock, { analyticsAreaName: SearchConstants_1.bannerIds[menuSearchItemGroup.type], highlightValue: searchValue, items: menuSearchItemGroup.items, onItemSuggestionFillClick: searchItemClickHandler, selectedItemIndex: !!selectedGroupAndItem && selectedGroupAndItem.type === menuSearchItemGroup.type
                            ? selectedGroupAndItem.index
                            : undefined, showSuggestionFill: menuSearchItemGroup.enableSuggestionFill && !isDesktop, showTextSubtle: menuSearchItemGroup.showTextSubtle, title: t(SearchConstants_1.blockTitles[menuSearchItemGroup.type]) }, menuSearchItemGroup.type))) })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.SearchResultsNothingFound, { analyticsAreaName: "header-search-suggest-zero-suggest", searchValue: searchValue, url: (0, SearchUtil_1.appendSearchQueryToUrl)(listerPageUrl, searchValue), onClick: nothingFoundClick, translations: searchResultsNothingFoundTranslations })) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MobileNavScrollObserver, { forceNavToBeShown: isSearchMenuActive }), isShopLanding && (0, jsx_runtime_1.jsx)(react_barcode_reader_1.default, { onScan: onBarcodeScanHandler, minLength: 9 })] }));
};
exports.MenuSearch = MenuSearch;
