"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterWizard = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const axios_1 = require("axios");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const FilterWizardModal_1 = require("./FilterWizardModal");
const AnalyticsActions_creators_1 = require("../../../../redux/analytics/actions/AnalyticsActions.creators");
const UrlSelectors_1 = require("../../../../redux/url/selectors/UrlSelectors");
const FilterWizard = ({ filter, filterPopover, filterWizardPath, initialItemsCount, updateFilters, urlFilters, }) => {
    const { mainWebShop, lang: locale, languageAnalytics: anaLang, fictiveWebShop, fredHopperSearchUrl, isShopLanding, } = (0, common_components_1.useAemConfig)();
    const [data, setData] = (0, react_1.useState)(null);
    const [itemsCount, setItemsCount] = (0, react_1.useState)(initialItemsCount);
    const [showFilterWizard, setShowFilterWizard] = (0, react_1.useState)(false);
    const [activeWizardFilterKeys, setActiveWizardFilterKeys] = (0, react_1.useState)([]);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({
        close: () => setShowFilterWizard(false),
    });
    const dispatch = (0, react_redux_1.useDispatch)();
    const searchQuery = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetUrlInformationSearch)());
    const activeStoreId = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetStoreId)());
    const loadFilterWizardData = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const axiosConfig = {
            method: 'GET',
            url: `${filterWizardPath}.model.${filter.filterOn}.json`,
            withCredentials: true,
        };
        try {
            const response = yield axios_1.default.request(axiosConfig);
            return response.data;
        }
        catch (_a) {
            return null;
        }
    });
    const fetchSearchResultsCount = (tmpFilter) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const axiosConfig = {
                method: 'GET',
                params: {
                    anaLang,
                    fictiveWebShop,
                    locale,
                    mainWebShop,
                    query: searchQuery,
                    storeId: activeStoreId,
                    platform: isShopLanding
                        ? common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.shoplanding
                        : common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.public_site,
                },
                url: `${fredHopperSearchUrl}?${tmpFilter}`,
                withCredentials: true,
            };
            const response = yield axios_1.default.request(axiosConfig);
            setItemsCount(response.data.itemCount);
        }
        catch (_b) { }
    });
    const openFilterWizard = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const filterWizardData = yield loadFilterWizardData();
        if (filterWizardData) {
            setData(filterWizardData);
        }
    });
    (0, react_1.useEffect)(() => {
        if (data) {
            handlePopoverOpen(null, null, true);
            dispatch(AnalyticsActions_creators_1.analyticsActions.filterWizardOpened(filter.filterOn));
            setShowFilterWizard(true);
        }
    }, [data]);
    (0, react_1.useEffect)(() => {
        if (initialItemsCount !== itemsCount) {
            setItemsCount(initialItemsCount);
        }
    }, [initialItemsCount]);
    (0, react_1.useEffect)(() => {
        const activeFilterValuesKeys = filter.values.filter(value => value.active).map(value => value.filterKey);
        setActiveWizardFilterKeys(activeFilterValuesKeys);
    }, [filter]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { noBubble: true, onClick: openFilterWizard, icon: {
                        color: cotopaxi_1.IconColor.ACTION,
                        name: cotopaxi_1.IconName.INFORMATION_OUTLINE,
                        size: cotopaxi_1.IconSize.MINOR,
                    }, variant: filterPopover ? cotopaxi_1.ButtonVariant.TRANSPARENT : cotopaxi_1.ButtonVariant.INVERSE_ALT, size: filterPopover ? cotopaxi_1.ButtonSize.SMALL : cotopaxi_1.ButtonSize.MEDIUM, shape: filterPopover ? cotopaxi_1.ButtonShape.RECTANGLE : cotopaxi_1.ButtonShape.PILL }) }), !!data && showFilterWizard && ((0, jsx_runtime_1.jsx)(FilterWizardModal_1.FilterWizardModal, { anchorEl: anchorEl, filter: filter, filterWizardDescription: data.description, filterWizardImage: data.image, filterWizardTitle: data.title, filterWizardValues: data.values, itemsCount: itemsCount, handlePopoverClose: handlePopoverClose, fetchSearchResultsCount: fetchSearchResultsCount, updateFilters: updateFilters, urlFilters: urlFilters, activeWizardFilterKeys: activeWizardFilterKeys, setActiveWizardFilterKeys: setActiveWizardFilterKeys }))] }));
};
exports.FilterWizard = FilterWizard;
