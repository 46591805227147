"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const asNumeral_1 = require("../vendors/asNumeral");
class VoucherUtil {
    static mapBasketAppliedVouchersToAppliedVouchers(vouchers, currency, t) {
        return vouchers.map((voucher) => {
            const amount = {
                amountOriginal: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(voucher.amountOriginal),
                amountUnused: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(voucher.amountUnused),
                amountUsedForThisBasket: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(voucher.amountUsedForThisBasket),
            };
            const formattedAmountUnused = (0, common_utils_1.prefixPriceWithCurrency)(amount.amountUnused, currency);
            return Object.assign(Object.assign({}, voucher), { title: this.getVoucherTitle(voucher, t), info: this.getVoucherInfo(voucher, formattedAmountUnused, t), amount: amount });
        });
    }
    static getVoucherTitle(voucher, t, hideVoucherCode) {
        const displayCode = hideVoucherCode ? '' : `: ${voucher.displayCode}`;
        if (voucher.type === common_types_1.AppliedVoucherTypes.GIFT) {
            return `${t('checkout:checkout.basket.voucher.type.giftvoucher')}${displayCode}`;
        }
        if ((voucher.type === common_types_1.AppliedVoucherTypes.UNIQUE_DISCOUNT || voucher.type === common_types_1.AppliedVoucherTypes.PAYBACK) &&
            voucher.description) {
            return `${voucher.description}${displayCode}`;
        }
        return `${t('checkout:checkout.basket.voucher.type.voucher')}${displayCode}`;
    }
    static getVoucherInfo({ type, singleUse }, amountUnused, t) {
        if (!singleUse) {
            return `${t('checkout:checkout.basket.voucher.multiUse')} ${amountUnused}`;
        }
        if (type === common_types_1.AppliedVoucherTypes.PAYBACK) {
            return `${t('checkout:checkout.basket.voucher.payback')} ${amountUnused}`;
        }
        if (type === common_types_1.AppliedVoucherTypes.CREDIT) {
            return t('checkout:checkout.basket.voucher.restValue', { amount: amountUnused });
        }
        return `${t('checkout:checkout.basket.voucher.singleUse.attention.1')}${amountUnused}${t('checkout:checkout.basket.voucher.singleUse.attention.2')} ${t('checkout:checkout.basket.voucher.singleUse.shop.along')}`;
    }
}
exports.default = VoucherUtil;
