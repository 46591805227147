"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpeningHours = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const DateUtil_1 = require("../../util/DateUtil");
const common_components_1 = require("@as-react/common-components");
const OpeningHour = ({ date, openingHour, closingHour, closed, locale, closedText }) => {
    const { weekday, day, month } = (0, DateUtil_1.formatDateToParts)(date, locale, {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
    });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [weekday, ' ', (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyXS", as: "span", children: `${day} ${month}` })] }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: closed ? closedText : `${openingHour} - ${closingHour}` })] }));
};
const OpeningHours = ({ openingHours }) => {
    const { locale } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.store);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: "auto-1fr", gapX: "10", gapY: "0", children: openingHours.map(openingHour => ((0, jsx_runtime_1.jsx)(OpeningHour, Object.assign({}, openingHour, { locale: locale, closedText: t('store.closed.txt') }), openingHour.date))) }));
};
exports.OpeningHours = OpeningHours;
