"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupCreditCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const SaferpayUtil_1 = require("../../../../util/SaferpayUtil");
const BuyForm_1 = require("../../forms/BuyForm");
const NewCreditCardForm_1 = require("./NewCreditCardForm");
const SavedCreditCardForm_1 = require("./SavedCreditCardForm");
const useABTest_1 = require("../../../../hooks/useABTest");
const PaymentGroupCreditCardContainer_1 = require("../../../../containers/buy/PaymentGroupCreditCardContainer");
const PaymentGroupCreditCard = ({ group, saferpayFieldsReady, isHostedTokenizationSuccess, creditCardSavingLegalMessage, onPreSubmit, onSubmitNewCard, onSubmitSavedCard, showSaveCardBlock = false, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const { saferpayConfig } = (0, common_components_1.useAemConfig)();
    const disableAmexPaymentCookie = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.DISABLE_AMEX_PAYMENT);
    const [isSubmitting, setIsSubmitting] = (0, react_1.useState)(false);
    const [validation, setValidation] = (0, react_1.useState)();
    const [showError, setShowError] = (0, react_1.useState)(false);
    const [saveCreditCardForFutureUse, setSaveCreditCardForFutureUse] = (0, react_1.useState)(false);
    const paymentMethods = group.paymentOptions.map(option => option.name.toLowerCase());
    let groupOgoneAliases = group.ogoneAliases || [];
    if (disableAmexPaymentCookie) {
        groupOgoneAliases = groupOgoneAliases.filter(card => card.brand !== PaymentGroupCreditCardContainer_1.AMEX_BRAND);
    }
    const [selectedSavedCard, setSelectedSavedCard] = (0, react_1.useState)(groupOgoneAliases[0]);
    const [showSavedCardsForm, setShowSavedCardsForm] = (0, react_1.useState)(groupOgoneAliases.length > 0);
    const submitButtonState = () => isHostedTokenizationSuccess ? (isSubmitting ? cotopaxi_1.ButtonState.LOADING : undefined) : cotopaxi_1.ButtonState.DISABLED;
    const handleSaveCreditCardChange = () => setSaveCreditCardForFutureUse(prev => !prev);
    const handleSelectedCardChange = (selectedCardId) => {
        const card = groupOgoneAliases.find(creditCard => creditCard.id === selectedCardId);
        setSelectedSavedCard(card);
    };
    const toggleSavedCardForm = () => {
        setValidation(undefined);
        setShowSavedCardsForm(prev => !prev);
        showError && setShowError(false);
    };
    const checkSaferpayStatusAndInit = () => {
        try {
            window.SaferpayFields.init({
                accessToken: saferpayConfig.fields.accessToken,
                url: saferpayConfig.fields.apiUrl,
                paymentMethods: paymentMethods,
                placeholders: {
                    cardnumber: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.CARDNUMBER}.placeholder`),
                    holdername: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.HOLDERNAME}.placeholder`),
                    expiration: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.EXPIRATION}.placeholder`),
                    cvc: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.CVC}.placeholder`),
                },
                style: SaferpayUtil_1.saferpayFieldStyles,
                onError: ({ message }) => {
                    setShowError(true);
                },
                onValidated: (e) => setValidation(prev => (Object.assign(Object.assign({}, prev), { [e.fieldType]: e }))),
            });
        }
        catch (e) {
            setShowError(true);
        }
    };
    const submitWithSavedCard = () => {
        try {
            onSubmitSavedCard && onSubmitSavedCard(selectedSavedCard);
        }
        catch (err) {
            setIsSubmitting(false);
            setShowError(true);
        }
    };
    const submitWithNewCard = () => {
        window.SaferpayFields.submit({
            onSuccess: ({ token }) => {
                try {
                    onSubmitNewCard && onSubmitNewCard(token);
                }
                catch (err) {
                    setIsSubmitting(false);
                    setShowError(true);
                }
            },
            onError: ({ message }) => {
                setIsSubmitting(false);
            },
        });
    };
    const handleSubmit = () => {
        setIsSubmitting(true);
        setShowError(false);
        onPreSubmit && onPreSubmit();
        if (showSavedCardsForm) {
            submitWithSavedCard();
        }
        else {
            submitWithNewCard();
        }
    };
    (0, react_1.useEffect)(() => {
        if (saferpayFieldsReady && !showSavedCardsForm) {
            checkSaferpayStatusAndInit();
        }
    }, [saferpayFieldsReady, showSavedCardsForm]);
    return ((0, jsx_runtime_1.jsx)(BuyForm_1.BuyForm, { onSubmit: handleSubmit, buttonState: submitButtonState(), checkoutStep: common_types_1.RoutePathname.PAYMENT, errorFeedback: showError ? t('buy.payment.form.error') : undefined, children: showSavedCardsForm ? ((0, jsx_runtime_1.jsx)(SavedCreditCardForm_1.default, { onSelectedCardChange: handleSelectedCardChange, savedCards: groupOgoneAliases, selectedSavedCard: selectedSavedCard, toggleSavedCardForm: toggleSavedCardForm })) : ((0, jsx_runtime_1.jsx)(NewCreditCardForm_1.default, { paymentMethods: paymentMethods, onSaveCreditCardChange: handleSaveCreditCardChange, saveCreditCard: saveCreditCardForFutureUse, showSaveCardBlock: showSaveCardBlock, hasSavedCards: groupOgoneAliases.length > 0, toggleSavedCardForm: toggleSavedCardForm, validation: validation, creditCardSavingLegalMessage: creditCardSavingLegalMessage })) }));
};
exports.PaymentGroupCreditCard = PaymentGroupCreditCard;
