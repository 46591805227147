"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectHQContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const CheckoutDeliveryContext_1 = require("../../../context/CheckoutDeliveryContext");
const CollectHQContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const [state] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { activeDeliveryOption } = state;
    return activeDeliveryOption &&
        activeDeliveryOption.name === common_types_1.DeliveryOptionsEnum.COLLECT_HQ &&
        Boolean(state.errors[activeDeliveryOption.name]) ? ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t(state.errors[activeDeliveryOption.name]), status: "error" })) : null;
};
exports.CollectHQContainer = CollectHQContainer;
