"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPMulti = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const MiniPDPExtensiveImageCarousel_1 = require("./MiniPDPExtensiveImageCarousel");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const MiniPDPDeliveryPromise_1 = require("./MiniPDPDeliveryPromise");
const MiniPDPMultiFooter_1 = require("./MiniPDPMultiFooter");
const MiniPDPMultiProductTileList_1 = require("./MiniPDPMultiProductTileList");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const MiniPDPMulti = ({ isOpen, popoverTitle, title, onClose, products, extensiveCarouselImages, wishlistProducts, deliveryPromise, cdnSettings, totalPrice, isPDP, onWishListToggle, onAddToBasket, fetchDeliveryPromise, fetchTotalPrice, }) => {
    const tilesWrapperRef = (0, react_1.useRef)(null);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfig)();
    const { currency, isShopLanding, mainWebShop, feature_toggles, imageCdnPrefix } = aemConfiguration;
    const { show_sticky_bar_pdp } = feature_toggles;
    const productsCount = products.length;
    const getInitSelectedProductsData = () => products.map(product => {
        const activeColor = (0, common_utils_1.findColor)(product.colours, product.colourId);
        return {
            product,
            colorId: product.colourId,
            sku: (0, common_utils_1.determineSelectedSku)(activeColor),
            quantity: 1,
            isSelected: false,
        };
    });
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isTablet = !isMobile && !isDesktop;
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({ close: onClose });
    const [selectedProductsData, setSelectedProductsData] = (0, react_1.useState)(getInitSelectedProductsData());
    const [sizeSelectErrors, setSizeSelectErrors] = (0, react_1.useState)(new Array(productsCount).fill(false));
    const onProductChange = (index, data) => {
        const newSelectedProductsData = [...selectedProductsData];
        newSelectedProductsData[index] = data;
        setSelectedProductsData(newSelectedProductsData);
    };
    const handleAddToBasket = () => {
        const sizeErrors = selectedProductsData.map(item => (item.isSelected ? !item.sku : false));
        if (sizeErrors.some(error => error)) {
            setSizeSelectErrors(sizeErrors);
            const firstErrorIndex = sizeErrors.findIndex(item => item);
            if (isPDP && !isMobile && tilesWrapperRef.current) {
                const element = tilesWrapperRef.current.querySelectorAll('.as-m-product-tile')[firstErrorIndex];
                const scrollingOffset = ScrollUtil_1.ScrollUtil.getScrollingOffset(isDesktop, isTablet, show_sticky_bar_pdp);
                ScrollUtil_1.ScrollUtil.scrollToPosition(element.offsetTop - scrollingOffset);
            }
            else {
                const elementInPopover = document.querySelectorAll(`.${cotopaxi_1.PopoverSelector.MODAL} .as-m-product-tile`)[firstErrorIndex];
                ScrollUtil_1.ScrollUtil.scrollToElement(elementInPopover, true);
            }
        }
        else {
            onAddToBasket(selectedProductsData.filter(item => item.isSelected));
            handlePopoverClose();
            setSelectedProductsData(getInitSelectedProductsData());
        }
    };
    const handleSetError = (error, index) => {
        setSizeSelectErrors(items => {
            const sizeErrors = [...items];
            sizeErrors[index] = error;
            return sizeErrors;
        });
    };
    (0, react_1.useEffect)(() => {
        if (isOpen) {
            handlePopoverOpen(null, null, true, true);
        }
        else {
            setSizeSelectErrors(new Array(productsCount).fill(false));
            handlePopoverClose();
        }
    }, [isOpen]);
    (0, react_1.useEffect)(() => {
        fetchDeliveryPromise(selectedProductsData.filter(product => product.isSelected && product.colorId && product.sku));
        fetchTotalPrice(selectedProductsData.filter(product => product.isSelected && product.colorId && product.sku));
    }, [selectedProductsData]);
    const numberProducts = selectedProductsData.filter(item => item.isSelected).length;
    const getTitleTranslation = () => {
        if (!isPDP) {
            return t('product.look.title');
        }
        return mainWebShop === common_types_1.MainWebShop.JUTTU ? t('product.look.pdp.title.specific') : t('product.look.pdp.title');
    };
    const popoverMiniPDPMulti = ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, type: cotopaxi_1.PopoverType.EXTENSIVE_DRAWER, onClose: handlePopoverClose, title: popoverTitle || getTitleTranslation(), extensiveDrawerElement: (0, jsx_runtime_1.jsx)(MiniPDPExtensiveImageCarousel_1.MiniPDPExtensiveImageCarousel, { cdnSettings: cdnSettings, imageCdnPrefix: imageCdnPrefix, images: extensiveCarouselImages }), footerElement: (0, jsx_runtime_1.jsx)(MiniPDPMultiFooter_1.default, { numberProducts: numberProducts, totalPrice: numberProducts ? totalPrice : undefined, currency: currency, handleAddToBasket: handleAddToBasket }), noFooterShadow: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: cotopaxi_1.ScrollableListVariant.SHADOW, fullHeight: true, children: (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(MiniPDPMultiProductTileList_1.MiniPDPMultiProductTileList, { tileIdSuffix: "-popover", products: products, selectedProductsData: selectedProductsData, wishlistProducts: wishlistProducts, sizeSelectErrors: sizeSelectErrors, isShopLanding: isShopLanding, isPDP: isPDP, handleSetError: handleSetError, onWishListToggle: onWishListToggle, onProductChange: onProductChange }), !!deliveryPromise && !!numberProducts && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 2, paddingBottom: 5, paddingLeft: 2, paddingRight: 2 } }, tablet: { spacing: { paddingLeft: 4, paddingRight: 4 } }, children: (0, jsx_runtime_1.jsx)(MiniPDPDeliveryPromise_1.MiniPDPDeliveryPromise, { deliveryPromise: deliveryPromise }) }))] }) }) }));
    if (isPDP) {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL], children: popoverMiniPDPMulti }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.TABLET, cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsxs)("div", { ref: tilesWrapperRef, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { marginTop: 5, marginBottom: 2 } }, desktop: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: title || getTitleTranslation() }) }), (0, jsx_runtime_1.jsx)(MiniPDPMultiProductTileList_1.MiniPDPMultiProductTileList, { products: products, selectedProductsData: selectedProductsData, wishlistProducts: wishlistProducts, sizeSelectErrors: sizeSelectErrors, isShopLanding: isShopLanding, isPDP: isPDP, handleSetError: handleSetError, onWishListToggle: onWishListToggle, onProductChange: onProductChange }), !!numberProducts && totalPrice && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { marginBottom: 1, marginTop: 2 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: [t('checkout:checkout.basket.total'), ":"] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Price, { size: cotopaxi_1.PriceSize.LARGE, currency: currency, priceActual: {
                                                formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(totalPrice.totalSellPrice),
                                            }, pricePrevious: totalPrice.totalSellPrice !== totalPrice.totalStandardPrice
                                                ? {
                                                    formattedPrice: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(totalPrice.totalStandardPrice),
                                                }
                                                : undefined })] }) })), !!deliveryPromise && !!numberProducts && (0, jsx_runtime_1.jsx)(MiniPDPDeliveryPromise_1.MiniPDPDeliveryPromise, { deliveryPromise: deliveryPromise }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, iconLeft: { name: cotopaxi_1.IconName.CART, size: cotopaxi_1.IconSize.MINOR }, text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.INHERIT, children: !numberProducts
                                            ? t('product:product.look.no.items')
                                            : numberProducts === 1
                                                ? t('product:product.look.add.item')
                                                : t('product:product.look.add.items', {
                                                    amount: numberProducts,
                                                }) }), variant: cotopaxi_1.ButtonVariant.BRANDED, state: !numberProducts ? cotopaxi_1.ButtonState.DISABLED : undefined, onClick: handleAddToBasket }) })] }) })] }));
    }
    return popoverMiniPDPMulti;
};
exports.MiniPDPMulti = MiniPDPMulti;
