"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListerResultContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const GridTileContainer_1 = require("../../components/lister/GridTileContainer");
const AnalyticsEnums_1 = require("../../enums/AnalyticsEnums");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const CompareActions_creators_1 = require("../../redux/compare/actions/CompareActions.creators");
const CompareSelectors_1 = require("../../redux/compare/selectors/CompareSelectors");
const ListerActions_creators_1 = require("../../redux/lister/actions/ListerActions.creators");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const WishListActions_creators_1 = require("../../redux/wishList/actions/WishListActions.creators");
const MonetateUtil_1 = require("../../util/MonetateUtil");
const ProductListerUtil_1 = require("../../util/ProductListerUtil");
const ListerResultContainer = () => {
    const isClient = (0, cotopaxi_1.useIsClient)();
    const { pageInfo, currency } = (0, common_components_1.useAemConfig)();
    const isLoading = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetLoadingState)());
    const pageSizeInformation = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetUrlInformationPageSize)());
    const productSearchInformation = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetProductSearchInformationState)());
    const contentBlocks = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetContentBlocks)());
    const singleActiveSizeFilterKeyName = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetSingleActiveSizeFilterKeyName)());
    const compareItems = (0, react_redux_1.useSelector)((0, CompareSelectors_1.makeGetCompareItems)());
    const urlInformationSearch = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetUrlInformationSearch)());
    const itemsToShow = (0, ProductListerUtil_1.getListerItemsToShow)(productSearchInformation.items, pageSizeInformation, isClient);
    const dispatch = (0, react_redux_1.useDispatch)();
    const onTileClick = () => dispatch(ListerActions_creators_1.listerActions.createTileClickAction());
    const onWishListToggle = (wishListProduct) => dispatch(WishListActions_creators_1.wishListActions.toggleProduct(wishListProduct));
    const updateCompareList = (compareProduct) => dispatch(CompareActions_creators_1.default.createUpdateOnListerAction(compareProduct));
    (0, react_1.useEffect)(() => {
        dispatch(ListerActions_creators_1.listerActions.createInitializeAction());
        dispatch(AnalyticsActions_creators_1.analyticsActions.productSearchPersonalization({
            personalized: productSearchInformation.personalized,
            traits: productSearchInformation.traits,
        }));
        if (pageInfo.isCategoryPage || pageInfo.isBrandAsCategoryPage || pageInfo.isBrandPage) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.listerResults(productSearchInformation.itemCount));
        }
        dispatch(AnalyticsActions_creators_1.analyticsActions.listerViewProductList(productSearchInformation.items, currency, pageInfo.isSearchPage ? AnalyticsEnums_1.Events.VIEW_SEARCH_RESULTS : AnalyticsEnums_1.Events.VIEW_PRODUCT_LIST, urlInformationSearch));
    }, []);
    (0, react_1.useEffect)(() => {
        if (isClient) {
            MonetateUtil_1.default.pushListerEvent(itemsToShow.filter(item => item.productId));
        }
    }, [isClient]);
    return ((0, jsx_runtime_1.jsx)(GridTileContainer_1.GridTileContainer, { isLoading: isLoading, items: itemsToShow, onTileClick: onTileClick, onWishListToggle: onWishListToggle, updateCompareList: updateCompareList, contentBlocks: contentBlocks, singleActiveSizeFilterKeyName: singleActiveSizeFilterKeyName, compareItems: compareItems }));
};
exports.ListerResultContainer = ListerResultContainer;
