"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllWeekdays = exports.formatTimeStampToDateParts = exports.formatDateToParts = exports.isTomorrow = exports.isToday = exports.convertMillisecondsToUTCDate = exports.convertMillisecondsToDays = exports.getDaysInMonth = exports.calculateDifferenceInMs = exports.formatTimeStampToDateString = exports.convertToDayJsDate = exports.format = exports.formatDate = void 0;
const GeneralConstants_1 = require("../constants/GeneralConstants");
// @ts-expect-error This module is declared with 'export ='
const customParseFormat_1 = require("dayjs/plugin/customParseFormat");
const dayjs = require("dayjs");
const dayjs_1 = require("dayjs");
const duration = require("dayjs/plugin/duration");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
dayjs.extend(duration);
const formatDate = (date, originalFormat, formatBy = GeneralConstants_1.DateFormat.YYYY_MM_DD.value) => {
    const finalDate = replaceAllDashesBySlashes(date);
    switch (originalFormat) {
        case GeneralConstants_1.DateFormat.DD_MM_YYYY.value:
            return (0, exports.format)(change(finalDate), formatBy);
        default:
            return (0, exports.format)(finalDate, formatBy);
    }
};
exports.formatDate = formatDate;
const format = (date, formatBy) => {
    const d = new Date(date);
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = d.getFullYear();
    switch (formatBy) {
        case GeneralConstants_1.DateFormat.DD_MM_YYYY.value:
            month = (month.length < 2 ? '0' : '') + month;
            day = (day.length < 2 ? '0' : '') + day;
            return [day, month, year].join('-');
        case GeneralConstants_1.DateFormat.YYYY_MM_DD.value:
            month = (month.length < 2 ? '0' : '') + month;
            day = (day.length < 2 ? '0' : '') + day;
            return [year, month, day].join('-');
        default:
            return date;
    }
};
exports.format = format;
const convertToDayJsDate = (inputValue) => {
    const dayJsInstance = (0, dayjs_1.extend)(customParseFormat_1.default);
    if (typeof inputValue === 'string') {
        const possibleDateFormats = ['DD-MM-YYYY', 'YYYY-MM-DD', 'DDMMYYYY'];
        let parsedDate = null;
        possibleDateFormats.forEach(formatDateParser => {
            if (!(!!parsedDate && parsedDate.isValid())) {
                // @ts-expect-error Type 'Dayjs' has no call signatures.
                parsedDate = dayJsInstance(inputValue, formatDateParser);
            }
        });
        return parsedDate;
    }
    return new dayjs_1.Dayjs(inputValue);
};
exports.convertToDayJsDate = convertToDayJsDate;
const formatTimeStampToDateString = (timeStamp, locale, format = {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
}) => {
    locale = locale.replace('_', '-');
    if (window.Intl) {
        return new Intl.DateTimeFormat(locale, format).format(timeStamp);
    }
    else {
        return new Date(timeStamp).toLocaleDateString(locale, format);
    }
};
exports.formatTimeStampToDateString = formatTimeStampToDateString;
const calculateDifferenceInMs = (from, till) => {
    const dateFrom = new Date(from);
    const dateTill = new Date(till);
    return dateFrom.getTime() - dateTill.getTime();
};
exports.calculateDifferenceInMs = calculateDifferenceInMs;
const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
};
exports.getDaysInMonth = getDaysInMonth;
const convertMillisecondsToDays = (milliseconds) => {
    // eslint-disable-next-line import/namespace
    return dayjs.duration(milliseconds).asDays();
};
exports.convertMillisecondsToDays = convertMillisecondsToDays;
const convertMillisecondsToUTCDate = (milliseconds, format) => {
    return dayjs(milliseconds).utc().format(format);
};
exports.convertMillisecondsToUTCDate = convertMillisecondsToUTCDate;
const isToday = (date) => {
    const currentDate = new Date();
    const targetDate = new Date(date);
    return (currentDate.getDate() === targetDate.getDate() &&
        currentDate.getMonth() === targetDate.getMonth() &&
        currentDate.getFullYear() === targetDate.getFullYear());
};
exports.isToday = isToday;
const isTomorrow = (date) => {
    const tomorrow = dayjs().add(1, 'day');
    return dayjs(date).isSame(tomorrow, 'day');
};
exports.isTomorrow = isTomorrow;
const change = (date) => {
    const dateParts = date.split('/');
    return `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
};
const replaceAllDashesBySlashes = (value) => {
    return value.replace(/-/g, '/');
};
const formatDateToParts = (date, locale, format) => (0, exports.formatTimeStampToDateParts)(new Date(date).getTime(), locale, format);
exports.formatDateToParts = formatDateToParts;
const formatTimeStampToDateParts = (timeStamp, locale, format = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
}) => {
    const date = new Date(timeStamp);
    const formattedDateParts = {};
    locale = locale.replace('_', '-');
    Object.keys(format).forEach(key => {
        formattedDateParts[key] = date.toLocaleDateString(locale, {
            [key]: format[key],
        });
    });
    return formattedDateParts;
};
exports.formatTimeStampToDateParts = formatTimeStampToDateParts;
const getAllWeekdays = (date, locale, format = {
    weekday: 'long',
}) => {
    const weekdays = [];
    for (let i = 0; i < 7; i++) {
        weekdays.push(new Intl.DateTimeFormat(locale, format).format(date));
        date.setDate(date.getDate() + 1);
    }
    return weekdays;
};
exports.getAllWeekdays = getAllWeekdays;
