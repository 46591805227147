"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
const LocalStorageUtil_1 = require("../../util/LocalStorageUtil");
const common_components_1 = require("@as-react/common-components");
const Breadcrumb = ({ crumbs = [], isBreadcrumbBackButtonEnabled }) => {
    const { pageInfo } = (0, common_components_1.useAemConfig)();
    const { isPDPPage } = pageInfo;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        const currentUrl = (_a = crumbs[crumbs.length - 1]) === null || _a === void 0 ? void 0 : _a.url;
        const penultimate = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.LAST_VISITED_URL_KEY);
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.LAST_VISITED_URL_KEY, currentUrl);
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.PENULTIMATE_VISITED_URL_KEY, penultimate);
        if (crumbs.length > 1) {
            LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.PENULT_CRUMB_URL_KEY, (_b = crumbs[crumbs.length - 2]) === null || _b === void 0 ? void 0 : _b.url);
        }
    }, [crumbs]);
    if (!crumbs || crumbs.length === 0)
        return null;
    const breadcrumbTranslations = {
        backTo: t(`${i18nConstants_1.I18N_NAMESPACES.general}:breadcrumb.back.to`),
    };
    const goToPreviousPage = (e) => {
        e.preventDefault();
        const { history, location } = window;
        const destinationUrl = crumbs.length >= 2 && crumbs[crumbs.length - 2].url;
        if (LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.PENULTIMATE_VISITED_URL_KEY) === destinationUrl ||
            location.pathname.includes('product-compare')) {
            history.back();
        }
        else {
            location.href = destinationUrl.toString();
        }
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Breadcrumb, { analyticsAreaName: "navigation-breadcrumb", crumbs: crumbs, isBreadcrumbBackButtonEnabled: isBreadcrumbBackButtonEnabled, goToPreviousPage: goToPreviousPage, isPDP: isPDPPage, translations: breadcrumbTranslations }));
};
exports.default = Breadcrumb;
