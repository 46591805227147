"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const useProductTileHelpers_1 = require("../../hooks/useProductTileHelpers");
const MonetateSelectors_1 = require("../../redux/monetate/selectors/MonetateSelectors");
const MonetateActions_creators_1 = require("../../redux/monetate/actions/MonetateActions.creators");
const BasketSelector_1 = require("../../redux/basket/selectors/BasketSelector");
const ProductGroup_1 = require("../../components/product-group/ProductGroup");
const ActionEnums_1 = require("../../enums/ActionEnums");
const ProductGroupMonetateContainer = ({ basketPopup, collapsed = false, collapsible, containerId, deliveryPromiseExplanations, inBasketOverview, numberOfTiles, onAddToBasketCallback, showComparison, tileFormat, title, removeOuterSpacing, }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { handleWishlistToggle, handleUpdateCompareList, handleAddToBasket, compareItems } = (0, useProductTileHelpers_1.useProductTileHelpers)();
    const recommendations = (0, react_redux_1.useSelector)((0, MonetateSelectors_1.makeGetMonetateRecommendations)(containerId));
    const products = (0, react_redux_1.useSelector)((0, MonetateSelectors_1.makeGetMonetateProducts)(containerId));
    const basketStatus = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketStatusState)());
    const getDeliveryPromise = (payload) => dispatch(MonetateActions_creators_1.monetateActions.getDeliveryPromise(Object.assign(Object.assign({}, payload), { productGroupId: containerId })));
    (0, react_1.useEffect)(() => {
        if (recommendations === null || recommendations === void 0 ? void 0 : recommendations.length) {
            dispatch(MonetateActions_creators_1.monetateActions.fetchMonetateProducts({ actionId: containerId }));
        }
    }, [recommendations]);
    if (!(products === null || products === void 0 ? void 0 : products.length))
        return null;
    return ((0, jsx_runtime_1.jsx)(ProductGroup_1.ProductGroup, { addToBasket: (...args) => handleAddToBasket(...args, basketPopup
            ? ActionEnums_1.ProductAddToBasketLocations.BASKET_POPUP_MONETATE
            : ActionEnums_1.ProductAddToBasketLocations.PRODUCT_GROUP_MONETATE), analyticsAreaName: "monetate-recommendation", basketPopup: basketPopup, basketStatus: basketStatus, collapsed: collapsed, collapsible: collapsible, compareItems: compareItems, deliveryPromiseExplanations: deliveryPromiseExplanations, getDeliveryPromise: getDeliveryPromise, hideBasketPopup: basketPopup || inBasketOverview, inBasketOverview: inBasketOverview, numberOfTiles: numberOfTiles, onAddToBasketCallback: onAddToBasketCallback, onWishListToggle: handleWishlistToggle, productGroupId: `product-group-monetate-${containerId}`, products: products, productTileFormat: tileFormat, recommendedProduct: true, showComparison: showComparison, title: title, updateCompareList: handleUpdateCompareList, analyticsProductGroupName: `mon_${containerId}`, monetateId: containerId, removeOuterSpacing: removeOuterSpacing }));
};
exports.default = ProductGroupMonetateContainer;
