"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const ColorSwatches_1 = require("../components/product-detail/color-swatches/ColorSwatches");
const ProductActions_1 = require("../redux/product/actions/ProductActions");
const common_components_1 = require("@as-react/common-components");
const ColorSwatchesContainer = ({ enableShopTheLook = false }) => {
    const { isShopLanding, feature_toggles: { categorized_product_features }, pageInfo: { isPDPPage }, productImageConfig, productSwatchConfig, } = (0, common_components_1.useAemConfig)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const setProductColor = (colourId) => {
        dispatch(ProductActions_1.productActions.createSetColorAction(colourId));
        if (isPDPPage && categorized_product_features) {
            dispatch(ProductActions_1.productActions.createGetTechnicalCategories(colourId));
        }
        if (enableShopTheLook) {
            dispatch(ProductActions_1.productActions.createGetLinkedProducts(colourId));
        }
    };
    const colors = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetAvailableColors)());
    const productCode = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)()).productCode;
    const selectedColorId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedColorId)());
    return ((0, jsx_runtime_1.jsx)(ColorSwatches_1.default, { colors: colors, productCdnDomains: productImageConfig.cdnDomains, productCode: productCode, selectedColorId: selectedColorId, setProductColor: setProductColor, swatchInformation: productSwatchConfig, isShopLanding: isShopLanding }));
};
exports.default = ColorSwatchesContainer;
