"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContinueShoppingCTA = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const NavigationUtil_1 = require("../../../util/NavigationUtil");
const ContinueShoppingCTA = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const { rootWithLanguageSuffix } = (0, common_components_1.useAemConfig)();
    const isMobile = (0, andes_react_1.useMediaQueryMatchesOnMobile)();
    return ((0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: isMobile, theme: "branded", variant: "secondary", dataTestId: "continue_shopping", text: t('account.payback.continue.shopping'), onClick: () => {
            window.location.href = (0, NavigationUtil_1.getHomepageUrl)(rootWithLanguageSuffix);
        }, iconLeft: { source: andes_icons_1.ArrowLeftIcon, size: 'sm' } }));
};
exports.ContinueShoppingCTA = ContinueShoppingCTA;
