"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewMessages = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const UnavailableProductsMessage_1 = require("./UnavailableProductsMessage");
const ApplePayMessage_1 = require("./ApplePayMessage");
const BasketMergedMessage_1 = require("./BasketMergedMessage");
const PaybackVoucherMessage_1 = require("./PaybackVoucherMessage");
const BuyAndGetMessages_1 = require("./BuyAndGetMessages");
const DynamicMessage_1 = require("./DynamicMessage");
const PromotionMessage_1 = require("./PromotionMessage");
const BasketOverviewMessages = () => ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsx)(UnavailableProductsMessage_1.UnavailableProductsMessage, {}), (0, jsx_runtime_1.jsx)(ApplePayMessage_1.ApplePayMessage, {}), (0, jsx_runtime_1.jsx)(BasketMergedMessage_1.BasketMergedMessage, {}), (0, jsx_runtime_1.jsx)(PaybackVoucherMessage_1.PaybackVoucherMessage, {}), (0, jsx_runtime_1.jsx)(BuyAndGetMessages_1.BuyAndGetMessages, {}), (0, jsx_runtime_1.jsx)(DynamicMessage_1.DynamicMessage, {}), (0, jsx_runtime_1.jsx)(PromotionMessage_1.PromotionMessage, {})] }));
exports.BasketOverviewMessages = BasketOverviewMessages;
