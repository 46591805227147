"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ActionEnums_1 = require("../../../enums/ActionEnums");
const checkoutActions = {
    createInitAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.INIT,
            payload,
        };
    },
    createInitSetAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.INIT_SET,
            payload,
        };
    },
    createErrorAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.ERROR,
        };
    },
    createLoginAnalyticsAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.LOGIN_ANALYTICS,
        };
    },
    createAnalyticsSuccessAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.ANALYTICS_SUCCESS,
            payload,
        };
    },
    createAnalyticsConfirmationPageAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.ANALYTICS_CONFIRMATION_PAGE_STEP,
        };
    },
    createAnalyticsMergeConfirmationPageAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.ANALYTICS_MERGE_CONFIRMATION_PAGE,
            payload,
        };
    },
    createGetCustomerDetailsAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_CUSTOMER_DETAILS,
        };
    },
    createSetCustomerDetailsAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_CUSTOMER_DETAILS,
            payload,
        };
    },
    createGetFastCheckoutOverviewAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_FAST_CHECKOUT_OVERVIEW,
        };
    },
    createSetFastCheckoutOverviewAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_FAST_CHECKOUT_OVERVIEW,
            payload,
        };
    },
    createSubmitFastCheckoutAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SUBMIT_FAST_CHECKOUT,
            payload,
        };
    },
    createGetDeliveryCountriesAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_DELIVERY_COUNTRIES,
        };
    },
    createSetDeliveryCountriesAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_COUNTRIES,
            payload,
        };
    },
    createSetDeliveryAddressStateAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_ADDRESS_STATE,
            payload,
        };
    },
    createSetDeliveryUSStatesAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_US_STATES,
            payload,
        };
    },
    createSetDeliveryDatesAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_DATES,
            payload,
        };
    },
    createGetDeliveryInfoAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_DELIVERY_INFO,
            payload,
        };
    },
    createSetDeliveryInfoAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_INFO,
            payload,
        };
    },
    createChangeDeliveryCountryAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.CHANGE_DELIVERY_COUNTRY,
            payload,
        };
    },
    createSetDeliveryAddressAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_INFO_ADDRESS,
            payload,
        };
    },
    createSaveDeliveryInfoAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SAVE_DELIVERY_INFO,
            payload,
        };
    },
    createSaveDeliveryInfoSuccessAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SAVE_DELIVERY_INFO_SUCCESS,
            payload,
        };
    },
    createSaveDeliveryInfoFailAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SAVE_DELIVERY_INFO_FAIL,
            payload,
        };
    },
    /** @deprecated Use the useSavePaymentOption hook instead */
    createSavePaymentOptionAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SAVE_PAYMENT_OPTION,
            payload,
        };
    },
    createSetSelectedPaymentOptionAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_SELECTED_PAYMENT_OPTION,
            payload,
        };
    },
    createSavePaymentOptionSuccessAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SAVE_PAYMENT_OPTION_SUCCESS,
            payload,
        };
    },
    createSetPaymentOptionErrorAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_PAYMENT_OPTION_ERROR,
            payload,
        };
    },
    createChangePaymentOptionAction({ option, group, }) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.CHANGE_PAYMENT_OPTION,
            payload: {
                option,
                group,
            },
        };
    },
    createGetCustomerTitlesAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_CUSTOMER_TITLES,
        };
    },
    createSetCustomerTitlesAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_CUSTOMER_TITLES,
            payload,
        };
    },
    createChangeDeliveryOptionAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.CHANGE_DELIVERY_OPTION,
            payload,
        };
    },
    createSetDeliveryOptionAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_OPTION,
            payload,
        };
    },
    createChangeDeliveryStoreAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.CHANGE_DELIVERY_STORE,
            payload,
        };
    },
    createSetDeliveryStoreAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_DELIVERY_STORE,
            payload,
        };
    },
    createSetPaymentInfoAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_PAYMENT_INFO,
            payload,
        };
    },
    createGetPaymentInfoAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_PAYMENT_INFO,
        };
    },
    createSetPaymentAddressStateAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_PAYMENT_ADDRESS_STATE,
            payload,
        };
    },
    createSetStoreDetailsAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_STORE_DETAILS,
            payload,
        };
    },
    createGetStoreDetailsAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_STORE_DETAILS,
            payload,
        };
    },
    createSetPaymentOptionSuccessAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_PAYMENT_OPTION_SUCCESS,
            payload,
        };
    },
    createVoucherChangesAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.VOUCHER_CHANGES,
        };
    },
    createSetKlarnaAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_KLARNA,
            payload,
        };
    },
    createSetSaferpayFieldsReadyAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_SAFERPAY_FIELDS_READY,
            payload,
        };
    },
    createGetPickupPointsBySearchAction(search) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_PICKUP_POINTS_BY_SEARCH,
            payload: search,
        };
    },
    createGetPickupPointsByLocationAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_PICKUP_POINTS_BY_LOCATION,
            payload,
        };
    },
    createSetPickupPointsAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_PICKUP_POINTS,
            payload,
        };
    },
    createResetPickupPointsAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.RESET_PICKUP_POINTS,
        };
    },
    createSetActivePickupPointIdAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_ACTIVE_PICKUP_POINT_ID,
            payload,
        };
    },
    createSetPickupPointLocationFieldErrorAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_PICK_UP_POINT_LOCATION_FIELD_ERROR,
            payload,
        };
    },
    createSetShowAllPickupPointsAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_SHOW_ALL_PICKUP_POINTS,
            payload,
        };
    },
    createChangeCollectionPointViewAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.CHANGE_COLLECTION_POINT_VIEW,
            payload,
        };
    },
    createSetLocationErrorCollectionPointAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_LOCATION_ERROR_COLLECTION_POINT,
            payload,
        };
    },
    createGetConfirmationOrderAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_CONFIRMATION_ORDER,
        };
    },
    createSetConfirmationOrderAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_CONFIRMATION_ORDER,
            payload,
        };
    },
    createSetConfirmationIsCollectStoreAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_CONFIRMATION_IS_COLLECT_STORE,
            payload,
        };
    },
    createSetConfirmationOrderErrorAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_CONFIRMATION_ORDER_ERROR,
            payload,
        };
    },
    createSetNewsletterPreferencesAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_NEWSLETTER_PREFERENCES,
            payload,
        };
    },
    createGetStoresBySearchAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_STORES_BY_SEARCH,
            payload,
        };
    },
    createGetStoresByLocationAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_STORES_BY_LOCATION,
            payload,
        };
    },
    createSetStoresAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_STORES,
            payload,
        };
    },
    createGetStoresFailAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.GET_STORES_FAIL,
        };
    },
    createSetLocationErrorCollectStoreAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_LOCATION_ERROR_COLLECT_STORE,
            payload,
        };
    },
    createSetActiveStoreIdAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_ACTIVE_STORE_ID,
            payload,
        };
    },
    createSetShowAllStoresAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_SHOW_ALL_STORES,
            payload,
        };
    },
    createChangeCollectStoreViewAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.CHANGE_COLLECT_STORE_VIEW,
            payload,
        };
    },
    createResetPaymentStateAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.RESET_PAYMENT_STATE,
        };
    },
    createShoplandingPaymentAction(shopNumber) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SHOPLANDING_PAYMENT,
            payload: shopNumber,
        };
    },
    createChangeCollectStoreOnlyInStockAction() {
        return {
            type: ActionEnums_1.CheckoutActionTypes.CHANGE_COLLECT_STORE_ONLY_IN_STOCK,
        };
    },
    createSetHasRegistrationAction(hasRegistration) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_HAS_REGISTRATION,
            payload: hasRegistration,
        };
    },
    createSetIsPaymentOptionUpdatingAction(payload) {
        return {
            type: ActionEnums_1.CheckoutActionTypes.SET_IS_PAYMENT_OPTION_UPDATING,
            payload,
        };
    },
};
exports.default = checkoutActions;
