"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsletterSubscription = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const CustomerApi_1 = require("../../../api/customer/CustomerApi");
const NewsletterSubscription = ({ order, newsletter, defaultNewsletterSubscription, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfig)();
    const { defaultRequestParams, servicesEndpoint } = aemConfiguration;
    const [subscription, setSubscription] = (0, react_1.useState)(defaultNewsletterSubscription);
    const [subscriptionUpdated, setSubscriptionUpdated] = (0, react_1.useState)(false);
    const updateNewsletterSubscription = (subscriptionValue) => {
        const profile_details = {
            email_newsletter: subscriptionValue,
        };
        setSubscriptionUpdated(false);
        try {
            CustomerApi_1.default.updatePreferences(servicesEndpoint, defaultRequestParams, profile_details, order.contactPerson);
            setSubscriptionUpdated(true);
        }
        catch (_a) { }
    };
    const toggleNewsletter = (e) => {
        const { checked } = e.target;
        setSubscription(checked);
        updateNewsletterSubscription(checked);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.CheckboxWithLabel, { id: "newsletterSubscription", name: "newsletterSubscription", value: "newsletterSubscription", defaultChecked: defaultNewsletterSubscription, labelChildren: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { type: cotopaxi_1.TextType.CONTENT, children: newsletter.checkboxText }), onChange: toggleNewsletter }), subscription && subscriptionUpdated && (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: newsletter.subscribedMessage }), !subscription && subscriptionUpdated && (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: newsletter.unsubscribedMessage })] }));
};
exports.NewsletterSubscription = NewsletterSubscription;
