"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateProductQuantitySaga = exports.updateProductQuantity = void 0;
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const BasketActions_creators_1 = require("../../basket/actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const BasketSagas_1 = require("./BasketSagas");
const CheckStockReservationSaga_1 = require("./CheckStockReservationSaga");
const common_types_1 = require("@as-react/common-types");
function* updateProductQuantity(action) {
    var _a;
    try {
        const { defaultRequestParams, servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateItemsErrorStateAction(null));
        const updateStatusObj = yield (0, effects_1.call)(BasketAPI_1.default.updateQuantity, action.payload.product, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateSuccessAction(action.payload.product, updateStatusObj.quantity));
    }
    catch (e) {
        const { response } = e;
        yield (0, effects_1.all)([(0, effects_1.call)(BasketSagas_1.getBasicBasket), (0, effects_1.call)(BasketSagas_1.getBasket), (0, effects_1.call)(CheckStockReservationSaga_1.checkStockReservation)]);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateItemsErrorStateAction(common_types_1.BasketCartItemError.ADD_FAILED, action.payload.product));
        if (response.status === common_types_1.HTTPStatusCode.BadRequest && ((_a = response.data) === null || _a === void 0 ? void 0 : _a.availableQuantity)) {
            yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateSuccessAction(action.payload.product, response.data.availableQuantity));
        }
    }
}
exports.updateProductQuantity = updateProductQuantity;
function* updateProductQuantitySaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.BasketActionTypes.UPDATE_QUANTITY_CUSTOM, updateProductQuantity);
}
exports.updateProductQuantitySaga = updateProductQuantitySaga;
