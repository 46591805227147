"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const CustomerActions_creators_1 = require("../../redux/customer/actions/CustomerActions.creators");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const CustomerSelector_1 = require("../../redux/customer/selectors/CustomerSelector");
const i18nConstants_1 = require("../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const CustomerAddressEdit_1 = require("../../components/account/delivery-addresses/CustomerAddressEdit");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const common_components_1 = require("@as-react/common-components");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const AddressUtil_1 = require("../../util/AddressUtil");
const CountryUtil_1 = require("../../util/CountryUtil");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const AccountConstant_1 = require("../../constants/AccountConstant");
const DATA_QA_BILLING_ADDRESS = 'billing_adr_name';
const DATA_QA_DELIVERY_ADDRESS = 'delivery_adr_name';
const AccountDeliveryAddressesContainer = ({ editProfileLink, accountAddressTitle, deliveryAddressTitle, }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const { defaultRequestParams, servicesEndpoint, lang, fictiveWebShop } = (0, common_components_1.useAemConfig)();
    const defaultCountryCode = (0, CountryUtil_1.getCountryByFictiveWebShop)(fictiveWebShop);
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const billingAddress = (0, react_redux_1.useSelector)((0, CustomerSelector_1.makeGetCustomerBillingAddress)());
    const deliveryAddresses = (0, react_redux_1.useSelector)((0, CustomerSelector_1.makeGetCustomerDeliveryAddresses)());
    const [isEditMode, setIsEditMode] = (0, react_1.useState)({});
    const [customerTitles, setCustomerTitles] = (0, react_1.useState)([]);
    const [showNewAddressForm, setShowNewAddressForm] = (0, react_1.useState)(false);
    const [countries, setCountries] = (0, react_1.useState)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const orderedDeliveryAddresses = (deliveryAddresses === null || deliveryAddresses === void 0 ? void 0 : deliveryAddresses.length) && deliveryAddresses.length > 0
        ? [billingAddress].concat(deliveryAddresses.filter(address => address.deliveryAddressId !== billingAddress.deliveryAddressId))
        : billingAddress
            ? [billingAddress]
            : null;
    const editProfileLinkWithQuery = `${editProfileLink}${AccountConstant_1.ACCOUNT_PROFILE_PAGE_LINK_EDIT_FORM_QUERY}`;
    const getAddressFields = (address, country) => (0, AddressUtil_1.getCustomerAddress)(address, country, t);
    const showAddNewAddressButton = billingAddress ? !!getAddressFields(billingAddress, billingAddress.country) : false;
    const handleRemoveAddress = (deliveryAddress) => () => {
        dispatch(CustomerActions_creators_1.default.deleteDeliveryAddress(deliveryAddress));
    };
    const toggleDeliveryAddressEditMode = (deliveryAddress) => () => setIsEditMode(Object.assign(Object.assign({}, isEditMode), { [`${deliveryAddress.deliveryAddressId}`]: !isEditMode[`${deliveryAddress.deliveryAddressId}`] }));
    const toggleShowNewAddressForm = () => setShowNewAddressForm(!showNewAddressForm);
    const renderAddress = (address, isBillingAddress) => {
        const mappedAddress = Object.assign(Object.assign({}, address), { country: address.countryCode });
        const addressFields = getAddressFields(mappedAddress, address.country);
        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { dataAttributes: { 'data-qa': isBillingAddress ? DATA_QA_BILLING_ADDRESS : DATA_QA_DELIVERY_ADDRESS }, mobileBleed: isBillingAddress, children: [address.defaultAddress && !isBillingAddress && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, emphasized: true, children: t('delivery.default') })), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: [address.firstName, " ", address.middleName, " ", address.lastName, !!address.companyName && (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, children: ` (${address.companyName})` })] }), addressFields ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: addressFields })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: t('delivery.add.address.details') }) }))] })] }));
    };
    const renderEditButton = (deliveryAddress) => {
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, iconLeft: { name: cotopaxi_1.IconName.PENCIL, size: cotopaxi_1.IconSize.MEDIUM }, text: t('account.details.edit'), size: cotopaxi_1.ButtonSize.SMALL, onClick: toggleDeliveryAddressEditMode(deliveryAddress), dataQA: "delivery_adr_edit" }));
    };
    const renderEditDeleteButtonGroup = (deliveryAddress) => {
        if (isEditMode[`${deliveryAddress.deliveryAddressId}`])
            return null;
        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.LEFT, gutter: cotopaxi_1.GroupGutter.LARGE, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, iconLeft: { name: cotopaxi_1.IconName.PENCIL, size: cotopaxi_1.IconSize.MEDIUM }, text: t('account.details.edit'), size: cotopaxi_1.ButtonSize.SMALL, onClick: toggleDeliveryAddressEditMode(deliveryAddress), dataQA: "delivery_adr_edit" }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, iconLeft: { name: cotopaxi_1.IconName.TRASH, size: cotopaxi_1.IconSize.MINOR }, text: t('account.address.delete'), size: cotopaxi_1.ButtonSize.SMALL, onClick: handleRemoveAddress(deliveryAddress), dataQA: "delivery_adr_delete" })] }));
    };
    const handleCancelClick = (deliveryAddressId) => deliveryAddressId
        ? setIsEditMode(Object.assign(Object.assign({}, isEditMode), { [`${deliveryAddressId}`]: false }))
        : null;
    (0, react_1.useEffect)(() => {
        const getTitles = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield CustomerApi_1.default.getTitles(servicesEndpoint, defaultRequestParams, lang);
                const sortedTitles = response.sort((t1, t2) => t1.sortOrder - t2.sortOrder);
                setCustomerTitles(sortedTitles);
            }
            catch (_a) { }
        });
        const getCountries = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            let tmpCountries;
            try {
                tmpCountries = (0, common_utils_1.getCustomWindowObject)(GeneralConstants_1.CUSTOM_OBJECT_COUNTRIES);
                if ((0, common_utils_1.isNullOrUndefined)(tmpCountries)) {
                    tmpCountries = yield CustomerApi_1.default.getCountries(servicesEndpoint, defaultRequestParams);
                }
                setCountries(tmpCountries);
            }
            catch (_b) { }
        });
        if (loggedIn) {
            dispatch(CustomerActions_creators_1.default.getDeliveryAddresses());
            getTitles();
            getCountries();
        }
    }, [loggedIn]);
    (0, react_1.useEffect)(() => {
        const mappedItems = {};
        deliveryAddresses === null || deliveryAddresses === void 0 ? void 0 : deliveryAddresses.forEach(item => {
            mappedItems[item.deliveryAddressId] = false;
        });
        setIsEditMode(mappedItems);
    }, [deliveryAddresses]);
    if (!loggedIn && !billingAddress && (deliveryAddresses === null || deliveryAddresses === void 0 ? void 0 : deliveryAddresses.length) && deliveryAddresses.length < 1)
        return null;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { gutter: false, desktop: { span: 8 }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, mobileBleed: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: accountAddressTitle.show ? cotopaxi_1.GroupAlign.SPACE_BETWEEN : cotopaxi_1.GroupAlign.RIGHT, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [accountAddressTitle.show && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SMALL_TITLE, children: accountAddressTitle.value || t('delivery.my-account-address') })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: editProfileLinkWithQuery, dataQA: "billing_adr_edit", iconStart: cotopaxi_1.IconName.PENCIL, size: cotopaxi_1.LinkSize.SMALL, children: t('account.details.edit') })] }) }), billingAddress && renderAddress(billingAddress, true), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { margin: cotopaxi_1.DividerMargin.XLARGE, mobileBleed: true }), deliveryAddressTitle.show && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, mobileBleed: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SMALL_TITLE, children: deliveryAddressTitle.value || t('delivery.my-delivery-address') }) })), (orderedDeliveryAddresses === null || orderedDeliveryAddresses === void 0 ? void 0 : orderedDeliveryAddresses.length) &&
                    orderedDeliveryAddresses.length > 0 &&
                    orderedDeliveryAddresses.map((deliveryAddress, index) => {
                        const showForm = isEditMode[deliveryAddress.deliveryAddressId];
                        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobileBleed: true, children: [isMobile ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, children: !showForm ? renderAddress(deliveryAddress, false) : null }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { children: !showForm && (!index || deliveryAddress.defaultAddress)
                                                ? renderEditButton(deliveryAddress)
                                                : renderEditDeleteButtonGroup(deliveryAddress) })] })) : ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, valign: cotopaxi_1.GroupVerticalAlign.TOP, inline: true, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, tablet: { spacing: { marginTop: 0 } }, children: [' ', !showForm ? renderAddress(deliveryAddress, false) : null] }), !showForm && (!index || deliveryAddress.defaultAddress)
                                            ? renderEditButton(deliveryAddress)
                                            : renderEditDeleteButtonGroup(deliveryAddress)] })), showForm && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { children: (0, jsx_runtime_1.jsx)(CustomerAddressEdit_1.CustomerAddressEdit, { address: Object.assign(Object.assign({}, deliveryAddress), { state: deliveryAddress.county }), titles: customerTitles, countries: countries, handleCancelClick: handleCancelClick }) })), (deliveryAddresses === null || deliveryAddresses === void 0 ? void 0 : deliveryAddresses.length) && deliveryAddresses.length - 1 !== index && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { margin: cotopaxi_1.DividerMargin.XLARGE }))] }, index));
                    }), showAddNewAddressButton && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 4, marginBottom: 6 } }, tablet: { spacing: { marginTop: 5 } }, mobileBleed: true, children: !showNewAddressForm ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: isMobile, grid: 12, tablet: 6, desktop: 6, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, onClick: toggleShowNewAddressForm, iconLeft: { name: cotopaxi_1.IconName.PLUS }, text: t('delivery.add-address'), target: cotopaxi_1.LinkTarget.SELF, rel: cotopaxi_1.LinkRelEnum.NOOPENER, dataQA: "add_new_address" }) })) : ((0, jsx_runtime_1.jsx)(CustomerAddressEdit_1.CustomerAddressEdit, { address: Object.assign(Object.assign({}, CustomerAddressEdit_1.emptyAddress), { idCountry: common_types_1.CountryId[defaultCountryCode.toUpperCase()] }), isAddAddress: true, titles: customerTitles, countries: countries, handleCancelClick: handleCancelClick, onCancelEmptyForm: toggleShowNewAddressForm })) }))] }) }));
};
exports.default = AccountDeliveryAddressesContainer;
