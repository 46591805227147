"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCheckoutLogin = void 0;
const tslib_1 = require("tslib");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_redux_1 = require("react-redux");
const react_1 = require("react");
const react_query_1 = require("@tanstack/react-query");
const SessionStorageUtil_1 = require("../util/SessionStorageUtil");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const EventUtil_1 = require("../util/EventUtil");
const EventEnum_1 = require("../enums/EventEnum");
const AuthActions_creators_1 = require("../redux/authentication/actions/AuthActions.creators");
const AuthUtil_1 = require("../util/AuthUtil");
const useWishlistLogin_1 = require("./useWishlistLogin");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const useCheckoutLogin = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const [errorMessage, setErrorMessage] = (0, react_1.useState)(null);
    const { defaultRequestParams, servicesEndpoint, root } = (0, common_components_1.useAemConfig)();
    const { wishlistLogin } = (0, useWishlistLogin_1.useWishlistLogin)();
    const { loginCheckoutMutation } = (0, common_queries_1.authMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutateAsync: loginCheckout } = (0, react_query_1.useMutation)(loginCheckoutMutation());
    const checkoutLogin = (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ username, password, keepSignedIn, location }) {
        var _b, _c;
        setErrorMessage(null);
        try {
            const loginResponse = yield loginCheckout({ username, password, keepSignedIn });
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.LOGIN_SUCCESS);
            if (!loginResponse.readyForCheckout) {
                SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.BASKET_CHANGED, true);
                window.location.href = `${root}checkout/basket-overview.html`;
                return;
            }
            if (loginResponse.fastCheckoutEligible) {
                dispatch(AuthActions_creators_1.authActions.fastCheckout(true));
            }
            dispatch(AnalyticsActions_creators_1.analyticsActions.loginStepOption(false));
            SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.GUEST_EMAIL);
            wishlistLogin();
            dispatch(BasketActions_creators_1.basketActions.createGetBasicAction());
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.USER_LOGGED_IN, { loggedIn: true });
        }
        catch (e) {
            const error = e;
            const errorMessage = (0, AuthUtil_1.getLoginErrorMessage)(Number((_c = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error_code), location);
            setErrorMessage(errorMessage);
        }
    });
    return { checkoutLogin, errorMessage };
};
exports.useCheckoutLogin = useCheckoutLogin;
