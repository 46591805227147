"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsletterSubscribeForm = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const formik_1 = require("formik");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../../constants/i18nConstants");
const EmailValidationWrapper_1 = require("../../components/email/EmailValidationWrapper");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const NewsletterSubscribeForm = ({ title, submitButtonText, feedbackState, feedbackMessage, onSuccess, onFail, }) => {
    const subscribedEmail = (0, react_1.useRef)([]);
    const isClient = (0, cotopaxi_1.useIsClient)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const initialValues = { email: '' };
    if (!isClient)
        return null;
    const handleSubmit = (values_1, _a) => tslib_1.__awaiter(void 0, [values_1, _a], void 0, function* (values, { resetForm }) {
        if (subscribedEmail.current.includes(values.email)) {
            onFail === null || onFail === void 0 ? void 0 : onFail(common_types_1.NewsletterSubscribeFeedbackTypes.ALREADY_SUBSCRIBED);
            return;
        }
        try {
            yield CustomerApi_1.default.subscribeToNewsletter(servicesEndpoint, defaultRequestParams, values.email);
            common_utils_1.CookieUtil.set(common_types_1.Cookie.STATE_GUARD, common_types_1.StateGuardEnum.NEWSLETTER_SUBSCRIPTION, { secure: true });
            subscribedEmail.current.push(values.email);
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            resetForm();
        }
        catch (_b) {
            onFail === null || onFail === void 0 ? void 0 : onFail();
        }
    });
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { name: "as-m-newsletter-subscribe-form", initialValues: initialValues, validationSchema: undefined, onSubmit: handleSubmit, validateOnBlur: false, children: ({ errors, touched, isSubmitting }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(EmailValidationWrapper_1.EmailValidationWrapper, { emailFieldKey: "email", errorMessages: {
                        invalidEmail: t('account.subscribe.invalid'),
                        required: t('account.subscribe.invalid'),
                    }, children: (_a) => {
                        var { renderError } = _a, formFieldProps = tslib_1.__rest(_a, ["renderError"]);
                        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [title && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { emphasized: true, size: cotopaxi_1.LabelSize.MINOR, htmlFor: "subscribe", children: title }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, Object.assign({ required: true, name: "email", type: cotopaxi_1.InputType.EMAIL, state: (0, formik_1.getIn)(errors, 'email') && (0, formik_1.getIn)(touched, 'email') && cotopaxi_1.InputState.ERROR, placeholder: t('account.subscribe.placeholder'), Component: cotopaxi_1.Input, maxLength: 100, dataQA: "email_address", fluidAdornment: true, adornmentEnd: isMobile ? undefined : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { type: cotopaxi_1.ButtonType.SUBMIT, variant: cotopaxi_1.ButtonVariant.BRANDED, text: submitButtonText, state: isSubmitting ? cotopaxi_1.ButtonState.LOADING : undefined })), renderError: (msg, textProps) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { padding: 1 }, theme: cotopaxi_1.BoxTheme.WHITE }, children: renderError ? renderError(msg, textProps) : (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, Object.assign({}, textProps, { children: msg })) })) }, formFieldProps))] }));
                    } }), feedbackState && feedbackMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { padding: 1 }, theme: cotopaxi_1.BoxTheme.WHITE }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: feedbackState === common_types_1.NewsletterSubscribeFeedbackTypes.SUCCESS ? cotopaxi_1.TextColor.POSITIVE : cotopaxi_1.TextColor.NEGATIVE, children: feedbackMessage }) })), isMobile && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { type: cotopaxi_1.ButtonType.SUBMIT, variant: cotopaxi_1.ButtonVariant.BRANDED, text: submitButtonText, state: isSubmitting ? cotopaxi_1.ButtonState.LOADING : undefined, fill: true }) }))] })) }));
};
exports.NewsletterSubscribeForm = NewsletterSubscribeForm;
