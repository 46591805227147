"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const CollectionPointContainer_1 = require("../../../../containers/buy/CollectionPointContainer");
const HomeDeliveryContainer_1 = require("../../../../containers/buy/HomeDeliveryContainer");
const CollectHQContainer_1 = require("../../../../containers/buy/collect-hq-container/CollectHQContainer");
const CollectStoreContainer_1 = require("../../../../containers/buy/CollectStoreContainer");
const react_i18next_1 = require("react-i18next");
const CurrencyUtil_1 = require("../../../../util/CurrencyUtil");
const BuyFormUtil_1 = require("../../../../util/BuyFormUtil");
const asNumeral_1 = require("../../../../vendors/asNumeral");
const CustomerUtil_1 = require("../../../../util/CustomerUtil");
const ScrollUtil_1 = require("../../../../util/ScrollUtil");
const DeliveryPromiseMessage_1 = require("../../../product-detail/delivery-promise/DeliveryPromiseMessage");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const andes_react_1 = require("@yonderland/andes-react");
const DeliveryOptions = ({ deliveryOptions, chosenDeliveryOption, collectInStorePromiseMsg, changeDeliveryOption, onDeliveryOptionsInit, }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { defaultRequestParams, servicesEndpoint, root } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)([cotopaxi_1.I18N_NAMESPACES.buy]);
    const { getInformation } = (0, common_queries_1.productQueries)({ servicesEndpoint, defaultRequestParams });
    const [activeDeliveryOptionId, setActiveDeliveryOptionId] = (0, react_1.useState)();
    const getContent = (option) => {
        switch (option.name) {
            case common_types_1.DeliveryOptionsEnum.COLLECT_HQ:
                return (0, jsx_runtime_1.jsx)(CollectHQContainer_1.default, { option: option });
            case common_types_1.DeliveryOptionsEnum.COLLECT_STORE:
                return (0, jsx_runtime_1.jsx)(CollectStoreContainer_1.default, { option: option });
            case common_types_1.DeliveryOptionsEnum.HOME_DELIVERY:
            case common_types_1.DeliveryOptionsEnum.NAMED_DAY:
            case common_types_1.DeliveryOptionsEnum.NEXT_DAY:
                return (0, jsx_runtime_1.jsx)(HomeDeliveryContainer_1.default, { option: option });
            case common_types_1.DeliveryOptionsEnum.PICKUP_POINT:
                return (0, jsx_runtime_1.jsx)(CollectionPointContainer_1.default, { option: option });
            default:
                return (0, jsx_runtime_1.jsx)("h2", { children: "not found" });
        }
    };
    const codes = [];
    deliveryOptions.forEach(option => option.productCodesCausingExclusion && codes.push(...option.productCodesCausingExclusion));
    const uniqueCodes = [...new Set(codes)];
    const exclusionProducts = (0, react_query_1.useQueries)({
        queries: uniqueCodes.map(code => getInformation({ productCode: code })),
        combine: results => results.map(result => result.data),
    });
    const onChange = (value, id) => {
        changeDeliveryOption(value);
        setActiveDeliveryOptionId(id);
    };
    const setExclusionTitle = (optionName) => optionName === common_types_1.DeliveryOptionsEnum.COLLECT_STORE
        ? t('buy.delivery.option.exclusion.collectstore')
        : t('buy.delivery.option.exclusion.homedelivery');
    const setExclusionCaveatText = (optionName) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [setExclusionTitle(optionName), " ", exclusionProducts === null || exclusionProducts === void 0 ? void 0 : exclusionProducts.map(product => product === null || product === void 0 ? void 0 : product.title).join(', '), ".", (0, jsx_runtime_1.jsx)("br", {}), t('buy.delivery.option.exclusion.suggestion.part1'), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Link, { href: `${root}checkout/basket-overview.html`, children: [t('buy.delivery.option.exclusion.suggestion.part2'), " ", t('buy.delivery.option.exclusion.suggestion.part3')] })] }));
    const getDeliveryPrice = (option) => !option.priceDelivery
        ? t('buy.delivery.price.free')
        : `${(0, CurrencyUtil_1.convertToCurrencySymbol)(option.priceCurrency)}${asNumeral_1.AsNumeral.getInstance().formatAsCurrency(option.priceDelivery)}`;
    const isDisabled = (option) => option.exclusionCausedByProduct;
    const showSubtitle = (option) => !isDisabled(option) &&
        (CustomerUtil_1.default.isDeliveredToDeliveryAddress(option.name)
            ? (0, BuyFormUtil_1.isAllDaysOptions)(deliveryOptions) || !(0, BuyFormUtil_1.isNamedDayOrHomeDeliveryOptions)(deliveryOptions)
            : !isDisabled(option));
    const getSubtitle = (option) => option.name === common_types_1.DeliveryOptionsEnum.COLLECT_STORE ? ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "success", children: collectInStorePromiseMsg })) : option.cutoff ? ((0, jsx_runtime_1.jsx)(DeliveryPromiseMessage_1.default, { deliveryPromise: option })) : ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "success", children: option.deliveryMessage }));
    (0, react_1.useEffect)(() => {
        onDeliveryOptionsInit(chosenDeliveryOption);
    }, []);
    (0, react_1.useEffect)(() => {
        const element = document.getElementById(`${chosenDeliveryOption}_${activeDeliveryOptionId}`);
        if (!element)
            return;
        ScrollUtil_1.ScrollUtil.scrollToPosition(element.offsetTop);
    }, [chosenDeliveryOption]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.CheckList, { open: [chosenDeliveryOption], dataQA: "delivery_form", children: (0, BuyFormUtil_1.filterDeliveryOptions)(deliveryOptions).map(option => ((0, jsx_runtime_1.jsx)(cotopaxi_1.CheckList.Item, { id: `${option.name}_${option.deliveryOptionId}`, title: option.displayName, subtitle: showSubtitle(option) ? getSubtitle(option) : null, icon: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: (0, BuyFormUtil_1.getDeliveryOptionIconName)(option.name), size: isMobile ? cotopaxi_1.IconSize.MAJOR : cotopaxi_1.IconSize.EXTRA_MAJOR }), radio: {
                id: option.name,
                name: 'delivery-items',
                value: option.name,
            }, isFreeDelivery: !option.priceDelivery, onChange: value => onChange(value, option.deliveryOptionId), disabled: isDisabled(option), caveat: isDisabled(option) && setExclusionCaveatText(option.name), deliveryPrice: getDeliveryPrice(option), contentWithoutSpacing: true, children: getContent(option) }, option.name))) }));
};
exports.default = DeliveryOptions;
