"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyBasketBlock = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const NavigationUtil_1 = require("../../../util/NavigationUtil");
const EmptyBasketBlock = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const { rootWithLanguageSuffix } = (0, common_components_1.useAemConfig)();
    return ((0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { contentAlign: "center", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "4", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { dataTestId: "empty_basket_message", children: t('basket.emptyBasket') }), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { theme: "branded", dataTestId: "empty_basket_action_button", text: t('basket.startShopping'), onClick: () => {
                        window.location.href = (0, NavigationUtil_1.getHomepageUrl)(rootWithLanguageSuffix);
                    } })] }) }));
};
exports.EmptyBasketBlock = EmptyBasketBlock;
