"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoBasketPopupSizeGuide = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const SizeGuideAPI_1 = require("../../api/SizeGuideAPI");
const i18nConstants_1 = require("../../constants/i18nConstants");
const HtmlInclude_1 = require("../common/html-include/HtmlInclude");
const common_components_1 = require("@as-react/common-components");
const PromoBasketPopupSizeGuide = ({ sizeGuideInformation, basketValidationState, setBasketValidationState, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfig)();
    const { defaultRequestParams, servicesEndpoint, sizeGuidePath, isSingleLanguageSite } = aemConfiguration;
    const [sizeGuideContent, setSizeGuideContent] = (0, react_1.useState)(null);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const handleSizeGuideOpen = (e) => {
        if (basketValidationState === common_types_1.BasketButtonError.SKU) {
            setBasketValidationState(null);
        }
        handlePopoverOpen(e);
    };
    const getContent = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const sizeGuideRequestParams = {
            brandId: sizeGuideInformation.brandId,
            genderId: sizeGuideInformation.genderFeatureId,
            isSingleLanguageSite: isSingleLanguageSite,
            productTypeId: sizeGuideInformation.productTypeFeatureId,
            sizeGuidePath: sizeGuidePath,
        };
        const data = yield SizeGuideAPI_1.default.fetchSizeGuide(servicesEndpoint, defaultRequestParams, sizeGuideRequestParams);
        if (!data || !data.url) {
            return null;
        }
        return SizeGuideAPI_1.default.fetchSizeGuideHTML(data.url);
    });
    (0, react_1.useEffect)(() => {
        getContent().then(content => setSizeGuideContent(content));
    }, [sizeGuideInformation]);
    if (!sizeGuideContent) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, tablet: { spacing: { marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { dataQA: "size_guide", iconRight: { name: cotopaxi_1.IconName.CHEVRON_RIGHT }, onClick: handleSizeGuideOpen, size: cotopaxi_1.ButtonSize.SMALL, text: t('product.size.guide.link'), variant: cotopaxi_1.ButtonVariant.LINK }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { nested: true, disableClickOutside: true, anchorEl: anchorEl, type: cotopaxi_1.PopoverType.MODAL, onClose: handlePopoverClose, title: t('product.size.guide.link'), dataQA: "size_guide_popup", children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: sizeGuideContent }) })] }));
};
exports.PromoBasketPopupSizeGuide = PromoBasketPopupSizeGuide;
