"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeSizeSaga = exports.changeSizeUpdate = void 0;
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const RemoveProductFromBasketSaga_1 = require("./RemoveProductFromBasketSaga");
const common_types_1 = require("@as-react/common-types");
function* changeSizeUpdate(action) {
    try {
        const { defaultRequestParams, servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
        const updatedProduct = Object.assign(Object.assign({}, action.payload.product), { sku: action.payload.newSku });
        yield (0, effects_1.call)(RemoveProductFromBasketSaga_1.removeProductFromBasket, action);
        yield (0, effects_1.call)(BasketAPI_1.default.addProductToBasket, updatedProduct, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createAddSuccessAction({ product: updatedProduct }));
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
    catch (_a) {
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateItemsErrorStateAction(common_types_1.BasketCartItemError.ADD_FAILED, action.payload.product));
    }
}
exports.changeSizeUpdate = changeSizeUpdate;
function* changeSizeSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.BasketActionTypes.CHANGE_SIZE, changeSizeUpdate);
}
exports.changeSizeSaga = changeSizeSaga;
