"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutLayoutContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const CheckoutOverviewContainer_1 = require("./CheckoutOverviewContainer");
const CheckoutStepper_1 = require("./CheckoutStepper");
const CheckoutStepContext_1 = require("../../context/CheckoutStepContext");
const CheckoutLayoutContainer = ({ step, children }) => ((0, jsx_runtime_1.jsx)(CheckoutStepContext_1.CheckoutStepContext.Provider, { value: { step }, children: (0, jsx_runtime_1.jsxs)(andes_react_1.CheckoutLayout, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.CheckoutLayout.Overview, { children: (0, jsx_runtime_1.jsx)(CheckoutOverviewContainer_1.CheckoutOverviewContainer, {}) }), (0, jsx_runtime_1.jsxs)(andes_react_1.CheckoutLayout.Main, { children: [(0, jsx_runtime_1.jsx)(CheckoutStepper_1.CheckoutStepper, {}), children] })] }) }));
exports.CheckoutLayoutContainer = CheckoutLayoutContainer;
