"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const ImageUtil_1 = require("../util/ImageUtil");
const CategoryTileContainer = ({ contentAlignment, cta, href, linkTitle, image, openLinkInNewTab, title, analytics, }) => {
    const { imageCdnPrefix } = (0, common_components_1.useAemConfig)();
    const imageSrcset = (0, ImageUtil_1.getPictureSrcset)(image, imageCdnPrefix);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.CategoryTile, { contentAlign: contentAlignment, href: href, linkTitle: linkTitle, dataAnalyticsBannerid: analytics === null || analytics === void 0 ? void 0 : analytics.bannerId, image: { src: image.imagePath, srcset: imageSrcset, isPicture: true }, openLinkInNewTab: openLinkInNewTab, cta: cta, title: title }));
};
exports.default = CategoryTileContainer;
