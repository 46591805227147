"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyAndGetMessages = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const ProductActions_1 = require("../../../../redux/product/actions/ProductActions");
const PromoBasketPopupActions_creators_1 = require("../../../../redux/promo-basket-popup/actions/PromoBasketPopupActions.creators");
const SessionStorageUtil_1 = require("../../../../util/SessionStorageUtil");
const StorageKeysConstants_1 = require("../../../../constants/StorageKeysConstants");
const HtmlInclude_1 = require("../../../common/html-include/HtmlInclude");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const BasketOverviewMessagesUtil_1 = require("../../../../util/BasketOverviewMessagesUtil");
const BuyAndGetMessages = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const popupMessages = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPopupMessagesState)());
    const popupMessagesEnriched = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPopupMessagesEnrichedState)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const loadProductDataByProductCode = (productCode) => dispatch(ProductActions_1.productActions.createLoadProductData(productCode));
    const openPromoBasketPopup = (message) => dispatch(PromoBasketPopupActions_creators_1.promoBasketPopupActions.createPopupOpenAction(message));
    const enrichedMessages = (popupMessagesEnriched.length > 0 ? popupMessagesEnriched : (0, BasketOverviewMessagesUtil_1.getEnrichedMessages)(popupMessages)).filter(message => !!message.message);
    (0, react_1.useEffect)(() => {
        if (!SessionStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.BUY_GET_POPUP_STORAGE_KEY)) {
            const message = enrichedMessages.find(messageItem => !!messageItem.productCode);
            if (message) {
                message.productCode && loadProductDataByProductCode(message.productCode);
                openPromoBasketPopup(message.message);
                SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.BUY_GET_POPUP_STORAGE_KEY, true);
            }
        }
    }, [enrichedMessages]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: enrichedMessages.map((message, index) => ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "informative", text: message.productCode ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [message.message, ' ', (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t('basket.buyandgetbanner.message.link'), onClick: () => {
                            message.productCode && loadProductDataByProductCode(message.productCode);
                            openPromoBasketPopup(message.message);
                        } })] })) : ((0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: message.message })) }, `${message.productCode}-${index}`))) }));
};
exports.BuyAndGetMessages = BuyAndGetMessages;
