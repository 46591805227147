"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const CustomerSelector_1 = require("../redux/customer/selectors/CustomerSelector");
const SessionSelector_1 = require("../redux/session/selectors/SessionSelector");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const CustomerActions_creators_1 = require("../redux/customer/actions/CustomerActions.creators");
const ActionEnums_1 = require("../enums/ActionEnums");
const ProductDetailV2Container_1 = require("./product/ProductDetailV2Container");
const StoreFinderPopup_1 = require("../components/product-detail/store-finder-popup/StoreFinderPopup");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const react_query_1 = require("@tanstack/react-query");
const LocalStorageUtil_1 = require("../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const StoreFinderContainer = ({ type = common_types_1.StoreFinderType.CLICK_AND_COLLECT, }) => {
    var _a, _b, _c;
    const aemConfiguration = (0, common_components_1.useAemConfig)();
    const { defaultRequestParams, servicesEndpoint, businessUnitId, isShopLanding, fictiveWebShop, pageInfo } = aemConfiguration;
    const dispatch = (0, react_redux_1.useDispatch)();
    const popupState = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketCCPopupState)());
    const profile = (0, react_redux_1.useSelector)((0, CustomerSelector_1.makeGetCustomerProfile)());
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetIsLoggedIn)());
    const selectedSku = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedSku)());
    const priceInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetPriceInformation)());
    const fulfillmentBySize = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillmentBySize)());
    const { selectedAdditionalProducts } = (0, ProductDetailV2Container_1.useProductDetailContext)();
    const productInfo = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductInfo)());
    const { isPDPPage } = pageInfo;
    const specialtyType = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSpecialtyType)());
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    (0, react_1.useEffect)(() => {
        if (!profile && isLoggedIn) {
            dispatch(CustomerActions_creators_1.default.getProfile());
        }
    }, [profile, isLoggedIn]);
    const [locationSearchParams, setLocationSearchParams] = (0, react_1.useState)();
    const { getClickAndCollectStoresOpeningsHours } = (0, common_queries_1.storeQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const productData = (productInfo === null || productInfo === void 0 ? void 0 : productInfo.productId) && isPDPPage
        ? [
            {
                skuId: Number(selectedSku),
                productId: Number(productInfo.productId),
                colorId: Number(productInfo.selectedColorId),
                quantity: 1,
            },
        ]
        : products
            .filter(product => product.productType === common_types_1.ProductTypes.PRODUCT)
            .map(product => ({
            skuId: Number(product.sku),
            productId: Number(product.productId),
            colorId: Number(product.colorId),
            quantity: product.quantity,
        }));
    const isSpecialtyTypeProducts = !!specialtyType || (!isPDPPage && !!products.find(product => product.specialtyStoreProduct));
    const isShowStock = locationSearchParams ? locationSearchParams.isShowStock : isSpecialtyTypeProducts && isPDPPage;
    const isEnabled = !popupState.collapsed &&
        locationSearchParams &&
        !!((_a = locationSearchParams.locationSearch) === null || _a === void 0 ? void 0 : _a.location) &&
        productData.length > 0;
    const storesQueryResult = (0, react_query_1.useQuery)(getClickAndCollectStoresOpeningsHours({
        businessUnitId,
        fictiveShopId: fictiveWebShop,
        productData,
        location: ((locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.locationSearch) || {}).location,
        onlyInStock: specialtyType ? false : isShowStock,
        onlyInSpecialtyStore: Boolean(isPDPPage && isShowStock && (!!specialtyType || isSpecialtyTypeProducts)),
        sortBy: (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.sortBy) || 'distance',
        isShopLanding,
        selectedStoreId: (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.locationSearch.type) &&
            (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.locationSearch.type) === common_types_1.LocationSearchType.CUSTOM_SEARCH
            ? undefined
            : (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.selectedStoreId) ||
                ((_b = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY)) === null || _b === void 0 ? void 0 : _b.storeId),
    }, {
        enabled: Boolean(isEnabled),
    }));
    const handleSearch = (searchParams) => {
        setLocationSearchParams(searchParams);
    };
    const handleStoreSelect = () => {
        if (type === common_types_1.StoreFinderType.CLICK_AND_COLLECT) {
            const quantity = 1;
            dispatch(selectedAdditionalProducts.length > 0
                ? BasketActions_creators_1.basketActions.createAddAdditionalProductsAction(selectedAdditionalProducts, quantity, ActionEnums_1.ProductAddToBasketLocations.CLICK_AND_COLLECT)
                : BasketActions_creators_1.basketActions.createAddAction(quantity, ActionEnums_1.ProductAddToBasketLocations.CLICK_AND_COLLECT, common_types_1.PDPVersion.V2));
        }
        if (type === common_types_1.StoreFinderType.BASKET) {
            dispatch(BasketActions_creators_1.basketActions.createGetAction());
        }
    };
    const handleClosePopup = () => dispatch(BasketActions_creators_1.basketActions.createCCPopupCloseAction());
    return ((0, jsx_runtime_1.jsx)(StoreFinderPopup_1.StoreFinderPopup, { type: type, isOpen: !popupState.collapsed, disabledCTA: Boolean(!((_c = priceInformation === null || priceInformation === void 0 ? void 0 : priceInformation.prices) === null || _c === void 0 ? void 0 : _c.SELL)), storeSourceExcluded: Boolean(fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded), handleClosePopup: handleClosePopup, onSearch: handleSearch, onStoreSelect: handleStoreSelect, storesQueryResult: storesQueryResult }));
};
exports.default = StoreFinderContainer;
