"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketMergedMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const StorageKeysConstants_1 = require("../../../../constants/StorageKeysConstants");
const SessionStorageUtil_1 = require("../../../../util/SessionStorageUtil");
const BasketMergedMessage = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const [basketsHaveMerged, setBasketsHaveMerged] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const basketsHaveMergedCheck = SessionStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.BASKET_CHANGED_STORAGE_KEY);
        if (basketsHaveMergedCheck) {
            SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.BASKET_CHANGED_STORAGE_KEY);
            setBasketsHaveMerged(basketsHaveMergedCheck);
        }
        else {
            setBasketsHaveMerged(false);
        }
    }, []);
    if (!basketsHaveMerged)
        return null;
    return (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "informative", text: t('basket.merged') });
};
exports.BasketMergedMessage = BasketMergedMessage;
