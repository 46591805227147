"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginRegistrationForm = exports.LoginRegistrationFormState = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const CustomerApi_1 = require("../../../api/customer/CustomerApi");
const react_redux_1 = require("react-redux");
const AuthActions_creators_1 = require("../../../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../../../redux/authentication/selectors/AuthSelectors");
const common_types_1 = require("@as-react/common-types");
const ImageUtil_1 = require("../../../util/ImageUtil");
const EmailValidationWrapper_1 = require("../../../components/email/EmailValidationWrapper");
const EmailLoginRegistrationChangeHandler_1 = require("./EmailLoginRegistrationChangeHandler");
var LoginRegistrationFormState;
(function (LoginRegistrationFormState) {
    LoginRegistrationFormState[LoginRegistrationFormState["REGISTER"] = 0] = "REGISTER";
    LoginRegistrationFormState[LoginRegistrationFormState["RESET"] = 1] = "RESET";
})(LoginRegistrationFormState || (exports.LoginRegistrationFormState = LoginRegistrationFormState = {}));
const LoginRegistrationForm = ({ sectionTitle, image, emailValidationErrorMessages, onSuccess, }) => {
    const { defaultRequestParams, servicesEndpoint, imageCdnPrefix } = (0, common_components_1.useAemConfig)();
    const [formState, setFormState] = (0, react_1.useState)(LoginRegistrationFormState.REGISTER);
    const [passwordButtonState, setPasswordButtonState] = (0, react_1.useState)(cotopaxi_1.ButtonState.INTERACTIVE);
    const dispatch = (0, react_redux_1.useDispatch)();
    const resetPasswordState = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetResetPasswordState)());
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.navigation, i18nConstants_1.I18N_NAMESPACES.buy]);
    (0, react_1.useEffect)(() => {
        formState === LoginRegistrationFormState.RESET &&
            (resetPasswordState === null || resetPasswordState === void 0 ? void 0 : resetPasswordState.success) &&
            setPasswordButtonState(cotopaxi_1.ButtonState.FINISHED);
    }, [resetPasswordState]);
    const handleSubmit = (_a, _b) => tslib_1.__awaiter(void 0, [_a, _b], void 0, function* ({ registrationEmail }, { setFieldError }) {
        var _c, _d;
        setPasswordButtonState(cotopaxi_1.ButtonState.LOADING);
        if (formState === LoginRegistrationFormState.RESET) {
            dispatch(AuthActions_creators_1.authActions.createResetCredentialsAction(registrationEmail));
            return;
        }
        try {
            const { accountStatusCode } = yield CustomerApi_1.default.emailExists(servicesEndpoint, defaultRequestParams, registrationEmail, false);
            if ([common_types_1.AuthStatusCode.NO_ACCOUNT, common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT].includes(accountStatusCode)) {
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(registrationEmail);
                return;
            }
            setFieldError('registrationEmail', t(emailValidationErrorMessages[accountStatusCode]));
            if (accountStatusCode !== common_types_1.AuthStatusCode.VALID_ACCOUNT) {
                // User has no password or an account on friendly fascia
                setFormState(LoginRegistrationFormState.RESET);
            }
        }
        catch (err) {
            if (((_d = (_c = err === null || err === void 0 ? void 0 : err.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.accountStatusCode) ===
                common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT) {
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(registrationEmail);
                return;
            }
        }
        setPasswordButtonState(cotopaxi_1.ButtonState.INTERACTIVE);
    });
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { name: "checkout-login-registration", initialValues: { registrationEmail: '' }, onSubmit: handleSubmit, children: ({ errors, touched, isSubmitting, isValid, submitCount }) => {
            if (submitCount > 0 && !isValid && !isSubmitting) {
                ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.querySelector(`[for="${Object.keys(errors)[0]}"]`));
            }
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: sectionTitle }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, desktop: { spacing: { marginTop: 2 } }, children: [image && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobileBleed: true, mobile: { spacing: { marginBottom: 3, paddingLeft: 0.5, paddingRight: 0.5 } }, tablet: { spacing: { marginBottom: 2, paddingLeft: 0, paddingRight: 0 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: image.imagePath, srcset: (0, ImageUtil_1.getPictureSrcset)(image, imageCdnPrefix), isPicture: true }) })), (0, jsx_runtime_1.jsx)(EmailValidationWrapper_1.EmailValidationWrapper, { emailFieldKey: "registrationEmail", errorMessages: emailValidationErrorMessages, children: formFieldProps => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: true, emphasized: true, htmlFor: "registrationEmail", size: cotopaxi_1.LabelSize.MINOR, children: t('account:account.flyout.email.placeholder') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, Object.assign({ type: cotopaxi_1.InputType.EMAIL, name: "registrationEmail", placeholder: t('account:account.flyout.email.placeholder'), required: true, state: errors.registrationEmail && touched.registrationEmail && cotopaxi_1.InputState.ERROR, adornmentEnd: errors.registrationEmail &&
                                                touched.registrationEmail && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, color: cotopaxi_1.IconColor.NEGATIVE })), Component: cotopaxi_1.Input, id: "registrationEmail" }, formFieldProps))] })) }), (0, jsx_runtime_1.jsx)(EmailLoginRegistrationChangeHandler_1.EmailLoginRegistrationChangeHandler, { emailFieldKey: "registrationEmail", formState: formState, setFormState: setFormState }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, desktop: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, type: cotopaxi_1.ButtonType.SUBMIT, text: formState === LoginRegistrationFormState.REGISTER
                                        ? t('account:account.registration.action.continue')
                                        : t('account:account.registration.action.setPassword'), state: passwordButtonState, finisher: {
                                        text: t('account:account.registration.action.setPassword.success'),
                                        icon: cotopaxi_1.IconName.CHECK,
                                    } }) })] })] }));
        } }));
};
exports.LoginRegistrationForm = LoginRegistrationForm;
