"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailTabsContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const ProductDetailTab_1 = require("../../components/product-detail/product-detail-tab/ProductDetailTab");
const ProductDetailTabPopover_1 = require("../../components/product-detail/product-detail-tab/ProductDetailTabPopover");
const ProductSustainabilityTabCTA_1 = require("../../components/product-detail/product-sustainability/ProductSustainabilityTabCTA");
const i18nConstants_1 = require("../../constants/i18nConstants");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const ProductActions_1 = require("../../redux/product/actions/ProductActions");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductDetailDescriptionContainer_1 = require("./ProductDetailDescriptionContainer");
const ProductDetailFeatureCategoriesContainer_1 = require("./ProductDetailFeatureCategoriesContainer");
const ProductDetailOurPlanetContainer_1 = require("./ProductDetailOurPlanetContainer");
const ProductDetailProductCareContainer_1 = require("./ProductDetailProductCareContainer");
const ProductDetailReviewTabContainer_1 = require("./ProductDetailReviewTabContainer");
const ProductDetailSpecificationContainer_1 = require("./ProductDetailSpecificationContainer");
const useFeatureToggle_1 = require("../../hooks/useFeatureToggle");
const ProductDetailTabsContainer = ({ productDetailTabs, deliveryPromiseExplanations, showTabsAsAccordion, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { pdpTemplateName } = (0, common_components_1.useAemConfig)();
    const our_planet = (0, useFeatureToggle_1.useFeatureToggle)('our_planet');
    const [accordionState, setAccordionState] = (0, react_1.useState)({});
    const userReview = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductReview)());
    const hasUserReview = Boolean(userReview);
    const expertReviews = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetExpertReviews)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const userReviewCount = hasUserReview ? userReview.numberOfScores : 0;
    const reviewCount = Number(userReviewCount) + expertReviews.length;
    const selectedTab = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedProductTab)());
    const isOurPlanet = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductOurPlanet)());
    const showOurPlanet = our_planet && isOurPlanet;
    const getTabTitleById = (tabId) => {
        const tabType = tabId.replace(/(^product_detail_tab_)|(-\d+$)/g, '');
        const tabTypeOrder = tabId.replace(/(^product_detail_tab_)|\D+/g, '');
        const tabByOrder = productDetailTabs[tabTypeOrder];
        return (tabByOrder === null || tabByOrder === void 0 ? void 0 : tabByOrder.title) || t(`pdp.tab.${tabType}.title`);
    };
    const onTabClick = (tabId) => dispatch(ProductActions_1.productActions.createSetSelectedProductTab(tabId));
    const getTabPopoverContent = (tab, parentId) => {
        switch (tab.type) {
            case common_types_1.ProductDetailTabTypes.DESCRIPTION:
                return (0, jsx_runtime_1.jsx)(ProductDetailDescriptionContainer_1.ProductDetailDescriptionContainer, { inPopover: !showTabsAsAccordion });
            case common_types_1.ProductDetailTabTypes.FEATURE_CATEGORIES:
                return ((0, jsx_runtime_1.jsx)(ProductDetailFeatureCategoriesContainer_1.ProductDetailFeatureCategoriesContainer, { includedCategories: tab.includedCategories, inPopover: !showTabsAsAccordion }));
            case common_types_1.ProductDetailTabTypes.PRODUCT_CARE:
                return ((0, jsx_runtime_1.jsx)(ProductDetailProductCareContainer_1.ProductDetailProductCareContainer, { deliveryPromiseExplanations: deliveryPromiseExplanations, inPopover: !showTabsAsAccordion, parentId: parentId }));
            case common_types_1.ProductDetailTabTypes.REVIEWS:
                return ((0, jsx_runtime_1.jsx)(ProductDetailReviewTabContainer_1.ProductDetailReviewTabContainer, { showEndUserReview: tab.showEndUserReview, showExpertReview: tab.showExpertReview, isReevoo: tab.isReevoo, reviewDescription: tab.reviewDescription, reviewTitle: tab.reviewTitle, reviewTypes: tab.reviewTypes, inPopover: !showTabsAsAccordion, expertReviewTitleOverride: tab.expertReviewTitleOverride }));
            case common_types_1.ProductDetailTabTypes.SPECIFICATIONS:
                return ((0, jsx_runtime_1.jsx)(ProductDetailSpecificationContainer_1.ProductDetailSpecificationContainer, { excludedCategories: tab.excludedCategories, inPopover: !showTabsAsAccordion }));
            case common_types_1.ProductDetailTabTypes.OUR_PLANET:
                return ((0, jsx_runtime_1.jsx)(ProductDetailOurPlanetContainer_1.ProductDetailOurPlanetContainer, { ourPlanetFeatures: tab.ourPlanetFeatures, ppcAltText: tab.ppcAltText, ppcImageUrl: tab.ppcImageUrl, ppcDescription: tab.ppcDescription, inPopover: !showTabsAsAccordion, readMoreUrl: tab.readMoreUrl, readMoreText: tab.readMoreText }));
            default:
                return null;
        }
    };
    const getTabFooterElement = (tab) => {
        switch (tab.type) {
            case common_types_1.ProductDetailTabTypes.OUR_PLANET:
                return showOurPlanet ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { spacing: { paddingLeft: 2, paddingRight: 2 } }, children: (0, jsx_runtime_1.jsx)(ProductSustainabilityTabCTA_1.ProductSustainabilityTabCTA, { url: tab.readMoreUrl, text: tab.readMoreText }) })) : null;
            default:
                return null;
        }
    };
    const tabs = productDetailTabs
        .map((tab, index) => {
        if (!tab.shouldDisplay) {
            return null;
        }
        const key = tab.type === common_types_1.ProductDetailTabTypes.REVIEWS
            ? `product_detail_tab_${tab.type}`
            : `product_detail_tab_${tab.type}-${index}`;
        const title = tab.title || t(`pdp.tab.${tab.type}.title`);
        const getTitleForTab = () => ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutterX: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { emphasized: true, type: showTabsAsAccordion ? cotopaxi_1.TextType.EXTRA_CONTENT : null, children: [title, tab.type === common_types_1.ProductDetailTabTypes.REVIEWS && ` (${reviewCount})`] }), tab.type === common_types_1.ProductDetailTabTypes.OUR_PLANET && showOurPlanet && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Tag, { color: cotopaxi_1.TagColor.SUSTAINABLE_INVERSE, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { children: [(0, jsx_runtime_1.jsx)("span", { children: t('product.sustainable') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.LEAF })] }) }))] }));
        return showTabsAsAccordion ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { id: key, title: getTitleForTab(), children: getTabPopoverContent(tab, key) }, key)) : ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ProductDetailTab_1.ProductDetailTab, { id: key, onClick: () => onTabClick(key), title: getTitleForTab(), children: (0, jsx_runtime_1.jsx)(ProductDetailTabPopover_1.ProductDetailTabPopover, { tabType: key, title: title, popoverFooterElement: getTabFooterElement(tab), children: getTabPopoverContent(tab, key) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {})] }, key));
    })
        .filter(Boolean);
    (0, react_1.useEffect)(() => {
        if (selectedTab) {
            setAccordionState({ [selectedTab]: true });
            dispatch(AnalyticsActions_creators_1.analyticsActions.productDetailTabOpen({ tabTitle: getTabTitleById(selectedTab), pdpTemplateName }));
        }
        else {
            setAccordionState({});
        }
    }, [selectedTab]);
    return tabs.length ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2, marginTop: 2.5 } }, tablet: { spacing: { marginTop: 5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: t('pdp.productDetailTabs.title') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}), showTabsAsAccordion ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Accordion, { outerState: accordionState, onItemClick: (id, isOpen) => onTabClick(isOpen ? id : null), children: tabs })) : (tabs)] })) : null;
};
exports.ProductDetailTabsContainer = ProductDetailTabsContainer;
