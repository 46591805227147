"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const StoreFinderForm_1 = require("../../store-finder-form/StoreFinderForm");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const useFeatureToggle_1 = require("../../../../hooks/useFeatureToggle");
const StoreUtil_1 = require("../../../../util/StoreUtil");
const StoreFinderGeneral = ({ isShowStock, isShowStockCheckbox, valuesForm, handleSubmitLocation, handleChangeStock, handleResetValues, specialtyType, storeFinderType, stores, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.product]);
    const storesOwnFasciaFirst = (0, useFeatureToggle_1.useFeatureToggle)('stores_own_fascia_first');
    const { mainWebShop, market } = (0, common_components_1.useAemConfig)();
    const fasciaFirstMessage = storesOwnFasciaFirst &&
        storeFinderType !== common_types_1.StoreFinderType.LOCATOR &&
        stores &&
        !StoreUtil_1.default.isAllStoresWithSameMainWebShop(mainWebShop, stores);
    const switcherText = specialtyType ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.specialty.stores.in.stock.${specialtyType}`, {
                specialty_type: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.specialty.type.${specialtyType}`),
            }), ' ', (0, jsx_runtime_1.jsx)(andes_react_1.Tooltip, { text: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.specialty.tooltip.stores.${specialtyType}`), hasCloseBtn: false, children: (0, jsx_runtime_1.jsx)(andes_react_1.Icon, { size: "sm", name: "information-outline" }) })] })) : (t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.stock.only`));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(StoreFinderForm_1.StoreFinderForm, { initialValues: valuesForm, onSubmit: handleSubmitLocation, handleResetValues: handleResetValues }), fasciaFirstMessage && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", children: StoreUtil_1.default.getStoreOwnFasciaTranslation(market, mainWebShop, t) })), isShowStockCheckbox && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { backgroundColor: "informative-bg", paddingBlock: "3", paddingInline: { xs: '5', sm: '6', lg: '8' }, marginInline: { xs: '-5', sm: '-6', lg: '-8' }, children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { contentAlign: "between", itemsAlign: "center", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyM", color: "informative", children: switcherText }), (0, jsx_runtime_1.jsx)(andes_react_1.Switch, { checked: isShowStock, id: "stores-with-stock", onChange: handleChangeStock })] }) }))] }));
};
exports.default = StoreFinderGeneral;
