"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExtensiveTileProductDeliveryPromisesSaga = exports.getDeliveryPromiseByColor = exports.getDeliveryPromise = exports.getDeliveryPromiseFromRemote = exports.getDeliveryPromiseExtendedFromRemote = exports.getDeliveryPromiseBySku = exports.getDeliveryPromiseExtended = exports.getNextSizes = exports.getPdpVersion = exports.getSelectedStoreId = exports.getSku = exports.getProductId = exports.getDeliveryPromiseSaga = void 0;
const common_utils_1 = require("@as-react/common-utils");
const effects_1 = require("redux-saga/effects");
const DeliveryPromiseAPI_1 = require("../../../api/DeliveryPromiseAPI");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const ProductActions_1 = require("../../product/actions/ProductActions");
const ProductRecentlyViewedActions_creators_1 = require("../../productRecentlyViewed/actions/ProductRecentlyViewedActions.creators");
const ProductGroupFredhopper_creators_1 = require("../../productGroupFredhopper/actions/ProductGroupFredhopper.creators");
const ProductGroupActions_creators_1 = require("../../productGroup/actions/ProductGroupActions.creators");
const ProductComparison_creators_1 = require("../../compare-page/actions/ProductComparison.creators");
const SingleProductTileActions_creators_1 = require("../../single-product-tile/actions/SingleProductTileActions.creators");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const MonetateActions_creators_1 = require("../../monetate/actions/MonetateActions.creators");
const WishListActions_creators_1 = require("../../wishList/actions/WishListActions.creators");
const ProductReducer_1 = require("../../product/reducers/ProductReducer");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
function* getDeliveryPromiseSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.SET_COLOR, getDeliveryPromiseByColor);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.SELECT_SIZE, getDeliveryPromiseBySku);
    yield (0, effects_1.takeEvery)(ActionEnums_1.ProductRecentlyViewedActionTypes.GET_DELIVERY_PROMISE, getExtensiveTileProductDeliveryPromisesSaga);
    yield (0, effects_1.takeEvery)(ActionEnums_1.ProductGroupActionTypes.GET_DELIVERY_PROMISE, getExtensiveTileProductDeliveryPromisesSaga);
    yield (0, effects_1.takeEvery)(ActionEnums_1.ProductGroupFredhopperTypes.GET_DELIVERY_PROMISE, getExtensiveTileProductDeliveryPromisesSaga);
    yield (0, effects_1.takeEvery)(ActionEnums_1.ProductComparisonActionTypes.GET_DELIVERY_PROMISE, getExtensiveTileProductDeliveryPromisesSaga);
    yield (0, effects_1.takeEvery)(ActionEnums_1.SingleProductTileActionTypes.GET_DELIVERY_PROMISE, getExtensiveTileProductDeliveryPromisesSaga);
    yield (0, effects_1.takeEvery)(ActionEnums_1.ProductActionTypes.GET_DELIVERY_PROMISE, getDeliveryPromise);
    yield (0, effects_1.takeEvery)(ActionEnums_1.ProductActionTypes.GET_DELIVERY_PROMISE_EXTENDED, getDeliveryPromiseExtended);
    yield (0, effects_1.takeEvery)(ActionEnums_1.ProductActionTypes.GET_PRODUCT_CARE_DELIVERY_PROMISE, getExtensiveTileProductDeliveryPromisesSaga);
    yield (0, effects_1.takeEvery)(ActionEnums_1.MonetateActionTypes.GET_DELIVERY_PROMISE, getExtensiveTileProductDeliveryPromisesSaga);
    yield (0, effects_1.takeEvery)(ActionEnums_1.WishListActionTypes.LISTER_GET_DELIVERY_PROMISE, getExtensiveTileProductDeliveryPromisesSaga);
}
exports.getDeliveryPromiseSaga = getDeliveryPromiseSaga;
const getProductId = (state) => state.product.productId;
exports.getProductId = getProductId;
const getSku = (state) => state.product.selectedSku;
exports.getSku = getSku;
const getSelectedStoreId = (state) => state.basket.clickAndCollectStoreId;
exports.getSelectedStoreId = getSelectedStoreId;
const getPdpVersion = (state) => state.product.pdpVersion;
exports.getPdpVersion = getPdpVersion;
const getNextSizes = (state, selectedColorId) => {
    const productColor = (state.product.productColorVariations || []).find((productColorVariation) => productColorVariation.colorId === selectedColorId);
    if (!(0, common_utils_1.isNullOrUndefined)(productColor)) {
        return productColor === null || productColor === void 0 ? void 0 : productColor.sizes.filter((size) => size.active);
    }
    return null;
};
exports.getNextSizes = getNextSizes;
function* getDeliveryPromiseExtended() {
    const productId = yield (0, effects_1.select)(exports.getProductId);
    const sku = yield (0, effects_1.select)(exports.getSku);
    const selectedStoreId = yield (0, effects_1.select)(exports.getSelectedStoreId);
    const { servicesEndpoint, authenticationConfig } = yield (0, effects_1.getContext)('aemConfiguration');
    const selectedStoreFromLocalStorage = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY);
    yield (0, effects_1.fork)(getDeliveryPromiseExtendedFromRemote, servicesEndpoint, authenticationConfig, productId, sku, selectedStoreFromLocalStorage ? Number(selectedStoreFromLocalStorage.storeId) : selectedStoreId);
}
exports.getDeliveryPromiseExtended = getDeliveryPromiseExtended;
function* getDeliveryPromiseBySku(action) {
    if (action.payload.isSoldOut || action.payload.isDisabled || !action.payload.sku) {
        return;
    }
    const skuId = action.payload.sku;
    const { servicesEndpoint, authenticationConfig } = yield (0, effects_1.getContext)('aemConfiguration');
    const productId = yield (0, effects_1.select)(exports.getProductId);
    yield (0, effects_1.fork)(getDeliveryPromiseFromRemote, servicesEndpoint, skuId, productId, authenticationConfig);
}
exports.getDeliveryPromiseBySku = getDeliveryPromiseBySku;
function* getDeliveryPromiseExtendedFromRemote(servicesEndpoint, authenticationConfig, productId, skuId, selectedStoreId) {
    try {
        const deliveryPromiseExtended = yield (0, effects_1.call)(DeliveryPromiseAPI_1.default.getDeliveryPromiseExtended, servicesEndpoint, authenticationConfig, productId, skuId, selectedStoreId);
        yield (0, effects_1.put)(ProductActions_1.productActions.createSetDeliveryPromiseExtended(deliveryPromiseExtended));
    }
    catch (_a) { }
}
exports.getDeliveryPromiseExtendedFromRemote = getDeliveryPromiseExtendedFromRemote;
function* getDeliveryPromiseFromRemote(servicesEndpoint, skuId, productId, authenticationConfig, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
actionCreator, colourId, productGroupId) {
    try {
        const deliveryPromise = yield (0, effects_1.call)(DeliveryPromiseAPI_1.default.getDeliveryPromise, servicesEndpoint, skuId, productId, authenticationConfig);
        if (actionCreator) {
            yield (0, effects_1.delay)(GeneralConstants_1.DELAY.DELAY_500);
            yield (0, effects_1.put)(actionCreator({ productId, deliveryPromise, colourId: Number(colourId), productGroupId }));
        }
        else {
            yield (0, effects_1.put)(ProductActions_1.productActions.createSetDeliveryPromise(deliveryPromise));
        }
    }
    catch (_a) { }
}
exports.getDeliveryPromiseFromRemote = getDeliveryPromiseFromRemote;
function* getDeliveryPromise() {
    const productId = yield (0, effects_1.select)(exports.getProductId);
    const sku = yield (0, effects_1.select)(exports.getSku);
    const { servicesEndpoint, authenticationConfig } = yield (0, effects_1.getContext)('aemConfiguration');
    yield (0, effects_1.fork)(getDeliveryPromiseFromRemote, servicesEndpoint, sku, productId, authenticationConfig);
}
exports.getDeliveryPromise = getDeliveryPromise;
function* getDeliveryPromiseByColor(action) {
    const sizes = yield (0, effects_1.select)(exports.getNextSizes, action.payload);
    const pdpVersion = yield (0, effects_1.select)(exports.getPdpVersion);
    const activeSizes = sizes === null || sizes === void 0 ? void 0 : sizes.filter((size) => (0, ProductReducer_1.isNotifyMeEnabled)(pdpVersion)
        ? (size.active && size.availability > 0) ||
            (size.active && size.availability <= 0 && size.historicAvailable && !size.storeSourceExcluded)
        : size.active && size.availability > 0);
    if ((activeSizes === null || activeSizes === void 0 ? void 0 : activeSizes.length) === 1) {
        const skuId = activeSizes[0].sku;
        const { servicesEndpoint, authenticationConfig } = yield (0, effects_1.getContext)('aemConfiguration');
        const productId = yield (0, effects_1.select)(exports.getProductId);
        yield (0, effects_1.fork)(getDeliveryPromiseFromRemote, servicesEndpoint, skuId, productId, authenticationConfig);
    }
}
exports.getDeliveryPromiseByColor = getDeliveryPromiseByColor;
function* getExtensiveTileProductDeliveryPromisesSaga(action) {
    const actionCreatorsMap = {
        [ActionEnums_1.ProductRecentlyViewedActionTypes.GET_DELIVERY_PROMISE]: ProductRecentlyViewedActions_creators_1.ProductRecentlyViewedActions.setDeliveryPromise,
        [ActionEnums_1.ProductGroupFredhopperTypes.GET_DELIVERY_PROMISE]: ProductGroupFredhopper_creators_1.ProductGroupFredhopperActions.setDeliveryPromise,
        [ActionEnums_1.ProductGroupActionTypes.GET_DELIVERY_PROMISE]: ProductGroupActions_creators_1.productGroupActions.setDeliveryPromise,
        [ActionEnums_1.ProductComparisonActionTypes.GET_DELIVERY_PROMISE]: ProductComparison_creators_1.productComparisonActions.setDeliveryPromise,
        [ActionEnums_1.SingleProductTileActionTypes.GET_DELIVERY_PROMISE]: SingleProductTileActions_creators_1.singleProductTileActions.setDeliveryPromise,
        [ActionEnums_1.ProductActionTypes.GET_PRODUCT_CARE_DELIVERY_PROMISE]: ProductActions_1.productActions.setProductCareDeliveryPromise,
        [ActionEnums_1.MonetateActionTypes.GET_DELIVERY_PROMISE]: MonetateActions_creators_1.monetateActions.setDeliveryPromise,
        [ActionEnums_1.WishListActionTypes.LISTER_GET_DELIVERY_PROMISE]: WishListActions_creators_1.wishListActions.setDeliveryPromise,
    };
    const actionCreator = actionCreatorsMap[action.type];
    try {
        const { skuId, productId, colourId, productGroupId } = action.payload;
        const { servicesEndpoint, authenticationConfig } = yield (0, effects_1.getContext)('aemConfiguration');
        yield (0, effects_1.fork)(getDeliveryPromiseFromRemote, servicesEndpoint, skuId, productId, authenticationConfig, actionCreator, colourId, productGroupId);
    }
    catch (_a) { }
}
exports.getExtensiveTileProductDeliveryPromisesSaga = getExtensiveTileProductDeliveryPromisesSaga;
function* deliveryPromisesSaga() {
    yield (0, effects_1.all)([getDeliveryPromiseSaga()]);
}
exports.default = deliveryPromisesSaga;
