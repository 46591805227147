"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DynamicMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_redux_1 = require("react-redux");
const ComponentConfigContext_1 = require("../../../../context/ComponentConfigContext");
const BasketOverviewMessagesUtil_1 = require("../../../../util/BasketOverviewMessagesUtil");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const DynamicMessage = () => {
    const { dynamicMessages } = (0, ComponentConfigContext_1.useBasketOverviewConfig)();
    const pricing = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPricingState)());
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const vouchers = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketVouchersState)());
    if (!dynamicMessages)
        return null;
    const dynamicMessage = (0, BasketOverviewMessagesUtil_1.getDynamicMessage)(dynamicMessages, products, pricing, vouchers);
    if (!dynamicMessage)
        return null;
    return (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "informative", text: dynamicMessage });
};
exports.DynamicMessage = DynamicMessage;
