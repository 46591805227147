"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const ReviewUtil_1 = require("../../util/ReviewUtil");
var FeefoOpenMode;
(function (FeefoOpenMode) {
    FeefoOpenMode["TAB"] = "tab";
    FeefoOpenMode["POPUP"] = "popup";
})(FeefoOpenMode || (FeefoOpenMode = {}));
const FEEFO_POPUP_TRIGGER_ID = 'feefologohere';
const FeefoBrandBadge = ({ openMode = FeefoOpenMode.TAB }) => {
    const { review: { feefoIdentifier }, } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.general);
    (0, react_1.useEffect)(() => {
        if (openMode === FeefoOpenMode.POPUP && feefoIdentifier) {
            (0, ReviewUtil_1.loadFeefoScript)(feefoIdentifier);
        }
    }, [openMode]);
    if (!feefoIdentifier)
        return null;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ConditionalWrapper, { condition: true, wrapper: children => openMode === FeefoOpenMode.TAB ? ((0, jsx_runtime_1.jsx)("a", { href: `https://www.feefo.com/reviews/${feefoIdentifier}`, target: "_blank", rel: "noreferrer", children: children })) : ((0, jsx_runtime_1.jsx)("div", { id: FEEFO_POPUP_TRIGGER_ID, children: children })), children: (0, jsx_runtime_1.jsx)("img", { alt: "Feefo logo", src: `https://api.feefo.com/api/logo?merchantidentifier=${feefoIdentifier}`, title: t(`${cotopaxi_1.I18N_NAMESPACES.general}:review.feefo.title`) }) }));
};
exports.default = FeefoBrandBadge;
