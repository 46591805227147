"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryEVoucher = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const CheckoutContext_1 = require("../../../context/CheckoutContext");
const andes_react_1 = require("@yonderland/andes-react");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const CheckoutDeliveryContext_1 = require("../../../context/CheckoutDeliveryContext");
const CustomerDetailsFields_1 = require("./CustomerDetailsFields");
const CustomerDetailsPreview_1 = require("./CustomerDetailsPreview");
const common_types_1 = require("@as-react/common-types");
const CustomerUtil_1 = require("../../../util/CustomerUtil");
const react_1 = require("react");
const DeliveryEVoucher = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const [{ info, errors }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { deliveryForm: { eVoucherTitle }, } = (0, CheckoutContext_1.useCheckoutConfig)();
    const [showCustomerDetailsForm, setShowCustomerDetailsForm] = (0, react_1.useState)(!CustomerUtil_1.default.isLoggedInUser(info));
    (0, react_1.useEffect)(() => {
        if (CustomerUtil_1.default.isLoggedInUser(info)) {
            setShowCustomerDetailsForm(false);
        }
    }, [info.customerDetails]);
    if (!info.orderReference) {
        return null;
    }
    const handleEditCustomerDetails = () => {
        setShowCustomerDetailsForm(true);
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard, { dataTestId: "delivery_option_evoucher", state: "active", children: [(0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "media", cellProps: { align: 'stretch' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "0.5", children: (0, jsx_runtime_1.jsx)(andes_react_1.RadioButton, { readOnly: true, id: "checkout-delivery-option-evoucher", name: "checkout-delivery-option-evoucher", checked: true }) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "header", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleS", children: eVoucherTitle }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "tag", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "success", children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.cost.free`) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: showCustomerDetailsForm ? ((0, jsx_runtime_1.jsx)(CustomerDetailsFields_1.CustomerDetailsFields, {})) : ((0, jsx_runtime_1.jsx)(CustomerDetailsPreview_1.CustomerDetailsPreview, { onEdit: handleEditCustomerDetails })) }), errors[common_types_1.DeliveryOptionsEnum.NAMED_DAY] && ((0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t(errors[common_types_1.DeliveryOptionsEnum.NAMED_DAY]), status: "error" }) }))] }));
};
exports.DeliveryEVoucher = DeliveryEVoucher;
