"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoucherBlock = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const FormField_1 = require("../../form/FormField");
const react_hook_form_1 = require("react-hook-form");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const react_i18next_1 = require("react-i18next");
const Yup = require("yup");
const yup_1 = require("@hookform/resolvers/yup");
const SuccessMessageBanner_1 = require("./SuccessMessageBanner");
const BASKET_COUPON_CODE_ID = 'basket-coupon-code';
const BASKET_COUPON_WEBCODE_ID = 'basket-coupon-webcode';
const VoucherBlock = ({ submitButtonLabel, onSubmit, isPaymentPage, isOpenByDefault = false, validateRegex, onAddSuccess, onAddError, onFieldsError, }) => {
    const { iconClasses, toggleProps, handleToggle, isOpen } = (0, andes_react_1.useCollapsible)({ open: isOpenByDefault });
    const [isSubmitting, setIsSubmitting] = (0, react_1.useState)(false);
    const [successMessages, setSuccessMessages] = (0, react_1.useState)([]);
    const [showWebcode, setShowWebcode] = (0, react_1.useState)(false);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.buy]);
    const validationSchema = Yup.object({
        code: Yup.string().required(t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.barcode.errormessage`)),
        webcode: showWebcode
            ? Yup.string().required(t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.webcode.errormessage`))
            : Yup.string(),
    });
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: { code: '', webcode: '' },
        resolver: (0, yup_1.yupResolver)(validationSchema),
    });
    const onCodeChange = (code) => {
        validateRegex && setShowWebcode(new RegExp(validateRegex).test(code));
    };
    const handleSubmit = (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ code, webcode }) {
        var _b, _c;
        if ((showWebcode && isPaymentPage) || !isPaymentPage) {
            try {
                setIsSubmitting(true);
                const appliedVoucher = yield onSubmit(code, webcode);
                setSuccessMessages(previousSuccessMessages => [
                    ...previousSuccessMessages,
                    { amount: appliedVoucher === null || appliedVoucher === void 0 ? void 0 : appliedVoucher.amountUsedForThisBasket },
                ]);
                formProps.reset();
                onAddSuccess(code);
                setIsSubmitting(false);
                isOpen && handleToggle();
            }
            catch (error) {
                setIsSubmitting(false);
                const message = (_c = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message;
                if (message) {
                    if (isDesktop) {
                        formProps.setError('code', { message });
                        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BASKET_COUPON_CODE_ID), isDesktop);
                    }
                    else {
                        formProps.setError('webcode', { message });
                        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BASKET_COUPON_WEBCODE_ID), isDesktop);
                    }
                }
                onAddError(message);
            }
        }
        if (!showWebcode && isPaymentPage) {
            formProps.setError('code', {
                type: 'custom',
                message: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.voucher.mediacode.error`),
            });
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(BASKET_COUPON_CODE_ID), isDesktop);
        }
    });
    const handleSuccessMessageDismiss = (index) => () => {
        setSuccessMessages(prev => prev.filter((successMessage, scmIndex) => scmIndex !== index));
    };
    (0, react_1.useEffect)(() => {
        if (onFieldsError && Object.keys(formProps.formState.errors).length) {
            const errors = Object.entries(formProps.formState.errors).reduce((res, [key, { message }]) => {
                if (message)
                    res[key] = message;
                return res;
            }, {});
            onFieldsError(errors);
        }
    }, [formProps.formState.errors.code, formProps.formState.errors.webcode]);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard, { onClick: handleToggle, dataTestId: "voucher_block", children: (0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard.Cell, { section: "content", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.CollapsibleToggle, Object.assign({}, toggleProps, { onClick: e => {
                        e.stopPropagation();
                        handleToggle();
                    }, tag: "div", dataTestId: "voucher_toggle", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { wrap: "nowrap", contentAlign: "between", gap: "2", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { wrap: "nowrap", gap: "2", children: [(0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.GiftIcon, color: "neutral", size: "sm" }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.voucher.title`) })] }), (0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { itemsAlign: "center", children: (0, jsx_runtime_1.jsx)("div", { className: iconClasses, children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.CaretDownIcon }) }) })] }), (successMessages === null || successMessages === void 0 ? void 0 : successMessages.length) > 0 && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "3", children: (0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "2", children: successMessages.map((successMessage, index) => ((0, jsx_runtime_1.jsx)(SuccessMessageBanner_1.SuccessMessageBanner, { amount: successMessage.amount, onDismiss: handleSuccessMessageDismiss(index) }, index))) }) }))] })), (0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleContent, { isOpen: isOpen, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { padding: "2", children: (0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(handleSubmit), name: "voucher-form", children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: 1, gapX: 4, gapY: 6, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "3", children: [(0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "code", required: true, children: (_a) => {
                                                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t('checkout:checkout.basket.voucher.barcode.label'), name: fieldProps.name, id: BASKET_COUPON_CODE_ID, dataTestId: "voucher_code", error: error, maxLength: 50, onKeyUp: () => {
                                                                onCodeChange(fieldProps.value);
                                                            }, onClick: event => event.stopPropagation() })));
                                                    } }), showWebcode && ((0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "webcode", children: (_a) => {
                                                        var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t('checkout:checkout.basket.voucher.webcode.label'), name: fieldProps.name, id: BASKET_COUPON_WEBCODE_ID, dataTestId: "voucher_webcode", error: error, maxLength: 50, onClick: event => event.stopPropagation() })));
                                                    } })), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { type: "submit", theme: "neutral", variant: "secondary", text: submitButtonLabel, onClick: event => {
                                                        event.stopPropagation();
                                                    }, state: isSubmitting ? 'loading' : 'interactive', dataTestId: "voucher_add" })] }) }) }) }) })) }) })] }) }));
};
exports.VoucherBlock = VoucherBlock;
