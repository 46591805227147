"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findContent = exports.findContentSaga = exports.getContentTags = exports.getContentPath = exports.getQueryInfo = void 0;
const effects_1 = require("redux-saga/effects");
const common_types_1 = require("@as-react/common-types");
const ContentAPI_1 = require("../../../api/content/ContentAPI");
const ContentActions_creators_1 = require("../../content/actions/ContentActions.creators");
const RouterSagas_1 = require("../../router/sagas/RouterSagas");
const ContentActionTypes_1 = require("../types/ContentActionTypes");
const UrlActions_creators_1 = require("../../url/actions/UrlActions.creators");
const SearchUtil_1 = require("../../../util/SearchUtil");
const getQueryInfo = (state) => state.url;
exports.getQueryInfo = getQueryInfo;
const getContentPath = (state) => state.content.path;
exports.getContentPath = getContentPath;
const getContentTags = (state) => state.content.tags;
exports.getContentTags = getContentTags;
function* findContentSaga() {
    yield (0, effects_1.takeLatest)(ContentActionTypes_1.ContentOverviewActionTypes.FIND_CONTENT, findContent);
}
exports.findContentSaga = findContentSaga;
function* findContent(action) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const contentPath = yield (0, effects_1.select)(exports.getContentPath);
        const contentTags = yield (0, effects_1.select)(exports.getContentTags);
        const { defaultRequestParams, servicesEndpoint, activeEnvironment, elasticsearchIndex } = aemConfigurationContext;
        const elasticSearchIndexWithEnv = (0, SearchUtil_1.getElasticSearchIndexWithEnv)(activeEnvironment, elasticsearchIndex);
        const queryInfo = yield (0, effects_1.select)(exports.getQueryInfo);
        const pushingRouterStateTask = yield (0, effects_1.fork)(RouterSagas_1.pushRouterState, Object.assign(Object.assign({}, queryInfo), { searchTerm: action.payload, page: 0, size: common_types_1.CONTENT_TILES_DEFAULT_COUNT }));
        let tags = [];
        if (contentTags) {
            tags = tags.concat(contentTags);
        }
        if (queryInfo.category) {
            tags.push(queryInfo.category);
        }
        if (queryInfo.type) {
            tags = tags.concat(queryInfo.type);
        }
        yield (0, effects_1.join)(pushingRouterStateTask);
        yield (0, effects_1.put)(UrlActions_creators_1.urlActions.setUrlParams(Object.assign(Object.assign({}, queryInfo), { searchTerm: action.payload, page: 0, size: common_types_1.CONTENT_TILES_DEFAULT_COUNT })));
        const content = yield (0, effects_1.call)(ContentAPI_1.default.getContentFromElasticSearch, defaultRequestParams, servicesEndpoint, elasticSearchIndexWithEnv, queryInfo.page, queryInfo.size, contentPath, action.payload, tags.length > 0 ? tags : null);
        yield (0, effects_1.put)(ContentActions_creators_1.contentOverviewActions.showPlaceholderTiles());
        yield (0, effects_1.put)(ContentActions_creators_1.contentOverviewActions.updaterContentAction(content));
    }
    catch (_a) { }
}
exports.findContent = findContent;
function* contentSaga() {
    yield (0, effects_1.all)([findContentSaga()]);
}
exports.default = contentSaga;
