"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const ProductRecentlyViewedActions_creators_1 = require("../../redux/productRecentlyViewed/actions/ProductRecentlyViewedActions.creators");
const ProductRecentlyViewedSelectors_1 = require("../../redux/productRecentlyViewed/selectors/ProductRecentlyViewedSelectors");
const common_components_1 = require("@as-react/common-components");
const WishListSelectors_1 = require("../../redux/wishList/selectors/WishListSelectors");
const MiniPDPContainer_1 = require("../MiniPDPContainer");
const ProductGroup_1 = require("../../components/product-group/ProductGroup");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const i18nConstants_1 = require("../../constants/i18nConstants");
const useProductTileHelpers_1 = require("../../hooks/useProductTileHelpers");
const ActionEnums_1 = require("../../enums/ActionEnums");
const ProductRecentlyViewedContainer = ({ tileFormat, deliveryPromiseExplanations, collapsed, collapsible, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const dispatch = (0, react_redux_1.useDispatch)();
    const { pageInfo, mainWebShop } = (0, common_components_1.useAemConfig)();
    const { isWishlistPage } = pageInfo;
    const productRecentlyViewed = (0, react_redux_1.useSelector)((0, ProductRecentlyViewedSelectors_1.maketGetProductRecentlyViewedState)());
    const wishlistItems = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetWishListProducts)());
    const wishlistTotalPages = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetWishlistTotalPages)());
    const { handleWishlistToggle, handleUpdateCompareList, handleAddToBasket, compareItems } = (0, useProductTileHelpers_1.useProductTileHelpers)();
    (0, react_1.useEffect)(() => {
        dispatch(ProductRecentlyViewedActions_creators_1.ProductRecentlyViewedActions.getRecentlyViewedProducts());
    }, []);
    const recentlyViewedProducts = (0, react_1.useMemo)(() => isWishlistPage
        ? productRecentlyViewed.filter(product => !wishlistItems.find(wishlistItem => wishlistItem.productId === product.productId))
        : productRecentlyViewed, [isWishlistPage, wishlistItems, productRecentlyViewed]);
    const getDeliveryPromise = (0, react_1.useCallback)((payload) => dispatch(ProductRecentlyViewedActions_creators_1.ProductRecentlyViewedActions.getDeliveryPromise(payload)), []);
    const [miniPDPProduct, setMiniPDPProduct] = (0, react_1.useState)(null);
    const handleMiniPDPClose = () => {
        setMiniPDPProduct(null);
    };
    if (recentlyViewedProducts.length === 0 && !miniPDPProduct) {
        return null;
    }
    if (isWishlistPage && wishlistTotalPages > 1) {
        return null;
    }
    const handleTileClick = (e, product, color) => {
        const isNotBever = mainWebShop !== GeneralConstants_1.FASCIA_AND_MAIN_WEB_SHOP['1_BEVER'];
        const isPhysicalVoucher = [
            common_types_1.ProductTypes.PAPER_VOUCHER,
            common_types_1.ProductTypes.CUSTOM_PAPER_VOUCHER,
            common_types_1.ProductTypes.GIFT_CARD,
            common_types_1.ProductTypes.CUSTOM_GIFT_CARD,
        ].includes(product.type);
        const isEVoucher = product.type === common_types_1.ProductTypes.E_VOUCHER;
        if (isEVoucher || (isPhysicalVoucher && isNotBever)) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        setMiniPDPProduct(Object.assign(Object.assign({}, product), { colourId: color.colourId }));
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ProductGroup_1.ProductGroup, { products: recentlyViewedProducts, onWishListToggle: handleWishlistToggle, updateCompareList: handleUpdateCompareList, productTileFormat: tileFormat, addToBasket: (...args) => handleAddToBasket(...args, ActionEnums_1.ProductAddToBasketLocations.RECENTLY_VIEWED), getDeliveryPromise: getDeliveryPromise, deliveryPromiseExplanations: deliveryPromiseExplanations, collapsed: collapsed, collapsible: collapsible, compareItems: compareItems, onTileClick: isWishlistPage ? handleTileClick : undefined, productGroupId: "product-recently-viewed", analyticsAreaName: "recently-viewed", title: t('pdp.recently-viewed.title'), analyticsProductGroupName: "recently_viewed" }), isWishlistPage && ((0, jsx_runtime_1.jsx)(MiniPDPContainer_1.MiniPDPContainer, { productCode: miniPDPProduct === null || miniPDPProduct === void 0 ? void 0 : miniPDPProduct.productCode, colorId: miniPDPProduct === null || miniPDPProduct === void 0 ? void 0 : miniPDPProduct.colourId, onClose: handleMiniPDPClose, deliveryPromiseExplanations: deliveryPromiseExplanations }))] }));
};
exports.default = ProductRecentlyViewedContainer;
