"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickupPoint = exports.sortOpeningHours = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const PickupPointUtil_1 = require("../../../../util/PickupPointUtil");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const NumberUtil_1 = require("../../../../util/NumberUtil");
const andes_icons_1 = require("@yonderland/andes-icons");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const DateUtil_1 = require("../../../../util/DateUtil");
const OpeningHours_1 = require("../../../opening-hours/OpeningHours");
const dayjs = require("dayjs");
const LOCALE_EN = 'en-GB';
const DATE_FORMAT = 'YYYY-MM-DD';
const HOUR_PLACEHOLDER = '00:00';
/**
 * Sorts the opening hours array by the current day of the week
 */
const sortOpeningHours = (openingHours) => {
    const today = dayjs();
    const { weekday: weekdayToday } = (0, DateUtil_1.formatTimeStampToDateParts)(today.toDate().getTime(), LOCALE_EN, {
        weekday: 'long',
    });
    // get all weekdays in English because the API returns them in English
    const weekdaysEN = (0, DateUtil_1.getAllWeekdays)(today.toDate(), LOCALE_EN);
    const allWeekdays = weekdaysEN.map((weekday, index) => {
        const knownOpeningHours = openingHours.find(schedule => schedule.dayOfWeek === weekday);
        const day = today.add(index, 'day');
        const date = day.format(DATE_FORMAT);
        return knownOpeningHours
            ? {
                date,
                weekday,
                closed: false,
                openingHour: knownOpeningHours.openingTime,
                closingHour: knownOpeningHours.closingTime,
            }
            : {
                date,
                weekday,
                closed: true,
                openingHour: HOUR_PLACEHOLDER,
                closingHour: HOUR_PLACEHOLDER,
            };
    });
    const indexCurrentDate = allWeekdays.findIndex(schedule => schedule.weekday === weekdayToday);
    return indexCurrentDate
        ? allWeekdays.slice(indexCurrentDate).concat(allWeekdays.slice(0, indexCurrentDate))
        : allWeekdays;
};
exports.sortOpeningHours = sortOpeningHours;
const PickupPoint = ({ pickupPoint, selected }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const { market } = (0, common_components_1.useAemConfig)();
    const [, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { address, city, distance, houseNumber, locationName, locationType, openingHours, organisationName, postcode, sanitizedLocationType, } = pickupPoint;
    const organisationNameWithType = `${organisationName}${PickupPointUtil_1.default.getTranslation(sanitizedLocationType, locationType, t)}`;
    const fullAdress = `${organisationNameWithType.trim()}, ${address}${houseNumber ? ` ${houseNumber}` : ''}, ${postcode} ${city}`;
    const isAlwaysOpen = PickupPointUtil_1.default.isAlwaysOpen(sanitizedLocationType || locationType);
    const getDistance = (market, distance) => (market === 'uk' ? distance : (0, NumberUtil_1.round)(distance / 1000, 0.1));
    const onClick = () => {
        dispatch({ type: 'highlight_pickup_point', payload: pickupPoint });
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard, { state: selected ? 'active' : 'resting', onClick: onClick, dataTestId: "pickuppoint_finder_item", children: [(0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "header", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleS", children: locationName }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "tag", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", strong: true, children: `${getDistance(market, distance)} ${t(market === 'uk'
                        ? 'checkout.delivery.pickuppoint.flyout.distance.unit.miles'
                        : 'checkout.delivery.pickuppoint.flyout.distance.unit.km')}` }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.MapPinIcon, size: "xs", color: "neutral" }) }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: fullAdress })] }) }), selected && ((0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", children: !!openingHours.length && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.ClockIcon, size: "xs", color: "neutral" }) }), isAlwaysOpen && selected ? ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t('checkout.delivery.pickuppoint.open.24') })) : (selected && ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "1", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, children: t('checkout.delivery.pickuppoint.openinghours.week') }), (0, jsx_runtime_1.jsx)(OpeningHours_1.OpeningHours, { openingHours: (0, exports.sortOpeningHours)(openingHours) })] })))] }) })) }))] }));
};
exports.PickupPoint = PickupPoint;
