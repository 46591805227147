"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useABTest = void 0;
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
// This hook provides us with 3 states:
// true: definitely render the AB test
// null: I'm not sure, wait for final answer
// false: definitely do not render the AB test
const useABTest = (abTestCookie) => {
    const abContext = (0, common_components_1.useABContextWrapper)();
    const hybridCookieUtil = new common_utils_1.HybridCookieUtil();
    if (typeof document !== 'undefined') {
        return !!hybridCookieUtil.hasABCookieSet(abTestCookie);
    }
    else {
        return (abContext === null || abContext === void 0 ? void 0 : abContext[abTestCookie]) || null;
    }
};
exports.useABTest = useABTest;
