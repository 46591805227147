"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyRegistrationContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const Registration_1 = require("../../components/account/registration/Registration");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const react_router_1 = require("react-router");
const cotopaxi_1 = require("@as/cotopaxi");
const BuySteps_1 = require("./buy-steps/BuySteps");
const AuthActions_creators_1 = require("../../redux/authentication/actions/AuthActions.creators");
const CheckoutActions_creators_1 = require("../../redux/checkout/actions/CheckoutActions.creators");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const RegistrationStep_1 = require("../../components/buy/login/login-flows/steps/RegistrationStep");
const BuyRegistrationContainer = ({ enableNewLoginFlows, registrationFormProps, history, }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const { root, isInAuthorMode } = (0, common_components_1.useAemConfig)();
    const { state: { email: defaultEmail }, } = (0, react_router_1.useLocation)();
    (0, react_1.useEffect)(() => {
        if (loggedIn) {
            setTimeout(() => {
                history.replace((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.DELIVERY}`, isInAuthorMode));
            }, GeneralConstants_1.DELAY.DELAY_500);
        }
    }, [loggedIn]);
    (0, react_1.useEffect)(() => {
        dispatch(CheckoutActions_creators_1.default.createSetHasRegistrationAction(true));
        dispatch(AnalyticsActions_creators_1.analyticsActions.registrationStep());
    }, []);
    const handleRegistrationSuccess = (email, password) => {
        dispatch(AuthActions_creators_1.authActions.registeredLogin({
            username: email,
            password: password,
            keepSignedIn: false,
            reloadOnSuccess: false,
            location: common_types_1.AuthFormLocation.CHECKOUT,
        }));
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, tablet: { spacing: { marginTop: 4 } }, desktop: { spacing: { marginTop: 7, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { desktop: { span: 8, push: 2 }, children: [(0, jsx_runtime_1.jsx)(BuySteps_1.BuySteps, { step: common_types_1.RoutePathname.REGISTRATION }), enableNewLoginFlows ? ((0, jsx_runtime_1.jsx)(RegistrationStep_1.RegistrationStep, Object.assign({ onSuccess: handleRegistrationSuccess, defaultEmail: defaultEmail }, registrationFormProps))) : ((0, jsx_runtime_1.jsx)(Registration_1.Registration, Object.assign({ onSuccess: handleRegistrationSuccess, defaultEmail: defaultEmail }, registrationFormProps)))] }) }) }));
};
exports.BuyRegistrationContainer = BuyRegistrationContainer;
