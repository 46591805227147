"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeDeliveryPhoneSelector = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const CustomerPhoneField_1 = require("../CustomerPhoneField");
const cotopaxi_1 = require("@as/cotopaxi");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const react_hook_form_1 = require("react-hook-form");
const Yup = require("yup");
const common_types_1 = require("@as-react/common-types");
const yup_1 = require("@hookform/resolvers/yup");
const SessionSelector_1 = require("../../../../redux/session/selectors/SessionSelector");
const react_redux_1 = require("react-redux");
const react_1 = require("react");
const CustomerUtil_1 = require("../../../../util/CustomerUtil");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const DeliverySheet_1 = require("../DeliverySheet");
const HomeDeliveryPhoneSelector = () => {
    var _a, _b;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const aemConfiguration = (0, common_components_1.useAemConfig)();
    const { defaultRequestParams, servicesEndpoint } = aemConfiguration;
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetIsLoggedIn)());
    const [{ info, openSelector, activeDeliveryOption }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const isOpen = openSelector === 'phone-number';
    const { updatePhoneNumber } = (0, common_queries_1.customerMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutate: updatePhoneNumberMutation } = (0, react_query_1.useMutation)(updatePhoneNumber());
    const initialPhoneNumber = CustomerUtil_1.default.convertPhoneNumberPrefix((_a = info.customerDetails) === null || _a === void 0 ? void 0 : _a.mobilePhoneNumber, '00', '+') || '';
    const validationSchema = Yup.object({
        mobilePhoneNumber: Yup.string().test('isPossiblePhoneNumber', t(common_types_1.ErrorMessage.INVALID_PHONE), val => !val || (0, cotopaxi_1.isPossiblePhoneNumber)(val)),
    });
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: { mobilePhoneNumber: initialPhoneNumber },
        mode: 'onTouched',
        resolver: (0, yup_1.yupResolver)(validationSchema),
    });
    (0, react_1.useEffect)(() => {
        var _a;
        formProps.reset({
            mobilePhoneNumber: CustomerUtil_1.default.convertPhoneNumberPrefix((_a = info.customerDetails) === null || _a === void 0 ? void 0 : _a.mobilePhoneNumber, '00', '+') || '',
        });
    }, [(_b = info.customerDetails) === null || _b === void 0 ? void 0 : _b.mobilePhoneNumber]);
    const handleSubmit = ({ mobilePhoneNumber, savePhoneNumber }) => {
        if (!mobilePhoneNumber || mobilePhoneNumber === initialPhoneNumber) {
            closeSelector();
            return;
        }
        const phoneNumber = CustomerUtil_1.default.convertPhoneNumberPrefix(mobilePhoneNumber, '+', '00');
        if (!savePhoneNumber) {
            updateCustomerPhoneNumber(phoneNumber);
            closeSelector();
            return;
        }
        updatePhoneNumberMutation(phoneNumber, {
            onSuccess: () => {
                updateCustomerPhoneNumber(phoneNumber);
                closeSelector();
            },
        });
    };
    const closeSelector = () => {
        dispatch({ type: 'set_open_selector', payload: undefined });
    };
    const updateCustomerPhoneNumber = (phone) => {
        dispatch({ type: 'set_customer_phone_number', payload: phone });
    };
    const getContent = () => ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(handleSubmit), noValidate: true, name: "pickup-point-search-form", children: (0, jsx_runtime_1.jsx)(CustomerPhoneField_1.CustomerPhoneField, { isRequired: activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.requiresPhoneNumber, showSavePhoneNumberCheckbox: isLoggedIn }) }) })));
    const getCTAContent = () => ((0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, theme: "branded", text: t('checkout:checkout.delivery.phone.flyout.confirm'), onClick: formProps.handleSubmit(handleSubmit) }));
    const cancelChangePhoneNumber = () => {
        dispatch({ type: 'set_open_selector', payload: undefined });
    };
    return (0, DeliverySheet_1.DeliverySheet)({
        isOpen,
        onClose: cancelChangePhoneNumber,
        getContent,
        showCTA: true,
        getCTAContent,
    });
};
exports.HomeDeliveryPhoneSelector = HomeDeliveryPhoneSelector;
