"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliverySummary = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const react_router_1 = require("react-router");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const BuyStoreAddressCard_1 = require("./BuyStoreAddressCard");
const BuyAddressCard_1 = require("../forms/buy-address-card/BuyAddressCard");
const DeliveryPromiseMessage_1 = require("../../../components/product-detail/delivery-promise/DeliveryPromiseMessage");
const DeliverySummary = ({ paymentInfo, storeDetails, hideBackToDeliveryLink, }) => {
    var _a, _b;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const history = (0, react_router_1.useHistory)();
    const { root, isInAuthorMode } = (0, common_components_1.useAemConfig)();
    const isPickUpPoint = paymentInfo.chosenDeliveryOption === common_types_1.DeliveryOptionsEnum.PICKUP_POINT;
    const isCollectHQ = paymentInfo.chosenDeliveryOption === common_types_1.DeliveryOptionsEnum.COLLECT_HQ;
    const redirectToDelivery = (e) => {
        e.preventDefault();
        history.push((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.DELIVERY}`, isInAuthorMode));
    };
    const deliveryPromise = paymentInfo.deliveryPromiseMessage
        ? {
            deliveryMessage: paymentInfo.deliveryPromiseMessage,
            hasDeliveryMessage: (_a = paymentInfo.hasDeliveryMessage) !== null && _a !== void 0 ? _a : true,
            name: paymentInfo.chosenDeliveryOption,
            cutoff: paymentInfo.deliveryPromiseCutoff,
        }
        : null;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: [t(`buy:buy.payment.delivery.information.header.${paymentInfo
                            .chosenDeliveryOption.toLowerCase()
                            .replace(/_/g, '')}`), !hideBackToDeliveryLink && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ['  ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: `${root}buy.html/${common_types_1.RoutePathname.DELIVERY}`, onClick: redirectToDelivery, dataQA: "back_to_delivery", children: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.edit`) }) }), ' '] }))] }) }), !isCollectHQ && paymentInfo.deliveryAddress && !paymentInfo.deliveryAddress.storeNumber && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(BuyAddressCard_1.BuyAddressCard, { address: paymentInfo.deliveryAddress, pickUpPoint: isPickUpPoint, textSize: cotopaxi_1.TextSize.SMALL }) })), storeDetails && ((_b = paymentInfo === null || paymentInfo === void 0 ? void 0 : paymentInfo.deliveryAddress) === null || _b === void 0 ? void 0 : _b.storeNumber) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(BuyStoreAddressCard_1.default, { storeDetails: storeDetails, textSize: cotopaxi_1.TextSize.SMALL }) })), deliveryPromise && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: deliveryPromise.cutoff ? ((0, jsx_runtime_1.jsx)(DeliveryPromiseMessage_1.default, { deliveryPromise: deliveryPromise })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.POSITIVE, dataQA: "delivery_promise", children: paymentInfo.deliveryPromiseMessage })) }))] }));
};
exports.DeliverySummary = DeliverySummary;
