"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFieldsBEWithValidation = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const formik_1 = require("formik");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const useDebouncedQuery_1 = require("../../../../../hooks/useDebouncedQuery");
const BuyFormUtil_1 = require("../../../../../util/BuyFormUtil");
const createMunicipalityOptions = (data) => (data === null || data === void 0 ? void 0 : data.map(item => ({
    text: `${item.postCode} - ${item.subMunicipality}`,
    id: item.subMunicipality,
    postCode: item.postCode,
    city: item.subMunicipality,
}))) || [];
const AddressFieldsBEWithValidation = ({ errors, isRequired = true, fields, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const { values, setFieldValue, touched, status, setFieldTouched } = (0, formik_1.useFormikContext)();
    const { defaultRequestParams, servicesEndpoint, lang } = (0, common_components_1.useAemConfig)();
    const { getMunicipality, getStreet, getHouseNumber, getBoxNumber } = (0, common_queries_1.postalQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const [addressName, houseNumberName, houseNumberAdditionName, postCodeName, cityName] = fields;
    const postCode = (0, formik_1.getIn)(values, postCodeName);
    const city = (0, formik_1.getIn)(values, cityName);
    const address = (0, formik_1.getIn)(values, addressName);
    const houseNumber = (0, formik_1.getIn)(values, houseNumberName);
    const houseNumberAddition = (0, formik_1.getIn)(values, houseNumberAdditionName);
    const isFocussed = (fieldName) => Boolean((status === null || status === void 0 ? void 0 : status.currentFocusField) === fieldName);
    const { data: postCodes } = (0, useDebouncedQuery_1.useDebouncedQuery)(getMunicipality({ lang, query: postCode }, {
        enabled: isFocussed(postCodeName) && postCode !== '',
        select: createMunicipalityOptions,
    }));
    const { data: cities } = (0, useDebouncedQuery_1.useDebouncedQuery)(getMunicipality({ lang, query: (0, BuyFormUtil_1.isValidPostCode)(postCode, 'BE') ? postCode : city, city }, {
        enabled: isFocussed(cityName) && city !== '',
        select: data => (0, BuyFormUtil_1.isValidPostCode)(postCode, 'BE')
            ? createMunicipalityOptions(data).filter(item => item.city.toLowerCase().includes(city.toLowerCase()))
            : createMunicipalityOptions(data),
    }));
    const { data: streets } = (0, useDebouncedQuery_1.useDebouncedQuery)(getStreet({ lang, municipality: city, postcode: postCode, query: address }, {
        enabled: isFocussed(addressName) && address !== '' && Boolean(postCode || city),
        select: data => (data === null || data === void 0 ? void 0 : data.map(item => ({
            text: item.street,
            id: item.street,
        }))) || [],
    }));
    const { data: houseNumbers } = (0, useDebouncedQuery_1.useDebouncedQuery)(getHouseNumber({ lang, municipality: city, postcode: postCode, street: address, query: houseNumber }, {
        enabled: isFocussed(houseNumberName) && houseNumber !== '' && Boolean(address && (postCode || city)),
        select: data => (data === null || data === void 0 ? void 0 : data.map(item => ({
            text: item.houseNumber,
            id: item.houseNumber,
        }))) || [],
    }));
    const { data: boxNumbers } = (0, useDebouncedQuery_1.useDebouncedQuery)(getBoxNumber({ lang, municipality: city, postcode: postCode, street: address, houseNumber, query: houseNumberAddition }, {
        enabled: isFocussed(houseNumberAdditionName) && Boolean(houseNumber && address && (postCode || city)),
        select: data => (data === null || data === void 0 ? void 0 : data.map(item => ({
            text: item.boxNumber,
            id: item.boxNumber,
        }))) || [],
    }));
    const chooseMunicipalityFromList = (_i, option) => {
        setFieldValue(postCodeName, option.postCode);
        setFieldValue(cityName, option.city);
        setFieldTouched(postCodeName, false, true);
        setFieldTouched(cityName, false, true);
    };
    const chooseStreetFromList = (_i, option) => {
        setFieldValue(addressName, option.id);
        setFieldTouched(addressName, false, true);
    };
    const chooseHouseNumberFromList = (_i, option) => {
        setFieldValue(houseNumberName, option.id);
        setFieldTouched(houseNumberName, false, true);
    };
    const chooseBoxNumberFromList = (_i, option) => {
        setFieldValue(houseNumberAdditionName, option.id);
        setFieldTouched(houseNumberAdditionName, false, true);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, tablet: 6, desktop: 3, noGutterLeft: true, noGutterRight: isMobile, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: { spacing: { marginBottom: 1 } }, desktop: { spacing: { marginBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: isRequired, emphasized: true, htmlFor: postCodeName, size: cotopaxi_1.LabelSize.MINOR, children: t('address.postCode') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { id: postCodeName, name: postCodeName, input: {
                                        id: `address-${postCodeName}`,
                                        name: postCodeName,
                                        value: postCode,
                                        placeholder: t('placeholder.postal.code.be'),
                                        state: (0, formik_1.getIn)(errors, postCodeName) && (0, formik_1.getIn)(touched, postCodeName) && cotopaxi_1.InputState.ERROR,
                                        minLength: 4,
                                        maxLength: 50,
                                        required: isRequired,
                                        autoComplete: cotopaxi_1.AutoComplete.NEW_PASSWORD,
                                    }, items: postCode ? postCodes || [] : [], chooseItem: chooseMunicipalityFromList, placeholder: t('placeholder.postal.code.be'), Component: cotopaxi_1.Autocomplete, componentType: cotopaxi_1.FormFieldType.AUTOCOMPLETE, dataQA: "postcode", required: isRequired })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, tablet: 6, desktop: 5, noGutterLeft: isMobile, noGutterRight: !isDesktop, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: { spacing: { marginBottom: 1 } }, desktop: { spacing: { marginBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: isRequired, emphasized: true, htmlFor: cityName, size: cotopaxi_1.LabelSize.MINOR, children: t('address.city') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { chooseItem: chooseMunicipalityFromList, Component: cotopaxi_1.Autocomplete, componentType: cotopaxi_1.FormFieldType.AUTOCOMPLETE, dataQA: "town_city", id: cityName, input: {
                                        id: `address-${cityName}`,
                                        name: cityName,
                                        placeholder: t('address.city.choose'),
                                        required: isRequired,
                                        state: (0, formik_1.getIn)(errors, cityName) && (0, formik_1.getIn)(touched, cityName) && cotopaxi_1.InputState.ERROR,
                                        value: city,
                                        autoComplete: cotopaxi_1.AutoComplete.NEW_PASSWORD,
                                    }, items: city ? cities || [] : [], name: cityName, required: isRequired })] }) })] }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { parent: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, tablet: 6, desktop: 6, noGutterLeft: true, noGutterRight: isMobile, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: isRequired, emphasized: true, htmlFor: addressName, size: cotopaxi_1.LabelSize.MINOR, children: t('address.street') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { chooseItem: chooseStreetFromList, Component: cotopaxi_1.Autocomplete, componentType: cotopaxi_1.FormFieldType.AUTOCOMPLETE, dataQA: "address_line1", id: addressName, input: {
                                        id: `address-${addressName}`,
                                        maxLength: 500,
                                        name: addressName,
                                        required: isRequired,
                                        state: (0, formik_1.getIn)(errors, addressName) && (0, formik_1.getIn)(touched, addressName) && cotopaxi_1.InputState.ERROR,
                                        value: address,
                                        autoComplete: cotopaxi_1.AutoComplete.NEW_PASSWORD,
                                    }, items: addressName ? streets || [] : [], name: addressName, required: isRequired })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, tablet: 3, desktop: 3, noGutterLeft: isMobile, noGutterRight: isMobile, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { required: isRequired, emphasized: true, htmlFor: houseNumberName, size: cotopaxi_1.LabelSize.MINOR, children: t('address.houseNumber') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { chooseItem: chooseHouseNumberFromList, Component: cotopaxi_1.Autocomplete, componentType: cotopaxi_1.FormFieldType.AUTOCOMPLETE, dataQA: "houseNumber", id: houseNumberName, input: {
                                        id: `address-${houseNumberName}`,
                                        maxLength: 20,
                                        name: houseNumberName,
                                        required: isRequired,
                                        state: (0, formik_1.getIn)(errors, houseNumberName) && (0, formik_1.getIn)(touched, houseNumberName) && cotopaxi_1.InputState.ERROR,
                                        value: houseNumber,
                                        autoComplete: cotopaxi_1.AutoComplete.NEW_PASSWORD,
                                    }, items: houseNumber ? houseNumbers || [] : [], name: houseNumberName, required: isRequired })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, tablet: 3, desktop: 3, noGutterLeft: isMobile, noGutterRight: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { emphasized: true, htmlFor: houseNumberAdditionName, size: cotopaxi_1.LabelSize.MINOR, children: t('address.houseNumberAddition') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { chooseItem: chooseBoxNumberFromList, Component: cotopaxi_1.Autocomplete, componentType: cotopaxi_1.FormFieldType.AUTOCOMPLETE, dataQA: "houseNumberAddition", id: houseNumberAdditionName, input: {
                                        id: `address-${houseNumberAdditionName}`,
                                        maxLength: 20,
                                        name: houseNumberAdditionName,
                                        state: (0, formik_1.getIn)(errors, houseNumberAdditionName) && (0, formik_1.getIn)(touched, houseNumberAdditionName) && cotopaxi_1.InputState.ERROR,
                                        value: houseNumberAddition,
                                        autoComplete: cotopaxi_1.AutoComplete.NEW_PASSWORD,
                                    }, items: boxNumbers || [], name: houseNumberAdditionName })] }) })] })] }));
};
exports.AddressFieldsBEWithValidation = AddressFieldsBEWithValidation;
