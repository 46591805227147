"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchMonetateProducts = void 0;
const common_utils_1 = require("@as-react/common-utils");
const effects_1 = require("redux-saga/effects");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const CookieConsentSelector_1 = require("../../cookieConsent/selectors/CookieConsentSelector");
const MonetateActions_creators_1 = require("../actions/MonetateActions.creators");
const MonetateSelectors_1 = require("../selectors/MonetateSelectors");
const MonetateUtil_1 = require("../../../util/MonetateUtil");
const EventEnum_1 = require("../../../enums/EventEnum");
const GeneralEnums_1 = require("../../../enums/GeneralEnums");
const ProductsAPI_1 = require("../../../api/ProductsAPI");
const ProductDetailAPI_1 = require("../../../api/ProductDetailAPI");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
function* customEventSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.GeneralActionTypes.CUSTOM_EVENT, handleCustomEvent);
}
function* handleCustomEvent(action) {
    switch (action.payload.eventType) {
        case EventEnum_1.EventEnum.MONETATE_SCRIPT_LOADED:
            yield (0, effects_1.put)(MonetateActions_creators_1.monetateActions.loaded());
            break;
        default:
            break;
    }
}
function* initMonetateSaga() {
    yield (0, effects_1.take)(ActionEnums_1.MonetateActionTypes.INIT);
    yield (0, effects_1.call)(initMonetate);
}
function* loadMonetateSaga() {
    yield (0, effects_1.take)(ActionEnums_1.MonetateActionTypes.LOAD);
    yield (0, effects_1.call)(loadMonetate);
}
function* onCookieConsentChangeSaga() {
    yield (0, effects_1.take)(ActionEnums_1.CookieConsentActionTypes.SET);
    yield (0, effects_1.call)(checkForMonetateLoad);
}
function* initMonetate() {
    const consentMarketing = yield (0, effects_1.select)((0, CookieConsentSelector_1.makeGetHasConsentOnMarketingCookies)());
    const isCookieConsentAccepted = LocalStorageUtil_1.default.getItem(StorageKeysConstants_1.COOKIE_CONSENT_ACCEPTED);
    if (isCookieConsentAccepted || consentMarketing) {
        yield (0, effects_1.put)(MonetateActions_creators_1.monetateActions.load());
    }
    else {
        yield (0, effects_1.put)(MonetateActions_creators_1.monetateActions.onHold());
    }
}
function* loadMonetate() {
    const { monetateUrl } = yield (0, effects_1.getContext)('aemConfiguration');
    if (monetateUrl) {
        yield (0, effects_1.call)(MonetateUtil_1.default.init, monetateUrl);
    }
}
function* checkForMonetateLoad() {
    const consentMarketing = yield (0, effects_1.select)((0, CookieConsentSelector_1.makeGetHasConsentOnMarketingCookies)());
    const monetateState = yield (0, effects_1.select)(MonetateSelectors_1.monetateStateSelector);
    if (consentMarketing && monetateState.scriptState === GeneralEnums_1.ScriptLoadingState.ON_HOLD) {
        yield (0, effects_1.put)(MonetateActions_creators_1.monetateActions.load());
    }
}
function* fetchProductsSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.MonetateActionTypes.FETCH_PRODUCTS, fetchProducts);
}
function* fetchMonetateProducts({ actionId, size, filterProductId }) {
    const recommendations = yield (0, effects_1.select)((0, MonetateSelectors_1.makeGetMonetateRecommendations)(actionId));
    const { defaultRequestParams, servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
    const products = yield (0, effects_1.all)(recommendations
        .filter(({ productcode }) => productcode)
        .map(({ productcode, idColour }) => (0, effects_1.call)(ProductsAPI_1.default.getProductInformationAPI, servicesEndpoint, defaultRequestParams, productcode, idColour ? Number(idColour) : undefined)));
    const availableProducts = products.filter(product => product && product.productCode && product.availability > 0);
    const filteredIdProducts = filterProductId
        ? availableProducts.filter(product => product.productId !== Number(filterProductId))
        : availableProducts;
    const filteredProducts = size ? filteredIdProducts.slice(0, size) : filteredIdProducts;
    const productsWithAvailableColors = filteredProducts.map(product => {
        const availableColors = product.colours.filter(color => color.availability > 0);
        const isDefaultColorAvailable = availableColors.find(color => color.colourId === product.colourId);
        return Object.assign(Object.assign({}, product), { colourId: isDefaultColorAvailable ? product.colourId : availableColors[0].colourId, colours: availableColors });
    });
    const productsPrices = yield ProductDetailAPI_1.default.getAllProductDetailPriceByProductId(productsWithAvailableColors.map(product => product.productId), servicesEndpoint, defaultRequestParams);
    const productsWithPrices = productsWithAvailableColors.map(product => {
        const productPrice = productsPrices.find(price => product.productId === price.productId);
        return (0, common_utils_1.mergeProductPricesToProductLister)(product, productPrice);
    });
    return productsWithPrices;
}
exports.fetchMonetateProducts = fetchMonetateProducts;
function* fetchProducts({ payload }) {
    try {
        const products = yield (0, effects_1.call)(fetchMonetateProducts, payload);
        yield (0, effects_1.put)(MonetateActions_creators_1.monetateActions.setMonetateProducts({ [payload.actionId]: products }));
    }
    catch (_a) { }
}
function* monetateSaga() {
    yield (0, effects_1.all)([
        initMonetateSaga(),
        onCookieConsentChangeSaga(),
        loadMonetateSaga(),
        customEventSaga(),
        fetchProductsSaga(),
    ]);
}
exports.default = monetateSaga;
