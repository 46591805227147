"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoBasketPopupSizeSelect = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const SizeSelectV2_1 = require("../product-detail/size-select-v2/SizeSelectV2");
const PromoBasketPopupSizeSelect = ({ basketValidationState, sizes, selectedSku, sizeRecommendation, selectSize, }) => {
    const { oneSizeProductCode } = (0, common_components_1.useAemConfig)();
    const oneSizeProduct = (0, common_utils_1.isOneSizeProduct)(sizes, oneSizeProductCode);
    if (oneSizeProduct) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, tablet: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(SizeSelectV2_1.SizeSelectV2, { id: "promo_basket_popup_size_select", isModal: true, sizes: sizes, selectedSku: selectedSku, selectSize: selectSize, sizeRecommendation: sizeRecommendation, basketValidationState: basketValidationState, isOneSizeProduct: oneSizeProduct }) }));
};
exports.PromoBasketPopupSizeSelect = PromoBasketPopupSizeSelect;
