"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreActualInfoTag = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const StoreInfoTag_1 = require("./StoreInfoTag");
const common_components_1 = require("@as-react/common-components");
dayjs.extend(utc);
dayjs.extend(timezone);
const StoreActualInfoTag = ({ scheduleEntries }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.store);
    const { market } = (0, common_components_1.useAemConfig)();
    const todayDate = dayjs().tz(GeneralConstants_1.TIMEZONE_BY_MARKET[market]);
    const todayTimeInMinutes = todayDate.hour() * 60 + todayDate.minute();
    const todayIndex = scheduleEntries.findIndex(entry => dayjs().isSame(entry.date, 'day')); // use new dayjs instance to avoid an issue comparing different timezones
    const todaySchedule = scheduleEntries[todayIndex];
    const getTranslateKey = (prefix) => {
        return todaySchedule.isExceptional ? `${prefix}.exceptionally` : `${prefix}.text`;
    };
    const currentlyOpened = !todaySchedule.closed &&
        todayTimeInMinutes >= todaySchedule.openTimeInMinutes &&
        todayTimeInMinutes < todaySchedule.closeTimeInMinutes;
    const openingLaterToday = !todaySchedule.closed && todayTimeInMinutes < todaySchedule.openTimeInMinutes;
    const openingTomorrow = !scheduleEntries[todayIndex + 1].closed;
    const openingLater = scheduleEntries.slice(todayIndex + 2).find(entry => !entry.closed);
    const indicationColor = currentlyOpened ? cotopaxi_1.TextColor.POSITIVE : cotopaxi_1.TextColor.NEGATIVE;
    const getText = () => {
        if (currentlyOpened) {
            return t(getTranslateKey('store.open.till'), { endHour: todaySchedule.closingHour });
        }
        if (openingLaterToday) {
            return t('store.open.from.text', { startHour: todaySchedule.openingHour });
        }
        if (openingTomorrow) {
            return t(getTranslateKey('store.open.tomorrow'), {
                startHour: scheduleEntries[todayIndex + 1].openingHour,
            });
        }
        if (openingLater) {
            return t(getTranslateKey('store.open.other'), {
                weekday: t(`store.day.${openingLater.day.toLowerCase()}`).toLowerCase(),
                startHour: openingLater.openingHour,
            });
        }
        return t('store.closed.txt');
    };
    return ((0, jsx_runtime_1.jsx)(StoreInfoTag_1.StoreInfoTag, { icon: cotopaxi_1.IconName.CLOCK, color: indicationColor, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: indicationColor, inline: true, children: getText() }) }));
};
exports.StoreActualInfoTag = StoreActualInfoTag;
