"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmbeddedContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const react_dom_1 = require("react-dom");
const BOTTOM_SHEET_HEIGHT = '80vh';
const BOTTOM_SHEET_INNER_SPACING = '96px';
const PRODUCT_OR_CATEGORY_PAGE_KEYS = ['p', 'c', 'categories'].join('|');
const PRODUCT_OR_CATEGORY_PAGE_REG_EXP = new RegExp(`/(${PRODUCT_OR_CATEGORY_PAGE_KEYS})/`);
const STORE_OR_BRAND_PAGE_KEYS = ['winkels', 'magasins', 'stores', 'merken', 'marques', 'brands'].join('|');
const STORE_OR_BRAND_PAGE_REG_EXP = new RegExp(`/(${STORE_OR_BRAND_PAGE_KEYS})[./]`);
const isProductOrCategoryUrl = (url) => url.search(PRODUCT_OR_CATEGORY_PAGE_REG_EXP) !== -1;
const isStoreOrBrandPageUrl = (url) => url.search(STORE_OR_BRAND_PAGE_REG_EXP) !== -1;
const EmbeddedContent = ({ href, isOpen, onClose }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isClient = (0, cotopaxi_1.useIsClient)();
    const { rootWithoutFinalSlash } = (0, common_components_1.useAemConfig)();
    const framelessURL = href.replace('.html', '.frameless.html');
    (0, cotopaxi_1.useLockBodyScroll)(isOpen);
    if (!isClient || !isOpen)
        return null;
    const isHomePageUrl = (url) => {
        const urlObj = new URL(url);
        urlObj.search = '';
        urlObj.hash = '';
        const urlWithoutParams = urlObj.toString();
        const origin = window.location.origin;
        if (rootWithoutFinalSlash) {
            return urlWithoutParams === `${origin}${rootWithoutFinalSlash}.html`;
        }
        else {
            return urlWithoutParams === origin || urlWithoutParams === `${origin}/`;
        }
    };
    const handleContentLoaded = (e) => {
        const iframe = e.target;
        if (iframe === null || iframe === void 0 ? void 0 : iframe.contentDocument) {
            iframe.contentDocument.addEventListener('click', e => {
                const link = e.target.closest('a');
                if (link && link.href) {
                    if (isProductOrCategoryUrl(link.href) || isStoreOrBrandPageUrl(link.href) || isHomePageUrl(link.href)) {
                        link.setAttribute('target', '_parent');
                    }
                    else {
                        link.setAttribute('target', '_self');
                        link.href = link.href.replace('.html', '.frameless.html');
                    }
                }
            });
        }
    };
    const getContent = (isBottomSheet) => ((0, jsx_runtime_1.jsx)("iframe", { width: "100%", style: {
            height: isBottomSheet ? `calc(${BOTTOM_SHEET_HEIGHT} - ${BOTTOM_SHEET_INNER_SPACING})` : '100%',
            border: 0,
        }, src: framelessURL, title: "embedded_content", onLoad: handleContentLoaded }));
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, react_dom_1.createPortal)(isMobile ? ((0, jsx_runtime_1.jsx)(andes_react_1.BottomSheet, { isOpen: true, onClose: onClose, children: getContent(true) })) : ((0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, { isOpen: true, onClose: onClose, side: "right", align: "top", position: "fixed", hasCloseBtnSpacing: false, customWidth: { width: '50vw', maxWidth: '800px', minWidth: '480px' }, children: getContent() })), document.body) }));
};
exports.EmbeddedContent = EmbeddedContent;
