"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailValidationWrapper = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const react_i18next_1 = require("react-i18next");
const Yup = require("yup");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const FormValidationUtil_1 = require("../../../util/FormValidationUtil");
const EmailValidationUtil_1 = require("../../email/EmailValidationUtil");
const andes_react_1 = require("@yonderland/andes-react");
const FormEmailError_1 = require("../FormEmailError");
const EmailValidationWrapper = ({ emailFieldKey, checkExists, errorMessages, sendEmail, disableValidation = false, children, setIsCollapsedForm, defaultSkipValidationFor, }) => {
    var _a;
    const { watch, getFieldState, setValue, formState, clearErrors } = (0, react_hook_form_1.useFormContext)();
    const { isSubmitting } = formState;
    const emailValue = watch(emailFieldKey);
    const { error: emailError } = getFieldState(emailFieldKey, formState);
    const validatedEmail = (0, react_1.useRef)(null);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const [suggestedEmail, setSuggestedEmail] = (0, react_1.useState)();
    const [emailStatus, setEmailStatus] = (0, react_1.useState)(undefined);
    const [skipValidationFor, setSkipValidationFor] = (0, react_1.useState)(defaultSkipValidationFor);
    const skipEmailValidation = Boolean(emailValue) && Boolean(skipValidationFor) && skipValidationFor === emailValue;
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const requiredMessage = (_a = errorMessages.required) !== null && _a !== void 0 ? _a : t('email.error.required');
    const validateEmailCallback = (0, react_1.useCallback)((0, EmailValidationUtil_1.validateEmail)(servicesEndpoint, skipEmailValidation, emailStatus, setSuggestedEmail, setEmailStatus, true, skipValidationFor), [skipEmailValidation, emailStatus, skipValidationFor]);
    const schema = Yup.string()
        .when('$regularCheck', ([regularCheck], tmpSchema) => regularCheck
        ? tmpSchema.required(requiredMessage).matches(FormValidationUtil_1.default.emailPatternRegex, errorMessages.invalidEmail)
        : tmpSchema)
        .when('$backendEmailValidationEnabled', ([backendValidation], tmpSchema) => backendValidation ? tmpSchema.test(validateEmailCallback).required(requiredMessage) : tmpSchema)
        .when('$checkExists', ([contextCheckExists], tmpSchema) => contextCheckExists
        ? tmpSchema
            .test((0, EmailValidationUtil_1.validateEmailExists)(servicesEndpoint, defaultRequestParams, errorMessages, true, true, sendEmail, setIsCollapsedForm))
            .required(requiredMessage)
        : tmpSchema)
        .when('$checkExistsWithValidation', ([checkExistsWithValidation], tmpSchema) => checkExistsWithValidation
        ? tmpSchema
            .test((0, EmailValidationUtil_1.validateEmailExists)(servicesEndpoint, defaultRequestParams, errorMessages, true, true, sendEmail, setIsCollapsedForm, true, skipEmailValidation, emailStatus, setSuggestedEmail, setEmailStatus))
            .required(requiredMessage)
        : tmpSchema);
    const handleEmailValidation = (shouldCheckExists) => (email) => {
        if (email === validatedEmail.current) {
            return emailError === null || emailError === void 0 ? void 0 : emailError.message;
        }
        validatedEmail.current = email;
        const backendEmailValidationEnabledContext = !(shouldCheckExists && checkExists);
        const checkExistsWithValidationContext = shouldCheckExists && checkExists;
        return schema
            .validate(email, {
            context: {
                checkExists: false,
                backendEmailValidationEnabled: backendEmailValidationEnabledContext,
                checkExistsWithValidation: checkExistsWithValidationContext,
                regularCheck: !backendEmailValidationEnabledContext && !checkExistsWithValidationContext,
            },
        })
            .then(() => undefined)
            .catch(error => error.message);
    };
    const handleAcceptClick = () => {
        setEmailStatus(`${common_types_1.EmailValidationResponseStatus.VALID}_${suggestedEmail}`);
        setValue(emailFieldKey, suggestedEmail, { shouldValidate: true });
    };
    const handleIgnoreClick = () => {
        clearErrors(emailFieldKey);
    };
    const renderError = (msg, textProps) => ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, Object.assign({ variant: "bodyS" }, textProps, { children: (0, jsx_runtime_1.jsx)(FormEmailError_1.FormEmailError, { emailStatus: msg, suggestedEmail: suggestedEmail, requiredErrorLabel: requiredMessage, onAcceptClick: handleAcceptClick, onIgnoreClick: handleIgnoreClick }) })));
    (0, react_1.useEffect)(() => {
        if ((emailError === null || emailError === void 0 ? void 0 : emailError.message) && setEmailStatus) {
            setEmailStatus(emailError.message);
        }
    }, [emailError]);
    (0, react_1.useEffect)(() => {
        if (skipValidationFor && emailValue !== skipValidationFor) {
            setSkipValidationFor(undefined);
        }
    }, [emailValue]);
    return ((0, jsx_runtime_1.jsx)("div", { children: children({
            validate: disableValidation ? undefined : handleEmailValidation(!isSubmitting),
            renderError,
        }) }));
};
exports.EmailValidationWrapper = EmailValidationWrapper;
