"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutDisabledMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ScrollUtil_1 = require("../../../../util/ScrollUtil");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const CheckoutDisabledMessage = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isDesktop = (0, andes_react_1.useMediaQueryMatchesOnDesktop)();
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const scrollToStoreSourceExcludedProduct = () => {
        const firstSSEProductIndex = products.findIndex(product => (product.sseProduct && !product.availableInSSEStore) ||
            (product.specialtyStoreProduct && !product.availableInSpecialtyStore));
        const element = document.querySelectorAll('[data-testid^="basket-product-tile-"]')[firstSSEProductIndex];
        ScrollUtil_1.ScrollUtil.scrollToElement(element, true, 'center');
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { color: "error", variant: "bodyS", children: [t('product.store.source.excluded.general'), (0, jsx_runtime_1.jsx)(andes_react_1.ConditionalWrapper, { condition: !isDesktop, wrapper: children => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [' ', (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { size: "sm", text: children, onClick: scrollToStoreSourceExcludedProduct })] })), children: t('product.store.source.excluded.edit.part2') }), t('product.store.source.excluded.edit.part3')] }));
};
exports.CheckoutDisabledMessage = CheckoutDisabledMessage;
