"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const CountrySelector_1 = require("../../../components/buy/delivery/country-selector/CountrySelector");
const DeliveryOptions_1 = require("../../../components/buy/delivery/delivery-options/DeliveryOptions");
const DeliveryEVoucherContainer_1 = require("../DeliveryEVoucherContainer");
const CheckoutActions_creators_1 = require("../../../redux/checkout/actions/CheckoutActions.creators");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const CheckoutSelector_1 = require("../../../redux/checkout/selectors/CheckoutSelector");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const SessionSelector_1 = require("../../../redux/session/selectors/SessionSelector");
const BasketActions_creators_1 = require("../../../redux/basket/actions/BasketActions.creators");
const BuyFormUtil_1 = require("../../../util/BuyFormUtil");
const AnalyticsEnums_1 = require("../../../enums/AnalyticsEnums");
const BuyDeliveryContainer = ({ singleDeliveryMessage, eVoucherTitle, collectInStorePromiseMsg, }) => {
    const { lang } = (0, common_components_1.useAemConfig)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const deliveryCountries = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliveryCountries)());
    const deliveryCountryId = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliveryCountryId)());
    const deliveryOptions = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliveryOptions)());
    const deliveryLoading = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetDeliveryLoading)());
    const chosenDeliveryOption = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetChosenDeliveryOption)());
    const requiresDelivery = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetRequiresDelivery)());
    const orderLinesHaveDifferentDeliveryPromises = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketDifferentDeliveryPromisesState)());
    const basketTotalQuantity = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const showSingleDeliveryMessage = basketTotalQuantity > 1 && orderLinesHaveDifferentDeliveryPromises && singleDeliveryMessage && requiresDelivery;
    const changeDeliveryCountry = (countryId) => dispatch(CheckoutActions_creators_1.default.createChangeDeliveryCountryAction(countryId));
    const changeDeliveryOption = (optionName) => {
        dispatch(CheckoutActions_creators_1.default.createChangeDeliveryOptionAction(optionName));
        dispatch(BasketActions_creators_1.basketActions.createGetAction());
    };
    const onDeliveryOptionsInit = (optionName) => {
        dispatch(AnalyticsActions_creators_1.analyticsActions.checkoutType(isLoggedIn ? AnalyticsEnums_1.CheckoutType.LOGGED_IN : AnalyticsEnums_1.CheckoutType.GUEST));
        dispatch(AnalyticsActions_creators_1.analyticsActions.deliveryStep(optionName));
    };
    (0, react_1.useEffect)(() => {
        dispatch(CheckoutActions_creators_1.default.createGetDeliveryInfoAction());
        dispatch(CheckoutActions_creators_1.default.createGetDeliveryCountriesAction());
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showSingleDeliveryMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { iconName: cotopaxi_1.IconName.TRUCK, text: singleDeliveryMessage, variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE }) })), requiresDelivery && ((0, jsx_runtime_1.jsx)(CountrySelector_1.default, { deliveryCountries: deliveryCountries, deliveryCountryId: deliveryCountryId, deliveryLoading: deliveryLoading, lang: lang, changeDeliveryCountry: changeDeliveryCountry })), !deliveryLoading && !(0, common_utils_1.isNullOrUndefined)(requiresDelivery) && !requiresDelivery && ((0, jsx_runtime_1.jsx)(DeliveryEVoucherContainer_1.default, { title: eVoucherTitle })), !deliveryLoading && requiresDelivery && deliveryOptions.length > 0 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, children: (0, jsx_runtime_1.jsx)(DeliveryOptions_1.default, { deliveryOptions: deliveryOptions, chosenDeliveryOption: (0, BuyFormUtil_1.chooseDeliveryOption)(deliveryOptions, chosenDeliveryOption), changeDeliveryOption: changeDeliveryOption, onDeliveryOptionsInit: onDeliveryOptionsInit, collectInStorePromiseMsg: collectInStorePromiseMsg }) }))] }));
};
exports.default = BuyDeliveryContainer;
