"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCategoryHierarchyTree = void 0;
const react_redux_1 = require("react-redux");
const ListerSelector_1 = require("../redux/lister/selectors/ListerSelector");
const UrlSelectors_1 = require("../redux/url/selectors/UrlSelectors");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const useCategoryHierarchyTree = () => {
    const { lang } = (0, common_components_1.useAemConfig)();
    const categoryHierarchyTree = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetCategoryHierarchyTree)());
    const defaultFilter = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetUrlFilters)()).defaultFilter;
    const getCategories = (filterString) => {
        let categoryList = [];
        const startKey = 'categories:c(';
        const endKey = ')';
        const splitKey = ';';
        const startKeyLength = startKey.length;
        const startKeyIndex = filterString ? filterString.indexOf(startKey) : -1;
        if (startKeyIndex !== -1) {
            const endKeyIndex = filterString.indexOf(endKey, startKeyIndex);
            categoryList = filterString
                .substring(startKeyIndex + startKeyLength, endKeyIndex)
                .split(splitKey)
                .map(item => parseInt(item, 10));
        }
        return categoryList;
    };
    const walkAndMapCategoryHierarchyTree = (node) => {
        var _a, _b;
        if (!((_b = (_a = node === null || node === void 0 ? void 0 : node.category) === null || _a === void 0 ? void 0 : _a.categoryTranslatedProperties[lang.toLocaleUpperCase()]) === null || _b === void 0 ? void 0 : _b.link)) {
            return null;
        }
        return {
            item: Object.assign({ categoryPath: node.categoryPath }, node.category),
            children: node.children
                .map(nodeChild => walkAndMapCategoryHierarchyTree(nodeChild))
                .filter(nodeChild => !!nodeChild),
        };
    };
    const categoryIds = (0, react_1.useMemo)(() => (categoryHierarchyTree && defaultFilter ? getCategories(defaultFilter) : []), [categoryHierarchyTree, defaultFilter]);
    const mappedCategoryHierarchyTree = (0, react_1.useMemo)(() => (categoryHierarchyTree && defaultFilter ? walkAndMapCategoryHierarchyTree(categoryHierarchyTree) : null), [categoryHierarchyTree, defaultFilter]);
    return { categoryIds, categoryHierarchyTree: mappedCategoryHierarchyTree };
};
exports.useCategoryHierarchyTree = useCategoryHierarchyTree;
