"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const SessionActions_creators_1 = require("../../redux/session/actions/SessionActions.creators");
const i18nConstants_1 = require("../../constants/i18nConstants");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const AccountMenuProfileContainer = ({ items, hideOnMobile, title }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const accountMenuTranslations = {
        logout: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.logout`),
    };
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const { isInAuthorMode } = (0, common_components_1.useAemConfig)();
    const logout = () => dispatch(SessionActions_creators_1.default.createLogoutAction());
    const [shouldRender, setShouldRender] = (0, react_1.useState)(isInAuthorMode);
    const mappedItems = items
        .filter(item => !item.hideForGuest || isLoggedIn)
        .map(item => ({
        href: item.link,
        label: item.label,
        isActive: item.active,
    }));
    const activeItemLabel = (_a = mappedItems.find(item => item.isActive)) === null || _a === void 0 ? void 0 : _a.label;
    (0, react_1.useEffect)(() => {
        var _a;
        if (((_a = sessionState === null || sessionState === void 0 ? void 0 : sessionState.authorities) === null || _a === void 0 ? void 0 : _a.length) && !shouldRender) {
            setShouldRender(true);
        }
    }, [sessionState]);
    if (!shouldRender)
        return null;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.MediaQueryWrapper, { query: cotopaxi_1.MediaQuery.DESKTOP, children: [title.show && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H3, size: cotopaxi_1.HeadingSize.S, children: title.value || t('account.account.popover') }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.AccountMenu, { spacing: cotopaxi_1.AccountMenuSpacing.SMALL, buttonSize: cotopaxi_1.ButtonSize.SMALL, items: mappedItems, onLogout: isLoggedIn ? logout : undefined, noMarginRight: true, translations: accountMenuTranslations })] }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: [!hideOnMobile && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, mobileBleed: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { size: cotopaxi_1.ButtonSize.XSMALL, variant: cotopaxi_1.ButtonVariant.LINK_SUBTLE, onClick: handlePopoverOpen, text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.EXTRA_TITLE, children: activeItemLabel }), iconRight: { name: cotopaxi_1.IconName.CHEVRON_RIGHT, size: cotopaxi_1.IconSize.MINOR } }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, onClose: handlePopoverClose, title: title.show ? title.value || t('account.account.popover') : null, rightElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: handlePopoverClose, icon: {
                                name: cotopaxi_1.IconName.CLOSE,
                                color: cotopaxi_1.IconColor.ACTION,
                                size: cotopaxi_1.IconSize.MAJOR,
                            } }), size: cotopaxi_1.PopoverSize.MEDIUM, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.AccountMenu, { items: mappedItems, onLogout: isLoggedIn ? logout : undefined, translations: accountMenuTranslations }) })] })] }));
};
exports.default = AccountMenuProfileContainer;
