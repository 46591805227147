"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaybackVoucherMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const CurrencyUtil_1 = require("../../../../util/CurrencyUtil");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const PaybackVoucherMessage = () => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const paybackVoucherMessages = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPaybackVoucherMessagesState)());
    const validPaybackVoucherMessage = paybackVoucherMessages[0];
    if (!validPaybackVoucherMessage)
        return null;
    const feedbackMessageContext = ((_a = validPaybackVoucherMessage.customMessage) === null || _a === void 0 ? void 0 : _a.length) > 0
        ? validPaybackVoucherMessage.customMessage
        : t('checkout.basket.voucher.payback.message', {
            currency: (0, CurrencyUtil_1.convertToCurrencySymbol)(validPaybackVoucherMessage.currency),
            amountUntilValid: validPaybackVoucherMessage.amountUntilValid.toFixed(2),
        });
    return (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "informative", text: feedbackMessageContext });
};
exports.PaybackVoucherMessage = PaybackVoucherMessage;
