"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaferpayField = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const andes_react_1 = require("@yonderland/andes-react");
const SaferpayField = ({ id, label, paymentMethods, type, validation, withGlossary, icon, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const getErrorInfoField = () => {
        switch (type) {
            case common_types_1.SaferpayFieldTypeEnum.CARDNUMBER:
                if ((validation === null || validation === void 0 ? void 0 : validation.reason) === common_types_1.SaferpayFieldValidationReason.UNSUPPORTED) {
                    return paymentMethods.length > 1
                        ? `${paymentMethods.slice(0, -1).join(', ')} ${t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.or`)} ${paymentMethods.slice(-1)}`
                        : paymentMethods[0];
                }
                return '';
            default:
                return '';
        }
    };
    const error = (validation === null || validation === void 0 ? void 0 : validation.isValid) !== undefined && !(validation === null || validation === void 0 ? void 0 : validation.isValid) ? ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { color: "error", variant: "bodyS", children: [t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.${validation.fieldType}.error.${validation.reason || common_types_1.SaferpayFieldValidationReason.INVALID}`), getErrorInfoField()] })) : undefined;
    const tooltip = withGlossary ? t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.saferpay.field.${type}.description`) : undefined;
    return ((0, jsx_runtime_1.jsx)(andes_react_1.SaferpayField, { id: id, label: label, icon: icon, error: error, tooltipText: tooltip, dataTestId: `saferpay-field-${type}` }));
};
exports.SaferpayField = SaferpayField;
