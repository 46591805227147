"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getElasticSearchIndexWithEnv = exports.getSearchItemGrouping = exports.getSearchContent = exports.getSearchBrandAsCategories = exports.getSearchCategories = exports.getSearchBrands = exports.getSearchKeywords = exports.getBrandAsCategorySuggestionsUrl = exports.getCategorySuggestionsUrl = exports.getBrandSuggestionsUrl = exports.getKeywordsSuggestionsUrl = exports.appendSearchQueryToUrl = void 0;
const common_types_1 = require("@as-react/common-types");
const UrlUtil_1 = require("./UrlUtil");
const SearchConstants_1 = require("../constants/SearchConstants");
const ArrayUtil_1 = require("./ArrayUtil");
const URL_PARAMETER_SEARCH = 'q';
const appendSearchQueryToUrl = (urlBase, searchTerm) => {
    const querystringAppender = urlBase.indexOf('?') >= 0 ? '&' : '?';
    return `${urlBase}${querystringAppender}${URL_PARAMETER_SEARCH}=${encodeURIComponent(searchTerm.trim())}`;
};
exports.appendSearchQueryToUrl = appendSearchQueryToUrl;
const getKeywordsSuggestionsUrl = (root, searchTerm) => {
    return (0, exports.appendSearchQueryToUrl)(`${root}lister.html`, searchTerm);
};
exports.getKeywordsSuggestionsUrl = getKeywordsSuggestionsUrl;
const getBrandSuggestionsUrl = (brandPagePathPrefix, suggestion) => {
    return (0, UrlUtil_1.concatUrls)(brandPagePathPrefix, suggestion.url);
};
exports.getBrandSuggestionsUrl = getBrandSuggestionsUrl;
const getCategorySuggestionsUrl = (categoryPagePathPrefix, suggestion) => {
    return (0, UrlUtil_1.concatUrls)(categoryPagePathPrefix, suggestion.url);
};
exports.getCategorySuggestionsUrl = getCategorySuggestionsUrl;
const getBrandAsCategorySuggestionsUrl = (categoryPagePathPrefix, suggestion) => {
    return (0, UrlUtil_1.concatUrls)(categoryPagePathPrefix, suggestion.url);
};
exports.getBrandAsCategorySuggestionsUrl = getBrandAsCategorySuggestionsUrl;
const getSearchKeywords = (keywords = [], suggestionsParentLink) => suggestionsParentLink
    ? keywords.map(item => ({
        name: item.searchTerm,
        url: (0, exports.getKeywordsSuggestionsUrl)(suggestionsParentLink, item.searchTerm),
    }))
    : [];
exports.getSearchKeywords = getSearchKeywords;
const getSearchBrands = (brands = [], brandsParentLink) => brandsParentLink
    ? brands
        .filter(suggestion => Boolean(suggestion.url))
        .map(item => ({
        name: item.name,
        url: (0, exports.getBrandSuggestionsUrl)(brandsParentLink, item),
    }))
    : [];
exports.getSearchBrands = getSearchBrands;
const getSearchCategories = (categories = [], categoriesParentLink) => categoriesParentLink
    ? categories
        .filter(suggestion => Boolean(suggestion.url))
        .map(item => ({
        name: item.name,
        url: (0, exports.getCategorySuggestionsUrl)(categoriesParentLink, item),
        subtitle: item.categoryNames.slice(0, item.categoryNames.length - 1),
    }))
    : [];
exports.getSearchCategories = getSearchCategories;
const getSearchBrandAsCategories = (brandAsCategories = [], categoriesParentLink) => categoriesParentLink
    ? brandAsCategories
        .filter(suggestion => Boolean(suggestion.url))
        .map(item => ({
        name: item.brand,
        url: (0, exports.getBrandAsCategorySuggestionsUrl)(categoriesParentLink, item),
        subtitle: item.categoryNames,
    }))
    : [];
exports.getSearchBrandAsCategories = getSearchBrandAsCategories;
const getSearchContent = (content = []) => content
    .filter(suggestion => Boolean(suggestion.url))
    .map(item => ({
    name: item.title,
    url: item.url,
}));
exports.getSearchContent = getSearchContent;
const wrapSubtitleWithTranslation = (menuSearchItem, limitSubtitles, t) => {
    let translatedSubtitle = undefined;
    if (menuSearchItem.subtitle && (0, ArrayUtil_1.hasItems)(menuSearchItem.subtitle)) {
        translatedSubtitle = t('flyout.search.result.category.subtitle', {
            category: concatSearchItemsSubtitles(menuSearchItem.subtitle, limitSubtitles),
        });
    }
    return Object.assign(Object.assign({}, menuSearchItem), { subtitle: [translatedSubtitle] });
};
const concatSearchItemsSubtitles = (subtitles, limitSubtitles) => {
    let commaSeparatedSubtitles = undefined;
    if (!!subtitles && subtitles.length > 0) {
        commaSeparatedSubtitles = limitSubtitles ? subtitles.slice(0, 2).join(', ') : subtitles.join(', ');
    }
    return commaSeparatedSubtitles;
};
const getSearchItemGrouping = ({ keywords = [], categories = [], brands = [], brandAsCategories = [], content = [], }, maxAmountEnhancedItems, t) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, ((0, ArrayUtil_1.hasItems)(keywords) && {
    [common_types_1.MenuSearchResultBlockVariant.SUGGESTIONS]: {
        type: common_types_1.MenuSearchResultBlockVariant.SUGGESTIONS,
        enableSuggestionFill: true,
        showTextSubtle: false,
        items: keywords.slice(0, SearchConstants_1.SEARCH_RESULT_LIMIT.FOR_SEARCH_SUGGESTIONS),
    },
})), ((0, ArrayUtil_1.hasItems)(categories) && {
    [common_types_1.MenuSearchResultBlockVariant.CATEGORIES]: {
        type: common_types_1.MenuSearchResultBlockVariant.CATEGORIES,
        enableSuggestionFill: false,
        showTextSubtle: false,
        items: categories.slice(0, maxAmountEnhancedItems).map(item => wrapSubtitleWithTranslation(item, true, t)),
    },
})), ((0, ArrayUtil_1.hasItems)(brands) && {
    [common_types_1.MenuSearchResultBlockVariant.BRANDS]: {
        type: common_types_1.MenuSearchResultBlockVariant.BRANDS,
        enableSuggestionFill: false,
        showTextSubtle: false,
        items: brands.slice(0, maxAmountEnhancedItems),
    },
})), ((0, ArrayUtil_1.hasItems)(brandAsCategories) && {
    [common_types_1.MenuSearchResultBlockVariant.BRAND_AS_CATEGORIES]: {
        type: common_types_1.MenuSearchResultBlockVariant.BRAND_AS_CATEGORIES,
        enableSuggestionFill: false,
        showTextSubtle: false,
        items: brandAsCategories
            .slice(0, maxAmountEnhancedItems)
            .map(item => wrapSubtitleWithTranslation(item, false, t)),
    },
})), ((0, ArrayUtil_1.hasItems)(content) && {
    [common_types_1.MenuSearchResultBlockVariant.CONTENT]: {
        type: common_types_1.MenuSearchResultBlockVariant.CONTENT,
        enableSuggestionFill: false,
        showTextSubtle: true,
        items: content.slice(0, maxAmountEnhancedItems),
    },
})));
exports.getSearchItemGrouping = getSearchItemGrouping;
const getElasticSearchIndexWithEnv = (activeEnvironment, elasticsearchIndex) => elasticsearchIndex ? `${activeEnvironment}_${elasticsearchIndex}` : 'dev_www-asadventure-com_nl';
exports.getElasticSearchIndexWithEnv = getElasticSearchIndexWithEnv;
