"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileNavigationSearch = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const useMenuSearch_1 = require("../../../hooks/useMenuSearch");
const ArrayUtil_1 = require("../../../util/ArrayUtil");
const SearchUtil_1 = require("../../../util/SearchUtil");
const SearchConstants_1 = require("../../../constants/SearchConstants");
const SearchActions_creators_1 = require("../../../redux/search/actions/SearchActions.creators");
const SessionStorageUtil_1 = require("../../../util/SessionStorageUtil");
const useVirtualKeyboard_1 = require("../../../hooks/useVirtualKeyboard");
const MobileNavigationSearch = ({ brandsParentLink, categoriesParentLink, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.navigation);
    const dispatch = (0, react_redux_1.useDispatch)();
    const { root } = (0, common_components_1.useAemConfig)();
    const listerPageUrl = `${root}lister.html`;
    const [searchTerm, setSearchTerm] = (0, react_1.useState)('');
    const searchBarInputRef = (0, react_1.useRef)(null);
    const virtualKeyboardHeight = (0, useVirtualKeyboard_1.useVirtualKeyboard)();
    const { menuSearchItemGrouping } = (0, useMenuSearch_1.useMenuSearch)({
        searchTerm,
        brandsParentLink,
        categoriesParentLink,
        suggestionsParentLink: root,
    });
    const hasSearchResults = !!menuSearchItemGrouping && (0, ArrayUtil_1.hasItems)(Object.keys(menuSearchItemGrouping));
    const handleSearchSubmit = (value) => {
        if (value.length === 0)
            return;
        dispatch(SearchActions_creators_1.searchActions.createAdobeAnalyticsSetSearchTermAction(value));
        window.location.href = (0, SearchUtil_1.getKeywordsSuggestionsUrl)(root, searchTerm);
    };
    const handleSearchValueChange = (value, byKeyDown) => {
        setSearchTerm(value);
        byKeyDown && handleSearchSubmit(value);
    };
    const handleSearchItemClick = (value) => {
        var _a;
        setSearchTerm(`${value} `);
        (_a = searchBarInputRef === null || searchBarInputRef === void 0 ? void 0 : searchBarInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Menu.Search, { placeholder: t('flyout.search.placeholder'), value: searchTerm, onChange: handleSearchValueChange, searchBarInputRef: searchBarInputRef, children: searchTerm ? (hasSearchResults ? ((0, jsx_runtime_1.jsx)("div", { style: (0, cotopaxi_1.isUserAgentAppleMobilePlatform)() && virtualKeyboardHeight
                ? { marginBottom: virtualKeyboardHeight }
                : undefined, children: Object.values(menuSearchItemGrouping).map(menuSearchItemGroup => ((0, jsx_runtime_1.jsx)(cotopaxi_1.SearchResultBlock, { analyticsAreaName: SearchConstants_1.bannerIds[menuSearchItemGroup.type], highlightValue: searchTerm, items: menuSearchItemGroup.items, onItemSuggestionFillClick: handleSearchItemClick, showSuggestionFill: menuSearchItemGroup.enableSuggestionFill, showTextSubtle: menuSearchItemGroup.showTextSubtle, title: t(SearchConstants_1.blockTitles[menuSearchItemGroup.type]) }, menuSearchItemGroup.type))) })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.SearchResultsNothingFound, { analyticsAreaName: "mobile-nav-search-zero-suggest", searchValue: searchTerm, url: (0, SearchUtil_1.appendSearchQueryToUrl)(listerPageUrl, searchTerm), onClick: () => SessionStorageUtil_1.default.setItem(SearchConstants_1.NOTHING_FOUND_SESSION_KEY, '1'), translations: { noResults: t('flyout.no.search.results') } }) }))) : null }));
};
exports.MobileNavigationSearch = MobileNavigationSearch;
