"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressPreview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const DeliveryAddressCard_1 = require("./DeliveryAddressCard");
const DeliveryPreselectedCardView_1 = require("./DeliveryPreselectedCardView");
const AddressPreview = ({ address, onEdit }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    if (!address) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(DeliveryPreselectedCardView_1.DeliveryPreselectedCardView, { dataTestId: "hd_address_card", header: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, children: t('checkout.delivery.customer.details.title') }), (0, jsx_runtime_1.jsx)(DeliveryAddressCard_1.DeliveryAddressCard, { address: address })] }), onEdit: onEdit }));
};
exports.AddressPreview = AddressPreview;
