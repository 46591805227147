"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoBasketPopupPreview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const ImageUtil_1 = require("../../util/ImageUtil");
const common_components_1 = require("@as-react/common-components");
const PromoBasketPopupPreview = ({ image, productCode }) => {
    const { productImageConfig } = (0, common_components_1.useAemConfig)();
    const imageUrl = (0, ImageUtil_1.getImageSourceSetByProductCode)(productCode, image, productImageConfig)[common_types_1.ProductImageSizesPortraitEnum.basket];
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, grid: 0, mobile: 5, mobileLarge: 5, tabletSmall: 5, tablet: 12, desktop: 12, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.5 } }, tablet: { spacing: { marginRight: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: imageUrl, alt: productCode, lazy: true }) }) }) }));
};
exports.PromoBasketPopupPreview = PromoBasketPopupPreview;
