"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeDeliveryAddressForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_hook_form_1 = require("react-hook-form");
const yup_1 = require("@hookform/resolvers/yup");
const AddressUtil_1 = require("../../../../util/AddressUtil");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const AddressFields_1 = require("../../../form/address-fields/AddressFields");
const CustomerDetailsFields_1 = require("../CustomerDetailsFields");
const CustomerDetailsFields_utils_1 = require("../../../form/registration-fields/CustomerDetailsFields.utils");
const addressFields_utils_1 = require("../../../form/address-fields/addressFields.utils");
const useScrollToFirstFormError_1 = require("../../../form/hooks/useScrollToFirstFormError");
const HomeDeliveryAddressForm = ({ address, handleSubmit, handleCancel, isPending, error, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const [{ info }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const customerValidationSchema = (0, CustomerDetailsFields_utils_1.getCustomerDetailsValidationSchema)({ t });
    const validationSchema = customerValidationSchema.concat((0, addressFields_utils_1.getAddressValidationSchema)({ country: info.country.toUpperCase(), t, isOptional: false }));
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: Object.assign(Object.assign({}, (address || AddressUtil_1.emptyAddress)), { idCountry: info.idCountry, country: info.country }),
        mode: 'onTouched',
        shouldFocusError: true,
        resolver: (0, yup_1.yupResolver)(validationSchema),
    });
    (0, useScrollToFirstFormError_1.useScrollToFirstFormError)(formProps.formState);
    const onSubmit = (data) => {
        const preparedAddress = (0, AddressUtil_1.prepareAddressByCountry)(data, '');
        handleSubmit(preparedAddress);
    };
    const getButtonState = () => (isPending ? 'loading' : 'interactive');
    const handleFormSubmit = () => {
        formProps.handleSubmit(onSubmit)();
    };
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(onSubmit), noValidate: true, name: "home-delivery-address-form", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { itemsAlign: "start", contentAlign: "between", gap: "6", children: [(0, jsx_runtime_1.jsx)(CustomerDetailsFields_1.CustomerDetailsFields, {}), (0, jsx_runtime_1.jsx)(andes_react_1.Grid, { fullWidth: true, children: (0, jsx_runtime_1.jsx)(AddressFields_1.AddressFields, { country: info.country, hasInitialAddress: !!address, isRequired: true }) }), error && (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t(common_types_1.ErrorMessage.GENERAL_ACCOUNT), status: "error" }), (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "3", children: [(0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { state: getButtonState(), theme: "branded", text: t('checkout.delivery.delivery.address.save'), onClick: handleFormSubmit }), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { theme: "branded", variant: "secondary", text: t('checkout.delivery.delivery.address.cancel'), onClick: handleCancel })] })] }) }) })));
};
exports.HomeDeliveryAddressForm = HomeDeliveryAddressForm;
