"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewProductItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const DeliveryPromiseUtil_1 = require("../../../util/DeliveryPromiseUtil");
const CartItem_1 = require("../../product-tile/cart-item/CartItem");
const EcochequeAvailabilityMessage_1 = require("../../ecocheque/EcochequeAvailabilityMessage");
const tooltipOptionsOverride = {
    placement: 'bottom',
};
const BasketOverviewProductItem = ({ deliveryPromiseExplanations, index, isLastItem, isProductDisabled, product, showDeliveryPromise, storeName, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.navigation, i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.product]);
    const errorRef = (0, react_1.useRef)(null);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const { market, mainWebShop, feature_toggles } = (0, common_components_1.useAemConfig)();
    const showEcochequesMessage = feature_toggles.ecocheques_cart_item_message && ((_a = product.features) === null || _a === void 0 ? void 0 : _a.includes('ecocheques_accepted'));
    const getMarket = () => (market === 'uk' ? 'uk' : mainWebShop);
    (0, react_1.useEffect)(() => {
        if (product.errorState === common_types_1.BasketCartItemError.ADD_FAILED && (errorRef === null || errorRef === void 0 ? void 0 : errorRef.current)) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(errorRef.current, isDesktop);
        }
    }, [product.errorState]);
    const setTooltipElement = () => {
        var _a, _b, _c;
        let deliveryExplanation = '';
        if (showDeliveryPromise && deliveryPromiseExplanations && product.productType !== common_types_1.ProductTypes.E_VOUCHER) {
            deliveryExplanation =
                (_b = (0, DeliveryPromiseUtil_1.getDeliveryExplanation)({ stockLocation: (_a = product.additionalProperties) === null || _a === void 0 ? void 0 : _a.stockLocation }, deliveryPromiseExplanations)) !== null && _b !== void 0 ? _b : '';
        }
        if (deliveryExplanation && ((_c = product.additionalProperties) === null || _c === void 0 ? void 0 : _c.deliveryMessage)) {
            (0, common_utils_1.setTooltip)({
                element: `#delivery-item-explanation-${product.productId}${product.sku ? `-${product.sku.toString()}` : ''}`,
                content: deliveryExplanation,
                showCloseButton: false,
                optionsOverride: tooltipOptionsOverride,
            });
        }
    };
    (0, react_1.useEffect)(() => {
        setTooltipElement();
        if (isProductDisabled) {
            (0, common_utils_1.setTooltip)({
                element: `#sse-product-explanation-${product.productId}`,
                content: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.${getMarket()}.info`),
                showCloseButton: false,
                optionsOverride: tooltipOptionsOverride,
            });
        }
    }, [product, deliveryPromiseExplanations]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        paddingTop: index !== 0 ? 3 : 0,
                        paddingBottom: 8,
                    },
                }, tablet: {
                    spacing: {
                        paddingBottom: isLastItem ? 5 : 3,
                    },
                }, desktop: { spacing: { paddingLeft: 0, paddingRight: 0 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: 3, children: [product.errorState === common_types_1.BasketCartItemError.ADD_FAILED && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { innerRef: errorRef, mobileBleed: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:basket.addProductFailed`), iconSize: cotopaxi_1.IconSize.MAJOR }) })), isProductDisabled && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { innerRef: errorRef, mobileBleed: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, text: storeName ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.INHERIT, size: cotopaxi_1.TextSize.SMALL, children: [t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.${getMarket()}.error`, {
                                            storeName,
                                        }), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { id: `sse-product-explanation-${product.productId}`, size: cotopaxi_1.IconSize.MINOR, color: cotopaxi_1.IconColor.NEGATIVE, name: cotopaxi_1.IconName.INFORMATION_OUTLINE, dynamicContent: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.${getMarket()}.info`), inline: true })] })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.INHERIT, size: cotopaxi_1.TextSize.SMALL, children: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.error`, {
                                        storeName,
                                    }) })), iconSize: cotopaxi_1.IconSize.MAJOR }) })), (0, jsx_runtime_1.jsx)(CartItem_1.CartItem, { product: product, showDeliveryPromise: showDeliveryPromise, deliveryPromiseExplanations: deliveryPromiseExplanations }), showEcochequesMessage && ((0, jsx_runtime_1.jsx)(EcochequeAvailabilityMessage_1.EcochequeAvailabilityMessage, { dataQA: "ecocheque_accepted_message", message: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:basket.feature.ecocheques_accepted.message`) }))] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { mobileBleed: isLastItem, tabletBleed: isLastItem })] }));
};
exports.BasketOverviewProductItem = BasketOverviewProductItem;
