"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSavePaymentOption = void 0;
const tslib_1 = require("tslib");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const react_1 = require("react");
const react_query_1 = require("@tanstack/react-query");
const URLParamUtil_1 = require("../util/URLParamUtil");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const KlarnaUtil_1 = require("../util/KlarnaUtil");
const BasketUtil_1 = require("../util/BasketUtil");
const CheckoutPaymentContext_1 = require("../context/CheckoutPaymentContext");
const i18nConstants_1 = require("../constants/i18nConstants");
const useSavePaymentOption = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const { defaultRequestParams, servicesEndpoint, root, isInAuthorMode } = (0, common_components_1.useAemConfig)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const [error, setError] = (0, react_1.useState)();
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [{ info }] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const { postOrderPaidByVoucher, postPayment, postPaymentProviderInput, postSubmitOrder } = (0, common_queries_1.checkoutMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutateAsync: orderPaidByVoucher } = (0, react_query_1.useMutation)(postOrderPaidByVoucher);
    const { mutateAsync: payment } = (0, react_query_1.useMutation)(postPayment);
    const { mutateAsync: paymentProviderInput } = (0, react_query_1.useMutation)(postPaymentProviderInput);
    const { mutate: submitOrder } = (0, react_query_1.useMutation)(postSubmitOrder);
    const savePaymentOptions = (...args_1) => tslib_1.__awaiter(void 0, [...args_1], void 0, function* ({ group } = {}) {
        try {
            setIsLoading(true);
            if (info.fullyPaid) {
                const { orderReference } = yield orderPaidByVoucher();
                const search = { status: 'accept', orderID: orderReference };
                const location = (0, URLParamUtil_1.generatePathUrl)(root, `buy/${common_types_1.RoutePathname.CONFIRMATION}.html`, isInAuthorMode, search);
                window.location.href = `${location.pathname}?${location.search}`;
            }
            else if (group) {
                const options = group.paymentOptions;
                const isSingleOption = options.length === 1;
                let optionId;
                let provider;
                let optionName;
                if (isSingleOption || group.name === common_types_1.PaymentGroupName.ECOCHEQUE) {
                    optionId = options[0].paymentOptionId;
                    provider = options[0].provider;
                    optionName = options[0].name;
                }
                else {
                    const findOption = options.find(opt => opt.name === group.name);
                    optionId = findOption.paymentOptionId;
                    provider = findOption.provider;
                    optionName = findOption.name;
                }
                const providerInformation = yield payment({
                    chosenPaymentOption: optionName,
                    chosenPaymentOptionId: optionId,
                    provider,
                    tp: `${defaultRequestParams.mainWebshop}.html`,
                });
                dispatch(AnalyticsActions_creators_1.analyticsActions.paymentRequest({ paymentGroup: group.name, paymentMethod: optionName }));
                if (providerInformation.provider === common_types_1.PaymentProvider.SAFERPAY ||
                    providerInformation.provider === common_types_1.PaymentProvider.WORLDLINE) {
                    const { redirectUrl } = yield paymentProviderInput(providerInformation.provider);
                    if (redirectUrl) {
                        location.href = redirectUrl;
                    }
                }
                else if (providerInformation.provider === common_types_1.PaymentProvider.KLARNA) {
                    const orderReference = info.orderReference;
                    KlarnaUtil_1.default.authorize([providerInformation.paymentMethodCategory], orderReference, result => {
                        if (result.approved === true) {
                            submitOrder({ provider: providerInformation.provider, authorizationToken: result.authorization_token }, {
                                onSuccess: redirectUrl => {
                                    location.href = redirectUrl;
                                },
                                onError: () => {
                                    setError(t(common_types_1.ErrorMessage.GENERAL_PAYMENT));
                                    setIsLoading(false);
                                },
                            });
                        }
                        else {
                            setIsLoading(false);
                        }
                    });
                }
            }
            setError(undefined);
            return true;
        }
        catch (error) {
            if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                location.href = `${root}checkout/basket-overview.html`;
                return;
            }
            setError(t(common_types_1.ErrorMessage.GENERAL_PAYMENT));
            setIsLoading(false);
            return false;
        }
    });
    return { savePaymentOptions, errorMessage: error, isLoading };
};
exports.useSavePaymentOption = useSavePaymentOption;
