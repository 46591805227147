"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDisplayedProductDetailTabs = void 0;
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const ProductUtil_1 = require("../util/ProductUtil");
const useDisplayedProductDetailTabs = (productDetailTabs) => {
    const detailInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDetailInformation)());
    const hasUserReview = Boolean((0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductReview)()));
    const hasExpertReview = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetExpertReviews)()).length > 0;
    const productCareData = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductCareData)());
    const hasProductCare = Boolean(productCareData && Object.keys(productCareData).length);
    const technicalCategories = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductTechnicalCategories)());
    const { lang } = (0, common_components_1.useAemConfig)();
    const shouldDisplayFeatureCategories = (featureCategories) => {
        if (!(technicalCategories === null || technicalCategories === void 0 ? void 0 : technicalCategories.length) || !(featureCategories === null || featureCategories === void 0 ? void 0 : featureCategories.length)) {
            return false;
        }
        const categoriesIds = technicalCategories.map(({ categoryId }) => categoryId);
        const hasCategories = featureCategories.some(({ id }) => categoriesIds.includes(Number(id)));
        const includedCategoriesIDs = featureCategories.map(({ id }) => Number(id));
        const filteredCategories = technicalCategories
            .map(ProductUtil_1.filterProductCategoryEmptyFeatures)
            .filter(category => category.features.length && includedCategoriesIDs.includes(category.categoryId));
        return hasCategories && filteredCategories.length > 0;
    };
    const shouldDisplayOurPlanetFeatureCategories = (featureCategories) => {
        if (!(technicalCategories === null || technicalCategories === void 0 ? void 0 : technicalCategories.length) || !(featureCategories === null || featureCategories === void 0 ? void 0 : featureCategories.length)) {
            return false;
        }
        const categoriesIds = technicalCategories.map(({ categoryId }) => categoryId);
        const hasCategories = featureCategories.some(({ id }) => categoriesIds.includes(Number(id)));
        const includedCategoriesIDs = featureCategories.map(({ id }) => Number(id));
        const filteredCategories = technicalCategories
            .map(ProductUtil_1.filterProductCategoryEmptyFeatures)
            .map(categories => (0, ProductUtil_1.filterProductCategoryFalseBooleanFeatures)(categories, lang))
            .filter(category => category.features.length && includedCategoriesIDs.includes(category.categoryId));
        return hasCategories && filteredCategories.length > 0;
    };
    return productDetailTabs.map(tab => {
        switch (tab.type) {
            case common_types_1.ProductDetailTabTypes.DESCRIPTION:
                tab.shouldDisplay = !!detailInformation.information || !!detailInformation.technicalDescription;
                break;
            case common_types_1.ProductDetailTabTypes.PRODUCT_CARE:
                tab.shouldDisplay = hasProductCare;
                break;
            case common_types_1.ProductDetailTabTypes.REVIEWS: {
                const shouldShowUserReview = hasUserReview && (tab.showEndUserReview || !!tab.reviewTitle);
                const shouldShowExpertReview = hasExpertReview && tab.showExpertReview;
                tab.shouldDisplay = shouldShowUserReview || shouldShowExpertReview || (!tab.isReevoo && !!tab.reviewTitle);
                break;
            }
            case common_types_1.ProductDetailTabTypes.FEATURE_CATEGORIES:
                tab.shouldDisplay = shouldDisplayFeatureCategories(tab.includedCategories);
                break;
            case common_types_1.ProductDetailTabTypes.OUR_PLANET:
                tab.shouldDisplay = shouldDisplayOurPlanetFeatureCategories(tab.ourPlanetFeatures);
                break;
            default:
                tab.shouldDisplay = true;
        }
        return tab;
    });
};
exports.useDisplayedProductDetailTabs = useDisplayedProductDetailTabs;
