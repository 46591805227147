"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginFlows = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const ImageUtil_1 = require("../../../../util/ImageUtil");
const EmailRecognitionStep_1 = require("./steps/EmailRecognitionStep");
const LoginStep_1 = require("./steps/LoginStep");
var Steps;
(function (Steps) {
    Steps[Steps["EMAIL_RECOGNITION"] = 0] = "EMAIL_RECOGNITION";
    Steps[Steps["LOGIN"] = 1] = "LOGIN";
})(Steps || (Steps = {}));
const LoginFlows = ({ image, flowType, redirectToRegistration }) => {
    const { imageCdnPrefix } = (0, common_components_1.useAemConfig)();
    const [step, setStep] = (0, react_1.useState)(Steps.EMAIL_RECOGNITION);
    const [email, setEmail] = (0, react_1.useState)('');
    const handleChangeStep = (email, isKnownEmail) => {
        if (isKnownEmail) {
            setStep(Steps.LOGIN);
        }
        else {
            redirectToRegistration(email);
        }
        setEmail(email);
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: image ? { bleed: { marginInline: 1.5 } } : undefined, desktop: image ? { bleed: { marginInline: 4 } } : undefined, children: (0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 12, gap: 0, children: [image && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: image ? { xs: 0, md: 6 } : { xs: 12, md: 8, lg: 6 }, offset: image ? undefined : { md: 3, lg: 4 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: image.imagePath, srcset: (0, ImageUtil_1.getPictureSrcset)(image, imageCdnPrefix), isPicture: true }) })), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: image ? { xs: 12, md: 6 } : { xs: 12, md: 8, lg: 6 }, offset: image ? undefined : { md: 3, lg: 4 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, fullHeight: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: 1, mobile: image
                                ? { spacing: { paddingTop: 5, paddingBottom: 5 } }
                                : { spacing: { paddingTop: 7, paddingBottom: 10 } }, tablet: image
                                ? { spacing: { paddingLeft: 6, paddingRight: 6 } }
                                : { spacing: { paddingLeft: 0.5, paddingRight: 0.5, paddingTop: 6, paddingBottom: 10 } }, desktop: image ? { spacing: { paddingLeft: 12, paddingRight: 12 } } : undefined, children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [step === Steps.EMAIL_RECOGNITION && ((0, jsx_runtime_1.jsx)(EmailRecognitionStep_1.EmailRecognitionStep, { onChangeStep: handleChangeStep, initialEmail: email, flowType: flowType })), step === Steps.LOGIN && (0, jsx_runtime_1.jsx)(LoginStep_1.LoginStep, { initialEmail: email })] }) }) }) })] }) }));
};
exports.LoginFlows = LoginFlows;
