"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuccessMessageBanner = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const useTimeout_1 = require("../../../hooks/useTimeout");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const SuccessMessageBanner = ({ amount, onDismiss }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const [isVisible, setIsVisible] = (0, react_1.useState)(true);
    const handleTimeout = () => {
        setIsVisible(false);
        onDismiss();
    };
    (0, useTimeout_1.useTimeout)(handleTimeout, GeneralConstants_1.DELAY.DELAY_5000);
    return isVisible ? ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t('checkout.basket.voucher.success', { amount }), status: "success", hasCloseBtn: false })) : null;
};
exports.SuccessMessageBanner = SuccessMessageBanner;
