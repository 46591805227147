"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateBasketProductAnalyticsSaga = exports.updateBasketProductAnalytics = void 0;
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
function* updateBasketProductAnalytics(action) {
    try {
        const { defaultRequestParams, servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
        const analyticsGoogleTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.updateBasketProductAnalytics, action.payload.product, action.payload.quantity, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateGoogleAnalyticsSuccessAction(analyticsGoogleTagManagerData));
        const analyticsAdobeTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.updateBasketProductDtm, action.payload.product, action.payload.quantity, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateAdobeAnalyticsSuccessAction(analyticsAdobeTagManagerData));
    }
    catch (_a) { }
}
exports.updateBasketProductAnalytics = updateBasketProductAnalytics;
function* updateBasketProductAnalyticsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.UPDATE_PRODUCT_SUCCESS, updateBasketProductAnalytics);
}
exports.updateBasketProductAnalyticsSaga = updateBasketProductAnalyticsSaga;
