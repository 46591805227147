"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const Icons = require("@yonderland/andes-icons");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_hook_form_1 = require("react-hook-form");
const yup_1 = require("@hookform/resolvers/yup");
const Yup = require("yup");
const FormField_1 = require("../../../form/FormField");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const LoginUtil_1 = require("../../../../util/LoginUtil");
const GuestLoginBlock_1 = require("./GuestLoginBlock");
const CheckoutContext_1 = require("../../../../context/CheckoutContext");
const useGuestLogin_1 = require("../../../../hooks/useGuestLogin");
const useCheckoutLogin_1 = require("../../../../hooks/useCheckoutLogin");
const LoginForm = ({ onEditEmailClick, onForgotPasswordClick }) => {
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.checkout,
        i18nConstants_1.I18N_NAMESPACES.general,
        i18nConstants_1.I18N_NAMESPACES.navigation,
    ]);
    const { mainWebShop } = (0, common_components_1.useAemConfig)();
    const { disableGuestLoginForRegisteredUser } = (0, CheckoutContext_1.useCheckoutConfig)();
    const [showPassword, setShowPassword] = (0, react_1.useState)(false);
    const [isLoginSubmitting, setIsLoginSubmitting] = (0, react_1.useState)(false);
    const [state] = (0, CheckoutContext_1.useCheckoutState)();
    const { email } = state.login;
    const validationSchema = Yup.object({
        password: Yup.string().required(t(`${i18nConstants_1.I18N_NAMESPACES.account}:delivery.required`)),
        keepSignedIn: Yup.boolean().required(),
    });
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: { keepSignedIn: false, password: '' },
        mode: 'onTouched',
        resolver: (0, yup_1.yupResolver)(validationSchema),
    });
    const { guestLogin, errorMessage: guestErrorMessage, isPending: isGuestLoginPending } = (0, useGuestLogin_1.useGuestLogin)();
    const { checkoutLogin, errorMessage: checkoutErrorMessage } = (0, useCheckoutLogin_1.useCheckoutLogin)();
    const guestError = t(guestErrorMessage || '');
    const loginError = (0, LoginUtil_1.getLoginError)(checkoutErrorMessage || '', t, mainWebShop);
    const handleGuestLoginClick = () => guestLogin(email);
    const onSubmit = values => {
        setIsLoginSubmitting(true);
        checkoutLogin({
            username: email,
            password: values.password,
            keepSignedIn: values.keepSignedIn,
            location: common_types_1.AuthFormLocation.CHECKOUT_V2,
        });
    };
    (0, react_1.useEffect)(() => {
        Boolean(checkoutErrorMessage) && setIsLoginSubmitting(false);
    }, [checkoutErrorMessage]);
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(onSubmit), name: "login-form", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", color: "emphasised", children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.title`) }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "3", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t([
                                        `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.intro.${mainWebShop}`,
                                        `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.intro`,
                                    ]) }) })] }), (loginError || guestError) && (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: loginError || guestError, status: "error" }), (0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { strong: true, wordBreak: "break", children: [email, ' - ', (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.edit`), onClick: onEditEmailClick })] }), (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "password", required: true, children: ({ fieldProps, error }) => ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { label: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.password.label`), name: "password", id: "password", autoComplete: "current-password", error: error, type: showPassword ? 'text' : 'password', labelAdornment: {
                                text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.password_forgotten.label`),
                                onClick: onForgotPasswordClick,
                            }, adornmentEnd: (0, jsx_runtime_1.jsx)(andes_react_1.IconButton, { size: "sm", variant: "plain", onClick: () => setShowPassword(prev => !prev), icon: { source: showPassword ? Icons.EyeFillIcon : Icons.EyeSlashFillIcon, color: 'subtle' } }) }))) }), (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "keepSignedIn", children: ({ fieldProps }) => ((0, jsx_runtime_1.jsx)(andes_react_1.Checkbox, Object.assign({}, fieldProps, { checked: fieldProps.value, label: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.keep_signed_in.label`), name: "keepSignedIn", id: "keepSignedIn", size: "xs" }))) }), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { type: "submit", theme: "branded", state: isLoginSubmitting ? 'loading' : 'interactive', text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.login.action`), fullWidth: true }), !disableGuestLoginForRegisteredUser && ((0, jsx_runtime_1.jsx)(GuestLoginBlock_1.GuestLoginBlock, { isLoading: isGuestLoginPending, onGuestLoginClick: handleGuestLoginClick }))] }) }) })));
};
exports.LoginForm = LoginForm;
