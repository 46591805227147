"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMenuSearch = void 0;
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const useDebouncedQuery_1 = require("./useDebouncedQuery");
const GeneralConstants_1 = require("../constants/GeneralConstants");
const SearchConstants_1 = require("../constants/SearchConstants");
const SearchUtil_1 = require("../util/SearchUtil");
const getSearchResultLimit = (searchTerm) => {
    if (searchTerm) {
        switch (searchTerm.trim().length) {
            case 1:
                return SearchConstants_1.SEARCH_RESULT_LIMIT.FOR_1_SEARCH_CHARACTER;
            case 2:
                return SearchConstants_1.SEARCH_RESULT_LIMIT.FOR_2_SEARCH_CHARACTER;
            default:
                return SearchConstants_1.SEARCH_RESULT_LIMIT.FOR_MORE_THEN_2_SEARCH_CHARACTER;
        }
    }
    else {
        return SearchConstants_1.SEARCH_RESULT_LIMIT.FOR_1_SEARCH_CHARACTER;
    }
};
const useMenuSearch = ({ searchTerm = '', suggestionsParentLink, brandsParentLink, categoriesParentLink, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.navigation);
    const aemConfiguration = (0, common_components_1.useAemConfig)();
    const { defaultRequestParams, servicesEndpoint } = aemConfiguration;
    const elasticsearchIndex = `${aemConfiguration.activeEnvironment}_${aemConfiguration.elasticsearchIndex}`;
    const searchResultLimit = getSearchResultLimit(searchTerm);
    const { getSearchSuggestions, getSearchContentSuggestion } = (0, common_queries_1.searchQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { data: suggestions } = (0, useDebouncedQuery_1.useDebouncedQuery)(getSearchSuggestions({ query: searchTerm }, {
        enabled: Boolean(searchTerm),
        retry: false,
        select: data => ({
            keywords: (0, SearchUtil_1.getSearchKeywords)(data === null || data === void 0 ? void 0 : data.keywords, suggestionsParentLink),
            brands: (0, SearchUtil_1.getSearchBrands)(data === null || data === void 0 ? void 0 : data.brands, brandsParentLink),
            categories: (0, SearchUtil_1.getSearchCategories)(data === null || data === void 0 ? void 0 : data.categories, categoriesParentLink),
            brandAsCategories: (0, SearchUtil_1.getSearchBrandAsCategories)(data === null || data === void 0 ? void 0 : data.brandAsCategories, categoriesParentLink),
        }),
    }), GeneralConstants_1.DELAY.DELAY_250);
    const { data: contentSuggestion } = (0, useDebouncedQuery_1.useDebouncedQuery)(getSearchContentSuggestion({ query: searchTerm, elasticsearchIndex }, {
        enabled: Boolean(searchTerm),
        retry: false,
        select: data => ({ content: (0, SearchUtil_1.getSearchContent)(data === null || data === void 0 ? void 0 : data.content) }),
    }), GeneralConstants_1.DELAY.DELAY_250);
    const menuSearchItemGrouping = (0, SearchUtil_1.getSearchItemGrouping)(Object.assign(Object.assign({}, suggestions), contentSuggestion), searchResultLimit, t);
    return { menuSearchItemGrouping };
};
exports.useMenuSearch = useMenuSearch;
