"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreAddressCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const StoreUtil_1 = require("../../../util/StoreUtil");
const StoreAddressCard = ({ storeDetails }) => {
    const { mainWebShop } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [StoreUtil_1.default.getStoreName(storeDetails, mainWebShop, t), (0, jsx_runtime_1.jsx)("br", {}), storeDetails.address.country === 'GB' ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [storeDetails.address.street, " ", storeDetails.address.number, (0, jsx_runtime_1.jsx)("br", {}), storeDetails.address.city, (0, jsx_runtime_1.jsx)("br", {}), storeDetails.address.zipCode] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [storeDetails.address.street, " ", storeDetails.address.number, ", ", storeDetails.address.zipCode, ' ', storeDetails.address.city] })), storeDetails.phoneNumber && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), storeDetails.phoneNumber] }))] }));
};
exports.StoreAddressCard = StoreAddressCard;
