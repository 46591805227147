"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostcodeWatcher = void 0;
const react_hook_form_1 = require("react-hook-form");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const addressFields_utils_1 = require("../addressFields.utils");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const common_types_1 = require("@as-react/common-types");
const PostcodeWatcher = ({ setAddresses, countryId, prefix }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const [postCodeFieldName, cityFieldName] = (0, ArrayUtil_1.mapWithAssert)([common_types_1.AddressFieldName.POSTCODE, common_types_1.AddressFieldName.CITY], name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    const { control, setError, setValue, clearErrors, formState } = (0, react_hook_form_1.useFormContext)();
    const [postalCode, city] = (0, react_hook_form_1.useWatch)({
        control,
        name: [postCodeFieldName, cityFieldName],
    });
    const { getAddresses } = (0, common_queries_1.generalQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { data: addresses, error: addressError } = (0, react_query_1.useQuery)(getAddresses({ countryId, postalCode: postalCode }, {
        enabled: Boolean(postalCode) && (0, addressFields_utils_1.isValidPostCode)(postalCode, countryId),
        retry: false,
    }));
    (0, react_1.useEffect)(() => {
        var _a;
        if (addressError && ((_a = addressError.response) === null || _a === void 0 ? void 0 : _a.status) !== common_types_1.HTTPStatusCode.InternalServerError) {
            setError(postCodeFieldName, { message: t('buy.error.no.results') });
        }
    }, [addressError]);
    (0, react_1.useEffect)(() => {
        if (!addresses || addresses.length === 0) {
            return;
        }
        const hasCustomCity = !!city && addresses.every(addressRecord => { var _a; return ((_a = addressRecord.city) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== city.toUpperCase(); });
        const postcodeIsTouched = Object.keys(formState.touchedFields).includes(postCodeFieldName);
        if (hasCustomCity && !postcodeIsTouched) {
            setAddresses([{ city }, ...addresses]);
            setValue(cityFieldName, city);
            clearErrors(cityFieldName);
            return;
        }
        setAddresses(addresses);
        if (addresses.length === 1 || postcodeIsTouched) {
            const address = addresses[0];
            setValue(cityFieldName, address.city);
            clearErrors(cityFieldName);
        }
    }, [addresses]);
    return null;
};
exports.PostcodeWatcher = PostcodeWatcher;
