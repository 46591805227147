"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerDetailsFields = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const FormField_1 = require("../FormField");
const CheckoutContext_1 = require("../../../context/CheckoutContext");
const CountryUtil_1 = require("../../../util/CountryUtil");
const CustomerDetailsFields_utils_1 = require("./CustomerDetailsFields.utils");
const DATE_SEPARATOR = '/';
const parseDate = (date) => {
    if (!date.day && !date.month && !date.year)
        return '';
    const day = date.day.length === 1 ? `0${date.day}` : date.day;
    const month = date.month.length === 1 ? `0${date.month}` : date.month;
    return [day, month, date.year].join(DATE_SEPARATOR);
};
const CustomerDetailsFields = ({ showMiddleName }) => {
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.checkout,
        i18nConstants_1.I18N_NAMESPACES.general,
        i18nConstants_1.I18N_NAMESPACES.navigation,
    ]);
    const { fictiveWebShop, defaultCountryCode } = (0, common_components_1.useAemConfig)();
    const { registrationForm: { showDateOfBirth, showMobilePhoneNumber, dateOfBirthExplanationText }, } = (0, CheckoutContext_1.useCheckoutConfig)();
    const defaultCellProps = { span: { xs: 12, md: 6 } };
    const nameCellProps = showMiddleName ? { span: { xs: 12, md: 4 } } : defaultCellProps;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 12, gapX: 4, gapY: 6, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, Object.assign({}, nameCellProps, { children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "firstName", required: true, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.firstname`), name: fieldProps.name, id: fieldProps.name, dataTestId: fieldProps.name, error: error, maxLength: 50 })));
                            } }) })), showMiddleName && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, Object.assign({}, nameCellProps, { children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "middleName", children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.middleName`), optionalText: t(`${i18nConstants_1.I18N_NAMESPACES.general}:form.field.optional`), name: fieldProps.name, id: fieldProps.name, dataTestId: fieldProps.name, error: error, maxLength: 50 })));
                            } }) }))), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, Object.assign({}, nameCellProps, { children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "lastName", required: true, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, fieldProps = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { innerRef: ref, label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.lastname`), name: fieldProps.name, id: fieldProps.name, dataTestId: fieldProps.name, error: error, maxLength: 50 })));
                            } }) }))] }), (showDateOfBirth || showMobilePhoneNumber) && ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 12, gapX: 4, gapY: 6, children: [showDateOfBirth && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, Object.assign({}, defaultCellProps, { children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "dateOfBirth", children: ({ fieldProps, error }) => ((0, jsx_runtime_1.jsx)(andes_react_1.DateField, { id: fieldProps.name, name: fieldProps.name, label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.dob`), optionalText: t(`${i18nConstants_1.I18N_NAMESPACES.general}:form.field.optional`), hint: dateOfBirthExplanationText, error: error, onChange: date => fieldProps.onChange(parseDate(date)), onComponentBlur: fieldProps.onBlur })) }) }))), showMobilePhoneNumber && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, Object.assign({}, defaultCellProps, { children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "mobilePhoneNumber", children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.PhoneField, Object.assign({}, options, { label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.mobilephone`), optionalText: t(`${i18nConstants_1.I18N_NAMESPACES.general}:form.field.optional`), countrySelectLabel: t(`${i18nConstants_1.I18N_NAMESPACES.general}:form.field.phone_number.country_select.label`), name: options.name, id: options.name, dataTestId: options.name, error: error, maxLength: 50, defaultCountry: (0, CountryUtil_1.getCountryByFictiveWebShop)(fictiveWebShop), countryOptionsOrder: CustomerDetailsFields_utils_1.phoneFieldCountriesOrder[defaultCountryCode] })));
                            } }) })))] }))] }));
};
exports.CustomerDetailsFields = CustomerDetailsFields;
