"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetPasswordForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_query_1 = require("@tanstack/react-query");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_hook_form_1 = require("react-hook-form");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const GuestLoginBlock_1 = require("./GuestLoginBlock");
const CheckoutContext_1 = require("../../../../context/CheckoutContext");
const useGuestLogin_1 = require("../../../../hooks/useGuestLogin");
const SetPasswordForm = ({ onEditEmailClick }) => {
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.checkout,
        i18nConstants_1.I18N_NAMESPACES.general,
        i18nConstants_1.I18N_NAMESPACES.navigation,
    ]);
    const { defaultRequestParams, servicesEndpoint, mainWebShop } = (0, common_components_1.useAemConfig)();
    const { disableGuestLoginForRegisteredUser } = (0, CheckoutContext_1.useCheckoutConfig)();
    const [state] = (0, CheckoutContext_1.useCheckoutState)();
    const { email, accountStatusCode } = state.login;
    const [resetPasswordEmailSent, setResetPasswordEmailSent] = (0, react_1.useState)(false);
    const [errorMessage, setErrorMessage] = (0, react_1.useState)('');
    const formProps = (0, react_hook_form_1.useForm)({ defaultValues: { email }, mode: 'onTouched' });
    const { sendPasswordResetEmailMutation } = (0, common_queries_1.customerMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutate: sendPasswordResetEmail, isPending: isSendPasswordPending } = (0, react_query_1.useMutation)(sendPasswordResetEmailMutation());
    const { guestLogin, errorMessage: guestErrorMessage, isPending: isGuestLoginPending } = (0, useGuestLogin_1.useGuestLogin)();
    const otherFasciaEmail = [
        common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA,
        common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA,
    ].includes(accountStatusCode);
    const handleGuestLoginClick = () => guestLogin(email);
    const onSubmit = ({ email }) => {
        sendPasswordResetEmail({ email }, {
            onSuccess: () => {
                setResetPasswordEmailSent(true);
                setErrorMessage('');
            },
            onError: () => {
                setErrorMessage(t(common_types_1.ErrorMessage.GENERAL_ACCOUNT));
            },
        });
    };
    (0, react_1.useEffect)(() => {
        guestErrorMessage && setErrorMessage(t(guestErrorMessage));
    }, [guestErrorMessage]);
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(onSubmit), name: "set-password-form", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", color: "emphasised", children: t([
                            `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.title.${mainWebShop}`,
                            `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.title`,
                        ]) }), !resetPasswordEmailSent && ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t(otherFasciaEmail
                            ? [
                                `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.known_email.other_fascia.intro.${mainWebShop}`,
                                `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.known_email.other_fascia.intro`,
                            ]
                            : [
                                `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.known_email.intro.${mainWebShop}`,
                                `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.known_email.intro`,
                            ]), status: "informative" })), errorMessage && (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: errorMessage, status: "error" }), resetPasswordEmailSent && !errorMessage && ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "success", text: t([
                            `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.success.${mainWebShop}`,
                            `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.success`,
                        ]) })), !resetPasswordEmailSent && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { strong: true, wordBreak: "break", children: [email, ' - ', (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.edit`), onClick: onEditEmailClick })] }), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { type: "submit", theme: "branded", state: isSendPasswordPending ? 'loading' : 'interactive', text: t([
                                    `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.action.${mainWebShop}`,
                                    `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.set_password.action`,
                                ]), fullWidth: true })] })), !disableGuestLoginForRegisteredUser && ((0, jsx_runtime_1.jsx)(GuestLoginBlock_1.GuestLoginBlock, { isLoading: isGuestLoginPending, onGuestLoginClick: handleGuestLoginClick }))] }) }) })));
};
exports.SetPasswordForm = SetPasswordForm;
