"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaleLineRow = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const ImageUtil_1 = require("../../util/ImageUtil");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const formik_1 = require("formik");
const ProductTileCompact_1 = require("../product-tile/compact/ProductTileCompact");
const SaleLineRow = ({ saleLine, fieldPrefix, returnReasons }) => {
    var _a, _b;
    const { errors, touched, values, setFieldValue, setFieldTouched, validateField } = (0, formik_1.useFormikContext)();
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.general]);
    const { productImageConfig } = (0, common_components_1.useAemConfig)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const imageUrl = (0, ImageUtil_1.getImageSourceSetByProductCode)(saleLine.productCode, saleLine.picture, productImageConfig);
    const hasReturnReasonError = (0, formik_1.getIn)(errors, `${fieldPrefix}.returnReasonId`) && (0, formik_1.getIn)(touched, `${fieldPrefix}.returnReasonId`);
    const hasReturnReasonFreeTextError = (0, formik_1.getIn)(errors, `${fieldPrefix}.returnReasonFreeText`) && (0, formik_1.getIn)(touched, `${fieldPrefix}.returnReasonFreeText`);
    const saleLineValues = (0, formik_1.getIn)(values, fieldPrefix);
    const freeTextFieldVisible = (_a = returnReasons.find(reason => reason.returnReasonId === parseInt(saleLineValues.returnReasonId, 10))) === null || _a === void 0 ? void 0 : _a.freeText;
    (0, react_1.useEffect)(() => {
        var _a, _b;
        if (((_a = saleLineValues.returnReasonFreeText) === null || _a === void 0 ? void 0 : _a.length) > common_types_1.DefaultSettings.MAX_LENGTH_TEXT_AREA) {
            const freeText = saleLineValues.returnReasonFreeText.slice(0, common_types_1.DefaultSettings.MAX_LENGTH_TEXT_AREA);
            setFieldTouched(`${fieldPrefix}.returnReasonFreeText`);
            setFieldValue(`${fieldPrefix}.returnReasonFreeText`, freeText);
        }
        else if (((_b = saleLineValues.returnReasonFreeText) === null || _b === void 0 ? void 0 : _b.length) < common_types_1.DefaultSettings.MAX_LENGTH_TEXT_AREA &&
            hasReturnReasonFreeTextError) {
            validateField(`${fieldPrefix}.returnReasonFreeText`);
        }
    }, [saleLineValues.returnReasonFreeText]);
    (0, react_1.useEffect)(() => {
        if (!saleLineValues.isSelected && saleLineValues.returnReasonId) {
            setFieldValue(`${fieldPrefix}.isSelected`, true);
        }
        setFieldTouched(`${fieldPrefix}.returnReasonId`);
    }, [saleLineValues.returnReasonId]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
            spacing: {
                paddingTop: 3,
                paddingBottom: 3,
            },
        }, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, wrap: isMobile ? undefined : cotopaxi_1.GroupWrap.NOWRAP, column: isMobile, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: isMobile ? undefined : { basis: '50%' }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    marginBottom: 2,
                                },
                            }, tablet: {
                                spacing: {
                                    marginBottom: 0,
                                },
                            }, children: (0, jsx_runtime_1.jsx)(ProductTileCompact_1.ProductTileCompact, { asStatic: true, brand: saleLine.brand, title: saleLine.productDescription, quantity: isDesktop ? undefined : saleLine.quantity, url: saleLine.url, image: imageUrl[common_types_1.ProductImageSizesPortraitEnum.thumb], sizeDescription: saleLine.productSize, colorDescription: saleLine.productColour, checkbox: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { dataQA: "order_item_checkbox", name: `${fieldPrefix}.isSelected`, id: `${fieldPrefix}.isSelected`, componentType: cotopaxi_1.FormFieldType.CHECKBOX, Component: cotopaxi_1.CheckboxWithLabel }) }), withoutInnerSpacing: true, dataQA: "order_product" }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: isMobile ? undefined : { basis: '50%' }, tablet: { spacing: { paddingRight: 1.5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { fullHeight: true, column: true, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, valign: cotopaxi_1.GroupVerticalAlign.BOTTOM, gutter: cotopaxi_1.GroupGutter.NONE, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.TABLET, cotopaxi_1.MediaQuery.DESKTOP], children: saleLine.quantity > 1 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { paddingRight: 0.5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, dataQA: "order_item_quantity", children: [t(`${i18nConstants_1.I18N_NAMESPACES.account}:returnItems.quantity`), ": ", saleLine.quantity] }) })) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Container, { type: cotopaxi_1.ContainerType.FLUID, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { grid: 4, tablet: 3, desktop: 3, gutter: false, children: saleLine.quantity > 1 && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                                                    spacing: {
                                                        marginBottom: 1.5,
                                                        marginRight: 2,
                                                    },
                                                }, tablet: {
                                                    spacing: {
                                                        marginBottom: 0,
                                                    },
                                                }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { htmlFor: `${saleLine.saleLineId}-quantity`, emphasized: true, size: cotopaxi_1.LabelSize.MINOR, children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:returnItems.form.quantity.label`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { dataQA: "order_item_quantity_select", name: `${fieldPrefix}.qty`, componentType: cotopaxi_1.FormFieldType.SELECT, Component: cotopaxi_1.Select, options: Array.from(Array(saleLine.quantity).keys()).map(x => ({
                                                            label: x + 1,
                                                            value: x + 1,
                                                        })) })] })) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { grid: 12, tablet: 9, desktop: 9, gutter: false, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { htmlFor: `${saleLine.saleLineId}-reason`, emphasized: true, size: cotopaxi_1.LabelSize.MINOR, children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:returnItems.form.reasonForReturn.label`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { dataQA: "reason_select", name: `${fieldPrefix}.returnReasonId`, componentType: cotopaxi_1.FormFieldType.SELECT, Component: cotopaxi_1.Select, options: returnReasons.map(reason => ({
                                                        value: reason.returnReasonId,
                                                        label: reason.description,
                                                    })), placeholder: t(`${i18nConstants_1.I18N_NAMESPACES.account}:returnItems.form.reasonForReturn.placeholder`), fullWidth: true, state: hasReturnReasonError ? cotopaxi_1.SelectState.ERROR : undefined })] })] })] }) })] }), saleLineValues.returnReasonId && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, desktop: { spacing: { paddingRight: 1.5, paddingLeft: 7 } }, children: [freeTextFieldVisible && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, { dataQA: "comment_input", name: `${fieldPrefix}.returnReasonFreeText`, Component: cotopaxi_1.Textarea, fullWidth: true, placeholder: t(`${i18nConstants_1.I18N_NAMESPACES.account}:returnItems.form.remarks.placeholder`), rows: 6, state: hasReturnReasonFreeTextError ? cotopaxi_1.TextareaState.ERROR : undefined })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: hasReturnReasonFreeTextError ? cotopaxi_1.GroupAlign.SPACE_BETWEEN : cotopaxi_1.GroupAlign.RIGHT, children: [hasReturnReasonFreeTextError && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.NEGATIVE, inline: true, children: t(`${i18nConstants_1.I18N_NAMESPACES.general}:voucher.message.invalid`) })), freeTextFieldVisible && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.SUBTLE, inline: true, children: [((_b = saleLineValues.returnReasonFreeText) === null || _b === void 0 ? void 0 : _b.length) || 0, " / ", common_types_1.DefaultSettings.MAX_LENGTH_TEXT_AREA] }))] }) })] }))] }));
};
exports.SaleLineRow = SaleLineRow;
