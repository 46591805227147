"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFieldsBE = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const BuyFormUtil_1 = require("../../../../../util/BuyFormUtil");
const AddressFieldsBEWithValidation_1 = require("./AddressFieldsBEWithValidation");
const AddressFieldsBEDefault_1 = require("./AddressFieldsBEDefault");
const useFeatureToggle_1 = require("../../../../../hooks/useFeatureToggle");
const AddressFieldsBE = props => {
    const fieldsName = [
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.HOUSE_NUMBER,
        common_types_1.AddressFieldName.HOUSE_NUMBER_ADDITION,
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.CUSTOM_TOWN,
    ];
    const fields = fieldsName.map(name => (0, BuyFormUtil_1.generateFieldName)(name, props.prefix));
    const bpost_address_validation = (0, useFeatureToggle_1.useFeatureToggle)('bpost_address_validation');
    return bpost_address_validation ? ((0, jsx_runtime_1.jsx)(AddressFieldsBEWithValidation_1.AddressFieldsBEWithValidation, Object.assign({}, props, { fields: fields }))) : ((0, jsx_runtime_1.jsx)(AddressFieldsBEDefault_1.AddressFieldsBEDefault, Object.assign({}, props, { fields: fields })));
};
exports.AddressFieldsBE = AddressFieldsBE;
