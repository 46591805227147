"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const ImageUtil_1 = require("../util/ImageUtil");
const MiniPDPMultiContainer_1 = require("./MiniPDPMultiContainer");
const i18nConstants_1 = require("../constants/i18nConstants");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const ShopTheLookContainer = ({ images, cdnSettings, products, imageLabel, miniPdpTitle, mainWebshopId, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { imageCdnPrefix } = (0, common_components_1.useAemConfig)();
    const [isOpenMiniPDP, setIsOpenMiniPDP] = (0, react_1.useState)(false);
    const dispatch = (0, react_redux_1.useDispatch)();
    const defaultImage = (0, react_1.useMemo)(() => images.find(image => image.isDefault) || images[0], [images]);
    const extensiveCarouselImages = images.map(image => image.image);
    const defaultImageSrcset = (0, ImageUtil_1.getPictureSrcset)(defaultImage.image, imageCdnPrefix, cdnSettings === null || cdnSettings === void 0 ? void 0 : cdnSettings.imageQuality, cdnSettings === null || cdnSettings === void 0 ? void 0 : cdnSettings.requestPassThrough);
    const onMiniPDPClose = () => setIsOpenMiniPDP(false);
    const openMiniPDP = () => {
        const image = defaultImage.image.imagePath.split('/');
        dispatch(AnalyticsActions_creators_1.analyticsActions.shopTheLookOpened(image[image.length - 1]));
        setIsOpenMiniPDP(true);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ImageLabel, { image: { src: defaultImage.image.imagePath, srcset: defaultImageSrcset, isPicture: true }, label: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.INHERIT, children: imageLabel || t('product.look.title') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.CHEVRON_RIGHT, size: cotopaxi_1.IconSize.MEDIUM })] }), onClick: openMiniPDP }), (0, jsx_runtime_1.jsx)(MiniPDPMultiContainer_1.default, { isOpen: isOpenMiniPDP, onClose: onMiniPDPClose, productIds: products, extensiveCarouselImages: extensiveCarouselImages, cdnSettings: cdnSettings, popoverTitle: miniPdpTitle, mainWebshopId: mainWebshopId })] }));
};
exports.default = ShopTheLookContainer;
