"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSchemaDataContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const HtmlInclude_1 = require("../../components/common/html-include/HtmlInclude");
const ProductUtil_1 = require("../../util/ProductUtil");
const AMOUNT_PRODUCTS_DATA = 20;
const generateProductsSchema = (products, root, origin) => {
    return {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: products.map((product, index) => ({
            '@type': 'ListItem',
            position: String(index + 1),
            item: {
                '@type': 'Product',
                productID: product.productCode,
                name: product.title,
                description: product.description,
                brand: {
                    name: product.brand,
                    '@type': 'http://schema.org/Organization',
                    '@context': 'http://schema.org/',
                },
                url: `${origin}${(0, ProductUtil_1.getProductDetailUrl)(product, root)}`,
            },
        })),
    };
};
const ProductSchemaDataContainer = () => {
    const { canonicalUrl, root } = (0, common_components_1.useAemConfig)();
    const listerProducts = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetListerItems)())
        .filter(item => (0, common_types_1.isProduct)(item))
        .slice(0, AMOUNT_PRODUCTS_DATA);
    const { origin } = new URL(canonicalUrl);
    const productsSchema = generateProductsSchema(listerProducts, root, origin);
    return (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { scriptFragment: JSON.stringify(productsSchema, null, 2), scriptType: HtmlInclude_1.ScriptTypes.LD_JSON });
};
exports.ProductSchemaDataContainer = ProductSchemaDataContainer;
