"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateUrl = exports.formatDate = exports.getDeliveryPromise = exports.filterScheduleEntries = exports.DeliveryUnit = void 0;
const DateUtil_1 = require("../../../../util/DateUtil");
var DeliveryUnit;
(function (DeliveryUnit) {
    DeliveryUnit["HOURS"] = "H";
    DeliveryUnit["WEEKS"] = "W";
    DeliveryUnit["DAYS"] = "D";
})(DeliveryUnit || (exports.DeliveryUnit = DeliveryUnit = {}));
const filterScheduleEntries = (scheduleEntries) => {
    const COUNT_DAYS = 7;
    const CURRENT_DATE = new Date(Date.now()).getDate();
    const indexCurrentDate = scheduleEntries.findIndex(schedule => new Date(schedule.date).getDate() === CURRENT_DATE);
    return [...scheduleEntries].splice(indexCurrentDate, COUNT_DAYS);
};
exports.filterScheduleEntries = filterScheduleEntries;
const getDeliveryPromise = (store, t) => {
    let term = '';
    const singleDelivery = store.deliveryFrom === store.deliveryTo && store.deliveryTo === 1;
    switch (store.deliveryUnit) {
        case DeliveryUnit.HOURS:
            term = t(singleDelivery ? 'store:store.check.hour' : 'store:store.check.hours');
            break;
        case DeliveryUnit.WEEKS:
            term = t(singleDelivery ? 'store:store.check.week' : 'store:store.check.weeks');
            break;
        case DeliveryUnit.DAYS:
            term = t(singleDelivery ? 'store:store.check.day' : 'store:store.check.days');
            break;
        default:
            break;
    }
    if (store.deliveryFrom === store.deliveryTo) {
        return `${store.deliveryTo} ${term}`;
    }
    return `${store.deliveryFrom}-${store.deliveryTo} ${term}`;
};
exports.getDeliveryPromise = getDeliveryPromise;
const formatDate = (date, locale, format) => (0, DateUtil_1.formatTimeStampToDateString)(new Date(date).getTime(), locale, format);
exports.formatDate = formatDate;
const generateUrl = (store, location) => `https://www.openstreetmap.org/directions?engine=fossgis_osrm_car&route=${location.lat}%2C${location.lng}
     %3B${store.latitude}%2C${store.longitude}`;
exports.generateUrl = generateUrl;
