"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewHeading = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
const BasketActions_creators_1 = require("../../../redux/basket/actions/BasketActions.creators");
const BasketOverviewHeading = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const { isShopLanding } = (0, common_components_1.useAemConfig)();
    const status = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketStatusState)());
    const dispatch = (0, react_redux_1.useDispatch)();
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { contentAlign: "between", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { as: "h1", variant: "headingS", children: t('checkout.basket.title.page') }), isShopLanding && status === common_types_1.BasketStatus.READY && ((0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { dataTestId: "reset_basket", text: t('basket.reset'), iconLeft: { source: andes_icons_1.TrashIcon, size: 'sm' }, onClick: () => dispatch(BasketActions_creators_1.basketActions.createResetBasketForShopLanding()) }))] }));
};
exports.BasketOverviewHeading = BasketOverviewHeading;
