"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.analyticsActions = void 0;
const common_types_1 = require("@as-react/common-types");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const AnalyticsEnums_1 = require("../../../enums/AnalyticsEnums");
exports.analyticsActions = {
    digitalPartnerEnrolmentStepAuth(step, analyticsDigitalPartner) {
        switch (step.to) {
            case common_types_1.AuthFormStep.LOGIN:
                return {
                    type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_LOGIN,
                    payload: analyticsDigitalPartner,
                };
            case common_types_1.AuthFormStep.REGISTRATION:
                return {
                    type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_REGISTER,
                    payload: analyticsDigitalPartner,
                };
            case common_types_1.AuthFormStep.PASSWORD_FORGOTTEN:
                return {
                    type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_PASSWORD_FORGOTTEN,
                    payload: analyticsDigitalPartner,
                };
        }
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_EMAIL,
            payload: analyticsDigitalPartner,
        };
    },
    digitalPartnerEnrolmentStart(analyticsDigitalPartner) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_START,
            payload: analyticsDigitalPartner,
        };
    },
    digitalPartnerEnrolmentStepAuthEmail(analyticsDigitalPartner) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_EMAIL,
            payload: analyticsDigitalPartner,
        };
    },
    digitalPartnerEnrolmentStepAuthLogin(analyticsDigitalPartner) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_LOGIN,
            payload: analyticsDigitalPartner,
        };
    },
    digitalPartnerEnrolmentStepAuthRegister(analyticsDigitalPartner) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_REGISTER,
            payload: analyticsDigitalPartner,
        };
    },
    digitalPartnerEnrolmentStepAuthPasswordForgotten(analyticsDigitalPartner) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_PASSWORD_FORGOTTEN,
            payload: analyticsDigitalPartner,
        };
    },
    digitalPartnerEnrolmentStepEligibilityCheck(analyticsDigitalPartner) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_ELIGIBILITY_CHECK,
            payload: analyticsDigitalPartner,
        };
    },
    digitalPartnerEnrolmentStepConfirmation(analyticsDigitalPartner) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_CONFIRMATION,
            payload: analyticsDigitalPartner,
        };
    },
    digitalPartnerEnrolmentLeave(analyticsDigitalPartner) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_LEAVE,
            payload: analyticsDigitalPartner,
        };
    },
    formsErrorMessage(errorMessages) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.FORMS_ERROR_MESSAGE,
            payload: errorMessages,
        };
    },
    searchUpdatedOnSiteSearchValue(searchValue) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.SEARCH_ON_SITE_SEARCH_UPDATE,
            payload: searchValue,
        };
    },
    cookieConsentAcceptAll(consentSettings) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.CONSENT_ACCEPT_ALL,
            payload: consentSettings,
        };
    },
    cookieConsentUpdated(consentSettings) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.CONSENT_UPDATE_PREFERENCES,
            payload: consentSettings,
        };
    },
    cookieConsentOpenPreferences(consentSettings) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.CONSENT_OPEN_PREFERENCES,
            payload: consentSettings,
        };
    },
    loginStepOption(isNewCustomer) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.LOGIN_STEP_OPTION,
            payload: isNewCustomer,
        };
    },
    checkoutStep(checkoutStep) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.CHECKOUT_STEP,
            payload: checkoutStep,
        };
    },
    registrationStep() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.REGISTRATION_STEP,
        };
    },
    deliveryStep(deliveryOption) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DELIVERY_STEP,
            payload: deliveryOption,
        };
    },
    deliveryStepOption(deliveryOption) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.DELIVERY_STEP_OPTION,
            payload: deliveryOption,
        };
    },
    paymentRequest(paymentRequest) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PAYMENT_REQUEST,
            payload: paymentRequest,
        };
    },
    paymentStep(chosenPaymentOption) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PAYMENT_STEP,
            payload: chosenPaymentOption,
        };
    },
    paymentStepOption(paymentOption) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PAYMENT_STEP_OPTION,
            payload: paymentOption,
        };
    },
    fastCheckoutStep() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.FAST_CHECKOUT_STEP,
        };
    },
    voucherAdded(code) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.VOUCHER_ADDED,
            payload: code,
        };
    },
    googleConfirmationPage(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.ANALYTICS_GOOGLE_CONFIRMATION_PAGE,
            payload,
        };
    },
    confirmationPage() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.CONFIRMATION_PAGE,
        };
    },
    filterWizardOpened(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.FILTER_WIZARD_OPENED,
            payload,
        };
    },
    filterWizardSelected(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.FILTER_WIZARD_SELECTED,
            payload,
        };
    },
    checkoutType(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.CHECKOUT_TYPE,
            payload,
        };
    },
    compareProductsFeatures(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.COMPARE_PRODUCTS_FEATURES,
            payload,
        };
    },
    productSearchPersonalization(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PRODUCT_SEARCH_PERSONALIZATION,
            payload,
        };
    },
    storeFilterStarted() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.STORE_FILTER_STARTED,
        };
    },
    storeFilterApplied() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.STORE_FILTER_APPLIED,
        };
    },
    storeFilterModified() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.STORE_FILTER_MODIFIED,
        };
    },
    shopTheLookOpened(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.SHOP_THE_LOOK_OPENED,
            payload,
        };
    },
    listerSortChange(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.LISTER_SORT_CHANGE,
            payload,
        };
    },
    newsletterPreferencesSubscriptionUpdate(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.NEWSLETTER_PREFERENCES_SUBSCRIPTION_UPDATE,
            payload,
        };
    },
    newsletterPreferencesSubscribe(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.NEWSLETTER_PREFERENCES_SUBSCRIBE,
            payload,
        };
    },
    newsletterPreferencesUnsubscribe(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.NEWSLETTER_PREFERENCES_UNSUBSCRIBE,
            payload,
        };
    },
    newsletterPreferencesUpdate(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.NEWSLETTER_PREFERENCES_UPDATE,
            payload,
        };
    },
    returnPortalLogin(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.RETURN_PORTAL_LOGIN,
            payload,
        };
    },
    returnPortalReturnOverviewOpened(hasItems) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.RETURN_PRODUCT_OVERVIEW,
            payload: hasItems,
        };
    },
    returnPortalAnnouncedOverviewOpened() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.RETURN_PRODUCT_OVERVIEW,
        };
    },
    returnProductsContinueClicked() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.RETURN_PRODUCTS_CONTINUE_CLICK,
            payload: {
                eventAction: AnalyticsEnums_1.ReturnPortalEventActionType.CONTINUE_TO_METHOD,
            },
        };
    },
    returnProductSelected() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.RETURN_PRODUCT_SELECTED,
            payload: {
                eventAction: AnalyticsEnums_1.ReturnPortalEventActionType.SELECT_PRODUCT,
            },
        };
    },
    returnProductUnselected() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.RETURN_PRODUCT_UNSELECTED,
            payload: {
                eventAction: AnalyticsEnums_1.ReturnPortalEventActionType.UNSELECT_PRODUCT,
            },
        };
    },
    returnMethodOverviewOpened() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.RETURN_METHOD_OVERVIEW,
        };
    },
    returnMethodConfirmClickClicked(returnMethod) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.RETURN_METHOD_CONFIRM_CLICK,
            payload: {
                eventAction: AnalyticsEnums_1.ReturnPortalEventActionType.CONFIRM_RETURN_METHOD,
                returnMethod,
            },
        };
    },
    returnMethodSelected(returnMethod) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.RETURN_METHOD_SELECTED,
            payload: {
                eventAction: AnalyticsEnums_1.ReturnPortalEventActionType.SELECT_RETURN_METHOD,
                returnMethod,
            },
        };
    },
    returnAnnounced(returnMethod) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.RETURN_ANNOUNCED,
            payload: {
                returnMethod,
            },
        };
    },
    showStoreStock() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.SHOW_STORE_STOCK,
        };
    },
    productDetailTabOpen(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PRODUCT_DETAIL_TAB_OPEN,
            payload,
        };
    },
    productDetailSizeGuideClick(pdpTemplateName) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PRODUCT_DETAIL_SIZE_GUIDE_CLICK,
            payload: pdpTemplateName,
        };
    },
    productDetailAddToBasket(analyticsAdobeTagManagerData, additionalProducts) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PRODUCT_DETAIL_ADD_TO_BASKET,
            payload: {
                analyticsAdobeTagManagerData,
                additionalProducts,
            },
        };
    },
    listerResults(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.LISTER_RESULTS,
            payload,
        };
    },
    listerViewProductList(items, currency, event, searchTerm) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.LISTER_VIEW_PRODUCT_LIST,
            payload: { items, currency, event, searchTerm },
        };
    },
    giftVoucherInit(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.GIFT_VOUCHER_INIT,
            payload,
        };
    },
    customerIdentification(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.CUSTOMER_IDENTIFICATION,
            payload,
        };
    },
    additionalProductSelector(products) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.ADDITIONAL_PRODUCT_SELECTOR_VIEWED,
            payload: products,
        };
    },
    bundleDealsViewed(colorId) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.BUNDLE_DEALS_VIEWED,
            payload: colorId,
        };
    },
    basketReminderNotificationViewed() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.BASKET_REMINDER_NOTIFICATION_VIEWED,
        };
    },
    basketReminderNotificationEvent(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.BASKET_REMINDER_NOTIFICATION_EVENT,
            payload,
        };
    },
    faqQuestionExpand(title) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.FAQ_QUESTION_EXPAND,
            payload: title,
        };
    },
    notifyMeRequest() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.NOTIFY_ME_REQUEST,
        };
    },
    applePayClick() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.APPLE_PAY_CLICK,
        };
    },
    applePayButtonInitialInViewport(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.APPLE_PAY_BUTTON_INITIAL_IN_VIEWPORT,
            payload,
        };
    },
    applePayButtonImpression(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.APPLE_PAY_BUTTON_IMPRESSION,
            payload,
        };
    },
    eTicketSendFeedback() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.ETICKET_SEND_FEEDBACK,
        };
    },
    paybackEnrichmentChanged(hasChanged) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PAYBACK_ENRICHMENT_CHANGED,
            payload: { hasChanged },
        };
    },
    paybackError(analyticsError) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PAYBACK_ERROR,
            payload: { analyticsError },
        };
    },
    productGroupViewed(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PRODUCT_GROUP_VIEWED,
            payload,
        };
    },
    productGroupItemClick(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PRODUCT_GROUP_ITEM_CLICK,
            payload,
        };
    },
    productVariantClick() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.PRODUCT_VARIANT_CLICK,
        };
    },
    blockedCheckout(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.BLOCKED_CHECKOUT,
            payload,
        };
    },
    mobileNavigationClick() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.MOBILE_NAVIGATION_CLICK,
        };
    },
    sizebayClick(payload) {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.SIZEBAY_CLICK,
            payload,
        };
    },
    checkoutPersonalDetails() {
        return {
            type: ActionEnums_1.AnalyticsActionTypes.CHECKOUT_PERSONAL_DETAILS,
        };
    },
};
