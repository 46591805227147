"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserLocation = void 0;
const react_query_1 = require("@tanstack/react-query");
const LocationUtil_1 = require("../../../../util/LocationUtil");
const LocalStorageUtil_1 = require("../../../../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../../../../constants/StorageKeysConstants");
const StoreAPI_1 = require("../../../../api/store/StoreAPI");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const OsmApi_1 = require("../../../../api/open-street-map/OsmApi");
const StoreUtil_1 = require("../../../../util/StoreUtil");
const StoreFinderConstants_1 = require("../../../../constants/StoreFinderConstants");
const react_redux_1 = require("react-redux");
const SessionSelector_1 = require("../../../../redux/session/selectors/SessionSelector");
const common_queries_1 = require("@as-react/common-queries");
const initLocation = {
    lat: '',
    lng: '',
};
const initLocationSearchResult = {
    location: initLocation,
    locationName: '',
    type: common_types_1.LocationSearchType.DEFAULT,
    state: 'INIT',
};
const useUserLocation = ({ shouldCheckLocation = true }) => {
    var _a;
    const { defaultRequestParams, servicesEndpoint, countryCodes, defaultCountryCode, isShopLanding } = (0, common_components_1.useAemConfig)();
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const locationFromStorage = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SEARCH_STORE_STORAGE_KEY);
    const { getProfile } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: profile } = (0, react_query_1.useQuery)(Object.assign(Object.assign({}, getProfile()), { enabled: Boolean(isLoggedIn) }));
    const enableQuery = {
        shoplanding: shouldCheckLocation && isShopLanding,
        localStorage: shouldCheckLocation && !isShopLanding && !!locationFromStorage,
        browserGeolocation: shouldCheckLocation && !isShopLanding && !locationFromStorage,
        profile: shouldCheckLocation && isLoggedIn && !isShopLanding && !locationFromStorage,
    };
    const browserLocationQueryResult = (0, react_query_1.useQuery)({
        queryKey: ['browserLocation'],
        queryFn: () => (0, LocationUtil_1.getLocationFromBrowser)(),
        enabled: enableQuery.browserGeolocation,
    });
    const { data: browserLocationData, error: browserLocationError, status: browserLocationStatus, } = browserLocationQueryResult;
    const osmAddressQueryResult = (0, react_query_1.useQuery)({
        queryKey: ['addressLocation', browserLocationData],
        queryFn: () => OsmApi_1.default.getAddressFromOSM(browserLocationData),
        enabled: browserLocationStatus !== 'pending' && !!browserLocationData && !browserLocationError,
        select: data => {
            if (data === null || data === void 0 ? void 0 : data.error) {
                return Object.assign(Object.assign({}, initLocationSearchResult), { state: 'ERROR' });
            }
            const locationName = `${data.address.road ? data.address.road : ''}${data.address.road && (data.address.town || data.address.city) ? ', ' : ''}${data.address.town ? data.address.town : data.address.city ? data.address.city : ''}`;
            return {
                location: {
                    lat: data.lat,
                    lng: data.lon,
                },
                locationName,
                type: common_types_1.LocationSearchType.SHARE_MY_LOCATION,
                state: 'SET',
            };
        },
    });
    const locationName = locationFromStorage ? locationFromStorage : ((_a = profile === null || profile === void 0 ? void 0 : profile.address) === null || _a === void 0 ? void 0 : _a.city) || '';
    const isPostCode = !!locationFromStorage && StoreUtil_1.default.isSearchByPostCode(locationName);
    const osmLocationQueryResult = (0, react_query_1.useQuery)({
        queryKey: ['locationLocation', locationName],
        queryFn: () => OsmApi_1.default.getLocationFromOSM(isPostCode ? StoreUtil_1.default.formatPostCode(locationName, defaultCountryCode) : locationName, isPostCode ? defaultCountryCode : countryCodes),
        enabled: !!locationName && (enableQuery.localStorage || enableQuery.profile),
        select: data => {
            const osmLocationByImportance = data
                .filter(place => StoreFinderConstants_1.LOCATION_TYPE.includes(place.type))
                .sort((a, b) => b.importance - a.importance);
            if (osmLocationByImportance.length > 0) {
                return {
                    location: {
                        lat: osmLocationByImportance[0].lat,
                        lng: osmLocationByImportance[0].lon,
                    },
                    locationName: locationName,
                    type: common_types_1.LocationSearchType.DEFAULT,
                    state: 'SET',
                };
            }
            return;
        },
    });
    const storeNumber = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY);
    const shoplandingQueryResult = (0, react_query_1.useQuery)({
        queryKey: ['shopLandingLocation', storeNumber],
        queryFn: () => StoreAPI_1.default.getShoplandingInfo(servicesEndpoint, defaultRequestParams.market, storeNumber),
        enabled: isShopLanding && !!storeNumber,
        select: data => {
            if (data === null || data === void 0 ? void 0 : data.geographic) {
                return {
                    location: {
                        lat: data.geographic.latitude,
                        lng: data.geographic.longitude,
                    },
                    locationName: '',
                    type: common_types_1.LocationSearchType.DEFAULT,
                    state: 'SET',
                };
            }
            return;
        },
    });
    if (isShopLanding) {
        return shoplandingQueryResult;
    }
    if ((enableQuery.localStorage || enableQuery.profile) && osmLocationQueryResult.data) {
        return osmLocationQueryResult;
    }
    if (browserLocationError) {
        return browserLocationQueryResult;
    }
    return osmAddressQueryResult;
};
exports.useUserLocation = useUserLocation;
