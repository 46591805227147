"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateAccount = void 0;
const tslib_1 = require("tslib");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_query_1 = require("@tanstack/react-query");
const QueryClientUtil_1 = require("../util/QueryClientUtil");
const useCreateAccount = () => {
    const [errorMessage, setErrorMessage] = (0, react_1.useState)(null);
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const { getLanguages } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { createAccountMutation } = (0, common_queries_1.customerMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutateAsync: create } = (0, react_query_1.useMutation)(createAccountMutation());
    const createAccount = (customerData) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setErrorMessage(null);
        try {
            const languages = yield QueryClientUtil_1.default.fetchQuery(getLanguages());
            const customerLanguage = languages.find(lang => lang.languageCode === customerData.language.toUpperCase());
            if (customerLanguage === null || customerLanguage === void 0 ? void 0 : customerLanguage.idLanguage) {
                customerData.idLanguage = customerLanguage.idLanguage;
            }
            yield create({ customerData });
            return true;
        }
        catch (e) {
            setErrorMessage(common_types_1.ErrorMessage.GENERAL_ACCOUNT);
            return false;
        }
    });
    return { createAccount, errorMessage };
};
exports.useCreateAccount = useCreateAccount;
