"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetBasketSaga = exports.resetBasket = void 0;
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
function* resetBasket() {
    try {
        const { defaultRequestParams, servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
        yield (0, effects_1.call)(BasketAPI_1.default.resetBasketForShopLanding, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
    catch (_a) { }
}
exports.resetBasket = resetBasket;
function* resetBasketSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.BasketActionTypes.RESET_BASKET_FOR_SHOPLANDING, resetBasket);
}
exports.resetBasketSaga = resetBasketSaga;
