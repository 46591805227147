"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFieldsBE = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const addressFields_utils_1 = require("../addressFields.utils");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const useFeatureToggle_1 = require("../../../../hooks/useFeatureToggle");
const AddressFieldsBEDefault_1 = require("./AddressFieldsBEDefault");
const AddressFieldsBEWithValidation_1 = require("./AddressFieldsBEWithValidation");
const AddressFieldsBE = (props) => {
    const fieldsName = [
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.HOUSE_NUMBER,
        common_types_1.AddressFieldName.HOUSE_NUMBER_ADDITION,
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.CUSTOM_TOWN,
    ];
    const fields = (0, ArrayUtil_1.mapWithAssert)(fieldsName, name => (0, addressFields_utils_1.generateFieldName)(name, props.prefix));
    const bpost_address_validation = (0, useFeatureToggle_1.useFeatureToggle)('bpost_address_validation');
    return bpost_address_validation ? ((0, jsx_runtime_1.jsx)(AddressFieldsBEWithValidation_1.AddressFieldsBEWithValidation, Object.assign({}, props, { fields: fields }))) : ((0, jsx_runtime_1.jsx)(AddressFieldsBEDefault_1.AddressFieldsBEDefault, Object.assign({}, props, { fields: fields })));
};
exports.AddressFieldsBE = AddressFieldsBE;
