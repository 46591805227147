"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleLoginGuestError = exports.handleLoginGuestSuccess = exports.loginGuestSuccessSaga = exports.loginGuest = exports.loginGuestSaga = exports.socialLoginFacebook = exports.socialLogin = exports.socialLoginSaga = exports.loadSocialAuthFacebook = exports.loadSocial = exports.loadSocialSaga = exports.resetCredentialsSaga = exports.resetCredentials = exports.logoutSaga = exports.logout = exports.loginSuccessSaga = exports.registeredLoginSaga = exports.loginReturnGuestSaga = exports.loginSaga = exports.handleLoginError = exports.handleLoginSuccess = exports.loginReturnGuest = exports.registeredLogin = exports.login = exports.getUserAuthStateSaga = exports.getUserAuthState = void 0;
const AnalyticsActions_creators_1 = require("./../../analytics/actions/AnalyticsActions.creators");
const common_types_1 = require("@as-react/common-types");
const effects_1 = require("redux-saga/effects");
const AuthApi_1 = require("../../../api/auth/AuthApi");
const AuthSocialLoginFacebookApi_1 = require("../../../api/auth/AuthSocialLoginFacebookApi");
const CustomerApi_1 = require("../../../api/customer/CustomerApi");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const EventEnum_1 = require("../../../enums/EventEnum");
const EventUtil_1 = require("../../../util/EventUtil");
const BasketUtil_1 = require("../../../util/BasketUtil");
const SessionStorageUtil_1 = require("../../../util/SessionStorageUtil");
const BasketActions_creators_1 = require("../../basket/actions/BasketActions.creators");
const WishListActions_creators_1 = require("../../wishList/actions/WishListActions.creators");
const AuthActions_creators_1 = require("../actions/AuthActions.creators");
const connected_react_router_1 = require("connected-react-router");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const SessionUtil_1 = require("../../../util/SessionUtil");
const AuthUtil_1 = require("../../../util/AuthUtil");
const getInitializedSocialList = state => state.authentication.social.initialized;
const getAuthError = state => state.authentication.error;
const getIsLoggedIn = state => (0, SessionUtil_1.isLoggedInUser)(state.session);
function* customEventSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.GeneralActionTypes.CUSTOM_EVENT, handleCustomEvent);
}
function* handleCustomEvent(action) {
    var _a;
    switch (action.payload.eventType) {
        case EventEnum_1.EventEnum.LATITUDE_USER_LOGGED_IN: // is dispatched from the AEM Latitude component
            yield (0, effects_1.put)(AuthActions_creators_1.authActions.loginSuccess(((_a = action.payload.data) === null || _a === void 0 ? void 0 : _a.redirectUrl)
                ? { redirectUrl: action.payload.data.redirectUrl }
                : { fetchWishlistProducts: true }));
            break;
        default:
            break;
    }
}
function* getUserAuthState() {
    try {
        const { servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
        yield (0, effects_1.call)(AuthApi_1.default.isAuthenticated, servicesEndpoint);
        yield (0, effects_1.put)(AuthActions_creators_1.authActions.updateUserAuthState({ authenticated: true }));
    }
    catch (_a) { }
}
exports.getUserAuthState = getUserAuthState;
function* getUserAuthStateSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.GET_USER_AUTH_STATE, getUserAuthState);
}
exports.getUserAuthStateSaga = getUserAuthStateSaga;
function* login(action) {
    try {
        const { servicesEndpoint, defaultRequestParams } = yield (0, effects_1.getContext)('aemConfiguration');
        const loginResponseData = yield (0, effects_1.call)(AuthApi_1.default.login, servicesEndpoint, defaultRequestParams, action.payload.username, action.payload.password, action.payload.keepSignedIn);
        if (!action.payload.reloadOnSuccess) {
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.LOGIN_SUCCESS, loginResponseData);
        }
        yield (0, effects_1.put)(AuthActions_creators_1.authActions.loginSuccess({ reload: action.payload.reloadOnSuccess }));
    }
    catch (error) {
        yield (0, effects_1.fork)(handleLoginError, error, action.payload.location);
    }
}
exports.login = login;
function* registeredLogin(action) {
    try {
        const { username, password, keepSignedIn, reloadOnSuccess } = action.payload;
        const { servicesEndpoint, root, defaultRequestParams } = yield (0, effects_1.getContext)('aemConfiguration');
        const loginResponseData = yield (0, effects_1.call)(AuthApi_1.default.registeredLogin, servicesEndpoint, defaultRequestParams, username, password, keepSignedIn);
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.LOGIN_SUCCESS, loginResponseData);
        if (!loginResponseData.data.readyForCheckout) {
            SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.BASKET_CHANGED, true);
            location.href = `${root}checkout/basket-overview.html`;
            return;
        }
        if (loginResponseData.data.fastCheckoutEligible) {
            yield (0, effects_1.put)(AuthActions_creators_1.authActions.fastCheckout(true));
        }
        yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.loginStepOption(false));
        yield (0, effects_1.put)(AuthActions_creators_1.authActions.loginSuccess({ reload: reloadOnSuccess }));
    }
    catch (error) {
        yield (0, effects_1.fork)(handleLoginError, error, action.payload.location);
    }
}
exports.registeredLogin = registeredLogin;
function* loginReturnGuest(action) {
    var _a, _b, _c, _d;
    try {
        const { servicesEndpoint, defaultRequestParams } = yield (0, effects_1.getContext)('aemConfiguration');
        yield (0, effects_1.call)(AuthApi_1.default.loginReturnGuest, servicesEndpoint, defaultRequestParams, action.payload.username, action.payload.orderNumber);
        yield (0, effects_1.put)(AuthActions_creators_1.authActions.createLoginReturnGuestSuccessAction());
    }
    catch (error) {
        if (((_a = error === null || error === void 0 ? void 0 : error.response.data) === null || _a === void 0 ? void 0 : _a.error_code) === '404' ||
            ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status) === common_types_1.HTTPStatusCode.Forbidden ||
            ((_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.code) ===
                common_types_1.GuestLoginResponseCode.VALIDATION) {
            yield (0, effects_1.put)(AuthActions_creators_1.authActions.createLoginReturnGuestFailAction(common_types_1.ErrorMessage.GUEST_ACCOUNT_DOES_NOT_MATCH));
        }
        else {
            yield (0, effects_1.put)(AuthActions_creators_1.authActions.createLoginReturnGuestFailAction(common_types_1.ErrorMessage.GENERAL));
        }
    }
}
exports.loginReturnGuest = loginReturnGuest;
function* handleLoginSuccess(action) {
    var _a, _b, _c;
    SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.GUEST_EMAIL);
    if (((_a = action.payload) === null || _a === void 0 ? void 0 : _a.reload) || ((_b = action.payload) === null || _b === void 0 ? void 0 : _b.redirectUrl)) {
        yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.login());
        yield (0, effects_1.take)(ActionEnums_1.WishListActionTypes.LOGGED_IN);
        if (action.payload.reload) {
            location.reload();
        }
        else if (action.payload.redirectUrl) {
            location.href = action.payload.redirectUrl;
        }
    }
    else {
        yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.login());
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.USER_LOGGED_IN, { loggedIn: true });
        if ((_c = action.payload) === null || _c === void 0 ? void 0 : _c.fetchWishlistProducts) {
            yield (0, effects_1.take)(ActionEnums_1.WishListActionTypes.LOGGED_IN);
            yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.getWishListProducts());
        }
    }
}
exports.handleLoginSuccess = handleLoginSuccess;
function* handleLoginError(error, location) {
    var _a, _b;
    const errorMessage = (0, AuthUtil_1.getLoginErrorMessage)(Number((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error_code), location);
    yield (0, effects_1.put)(AuthActions_creators_1.authActions.loginFail(errorMessage));
}
exports.handleLoginError = handleLoginError;
function* loginSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.LOGIN, login);
}
exports.loginSaga = loginSaga;
function* loginReturnGuestSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.LOGIN_RETURN_GUEST, loginReturnGuest);
}
exports.loginReturnGuestSaga = loginReturnGuestSaga;
function* registeredLoginSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.REGISTERED_LOGIN, registeredLogin);
}
exports.registeredLoginSaga = registeredLoginSaga;
function* loginSuccessSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.LOGIN_SUCCESS, handleLoginSuccess);
}
exports.loginSuccessSaga = loginSuccessSaga;
function* logout() {
    try {
        const { servicesEndpoint, defaultRequestParams } = yield (0, effects_1.getContext)('aemConfiguration');
        yield (0, effects_1.call)(AuthApi_1.default.logout, servicesEndpoint, defaultRequestParams);
        if (AuthSocialLoginFacebookApi_1.default.isInitialized()) {
            const isAuthenticated = yield (0, effects_1.call)(AuthSocialLoginFacebookApi_1.default.isAuthenticated);
            if (isAuthenticated) {
                yield (0, effects_1.call)(AuthSocialLoginFacebookApi_1.default.logoutWithFacebook);
            }
        }
        yield (0, effects_1.put)(AuthActions_creators_1.authActions.logoutSuccess());
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.USER_LOGGED_OUT);
    }
    catch (_a) { }
}
exports.logout = logout;
function* logoutSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.LOGOUT, logout);
}
exports.logoutSaga = logoutSaga;
function* resetCredentials(action) {
    var _a, _b;
    try {
        const { servicesEndpoint, defaultRequestParams } = yield (0, effects_1.getContext)('aemConfiguration');
        yield (0, effects_1.call)(CustomerApi_1.default.sendPasswordResetMail, servicesEndpoint, defaultRequestParams, action.payload);
        yield (0, effects_1.put)(AuthActions_creators_1.authActions.createResetCredentialsSuccessAction('navigation:flyout.account.succes.operation'));
    }
    catch (error) {
        let errorMessage = 'navigation:flyout.account.error.operation.mail';
        if (((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error_code) === '404') {
            errorMessage = 'navigation:flyout.account.error.email.unknown.user';
        }
        yield (0, effects_1.put)(AuthActions_creators_1.authActions.createResetCredentialsErrorAction(errorMessage));
    }
}
exports.resetCredentials = resetCredentials;
function* resetCredentialsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.RESET_PASSWORD, resetCredentials);
}
exports.resetCredentialsSaga = resetCredentialsSaga;
function* loadSocialSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.INIT_SOCIAL, loadSocial);
}
exports.loadSocialSaga = loadSocialSaga;
function* loadSocial() {
    try {
        const initialized = yield (0, effects_1.select)(getInitializedSocialList);
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const enabledList = aemConfigurationContext.thirdparty.socialLogin.enabledList;
        const loadList = enabledList.filter((item) => !initialized.includes(item));
        const socialAuthObject = {
            [common_types_1.AemConfigurationThirdpartySocialLoginProvider.FACEBOOK]: loadSocialAuthFacebook,
        };
        yield (0, effects_1.all)(loadList
            .filter((item) => !!socialAuthObject[item])
            .map((item) => (0, effects_1.spawn)(socialAuthObject[item])));
    }
    catch (_a) { }
}
exports.loadSocial = loadSocial;
function* loadSocialAuthFacebook() {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const facebookConfig = aemConfigurationContext.thirdparty.facebook;
        const { pageInfo, locale, servicesEndpoint, defaultRequestParams } = aemConfigurationContext;
        const { isBuyPage } = pageInfo;
        AuthSocialLoginFacebookApi_1.default.setApiPrefix(servicesEndpoint);
        AuthSocialLoginFacebookApi_1.default.setDefaultRequestParams(defaultRequestParams);
        AuthSocialLoginFacebookApi_1.default.setLocale(locale);
        AuthSocialLoginFacebookApi_1.default.setIsBuyPage(isBuyPage);
        AuthSocialLoginFacebookApi_1.default.setFacebookApplicationId(facebookConfig.appId);
        yield (0, effects_1.call)(AuthSocialLoginFacebookApi_1.default.initializeSDK);
        yield (0, effects_1.put)(AuthActions_creators_1.authActions.createLoadSocialSuccessAction(common_types_1.AemConfigurationThirdpartySocialLoginProvider.FACEBOOK));
    }
    catch (_a) { }
}
exports.loadSocialAuthFacebook = loadSocialAuthFacebook;
function* socialLoginSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.SOCIAL_LOGIN, socialLogin);
}
exports.socialLoginSaga = socialLoginSaga;
function* socialLogin(action) {
    switch (action.payload) {
        case common_types_1.AemConfigurationThirdpartySocialLoginProvider.FACEBOOK:
            yield (0, effects_1.fork)(socialLoginFacebook);
            break;
        default:
            break;
    }
}
exports.socialLogin = socialLogin;
function* socialLoginFacebook() {
    try {
        const authError = yield (0, effects_1.select)(getAuthError);
        const isLoggedIn = yield (0, effects_1.select)(getIsLoggedIn);
        const isAuthenticatedOnFacebook = yield (0, effects_1.call)(AuthSocialLoginFacebookApi_1.default.isAuthenticated);
        if (authError === AuthSocialLoginFacebookApi_1.FacebookErrorsEnum.PERMISSION_EMAIL) {
            const loggedIn = yield (0, effects_1.call)(AuthSocialLoginFacebookApi_1.default.updatePermissions);
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.USER_LOGGED_IN, { loggedIn });
        }
        else if (!isAuthenticatedOnFacebook) {
            const loggedIn = yield (0, effects_1.call)(AuthSocialLoginFacebookApi_1.default.login);
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.USER_LOGGED_IN, { loggedIn });
        }
        else if (isAuthenticatedOnFacebook && !isLoggedIn) {
            yield (0, effects_1.call)(AuthSocialLoginFacebookApi_1.default.logoutWithFacebook);
            const loggedIn = yield (0, effects_1.call)(AuthSocialLoginFacebookApi_1.default.login);
            EventUtil_1.default.dispatch(EventEnum_1.EventEnum.USER_LOGGED_IN, { loggedIn });
        }
    }
    catch (e) {
        yield (0, effects_1.put)(AuthActions_creators_1.authActions.loginFail(e.message));
    }
}
exports.socialLoginFacebook = socialLoginFacebook;
function* loginGuestSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.LOGIN_GUEST, loginGuest);
}
exports.loginGuestSaga = loginGuestSaga;
function* loginGuest(action) {
    const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
    const { isShopLanding, servicesEndpoint, defaultRequestParams } = aemConfigurationContext;
    const isKnownCustomerCard = action.payload.exploreMoreCardNumber && action.payload.customerUUID;
    try {
        if (isKnownCustomerCard) {
            yield (0, effects_1.call)(CustomerApi_1.default.updateCardWithEmail, servicesEndpoint, defaultRequestParams, action.payload.userName, action.payload.customerUUID);
        }
        yield (0, effects_1.call)(AuthApi_1.default.loginGuest, servicesEndpoint, defaultRequestParams, action.payload.userName, action.payload.exploreMoreCardNumber);
        yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.loginStepOption(true));
        yield (0, effects_1.put)(AuthActions_creators_1.authActions.createLoginGuestSuccessAction());
        if (!isShopLanding) {
            SessionStorageUtil_1.default.setItem(StorageKeysConstants_1.GUEST_EMAIL, action.payload.userName);
        }
    }
    catch (error) {
        yield (0, effects_1.fork)(handleLoginGuestError, error);
        if (!isShopLanding) {
            SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.GUEST_EMAIL);
        }
    }
}
exports.loginGuest = loginGuest;
function* loginGuestSuccessSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.AuthActionTypes.LOGIN_GUEST_SUCCESS, handleLoginGuestSuccess);
}
exports.loginGuestSuccessSaga = loginGuestSuccessSaga;
function* handleLoginGuestSuccess() {
    const { isInAuthorMode, root } = yield (0, effects_1.getContext)('aemConfiguration');
    yield (0, effects_1.put)((0, connected_react_router_1.push)((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.DELIVERY}`, isInAuthorMode)));
    yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.login());
    yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetBasicAction());
    EventUtil_1.default.dispatch(EventEnum_1.EventEnum.USER_LOGGED_IN, { loggedIn: true });
}
exports.handleLoginGuestSuccess = handleLoginGuestSuccess;
function* handleLoginGuestError(error) {
    var _a;
    const { root } = yield (0, effects_1.getContext)('aemConfiguration');
    let guestErrorMessage;
    if (BasketUtil_1.default.isBasketNotInitialized(error)) {
        location.href = `${root}checkout/basket-overview.html`;
        return;
    }
    if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error) === common_types_1.CheckoutErrorMessage.INVALID_EMAIL) {
        guestErrorMessage = common_types_1.ErrorMessage.INVALID_EMAIL;
    }
    else {
        guestErrorMessage = common_types_1.ErrorMessage.API_ERROR;
    }
    yield (0, effects_1.put)(AuthActions_creators_1.authActions.createLoginGuestFailAction(guestErrorMessage));
}
exports.handleLoginGuestError = handleLoginGuestError;
function* authenticationSaga() {
    yield (0, effects_1.all)([
        customEventSaga(),
        getUserAuthStateSaga(),
        loginSaga(),
        registeredLoginSaga(),
        loginSuccessSaga(),
        logoutSaga(),
        resetCredentialsSaga(),
        loadSocialSaga(),
        socialLoginSaga(),
        loginGuestSaga(),
        loginGuestSuccessSaga(),
        loginReturnGuestSaga(),
    ]);
}
exports.default = authenticationSaga;
