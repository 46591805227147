"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompareEmptyPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const CompareEmptyPage = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { root } = (0, common_components_1.useAemConfig)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
            spacing: {
                paddingTop: 7,
                paddingBottom: 7,
            },
        }, tablet: {
            spacing: {
                paddingTop: 16,
                paddingBottom: 16,
            },
        }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, align: cotopaxi_1.GroupAlign.SPACE_AROUND, valign: !isMobile && cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.LARGE, inline: true, align: cotopaxi_1.TextAlign.CENTER, children: t('compare.empty.nothing.added') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: {
                            marginTop: 2,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: isMobile, href: `${root}lister.html`, text: t('compare.empty.add.products') }) })] }) }));
};
exports.CompareEmptyPage = CompareEmptyPage;
