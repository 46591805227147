"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompareCarousel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const ProductTileVertical_1 = require("../../product-tile/vertical/ProductTileVertical");
const ScreenWidthConstants_1 = require("../../../constants/ScreenWidthConstants");
const SliderConstants_1 = require("../../../constants/SliderConstants");
const ProductUtil_1 = require("../../../util/ProductUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const CompareCarousel = ({ comparisonProducts, onCloseClick, deliveryPromiseExplanations, addToBasket, getDeliveryPromise, setSwiperInstance, compareItemSelectSize, }) => {
    const { root } = (0, common_components_1.useAemConfig)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const [compareCarouselSwiper, setCompareCarouselSwiper] = (0, react_1.useState)(null);
    const setSwiper = (swiper) => {
        setCompareCarouselSwiper(swiper);
        setSwiperInstance(swiper);
    };
    (0, react_1.useEffect)(() => {
        if (compareCarouselSwiper !== null) {
            compareCarouselSwiper.update();
        }
    }, [comparisonProducts, compareCarouselSwiper]);
    const compareCarouselConfig = {
        variant: cotopaxi_1.CarouselVariant.PRODUCT_TILE_NORMAL,
        arrowsConfig: {
            color: cotopaxi_1.SliderNavColor.BASE,
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
        },
        showPagination: false,
        fullWidth: true,
        configurationOverrides: {
            preventClicks: true,
            slidesPerView: 'auto',
            spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
            watchOverflow: true,
            getSwiper: setSwiper,
            breakpoints: {
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET]: {
                    slidesPerView: 'auto',
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.TABLET,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP]: {
                    slidesPerView: SliderConstants_1.SLIDES_AMOUNT_DESKTOP,
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.DESKTOP,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP_LARGE]: {
                    slidesPerView: SliderConstants_1.SLIDES_AMOUNT_DESKTOP_LARGE,
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.DESKTOP,
                },
            },
        },
    };
    const compareSlide = (0, react_1.useCallback)((product) => {
        const productOutOfStock = (0, ProductUtil_1.isProductOutOfStock)(product);
        const colorOutOfStock = (0, ProductUtil_1.isProductWithColorOutOfStock)(product);
        return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { gutter: false, grid: 4, desktop: 3, tablet: 4, tabletSmall: 4, children: [(0, jsx_runtime_1.jsx)(ProductTileVertical_1.ProductTileVertical, { product: product, initialActiveSku: product.selectedSku, showSwatchesOnHover: false, iconName: cotopaxi_1.IconName.CLOSE, onIconClick: onCloseClick, getDeliveryPromise: getDeliveryPromise, handleSizeSelect: compareItemSelectSize, deliveryPromiseExplanations: deliveryPromiseExplanations, format: "extensive", productTileExtensiveVariant: isDesktop ? null : 'simple', addToBasket: addToBasket, showProductOutOfStockOverlay: true, showColorOutOfStockOverlay: true, clickAndCollectOnly: isDesktop && (0, ProductUtil_1.isClickAndCollectOnly)(product), inStoreOnly: isDesktop && (0, ProductUtil_1.isInStoreOnly)(product), dropdownProps: { appendToBody: true } }), !isMobile && (productOutOfStock || colorOutOfStock) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { spacing: { marginTop: productOutOfStock ? 2.5 : 0 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.INFO, text: productOutOfStock ? (t('product.out.of.stock.feedback')) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [t('product.color.out.of.stock.feedback.part1'), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: (0, ProductUtil_1.getProductDetailUrl)(product, root), children: t('product.color.out.of.stock.feedback.part2') })] })), iconSize: cotopaxi_1.IconSize.MAJOR }) }))] }, `${product.productCode}${product.colourId.toString()}`));
    }, [comparisonProducts, addToBasket, isDesktop]);
    return (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, compareCarouselConfig, { children: comparisonProducts.map(compareSlide) }));
};
exports.CompareCarousel = CompareCarousel;
