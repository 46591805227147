"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryPromiseContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const DeliveryPromise_1 = require("../components/product-detail/delivery-promise/DeliveryPromise");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const common_components_1 = require("@as-react/common-components");
const DeliveryPromiseUtil_1 = require("../util/DeliveryPromiseUtil");
const DeliveryPromiseContainer = ({ deliveryPromiseExplanations, freeDeliveryMessage, thresholdFreeDelivery, }) => {
    var _a;
    const { feature_toggles } = (0, common_components_1.useAemConfig)();
    const deliveryPromise = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDeliveryPromises)());
    const sizeCombinationNotExist = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSizeCombinationNotExist)());
    const unavailableOnline = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)()).unavailableOnline;
    const fulfillment = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillment)());
    const fulfillmentBySize = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillmentBySize)());
    if (sizeCombinationNotExist ||
        (fulfillment === null || fulfillment === void 0 ? void 0 : fulfillment.hdExcluded) ||
        (fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded) ||
        unavailableOnline) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(DeliveryPromise_1.default, { featureToggles: feature_toggles, deliveryPromises: (_a = deliveryPromise === null || deliveryPromise === void 0 ? void 0 : deliveryPromise.deliveryPromises.filter(deliveryPromise => deliveryPromise.deliveryMessage)) !== null && _a !== void 0 ? _a : [], freeDeliveryMessage: freeDeliveryMessage, thresholdFreeDelivery: thresholdFreeDelivery, deliveryPromiseExplanation: (0, DeliveryPromiseUtil_1.getDeliveryExplanation)(deliveryPromise, deliveryPromiseExplanations) }));
};
exports.DeliveryPromiseContainer = DeliveryPromiseContainer;
