"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestLoginBlock = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const CheckoutContext_1 = require("../../../../context/CheckoutContext");
const GuestLoginBlock = ({ onGuestLoginClick, isLoading }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const { mainWebShop } = (0, common_components_1.useAemConfig)();
    const { prominentGuestCTA } = (0, CheckoutContext_1.useCheckoutConfig)();
    const buttonState = isLoading ? 'loading' : 'interactive';
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Divider, { text: t('checkout.login.guest.divider.label') }), prominentGuestCTA ? ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { alignment: "center", children: t([`checkout.login.guest.intro.${mainWebShop}`, 'checkout.login.guest.intro']) }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "3", children: (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, state: buttonState, variant: "secondary", text: t('checkout.login.guest.action'), onClick: onGuestLoginClick }) })] })) : ((0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { contentAlign: "center", children: (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t('checkout.login.guest.action'), state: buttonState, onClick: onGuestLoginClick }) }))] }));
};
exports.GuestLoginBlock = GuestLoginBlock;
