"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniPDPPrice = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const asNumeral_1 = require("../../../vendors/asNumeral");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductUtil_1 = require("../../../util/ProductUtil");
const common_components_1 = require("@as-react/common-components");
const MiniPDPPrice = ({ color, selectedSize, isOutOfStock }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { currency } = (0, common_components_1.useAemConfig)();
    const prices = (selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.prices) || color.prices;
    const productPriceTranslations = {
        savings: t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.dynamic.savings`),
        discount: t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.bundles.discount.info`),
    };
    const productPrices = (prices === null || prices === void 0 ? void 0 : prices.SELL)
        ? (0, common_utils_1.generateCotopaxiPriceViewModel)({
            prices,
            hasDiscount: color.hasDiscount,
        }, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency)
        : null;
    const getDiscount = () => selectedSize
        ? (0, ProductUtil_1.getProductDiscount)(selectedSize.productDiscountAmount, selectedSize.productDiscountPercentage, currency)
        : (0, ProductUtil_1.getProductDiscount)(color.price.productDiscountAmount, color.price.productDiscountPercentage, currency);
    if (!productPrices) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, opacity: isOutOfStock && 0.5, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductPrice, { currency: currency, priceActual: productPrices.priceActual, pricePrevious: productPrices.pricePrevious, hasDiscount: color.hasDiscount, productDiscount: color.hasDiscount ? getDiscount() : null, size: isMobile ? cotopaxi_1.PriceSize.MEDIUM : cotopaxi_1.PriceSize.LARGE, gutter: cotopaxi_1.GroupGutter.SMALL, priceSpacing: isMobile ? cotopaxi_1.PriceSpacing.MEDIUM : cotopaxi_1.PriceSpacing.XSMAll, translations: productPriceTranslations }) }));
};
exports.MiniPDPPrice = MiniPDPPrice;
