"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const UrlActions_creators_1 = require("../../redux/url/actions/UrlActions.creators");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const MetaLinkUtil_1 = require("../../util/MetaLinkUtil");
const ScrollUtil_1 = require("../../util/ScrollUtil");
const DOMElementsConstants_1 = require("../../constants/DOMElementsConstants");
const ContentSelectors_1 = require("../../redux/content/selectors/ContentSelectors");
const ContentListerPaginationContainer = () => {
    const { canonicalUrl } = (0, common_components_1.useAemConfig)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const queryInformation = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetUrlQueryInformationState)());
    const totalPages = (0, react_redux_1.useSelector)((0, ContentSelectors_1.makeGetContentTotalPages)());
    const onPageChangeHandler = (newPage) => {
        dispatch(UrlActions_creators_1.urlActions.updatePage(newPage));
        ScrollUtil_1.ScrollUtil.scrollToElement(document.querySelector(DOMElementsConstants_1.LISTER_LAYOUT));
    };
    (0, react_1.useEffect)(() => {
        (0, MetaLinkUtil_1.generateRelatedProductPageLinks)(canonicalUrl, queryInformation.page, queryInformation.size, totalPages);
    }, [queryInformation.page, queryInformation.size, totalPages]);
    if (totalPages <= 1)
        return null;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_AROUND, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Pagination, { baseUrl: canonicalUrl, currentPage: queryInformation.page || 0, onPageChange: onPageChangeHandler, totalPages: totalPages }) }));
};
exports.default = ContentListerPaginationContainer;
