"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const MiniPDPMulti_1 = require("../components/product-detail/mini-pdp/MiniPDPMulti");
const ProductsAPI_1 = require("../api/ProductsAPI");
const ProductDetailAPI_1 = require("../api/ProductDetailAPI");
const DeliveryPromiseAPI_1 = require("../api/DeliveryPromiseAPI");
const WishListSelectors_1 = require("../redux/wishList/selectors/WishListSelectors");
const WishListActions_creators_1 = require("../redux/wishList/actions/WishListActions.creators");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const ActionEnums_1 = require("../enums/ActionEnums");
const MiniPDPMultiContainer = ({ popoverTitle, title, onClose, productIds, cdnSettings, extensiveCarouselImages = [], isOpen, productsInfo = [], isPDP = false, mainWebshopId, }) => {
    const { defaultRequestParams, servicesEndpoint, authenticationConfig } = (0, common_components_1.useAemConfig)();
    const [shouldRender, setShouldRender] = (0, react_1.useState)(isPDP);
    const [products, setProducts] = (0, react_1.useState)([]);
    const [deliveryPromise, setDeliveryPromise] = (0, react_1.useState)(undefined);
    const [totalPrice, setTotalPrice] = (0, react_1.useState)(undefined);
    const dispatch = (0, react_redux_1.useDispatch)();
    const wishlistProducts = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetWishListProducts)());
    const onWishListToggle = (wishListProduct) => dispatch(WishListActions_creators_1.wishListActions.toggleProduct(wishListProduct));
    const onAddToBasket = (basketProducts) => dispatch(BasketActions_creators_1.basketActions.createAddBulkAction(basketProducts, ActionEnums_1.ProductAddToBasketLocations.MINI_PDP_SOP_THE_LOOK, isPDP ? productsInfo.length : products.length));
    const _onClose = () => {
        setTimeout(() => {
            onClose();
        }, cotopaxi_1.POPOVER_ANIMATION_DURATION);
    };
    const fetchProducts = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (!(productIds === null || productIds === void 0 ? void 0 : productIds.length))
            return;
        try {
            const productsData = yield Promise.all(productIds.map(product => {
                var _a;
                return ProductsAPI_1.default.getSingleProductData(servicesEndpoint, defaultRequestParams.mainWebshop, defaultRequestParams.anaLang, defaultRequestParams.language, defaultRequestParams.shopId, product.productCode, product.productColour, (_a = product.fallbackProducts) === null || _a === void 0 ? void 0 : _a.map(fallbackProduct => ({
                    productCode: fallbackProduct.productCode,
                    productColor: fallbackProduct.productColour,
                })), true);
            })).then(result => result.filter(Boolean));
            const productsPriceData = yield ProductDetailAPI_1.default.getAllProductDetailPriceByProductId(productsData.map(product => product.productId), servicesEndpoint, defaultRequestParams);
            const mergedProducts = productsData
                .map((product, i) => (0, common_utils_1.mergeProductPricesToProductLister)(product, productsPriceData[i]))
                .map(item => (Object.assign(Object.assign({}, item), { colours: item.colours.sort(color => (color.colourId === item.colourId ? -1 : 1)) })));
            setProducts(mergedProducts);
            setShouldRender(true);
        }
        catch (_a) { }
    });
    const fetchDeliveryPromise = (items) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (!items.length) {
            setDeliveryPromise(undefined);
            return;
        }
        try {
            const deliveryPromiseData = yield DeliveryPromiseAPI_1.default.getBulkDeliveryPromise(items.map(item => ({ colorId: item.colorId, productId: item.product.productId, skuId: item.sku })), servicesEndpoint, authenticationConfig);
            const latestDeliveryPromise = deliveryPromiseData
                .map(item => (Object.assign(Object.assign({}, item), { deliveryDateTo: new Date(item.deliveryDateTo).getTime() })))
                .sort((a, b) => b.deliveryDateTo - a.deliveryDateTo)[0];
            setDeliveryPromise(latestDeliveryPromise);
        }
        catch (_b) { }
    });
    const fetchTotalPrice = (items) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (!items.length) {
            setTotalPrice(undefined);
            return;
        }
        try {
            const data = yield ProductsAPI_1.default.getTotalPriceProducts(items.map(item => ({
                colorId: item.colorId,
                productId: item.product.productId,
                skuId: Number(item.sku),
            })), servicesEndpoint, defaultRequestParams, mainWebshopId);
            setTotalPrice(data);
        }
        catch (_c) { }
    });
    (0, react_1.useEffect)(() => {
        if (isOpen && !shouldRender && !isPDP) {
            fetchProducts();
        }
    }, [isOpen]);
    (0, react_1.useEffect)(() => {
        if (Boolean(productIds === null || productIds === void 0 ? void 0 : productIds.length) && shouldRender && !isPDP) {
            setShouldRender(false);
            fetchProducts();
        }
    }, [productIds]);
    if (!shouldRender) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(MiniPDPMulti_1.MiniPDPMulti, { isOpen: isOpen, popoverTitle: popoverTitle, title: title, onClose: _onClose, products: isPDP ? productsInfo : products, extensiveCarouselImages: extensiveCarouselImages, wishlistProducts: wishlistProducts, onWishListToggle: onWishListToggle, onAddToBasket: onAddToBasket, fetchDeliveryPromise: fetchDeliveryPromise, fetchTotalPrice: fetchTotalPrice, deliveryPromise: deliveryPromise, totalPrice: totalPrice, cdnSettings: cdnSettings, isPDP: isPDP }));
};
exports.default = MiniPDPMultiContainer;
