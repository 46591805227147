"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const CompareActions_creators_1 = require("../../redux/compare/actions/CompareActions.creators");
const ProductConstants_1 = require("../../constants/ProductConstants");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CompareSelectors_1 = require("../../redux/compare/selectors/CompareSelectors");
const common_components_1 = require("@as-react/common-components");
const ProductCompareSelectorContainer = ({ product: { brand, seoUrl, colorId, productId, productCode, title, image, colorDescription, sizes, gender, buyingGroup, productQualifier, brandValue, }, isCompareProduct, selectedSku, compareItems, updateCompareList, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { rootWithLanguageSuffix, root } = (0, common_components_1.useAemConfig)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const selectedSize = sizes === null || sizes === void 0 ? void 0 : sizes.find((size) => size.sku === selectedSku);
    const compareProduct = {
        id: productId,
        color: colorId,
        url: `${rootWithLanguageSuffix}/${ProductConstants_1.PRODUCT_URL_PREFIX}${seoUrl.indexOf('/') === 0 ? '' : '/'}${seoUrl}.html?colour${colorId}`,
        title,
        brand,
        image,
        productCode,
        selected: false,
        colorDescription,
        size: (selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.originalSize) !== '.' && (selectedSize === null || selectedSize === void 0 ? void 0 : selectedSize.originalSize),
        collapsedComparePopup: true,
        gender,
        buyingGroup,
        productQualifier,
        brandValue,
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.CheckboxWithLabel, { id: `product-compare-${compareProduct.productCode}-${compareProduct.color}`, name: `product-compare-${compareProduct.productCode}-${compareProduct.color}`, value: `product-compare-${compareProduct.productCode}-${compareProduct.color}`, checked: isCompareProduct, onChange: () => updateCompareList(compareProduct), size: cotopaxi_1.LabelSize.MINOR, align: cotopaxi_1.CheckboxWithLabelVerticallyAlign.CENTER, labelChildren: isMobile && isCompareProduct && (compareItems === null || compareItems === void 0 ? void 0 : compareItems.length) > 1 ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { text: t('productCompare.pdp.checkbox'), variant: cotopaxi_1.ButtonVariant.OUTLINED_ALT, size: cotopaxi_1.ButtonSize.XSMALL, shape: cotopaxi_1.ButtonShape.PILL, iconRight: {
                name: cotopaxi_1.IconName.CHEVRON_RIGHT,
                size: cotopaxi_1.IconSize.MEDIUM,
            }, href: `${root}products/product-compare.html` })) : (t('product:productCompare.pdp.checkbox')) }));
};
const makeMapStateToProps = () => {
    const getCompareProductInformation = (0, ProductSelector_1.makeGetProductCompareInformation)();
    const getIsCompareProduct = (0, ProductSelector_1.makeGetIsCompareProduct)();
    const getSelectedSku = (0, ProductSelector_1.makeGetSelectedSku)();
    const getCompareItems = (0, CompareSelectors_1.makeGetCompareItems)();
    const mapStateToProps = (state) => ({
        product: getCompareProductInformation(state),
        isCompareProduct: getIsCompareProduct(state),
        selectedSku: getSelectedSku(state),
        compareItems: getCompareItems(state),
    });
    return mapStateToProps;
};
const mapDispatchToProps = {
    updateCompareList: CompareActions_creators_1.default.createUpdateOnProductDetailPageAction,
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(ProductCompareSelectorContainer);
