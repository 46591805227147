"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WishlistEmptyPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const WishlistEmptyPage = ({ loggedIn, handleLoginClick }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const aemConfiguration = (0, common_components_1.useAemConfig)();
    const handleGoShopping = (e) => {
        e.preventDefault();
        window.location.href = `${aemConfiguration.rootWithLanguageSuffix}.html`;
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 3, paddingBottom: 7 } }, tablet: { spacing: { paddingTop: 8, paddingBottom: 16 } }, desktop: { spacing: { paddingTop: 12, paddingBottom: 12 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { desktop: { span: 6, push: 3 }, tablet: { span: 10, push: 1 }, mobile: { span: 10, push: 1 }, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.LARGE, align: cotopaxi_1.TextAlign.CENTER, children: [t('wishlist.empty.list.message'), " ", (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.HEART_EMPTY, size: cotopaxi_1.IconSize.MINOR, inline: true })] }), !loggedIn && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 3 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.LARGE, align: cotopaxi_1.TextAlign.CENTER, children: [t('wishlist.empty.list.not.loggedIn.user.prefix'), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, onClick: handleLoginClick, text: t('wishlist.empty.list.not.loggedIn.user.link') }), t('wishlist.empty.list.not.loggedIn.user.suffix')] }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 2 } }, desktop: { spacing: { paddingTop: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.NONE, align: cotopaxi_1.GroupAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: handleGoShopping, text: t('wishlist.empty.list.button') }) }) })] }) }) }));
};
exports.WishlistEmptyPage = WishlistEmptyPage;
