"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryAddressCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const DeliveryAddressCard = ({ pickUpPoint, address, emphasizedLocationName, title, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const getAddressByIdCountry = () => {
        switch (address.idCountry) {
            case common_types_1.CountryId.GB:
            case common_types_1.CountryId.GG:
            case common_types_1.CountryId.JE:
                return ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [address.address, address.addressLine2 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), address.addressLine2] })), address.addressLine3 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), address.addressLine3] })), address.addressLine4 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), address.addressLine4] })), (0, jsx_runtime_1.jsx)("br", {}), address.city, address.county && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), address.county] })), (0, jsx_runtime_1.jsx)("br", {}), address.postCode] }));
            case common_types_1.CountryId.US:
            case common_types_1.CountryId.AU:
            case common_types_1.CountryId.NZ:
                return ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [address.address, address.addressLine2 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), address.addressLine2] })), (0, jsx_runtime_1.jsx)("br", {}), address.city, address.stateIso && address.idCountry === common_types_1.CountryId.US && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), address.state] })), (0, jsx_runtime_1.jsx)("br", {}), address.postCode] }));
            case common_types_1.CountryId.NL:
            case common_types_1.CountryId.BE:
            case common_types_1.CountryId.LU:
            case common_types_1.CountryId.DE:
            case common_types_1.CountryId.FR:
                return ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [address.address, address.houseNumber && (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [" ", address.houseNumber] }), address.houseNumberAddition && address.idCountry !== common_types_1.CountryId.DE && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [' ', t('buy.payment.address.houseNumberAddition'), " ", address.houseNumberAddition] })), (0, jsx_runtime_1.jsx)("br", {}), address.postCode, " ", address.city] }));
            default:
                return ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [address.address, " ", address.addressLine2, (0, jsx_runtime_1.jsx)("br", {}), address.postCode, " ", address.city] }));
        }
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [title && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { as: "span", strong: true, children: title }), (0, jsx_runtime_1.jsx)("br", {})] })), !pickUpPoint && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [address.firstName, " ", address.middleName ? `${address.middleName} ` : '', address.lastName, address.companyName && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), address.companyName] }))] })), address.organisationName && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!pickUpPoint && (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { as: "span", strong: true, children: address.organisationName })] })), address.locationName && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { as: "span", strong: emphasizedLocationName, children: address.locationName }), ' ', address.pickupPointNumber, " ", address.customerAccountId] })), address.pickupPointName && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), address.pickupPointName] }))] }), address.idCountry && getAddressByIdCountry()] }));
};
exports.DeliveryAddressCard = DeliveryAddressCard;
