"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeBasketsSaga = exports.mergeBaskets = void 0;
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const common_types_1 = require("@as-react/common-types");
function* mergeBaskets(action) {
    try {
        const { defaultRequestParams, servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
        if (action.payload.type === common_types_1.BasketMergeTypes.PERSONAL_SHOPPING_ADVICE) {
            yield (0, effects_1.call)(BasketAPI_1.default.personalShoppingAdviceMerge, action.payload.basketToken, servicesEndpoint, defaultRequestParams);
        }
        else {
            yield (0, effects_1.call)(BasketAPI_1.default.merge, action.payload.basketToken, servicesEndpoint, defaultRequestParams);
        }
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createGetAction());
    }
    catch (_a) { }
}
exports.mergeBaskets = mergeBaskets;
function* mergeBasketsSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.BasketActionTypes.MERGE, mergeBaskets);
}
exports.mergeBasketsSaga = mergeBasketsSaga;
