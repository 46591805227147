"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Login = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const FormValidationUtil_1 = require("../../../util/FormValidationUtil");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const LoginUtil_1 = require("../../../util/LoginUtil");
const common_utils_1 = require("@as-react/common-utils");
const LoginForm_1 = require("../../login-form/LoginForm");
const Login = ({ login, facebook, registrationLink, socialLogin, onAuthResetForm, onEmailChange, cookieConsentSettingAvailable, }) => {
    const { socialEnabledList } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.checkout,
        i18nConstants_1.I18N_NAMESPACES.general,
        i18nConstants_1.I18N_NAMESPACES.navigation,
    ]);
    const [shouldRenderSocial, setShouldRenderSocial] = (0, react_1.useState)(cookieConsentSettingAvailable);
    (0, react_1.useEffect)(() => {
        setShouldRenderSocial(cookieConsentSettingAvailable || common_utils_1.CookieUtil.isCookieLevelAcceptAll());
        onAuthResetForm();
    }, []);
    const handleValidation = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = t(`${i18nConstants_1.I18N_NAMESPACES.account}:delivery.required`);
        }
        else if (!FormValidationUtil_1.default.isValidEmailPattern(values.email)) {
            errors.email = t(`${i18nConstants_1.I18N_NAMESPACES.general}:email.error.invalid`);
        }
        if (!values.password) {
            errors.password = t(`${i18nConstants_1.I18N_NAMESPACES.account}:delivery.required`);
        }
        return errors;
    };
    const onErrorHandler = (errors) => {
        ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.querySelector(`[for="${errors[0]}"]`), true);
    };
    const handleSocialLoginFacebook = () => {
        socialLogin(common_types_1.AemConfigurationThirdpartySocialLoginProvider.FACEBOOK);
    };
    return ((0, jsx_runtime_1.jsxs)("div", { "data-analytics-bannerid": "header-account-login", children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, gutterBottom: true, children: (0, jsx_runtime_1.jsx)(LoginForm_1.LoginForm, { onSubmit: login.onSubmit, action: login.action, forgotPasswordClicked: login.forgotPasswordClicked, errorFeedback: (0, LoginUtil_1.getLoginError)(login.errorFeedback, t), validate: handleValidation, onEmailChange: onEmailChange, onSubmitError: onErrorHandler, initialEmail: login.initialEmail, loading: login.loading }) }) }), shouldRenderSocial && socialEnabledList.includes(common_types_1.AemConfigurationThirdpartySocialLoginProvider.FACEBOOK) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, gutterBottom: true, gutterTop: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, onClick: handleSocialLoginFacebook, variant: cotopaxi_1.ButtonVariant.FACEBOOK, state: facebook.loading ? cotopaxi_1.ButtonState.LOADING : undefined, text: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.social.media.login`, {
                            platform: 'Facebook',
                        }), iconLeft: { name: cotopaxi_1.IconName.FACEBOOK_BOX } }) }) })), registrationLink && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { gutter: false, gutterBottom: true, gutterTop: true, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, emphasized: true, children: [t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.navigation.registration.cta.text`), ' '] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: registrationLink, dataQA: "register_link", children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.navigation.registration.cta.link`) })] }) }))] }));
};
exports.Login = Login;
