"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollToFirstFormError = void 0;
const react_1 = require("react");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const scrollToFirstError = (err, path = '') => {
    const errorRecord = Object.values(err).find((element, index) => {
        if (element !== null && typeof element === 'object') {
            path = path ? `${path}.${Object.keys(err)[index]}` : path + Object.keys(err)[index];
            return true;
        }
        return false;
    });
    if (errorRecord === null || errorRecord === void 0 ? void 0 : errorRecord.ref) {
        const element = document.getElementById(path);
        ScrollUtil_1.ScrollUtil.scrollToElement(element, true, 'center');
    }
    else if (errorRecord) {
        scrollToFirstError(errorRecord, path);
    }
};
function useScrollToFirstFormError(formState) {
    (0, react_1.useEffect)(() => {
        if (Object.keys(formState.errors).length === 0) {
            return;
        }
        scrollToFirstError(formState.errors);
    }, [formState.errors]);
}
exports.useScrollToFirstFormError = useScrollToFirstFormError;
