"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupBancontact = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const PaymentGroupContent_1 = require("./PaymentGroupContent");
const common_utils_1 = require("@as-react/common-utils");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const AnalyticsActions_creators_1 = require("../../../../redux/analytics/actions/AnalyticsActions.creators");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const react_query_1 = require("@tanstack/react-query");
const EventUtil_1 = require("../../../../util/EventUtil");
const EventEnum_1 = require("../../../../enums/EventEnum");
const asNumeral_1 = require("../../../../vendors/asNumeral");
const QueryClientUtil_1 = require("../../../../util/QueryClientUtil");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const useSaferpay_1 = require("../../../../hooks/useSaferpay");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const BancontactForm_1 = require("./forms/BancontactForm");
const CheckoutPaymentContext_1 = require("../../../../context/CheckoutPaymentContext");
const logger_1 = require("@as-react/logger");
const ScrollUtil_1 = require("../../../../util/ScrollUtil");
const MAX_BASKET_TOTAL_FOR_APP = 500;
const BANCONTACT_PAYMENT_METHODS = ['bancontact'];
const ANALYTICS_PAYMENT_METHOD_QR = 'Bancontact QR Saferpay';
let timers = [];
const PaymentGroupBancontact = ({ disabled, selected, group }) => {
    const paymentGroupContentRef = (0, react_1.useRef)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const [isTimerRun, setIsTimerRun] = (0, react_1.useState)(false);
    const [hasAppOptions, setHasAppOptions] = (0, react_1.useState)(false);
    const [appOptionsState, setAppOptionsState] = (0, react_1.useState)(common_types_1.SaferpayFieldsBancontactAppOptionsState.LOADING);
    const [qrCodeImage, setQrCodeImage] = (0, react_1.useState)('');
    const [intentUrl, setIntentUrl] = (0, react_1.useState)('');
    const [isSubmitting, setIsSubmitting] = (0, react_1.useState)(false);
    const [showError, setShowError] = (0, react_1.useState)(false);
    const { defaultRequestParams, servicesEndpoint, currency } = (0, common_components_1.useAemConfig)();
    const { hasError: hasSaferpayError, validation } = (0, useSaferpay_1.useSaferpay)({
        paymentMethods: BANCONTACT_PAYMENT_METHODS,
        enabled: selected,
    });
    const [{ optionError }] = (0, CheckoutPaymentContext_1.usePaymentState)();
    const { priceTotalBasket } = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPricingState)());
    const optionSelectError = optionError[group.name];
    const dispatch = (0, react_redux_1.useDispatch)();
    const endTimers = () => {
        timers.forEach(timer => timer.end());
        timers = [];
    };
    const { getSaferpayAltStatus } = (0, common_queries_1.checkoutQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { postSaferpayAlt, postSaferpayToken } = (0, common_queries_1.checkoutMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutate: mutatePostSaferpayAlt } = (0, react_query_1.useMutation)(postSaferpayAlt);
    const { mutate: mutatePostSaferpayToken } = (0, react_query_1.useMutation)(postSaferpayToken);
    const startAppPaymentTimer = (expiration) => {
        const expirationDate = new Date(expiration).getTime();
        const paymentTimer = new common_utils_1.Poller(() => {
            const currentTime = Date.now();
            if (currentTime > expirationDate) {
                return true;
            }
            return false;
        }, GeneralConstants_1.DELAY.DELAY_1000);
        paymentTimer.run().then(() => {
            if (paymentTimer && paymentTimer.active) {
                setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.DISABLED);
            }
        });
        timers.push(paymentTimer);
        setIsTimerRun(true);
    };
    const startPollingForAppPayment = (requestId) => {
        const paymentPoller = new common_utils_1.Poller(() => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            const result = yield QueryClientUtil_1.default.fetchQuery(getSaferpayAltStatus(requestId));
            if ((result === null || result === void 0 ? void 0 : result.status) &&
                (result.status === common_types_1.SaferpayAltStatusResult.CAPTURED || result.status === common_types_1.SaferpayAltStatusResult.FAILURE)) {
                dispatch(AnalyticsActions_creators_1.analyticsActions.paymentRequest({
                    paymentGroup: common_types_1.PaymentGroupName.BANCONTACT_SAFERPAY,
                    paymentMethod: ANALYTICS_PAYMENT_METHOD_QR,
                }));
                return result.redirectUrl;
            }
            return;
        }), GeneralConstants_1.DELAY.DELAY_3000);
        paymentPoller
            .run()
            .then((redirectUrl) => {
            if (redirectUrl) {
                location.href = redirectUrl;
            }
        })
            .catch(({ message }) => {
            logger_1.Logger.logError(`Saferpay-fields polling error ${message}`);
        });
        timers.push(paymentPoller);
        setIsTimerRun(true);
    };
    const initBancontactPayment = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        endTimers();
        setQrCodeImage('');
        setIntentUrl('');
        setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.LOADING);
        mutatePostSaferpayAlt(undefined, {
            onSuccess: result => {
                endTimers();
                startAppPaymentTimer(result.expiration);
                startPollingForAppPayment(result.requestId);
                setQrCodeImage(result.qrCodeImage);
                setIntentUrl(result.intentUrl);
                setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.ENABLED);
            },
            onError: () => {
                setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.DISABLED);
            },
        });
    });
    const handleSubmit = () => {
        setIsSubmitting(true);
        dispatch(AnalyticsActions_creators_1.analyticsActions.paymentRequest({
            paymentGroup: common_types_1.PaymentGroupName.BANCONTACT_SAFERPAY,
        }));
        window.SaferpayFields.submit({
            onSuccess: (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ token }) {
                mutatePostSaferpayToken({ token }, {
                    onSuccess: ({ redirectUrl }) => {
                        if (redirectUrl) {
                            location.href = redirectUrl;
                        }
                    },
                    onError: () => {
                        setIsSubmitting(false);
                        setShowError(true);
                    },
                });
            }),
            onError: () => {
                ScrollUtil_1.ScrollUtil.scrollToElement(paymentGroupContentRef.current, true);
                setIsSubmitting(false);
            },
        });
    };
    const handleVoucherChange = () => {
        selected && hasAppOptions && initBancontactPayment();
    };
    (0, react_1.useEffect)(() => {
        if (selected && hasAppOptions) {
            initBancontactPayment();
        }
        else {
            endTimers();
        }
        EventUtil_1.default.listen(EventEnum_1.EventEnum.VOUCHER_CHANGED, handleVoucherChange);
        return () => {
            EventUtil_1.default.remove(EventEnum_1.EventEnum.VOUCHER_CHANGED, handleVoucherChange);
        };
    }, [selected, hasAppOptions]);
    (0, react_1.useEffect)(() => {
        return () => {
            isTimerRun && endTimers();
        };
    }, [isTimerRun]);
    (0, react_1.useEffect)(() => {
        setHasAppOptions(priceTotalBasket <= MAX_BASKET_TOTAL_FOR_APP);
    }, [priceTotalBasket]);
    return ((0, jsx_runtime_1.jsx)(PaymentGroupContent_1.PaymentGroupContent, { group: group, selected: selected, onSubmit: handleSubmit, error: (optionSelectError !== null && optionSelectError !== void 0 ? optionSelectError : (!!hasSaferpayError || showError))
            ? t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.form.error`)
            : undefined, disabled: disabled || !!hasSaferpayError || showError, isSubmitting: isSubmitting, innerRef: paymentGroupContentRef, children: selected && ((0, jsx_runtime_1.jsx)(BancontactForm_1.BancontactForm, { appOptionsState: appOptionsState, currency: currency, hasAppOptions: hasAppOptions, intentUrl: intentUrl, paymentMethods: BANCONTACT_PAYMENT_METHODS, priceTotalBasket: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(priceTotalBasket), qrCodeImage: qrCodeImage, validation: validation })) }));
};
exports.PaymentGroupBancontact = PaymentGroupBancontact;
