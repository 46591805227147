"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTopFiveTile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const ImageUtil_1 = require("../../util/ImageUtil");
const i18nConstants_1 = require("../../constants/i18nConstants");
const asNumeral_1 = require("../../vendors/asNumeral");
const ProductUtil_1 = require("../../util/ProductUtil");
const IMAGE_WIDTH = 72;
const ProductTopFiveTile = ({ product, ranking }) => {
    var _a, _b;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { currency, productImageConfig, root } = (0, common_components_1.useAemConfig)();
    const activeColour = product.colours.find(colour => colour.colourId === product.colourId);
    const productUrl = (0, ProductUtil_1.getProductDetailUrl)(product, root);
    const cotopaxiPrice = (0, common_utils_1.generateCotopaxiPriceViewModel)({ prices: activeColour.prices, hasDiscount: activeColour.hasDiscount }, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency);
    const productContext = Object.assign(Object.assign({ productCode: product.productCode, productId: product.productId, activeColorId: product.colourId, brand: product.brand, title: product.title, image: (0, ImageUtil_1.getImageSourceSetByProductCode)(product.productCode, activeColour.image, productImageConfig)[common_types_1.ProductImageSizesPortraitEnum.bundles], currency }, cotopaxiPrice), { review: { score: (_a = product.productRating) !== null && _a !== void 0 ? _a : 0, amount: (_b = product.productReviews) !== null && _b !== void 0 ? _b : 0 } });
    const [width, height] = common_types_1.ProductImageSizesPortraitEnum.bundles.split('x').map(Number);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.ProductTile, { variant: "horizontal", product: productContext, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Area, { area: "primary", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: productUrl, variant: cotopaxi_1.LinkVariant.CONTAINER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Image, { width: width, height: height, containerOptions: {
                            mobile: {
                                spacing: { marginRight: 1.5 },
                                width: IMAGE_WIDTH,
                            },
                        } }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Area, { area: "secondary", children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { fullHeight: true, gutter: cotopaxi_1.GroupGutter.NONE, inline: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { grow: 1 }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, fullHeight: true, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: { mobile: 1 }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: productUrl, variant: cotopaxi_1.LinkVariant.SUBTLE, dataQA: "product_title", children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Title, { size: cotopaxi_1.TextSize.SMALL }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Rating, { amountSize: cotopaxi_1.TextSize.XSMALL, amountColor: cotopaxi_1.TextColor.SUBTLE })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductTile.Price, { size: cotopaxi_1.PriceSize.SMALL })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { shrink: 0 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { fullHeight: true, valign: cotopaxi_1.GroupVerticalAlign.BOTTOM, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, color: cotopaxi_1.TextColor.SUBTLE, size: cotopaxi_1.TextSize.XLARGE, children: ranking }) }) })] }) })] }));
};
exports.ProductTopFiveTile = ProductTopFiveTile;
