"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotifyMeForm = exports.NOTIFY_ME_FORM_NAME = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const EmailValidationWrapper_1 = require("../../components/email/EmailValidationWrapper");
const i18nConstants_1 = require("../../constants/i18nConstants");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductActions_1 = require("../../redux/product/actions/ProductActions");
const ProductsAPI_1 = require("../../api/ProductsAPI");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const emailFieldName = 'notifyMeEmail';
exports.NOTIFY_ME_FORM_NAME = 'notify-me';
const NotifyMeForm = ({ withNewDesign }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.product]);
    const { defaultRequestParams, servicesEndpoint, privacyPolicyLink } = (0, common_components_1.useAemConfig)();
    const formikRef = (0, react_1.useRef)(null);
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [submittedEmail, setSubmittedEmail] = (0, react_1.useState)();
    const [emailFieldFocused, setEmailFieldFocused] = (0, react_1.useState)(false);
    const [isSubmitError, setIsSubmitError] = (0, react_1.useState)(false);
    const [shouldNotResetStateOnSizeChange, setShouldNotResetStateOnSizeChange] = (0, react_1.useState)(false);
    const dispatch = (0, react_redux_1.useDispatch)();
    const initialEmail = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetUserEmail)()) || '';
    const selectedSku = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedSku)());
    const { productId } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductInfo)());
    (0, react_1.useEffect)(() => {
        if (shouldNotResetStateOnSizeChange) {
            setShouldNotResetStateOnSizeChange(false);
        }
        else {
            setLoading(false);
            setSubmittedEmail(undefined);
            setEmailFieldFocused(false);
            formikRef.current.resetForm();
        }
    }, [selectedSku]);
    const onSubmit = (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ notifyMeEmail }) {
        if (submittedEmail === notifyMeEmail || loading) {
            return;
        }
        if (!selectedSku) {
            setShouldNotResetStateOnSizeChange(true);
            dispatch(ProductActions_1.productActions.setNotifyMeError(common_types_1.NotifyMeError.SELECT_SIZE));
            return;
        }
        setLoading(true);
        try {
            yield ProductsAPI_1.default.notifyMeSubscribe(servicesEndpoint, defaultRequestParams, Number(productId), Number(selectedSku), notifyMeEmail);
            dispatch(AnalyticsActions_creators_1.analyticsActions.notifyMeRequest());
            setLoading(false);
            setEmailFieldFocused(false);
            setIsSubmitError(false);
            setSubmittedEmail(notifyMeEmail);
        }
        catch (_b) {
            setLoading(false);
            setEmailFieldFocused(false);
            setIsSubmitError(true);
        }
    });
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { enableReinitialize: true, name: exports.NOTIFY_ME_FORM_NAME, innerRef: formikRef, initialValues: { notifyMeEmail: initialEmail }, validationSchema: undefined, onSubmit: onSubmit, validateOnBlur: false, children: ({ errors, touched, values, setFieldValue }) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 }, bleed: { marginInline: withNewDesign ? 2 : 0 } }, children: isSubmitError ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, text: t(common_types_1.ErrorMessage.API_ERROR) })) : submittedEmail ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { iconName: cotopaxi_1.IconName.INFORMATION_OUTLINE, variant: cotopaxi_1.FeedbackMessageVariant.POSITIVE, text: t('product:notifyMe.success.message', { email: submittedEmail }) })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.INFO, text: t('product:notifyMe.instruction') })) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { emphasized: true, htmlFor: emailFieldName, size: cotopaxi_1.LabelSize.MINOR, children: t('product:notifyMe.email.label') }) }), (0, jsx_runtime_1.jsx)(EmailValidationWrapper_1.EmailValidationWrapper, { skipValidationFor: initialEmail, emailFieldKey: emailFieldName, errorMessages: {
                        invalidEmail: t('account.subscribe.invalid'),
                        required: t('account.subscribe.invalid'),
                    }, children: (_a) => {
                        var { renderError } = _a, formFieldProps = tslib_1.__rest(_a, ["renderError"]);
                        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormField, Object.assign({ required: true, name: emailFieldName, type: cotopaxi_1.InputType.EMAIL, placeholder: t('product:notifyMe.email.placeholder'), Component: cotopaxi_1.Input, maxLength: 100, dataQA: "notify_me_email_address", state: (errors.notifyMeEmail && touched.notifyMeEmail && cotopaxi_1.InputState.ERROR) || undefined, onFocus: () => setEmailFieldFocused(true), adornmentEnd: !emailFieldFocused && values.notifyMeEmail === submittedEmail ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.CHECK })) : (values.notifyMeEmail && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT_SUBTLE, icon: { name: cotopaxi_1.IconName.CLOSE, size: cotopaxi_1.IconSize.MAJOR }, onClick: () => setFieldValue(emailFieldName, '', true) }))), renderError: (msg, textProps) => renderError ? renderError(msg, textProps) : (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, Object.assign({}, textProps, { children: msg })) }, formFieldProps)));
                    } }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, type: cotopaxi_1.ButtonType.SUBMIT, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, size: cotopaxi_1.ButtonSize.LARGE, state: loading ? cotopaxi_1.ButtonState.LOADING : undefined, text: t('product:notifyMe.submit.button'), iconLeft: {
                            name: cotopaxi_1.IconName.EMAIL,
                            size: cotopaxi_1.IconSize.MAJOR,
                        } }) }), Boolean(privacyPolicyLink) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.SUBTLE, children: [t('product:notifyMe.privacy.policy.part1'), (0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: privacyPolicyLink, target: cotopaxi_1.LinkTarget.BLANK, children: t('product:notifyMe.privacy.policy.part2') }), t('product:notifyMe.privacy.policy.part3')] }) }))] })) }));
};
exports.NotifyMeForm = NotifyMeForm;
