"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewAdvantagesBlock = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const ComponentConfigContext_1 = require("../../../context/ComponentConfigContext");
const andes_icons_1 = require("@yonderland/andes-icons");
const BasketOverviewAdvantagesBlock = () => {
    const { advantages } = (0, ComponentConfigContext_1.useBasketOverviewConfig)();
    if (!advantages) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "3", children: advantages.map(advantage => ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { wrap: "nowrap", gap: "3", children: [(0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { color: "neutral", source: andes_icons_1.CheckIcon, size: "sm" }), (0, jsx_runtime_1.jsx)(andes_react_1.ConditionalWrapper, { condition: !!advantage.link, wrapper: children => ((0, jsx_runtime_1.jsx)(andes_react_1.Link, { target: advantage.linkOpensInNewTab ? '_blank' : '_self', href: advantage.link, rel: "noreferrer", children: children })), children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: advantage.text }) })] }, advantage.text.replace(/ /g, '-')))) }));
};
exports.BasketOverviewAdvantagesBlock = BasketOverviewAdvantagesBlock;
