"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcochequeAvailabilityMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const i18nConstants_1 = require("../../constants/i18nConstants");
const EcochequeAvailabilityMessage = ({ message, dataQA, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const { isShopLanding } = (0, common_components_1.useAemConfig)();
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { padding: 2 }, theme: cotopaxi_1.BoxTheme.PRIMARY }, dataAttributes: { 'data-qa': dataQA }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: "GS500", children: message }), !isShopLanding && ((0, jsx_runtime_1.jsx)(andes_react_1.Tooltip, { hasCloseBtn: false, text: t(`basket.feature.ecocheques_accepted.tooltip`), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { size: cotopaxi_1.IconSize.MEDIUM, color: "GS500", name: cotopaxi_1.IconName.INFORMATION_OUTLINE, inline: true }) }))] }) }));
};
exports.EcochequeAvailabilityMessage = EcochequeAvailabilityMessage;
