"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CheckoutActions_creators_1 = require("../../redux/checkout/actions/CheckoutActions.creators");
const CheckoutSelector_1 = require("../../redux/checkout/selectors/CheckoutSelector");
const KlarnaUtil_1 = require("../../util/KlarnaUtil");
const BuyForm_1 = require("../../components/buy/forms/BuyForm");
const common_components_1 = require("@as-react/common-components");
const SCRIPT_KEY = 'klarna-payments-sdk-js';
const PaymentGroupKlarnaContainer = ({ group }) => {
    const { klarnaConfig } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const dispatch = (0, react_redux_1.useDispatch)();
    const paymentOptionError = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetPaymentOptionError)());
    const isSetPaymentOptionSuccess = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetIsSetPaymentOptionSuccess)());
    const { klarnaToken } = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetKlarna)());
    const orderReference = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetPaymentOrderReference)());
    const [isKlarnaLoaded, setIsKlarnaLoaded] = (0, react_1.useState)(false);
    const [hasError, setHasError] = (0, react_1.useState)(false);
    const onSubmit = (values) => {
        dispatch(CheckoutActions_creators_1.default.createSavePaymentOptionAction({ values, group }));
    };
    const initKlarna = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield KlarnaUtil_1.default.init({
                token: klarnaToken,
                url: klarnaConfig.paymentsScriptUrl,
                key: SCRIPT_KEY,
            });
            KlarnaUtil_1.default.load('#klarna_container', [group.paymentOptions[0].paymentMethodCode], orderReference, () => {
                setIsKlarnaLoaded(true);
            });
        }
        catch (_a) {
            setIsKlarnaLoaded(false);
            setHasError(true);
        }
    });
    (0, react_1.useEffect)(() => {
        if (klarnaToken && orderReference) {
            initKlarna();
        }
    }, [klarnaToken, orderReference]);
    return ((0, jsx_runtime_1.jsxs)(BuyForm_1.BuyForm, { onSubmit: onSubmit, buttonState: isSetPaymentOptionSuccess && isKlarnaLoaded ? undefined : cotopaxi_1.ButtonState.DISABLED, buttonText: t('buy.payment.confirm'), checkoutStep: common_types_1.RoutePathname.PAYMENT, errorFeedback: paymentOptionError ? t(paymentOptionError) : hasError ? t('buy.payment.form.error') : null, children: [(0, jsx_runtime_1.jsx)("div", { id: "klarna_container", style: { display: isKlarnaLoaded ? 'block' : 'none' } }), !isKlarnaLoaded && !hasError && !paymentOptionError && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingTop: 4, paddingBottom: paymentOptionError || hasError ? 4 : 2 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.REDO_SOLID, color: cotopaxi_1.IconColor.ACTION, size: cotopaxi_1.IconSize.MEDIUM, animation: cotopaxi_1.IconAnimation.SPIN }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, type: cotopaxi_1.TextType.EXTRA_CONTENT, align: cotopaxi_1.TextAlign.CENTER, children: t('buy.payment.klarna.loading.message') })] }) }))] }));
};
exports.default = PaymentGroupKlarnaContainer;
