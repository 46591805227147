"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchContent = exports.fetchProducts = exports.updateFiltersAndFetchProducts = exports.updateStoreIdAndFetchProducts = exports.updateSortAndFetchProducts = exports.updateSizeAndFetchProducts = exports.updatePageAndFetchData = exports.getActiveFilters = exports.getContentFiltersConfig = exports.getContentPath = exports.isContentOverviewPage = exports.isListerPage = exports.getFredhopperSegment = exports.getQueryInfo = exports.updateFiltersAndFetchProductsSaga = exports.updateStoreIdAndFetchProductsSaga = exports.updateSortAndFetchProductsSaga = exports.updateSizeAndFetchProductsSaga = exports.updatePageAndFetchDataSaga = void 0;
const effects_1 = require("redux-saga/effects");
const ProductsAPI_1 = require("../../../api/ProductsAPI");
const ContentAPI_1 = require("../../../api/content/ContentAPI");
const ListerActions_creators_1 = require("../../lister/actions/ListerActions.creators");
const ContentActions_creators_1 = require("../../content/actions/ContentActions.creators");
const common_types_1 = require("@as-react/common-types");
const RouterSagas_1 = require("../../router/sagas/RouterSagas");
const UrlActions_creators_1 = require("../actions/UrlActions.creators");
const AnalyticsActions_creators_1 = require("../../analytics/actions/AnalyticsActions.creators");
const CompareSagas_1 = require("../../compare/sagas/CompareSagas");
const WishListSaga_1 = require("../../wishList/sagas/WishListSaga");
const common_utils_1 = require("@as-react/common-utils");
const logger_1 = require("@as-react/logger");
const AnalyticsEnums_1 = require("../../../enums/AnalyticsEnums");
const MonetateUtil_1 = require("../../../util/MonetateUtil");
const ProductListerUtil_1 = require("../../../util/ProductListerUtil");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const axios_1 = require("axios");
const FilterUtil_1 = require("../../../util/FilterUtil");
const SearchUtil_1 = require("../../../util/SearchUtil");
function* updatePageAndFetchDataSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.UrlActionTypes.UPDATE_PAGE, updatePageAndFetchData);
}
exports.updatePageAndFetchDataSaga = updatePageAndFetchDataSaga;
function* updateSizeAndFetchProductsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.UrlActionTypes.UPDATE_SIZE, updateSizeAndFetchProducts);
}
exports.updateSizeAndFetchProductsSaga = updateSizeAndFetchProductsSaga;
function* updateSortAndFetchProductsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.UrlActionTypes.UPDATE_SORT, updateSortAndFetchProducts);
}
exports.updateSortAndFetchProductsSaga = updateSortAndFetchProductsSaga;
function* updateStoreIdAndFetchProductsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.UrlActionTypes.UPDATE_STORE_ID, updateStoreIdAndFetchProducts);
}
exports.updateStoreIdAndFetchProductsSaga = updateStoreIdAndFetchProductsSaga;
function* updateFiltersAndFetchProductsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.FilterActionTypes.SET_FILTERS, updateFiltersAndFetchProducts);
    yield (0, effects_1.takeLatest)(ActionEnums_1.FilterActionTypes.REMOVE_FILTER, updateFiltersAndFetchProducts);
    yield (0, effects_1.takeLatest)(ActionEnums_1.FilterActionTypes.REMOVE_ALL_FILTERS, updateFiltersAndFetchProducts);
}
exports.updateFiltersAndFetchProductsSaga = updateFiltersAndFetchProductsSaga;
const getQueryInfo = (state) => state.url;
exports.getQueryInfo = getQueryInfo;
const getFredhopperSegment = (state) => state.lister.segment;
exports.getFredhopperSegment = getFredhopperSegment;
const isListerPage = (state) => !!state.lister;
exports.isListerPage = isListerPage;
const isContentOverviewPage = (state) => !!state.content;
exports.isContentOverviewPage = isContentOverviewPage;
const getContentPath = (state) => state.content.path;
exports.getContentPath = getContentPath;
const getContentFiltersConfig = (state) => ({
    types: state.content.types,
    categories: state.content.categories,
    filtersPath: state.content.filtersPath,
    hideCategoryFilter: state.content.hideCategoryFilter,
    hideTypeFilter: state.content.hideTypeFilter,
    typeFilterOptions: state.content.typeFilterOptions,
    typeFilterSortingOptions: state.content.typeFilterSortingOptions,
    categoryFilterOptions: state.content.categoryFilterOptions,
    categoryFilterSortingOptions: state.content.categoryFilterSortingOptions,
    tags: state.content.tags,
});
exports.getContentFiltersConfig = getContentFiltersConfig;
const getActiveFilters = (state) => state.filters.activeFilters;
exports.getActiveFilters = getActiveFilters;
function* updatePageAndFetchData(action) {
    let queryInfo = yield (0, effects_1.select)(exports.getQueryInfo);
    const isLister = yield (0, effects_1.select)(exports.isListerPage);
    const isContentOverview = yield (0, effects_1.select)(exports.isContentOverviewPage);
    if (isLister) {
        queryInfo = Object.assign(Object.assign({}, queryInfo), { page: action.payload });
        yield (0, effects_1.put)(UrlActions_creators_1.urlActions.setUrlParams(queryInfo));
        yield (0, effects_1.fork)(fetchProducts, queryInfo, action);
    }
    else if (isContentOverview) {
        queryInfo = Object.assign(Object.assign({}, queryInfo), { page: action.payload, size: common_types_1.CONTENT_TILES_DEFAULT_COUNT });
        yield (0, effects_1.put)(UrlActions_creators_1.urlActions.setUrlParams(queryInfo));
        yield (0, effects_1.fork)(fetchContent, queryInfo);
    }
}
exports.updatePageAndFetchData = updatePageAndFetchData;
function* updateSizeAndFetchProducts(action) {
    let queryInfo = yield (0, effects_1.select)(exports.getQueryInfo);
    const isLister = yield (0, effects_1.select)(exports.isListerPage);
    const isContentOverview = yield (0, effects_1.select)(exports.isContentOverviewPage);
    queryInfo = Object.assign(Object.assign({}, queryInfo), { size: action.payload, page: 0 });
    yield (0, effects_1.put)(UrlActions_creators_1.urlActions.setUrlParams(queryInfo));
    if (isLister) {
        yield (0, effects_1.fork)(fetchProducts, queryInfo, action);
    }
    else if (isContentOverview) {
        yield (0, effects_1.fork)(fetchContent, queryInfo);
    }
}
exports.updateSizeAndFetchProducts = updateSizeAndFetchProducts;
function* updateSortAndFetchProducts(action) {
    let queryInfo = yield (0, effects_1.select)(exports.getQueryInfo);
    queryInfo = Object.assign(Object.assign({}, queryInfo), { sort: action.payload, page: 0 });
    yield (0, effects_1.put)(UrlActions_creators_1.urlActions.setUrlParams(queryInfo));
    yield (0, effects_1.put)(ListerActions_creators_1.listerActions.showPlaceholderTiles());
    yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.listerSortChange(action.payload));
    yield (0, effects_1.fork)(fetchProducts, queryInfo, action);
}
exports.updateSortAndFetchProducts = updateSortAndFetchProducts;
function* updateStoreIdAndFetchProducts(action) {
    let queryInfo = yield (0, effects_1.select)(exports.getQueryInfo);
    queryInfo = Object.assign(Object.assign({}, queryInfo), { storeId: queryInfo.storeId === action.payload ? undefined : action.payload, page: 0 });
    yield (0, effects_1.put)(UrlActions_creators_1.urlActions.setUrlParams(queryInfo));
    yield (0, effects_1.put)(ListerActions_creators_1.listerActions.showPlaceholderTiles());
    yield (0, effects_1.fork)(fetchProducts, queryInfo, action);
}
exports.updateStoreIdAndFetchProducts = updateStoreIdAndFetchProducts;
function* updateFiltersAndFetchProducts(action) {
    var _a;
    const queryInfo = yield (0, effects_1.select)(exports.getQueryInfo);
    const isLister = yield (0, effects_1.select)(exports.isListerPage);
    const isContentOverview = yield (0, effects_1.select)(exports.isContentOverviewPage);
    if (isLister) {
        yield (0, effects_1.put)(ListerActions_creators_1.listerActions.showPlaceholderTiles());
        yield (0, effects_1.fork)(fetchProducts, queryInfo, action);
    }
    else if (isContentOverview) {
        const shouldUpdateFilters = action.type === ActionEnums_1.FilterActionTypes.REMOVE_ALL_FILTERS ||
            ((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.queryParamKey) ===
                common_types_1.ContentFilterQueryParamKey.CATEGORY;
        yield (0, effects_1.fork)(fetchContent, queryInfo, shouldUpdateFilters);
    }
}
exports.updateFiltersAndFetchProducts = updateFiltersAndFetchProducts;
function* fetchProducts(queryInfo, action) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const { currency, pageInfo, authenticationConfig, fredHopperSearchUrl, isShopLanding } = aemConfigurationContext;
        const defaultListerSort = aemConfigurationContext.lister.sort.defaultValue;
        const pushingRouterStateTask = yield (0, effects_1.fork)(RouterSagas_1.pushRouterState, queryInfo);
        yield (0, effects_1.join)(pushingRouterStateTask);
        const fredhopperSegment = yield (0, effects_1.select)(exports.getFredhopperSegment);
        const activeFilters = yield (0, effects_1.select)(exports.getActiveFilters);
        const searchResult = yield (0, effects_1.call)(ProductsAPI_1.default.fetchProducts, queryInfo, fredHopperSearchUrl, defaultListerSort, authenticationConfig, fredhopperSegment, isShopLanding);
        switch (action.type) {
            case ActionEnums_1.UrlActionTypes.UPDATE_SIZE:
                yield (0, effects_1.put)(ListerActions_creators_1.listerActions.updateProductsAndTotalPages(searchResult.items, searchResult.totalPages));
                break;
            case ActionEnums_1.FilterActionTypes.SET_FILTERS:
            case ActionEnums_1.FilterActionTypes.REMOVE_FILTER:
            case ActionEnums_1.FilterActionTypes.REMOVE_ALL_FILTERS:
            case ActionEnums_1.UrlActionTypes.UPDATE_STORE_ID: {
                const mappedFilters = activeFilters
                    ? searchResult.filters.map(filter => (Object.assign(Object.assign({}, filter), { isActive: (0, FilterUtil_1.isActiveFilter)(filter, activeFilters) })))
                    : searchResult.filters;
                yield (0, effects_1.put)(ListerActions_creators_1.listerActions.updateExistingFilters(mappedFilters));
                yield (0, effects_1.put)(ListerActions_creators_1.listerActions.updateProductsAndTotalPagesAndItemCount(searchResult.items, searchResult.totalPages, searchResult.itemCount));
                break;
            }
            default:
                yield (0, effects_1.put)(ListerActions_creators_1.listerActions.updateProducts(searchResult.items));
                yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.listerViewProductList(searchResult.items, currency, pageInfo.isSearchPage ? AnalyticsEnums_1.Events.VIEW_SEARCH_RESULTS : AnalyticsEnums_1.Events.VIEW_PRODUCT_LIST, queryInfo.q));
        }
        const partialRootState = yield (0, effects_1.select)(RouterSagas_1.getStoreData);
        yield (0, effects_1.fork)(CompareSagas_1.updateCompareProducts);
        yield (0, effects_1.fork)(RouterSagas_1.replaceRouterState, partialRootState.url, partialRootState);
        yield (0, effects_1.fork)(WishListSaga_1.getWishListProducts);
        const listerItemsToShow = (0, ProductListerUtil_1.getListerItemsToShow)(searchResult.items, queryInfo.size, true);
        MonetateUtil_1.default.pushListerEvent(listerItemsToShow.filter(item => item.productId));
    }
    catch (error) {
        if (!(0, axios_1.isAxiosError)(error)) {
            logger_1.Logger.logError(error);
        }
    }
}
exports.fetchProducts = fetchProducts;
function* fetchContent(queryInfo, shouldUpdateFilters = false) {
    try {
        const aemConfigurationContext = yield (0, effects_1.getContext)('aemConfiguration');
        const { defaultRequestParams, servicesEndpoint, activeEnvironment, elasticsearchIndex } = aemConfigurationContext;
        const elasticSearchIndexWithEnv = (0, SearchUtil_1.getElasticSearchIndexWithEnv)(activeEnvironment, elasticsearchIndex);
        const contentFiltersConfig = yield (0, effects_1.select)(exports.getContentFiltersConfig);
        const contentPath = yield (0, effects_1.select)(exports.getContentPath);
        let tags = [];
        if (contentFiltersConfig.tags) {
            tags = tags.concat(contentFiltersConfig.tags);
        }
        if (queryInfo.category) {
            tags.push(queryInfo.category);
        }
        if (queryInfo.type) {
            tags = tags.concat(queryInfo.type);
        }
        const pushingRouterStateTask = yield (0, effects_1.fork)(RouterSagas_1.pushRouterState, queryInfo);
        yield (0, effects_1.join)(pushingRouterStateTask);
        const content = yield (0, effects_1.call)(ContentAPI_1.default.getContentFromElasticSearch, defaultRequestParams, servicesEndpoint, elasticSearchIndexWithEnv, queryInfo.page, queryInfo.size, contentPath, queryInfo.searchTerm, tags.length > 0 ? tags : undefined);
        if (shouldUpdateFilters) {
            const filtersData = yield (0, effects_1.call)(ContentAPI_1.default.getFilters, servicesEndpoint, defaultRequestParams.mainWebshop, contentFiltersConfig.filtersPath, queryInfo.category, queryInfo.type);
            const filters = [];
            if (!contentFiltersConfig.hideCategoryFilter && filtersData.categories && filtersData.categories.length > 0) {
                const categoriesFilterData = (0, common_utils_1.mergeContentFilterData)(filtersData.categories, contentFiltersConfig.categories);
                if (categoriesFilterData.length > 0) {
                    filters.push((0, common_utils_1.normalizeContentFilter)(categoriesFilterData, ActionEnums_1.FilterActionTypes.LINK_FILTER, common_types_1.ContentFilterQueryParamKey.CATEGORY, 'content.overview.tag.selection.title.department', contentFiltersConfig.categoryFilterSortingOptions, contentFiltersConfig.categoryFilterOptions, [queryInfo.category]));
                }
            }
            if (!contentFiltersConfig.hideTypeFilter && filtersData.types && filtersData.types.length > 0) {
                const typesFilterData = (0, common_utils_1.mergeContentFilterData)(filtersData.types, contentFiltersConfig.types);
                if (typesFilterData.length > 0) {
                    filters.push((0, common_utils_1.normalizeContentFilter)(typesFilterData, ActionEnums_1.FilterActionTypes.MULTISELECT_FILTER, common_types_1.ContentFilterQueryParamKey.TYPE, 'content.overview.tag.selection.title.type', contentFiltersConfig.typeFilterSortingOptions, contentFiltersConfig.typeFilterOptions, queryInfo.type));
                }
            }
            yield (0, effects_1.put)(ContentActions_creators_1.contentOverviewActions.updaterFiltersAction(filters));
        }
        yield (0, effects_1.put)(ContentActions_creators_1.contentOverviewActions.showPlaceholderTiles());
        yield (0, effects_1.put)(ContentActions_creators_1.contentOverviewActions.updaterContentAction(content));
        const partialRootState = yield (0, effects_1.select)(RouterSagas_1.getContentStoreData);
        yield (0, effects_1.fork)(RouterSagas_1.replaceRouterState, partialRootState.url, partialRootState);
    }
    catch (error) {
        if (!(0, axios_1.isAxiosError)(error)) {
            logger_1.Logger.logError(error);
        }
    }
}
exports.fetchContent = fetchContent;
function* urlSaga() {
    yield (0, effects_1.all)([
        updatePageAndFetchDataSaga(),
        updateSizeAndFetchProductsSaga(),
        updateSortAndFetchProductsSaga(),
        updateStoreIdAndFetchProductsSaga(),
        updateFiltersAndFetchProductsSaga(),
    ]);
}
exports.default = urlSaga;
