"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const ImageUtil_1 = require("../../util/ImageUtil");
const VideoPlayer = ({ poster, video }) => {
    const { isEditor, imageCdnPrefix } = (0, common_components_1.useAemConfig)();
    const config = {
        link: video.link,
        autoplay: video.autoplay && !isEditor,
        heightType: video.heightType,
        height: video.height,
        muted: video.muted,
        controls: !video.hideControls,
    };
    const overlay = (poster === null || poster === void 0 ? void 0 : poster.image)
        ? {
            src: poster.image.imagePath,
            srcset: (0, ImageUtil_1.getPictureSrcset)({
                imagePath: poster.image.imagePath,
                renditions: poster.image.renditions,
                lastModified: poster.image.lastModified,
            }, imageCdnPrefix),
            isPicture: true,
            lazy: true,
        }
        : undefined;
    return (0, jsx_runtime_1.jsx)(cotopaxi_1.VideoPlayer, { poster: overlay, config: config });
};
exports.default = VideoPlayer;
