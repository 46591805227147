"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentGroupTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const PaymentGroupUtil_1 = require("../../../util/PaymentGroupUtil");
const PaymentGroupTitle = ({ group, disabled }) => {
    const { t } = (0, react_i18next_1.useTranslation)(cotopaxi_1.I18N_NAMESPACES.buy);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: disabled ? 'neutral-disabled' : 'neutral', children: t(`buy.payment.group.${(0, PaymentGroupUtil_1.getGroupName)(group)}`) }));
};
exports.PaymentGroupTitle = PaymentGroupTitle;
