"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonalDetails = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const CheckoutContext_1 = require("../../../context/CheckoutContext");
const DateUtil_1 = require("../../../util/DateUtil");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const PersonalDetailsForm_1 = require("./PersonalDetailsForm");
const AddressUtil_1 = require("../../../util/AddressUtil");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const useCheckoutLogin_1 = require("../../../hooks/useCheckoutLogin");
const LoginUtil_1 = require("../../../util/LoginUtil");
const useCreateAccount_1 = require("../../../hooks/useCreateAccount");
const CustomerUtil_1 = require("../../../util/CustomerUtil");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const PersonalDetails = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const { lang, mainWebShop } = (0, common_components_1.useAemConfig)();
    const [state, dispatch] = (0, CheckoutContext_1.useCheckoutState)();
    const [isSubmitting, setIsSubmitting] = (0, react_1.useState)(false);
    const { checkoutLogin, errorMessage: loginErrorMessage } = (0, useCheckoutLogin_1.useCheckoutLogin)();
    const { createAccount, errorMessage: createAccountErrorMessage } = (0, useCreateAccount_1.useCreateAccount)();
    const reduxDispatch = (0, react_redux_1.useDispatch)();
    const loginError = (0, LoginUtil_1.getLoginError)(loginErrorMessage || '', t, mainWebShop);
    const createError = createAccountErrorMessage ? t(createAccountErrorMessage) : undefined;
    const handleSubmit = (values) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsSubmitting(true);
        const customerData = Object.assign({ email: state.login.email, password: state.login.password, idTitle: Number(values.title), firstName: values.firstName || '', lastName: values.lastName || '', middleName: values.middleName, gender: values.gender || '', dateOfBirth: values.dateOfBirth
                ? (0, DateUtil_1.formatDate)(values.dateOfBirth, GeneralConstants_1.DateFormat.DD_MM_YYYY.value)
                : values.dateOfBirth, mobilePhoneNumber: (_a = CustomerUtil_1.default.convertPhoneNumberPrefix(values.mobilePhoneNumber, '+', '00')) !== null && _a !== void 0 ? _a : '', language: lang, preference: {
                preference: false,
                exploreMoreNewsletter: Boolean(values.newsletter),
            }, address: (0, AddressUtil_1.prepareAddressByCountry)(values.address) }, (values.invoice
            ? {
                finance: {
                    needInvoice: true,
                    vatNumber: values.finance.vatNumber,
                    companyName: values.finance.companyName,
                    department: values.finance.departmentName,
                    isVat: values.finance.isVat,
                    isViesVerified: values.finance.isViesVerified,
                },
            }
            : {}));
        const result = yield createAccount(customerData);
        if (result) {
            dispatch({ type: 'registration_passed' });
            checkoutLogin({
                username: state.login.email,
                password: state.login.password,
                keepSignedIn: false,
                location: common_types_1.AuthFormLocation.CHECKOUT_V2,
            });
        }
    });
    (0, react_1.useEffect)(() => {
        reduxDispatch(AnalyticsActions_creators_1.analyticsActions.checkoutPersonalDetails());
    }, []);
    (0, react_1.useEffect)(() => {
        if (loginError || createError) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.querySelector(`[name="${PersonalDetailsForm_1.PERSONAL_DETAILS_FORM_NAME}"]`));
            setIsSubmitting(false);
        }
    }, [loginError, createError]);
    return ((0, jsx_runtime_1.jsx)(PersonalDetailsForm_1.PersonalDetailsForm, { onSubmit: handleSubmit, isLoading: isSubmitting, errorFeedback: loginError || createError }));
};
exports.PersonalDetails = PersonalDetails;
