"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliverySubmitForm = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const react_router_1 = require("react-router");
const SessionStorageUtil_1 = require("../../../util/SessionStorageUtil");
const CheckoutDeliveryContext_1 = require("../../../context/CheckoutDeliveryContext");
const andes_react_1 = require("@yonderland/andes-react");
const BuyFormUtil_1 = require("../../../util/BuyFormUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const axios_1 = require("axios");
const BasketUtil_1 = require("../../../util/BasketUtil");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const Yup = require("yup");
const yup_1 = require("@hookform/resolvers/yup");
const addressFields_utils_1 = require("../../form/address-fields/addressFields.utils");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const useScrollToFirstFormError_1 = require("../../form/hooks/useScrollToFirstFormError");
const CustomerUtil_1 = require("../../../util/CustomerUtil");
const CustomerDetailsFields_utils_1 = require("../../form/registration-fields/CustomerDetailsFields.utils");
const CheckoutContext_1 = require("../../../context/CheckoutContext");
const invoiceFields_utils_1 = require("../../form/invoice-fields/invoiceFields.utils");
const common_utils_1 = require("@as-react/common-utils");
const DeliveryForm_utils_1 = require("./DeliveryForm.utils");
const DeliverySubmitForm = ({ children }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.general]);
    const { defaultRequestParams, servicesEndpoint, enableFullVatForm, isInAuthorMode, root } = (0, common_components_1.useAemConfig)();
    const history = (0, react_router_1.useHistory)();
    const [{ info, deliveryCountryId, activeDeliveryOption, homeDelivery, collectStore }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { deliveryForm: { hideInvoice, showMobilePhoneNumber: showPhoneNumberAEMCheckbox }, } = (0, CheckoutContext_1.useCheckoutConfig)();
    const shouldValidate = (0, react_1.useRef)(true);
    const isProviderPostNL = (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.provider) === common_types_1.Provider.POSTNL;
    const showMobilePhoneNumber = Boolean(showPhoneNumberAEMCheckbox && (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.showPhoneNumber));
    const [validationSchema, setValidationSchema] = (0, react_1.useState)((0, CustomerDetailsFields_utils_1.getCustomerDetailsValidationSchema)({ t }));
    const queryClient = (0, react_query_1.useQueryClient)();
    const { postDeliveryOption, postDelivery } = (0, common_queries_1.checkoutMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { getDeliveryInfo } = (0, common_queries_1.checkoutQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutateAsync: postDeliveryOptionMutation } = (0, react_query_1.useMutation)(postDeliveryOption);
    const { mutate: postDeliveryMutation, isPending, isError } = (0, react_query_1.useMutation)(postDelivery);
    const isInvoiceEnabled = CustomerUtil_1.default.isInvoiceEnabled(hideInvoice, info);
    (0, react_1.useEffect)(() => {
        if (!info || !activeDeliveryOption) {
            return;
        }
        let validationSchemaTmp = (0, CustomerDetailsFields_utils_1.getCustomerDetailsValidationSchema)({ t });
        if ((activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.name) === common_types_1.DeliveryOptionsEnum.PICKUP_POINT) {
            const tmpValidationSchema = isProviderPostNL
                ? validationSchemaTmp.concat((0, addressFields_utils_1.getAddressValidationSchema)({ t, country: info.country, prefix: 'address.' }))
                : validationSchemaTmp;
            validationSchemaTmp = isInvoiceEnabled
                ? tmpValidationSchema.concat((0, invoiceFields_utils_1.getInvoiceValidationSchema)({ t, country: info.country, fullVatFormEnabled: enableFullVatForm }))
                : tmpValidationSchema;
        }
        // Validation for Home Delivery
        if (activeDeliveryOption && CustomerUtil_1.default.isDeliveredToDeliveryAddress(activeDeliveryOption.name)) {
            const tmpValidationSchema = validationSchemaTmp
                .concat((0, invoiceFields_utils_1.getInvoiceValidationSchema)({ t, country: info.country, fullVatFormEnabled: enableFullVatForm }))
                .concat((0, addressFields_utils_1.getAddressValidationSchema)({ t, country: info.country, prefix: 'address.' }))
                .concat(Yup.object({ saveAddress: Yup.boolean() }));
            validationSchemaTmp = showMobilePhoneNumber
                ? tmpValidationSchema.concat(Yup.object({
                    mobilePhoneNumber: Yup.string().test('isPossiblePhoneNumber', t(common_types_1.ErrorMessage.INVALID_PHONE), val => !val || (0, andes_react_1.isPossiblePhoneNumber)(val)),
                }))
                : tmpValidationSchema;
        }
        setValidationSchema(validationSchemaTmp);
    }, [activeDeliveryOption, isInvoiceEnabled, info, isProviderPostNL, showMobilePhoneNumber]);
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const data = yield queryClient.fetchQuery(getDeliveryInfo(deliveryCountryId, { enabled: !deliveryCountryId }));
            const initialValues = (0, DeliveryForm_utils_1.getInitialValues)(data, (0, common_utils_1.mergeObjectDeep)(DeliveryForm_utils_1.initialValuesDeliveryForm, {
                address: { idCountry: data.idCountry },
                invoice: { address: { idCountry: data.idCountry } },
                idCountry: data.idCountry,
            }), data.idCountry === ((_a = homeDelivery.selectedAddress) === null || _a === void 0 ? void 0 : _a.idCountry) ? homeDelivery.selectedAddress : undefined, activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.name, data.idCountry);
            return initialValues;
        }),
        mode: 'onTouched',
        shouldFocusError: true,
        resolver: (0, react_1.useMemo)(() => validationSchema && (0, yup_1.yupResolver)(validationSchema), [validationSchema]),
    });
    (0, react_1.useEffect)(() => {
        const updateFormValues = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            const address = (0, DeliveryForm_utils_1.getPreselectedAddress)(Object.assign(Object.assign({}, info), { idCountry: deliveryCountryId }), homeDelivery.selectedAddress);
            const newValues = (0, DeliveryForm_utils_1.getInitialValues)(info, (0, common_utils_1.mergeObjectDeep)(DeliveryForm_utils_1.initialValuesDeliveryForm, {
                address: { idCountry: deliveryCountryId },
                invoice: { address: { idCountry: deliveryCountryId } },
                idCountry: deliveryCountryId,
            }), deliveryCountryId === (address === null || address === void 0 ? void 0 : address.idCountry) ? address : undefined, activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.name, deliveryCountryId);
            formProps.reset(newValues);
        });
        updateFormValues();
    }, [deliveryCountryId]);
    (0, useScrollToFirstFormError_1.useScrollToFirstFormError)(formProps.formState);
    (0, react_1.useEffect)(() => {
        const validateForm = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            if (!validationSchema) {
                return;
            }
            yield validationSchema.isValid(formProps.formState, { abortEarly: false });
            shouldValidate.current = false;
        });
        if (shouldValidate.current) {
            validateForm();
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (isError) {
            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.API_ERROR });
        }
    }, [isError]);
    if (!info) {
        return null;
    }
    const onSubmit = (values) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.GUEST_USER_CHECKOUT_DATA);
        info.chosenDeliveryOption = activeDeliveryOption ? activeDeliveryOption.name : info.chosenDeliveryOption;
        const deliveryForm = (0, DeliveryForm_utils_1.createDeliveryForm)(info, values);
        switch (info.chosenDeliveryOption) {
            case common_types_1.DeliveryOptionsEnum.HOME_DELIVERY:
            case common_types_1.DeliveryOptionsEnum.NAMED_DAY:
            case common_types_1.DeliveryOptionsEnum.NEXT_DAY:
                deliveryForm
                    .updateHomeDeliveryData(values, homeDelivery.selectedAddress)
                    .updatePhoneNumberData(values)
                    .updateInvoiceData(values);
                break;
            case common_types_1.DeliveryOptionsEnum.COLLECT_STORE:
                deliveryForm.updateCollectStoreData(values, collectStore.selectedStore);
                break;
            case common_types_1.DeliveryOptionsEnum.PICKUP_POINT:
                deliveryForm.updatePickupPointData(values, isProviderPostNL);
                break;
            default:
                deliveryForm.updateCustomerData(values);
                break;
        }
        if (info.chosenDeliveryOption) {
            try {
                yield postDeliveryOptionMutation(info.chosenDeliveryOption);
            }
            catch (error) {
                if ((0, axios_1.isAxiosError)(error) && BasketUtil_1.default.isBasketNotInitialized(error)) {
                    location.href = `${root}checkout/basket-overview.html`;
                }
            }
        }
        postDeliveryMutation(deliveryForm.data, {
            onSuccess: () => {
                queryClient.refetchQueries({
                    queryKey: common_queries_1.checkoutQueryKeys.getPaymentInfo(),
                    stale: false,
                });
                history.push((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.PAYMENT}`, isInAuthorMode));
            },
            onError: error => {
                var _a;
                if ((0, axios_1.isAxiosError)(error)) {
                    if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                        location.href = `${root}checkout/basket-overview.html`;
                        return;
                    }
                    if ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.find((item) => item.code === 'non_numeric_field')) {
                        dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.INVALID_POSTAL_CODE });
                    }
                    else {
                        if ((0, BuyFormUtil_1.isUkCountry)(deliveryForm.data.country)) {
                            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.GENERAL_ADDRESS_BUY });
                        }
                        else {
                            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.API_ERROR });
                        }
                    }
                }
            },
        });
    });
    const getButtonState = () => {
        if (isPending) {
            return 'loading';
        }
        if (!activeDeliveryOption && info.requiresDelivery) {
            return 'disabled';
        }
        return 'interactive';
    };
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsxs)("form", { noValidate: true, name: "checkout-delivery-details-form", style: { display: 'contents' }, children: [children, (0, jsx_runtime_1.jsx)(andes_react_1.CheckoutLayout.Sticky, { children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: 12, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, lg: 4 }, offset: { lg: 9 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, state: getButtonState(), text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.submit`), theme: "branded", type: "submit", onClick: formProps.handleSubmit(onSubmit), dataTestId: "delivery_submit" }) }) }) })] }) })));
};
exports.DeliverySubmitForm = DeliverySubmitForm;
