"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryPromiseContainerExtended = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const common_components_1 = require("@as-react/common-components");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const LocalStorageUtil_1 = require("../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../constants/StorageKeysConstants");
const NotifyMeForm_1 = require("../components/notify-me/NotifyMeForm");
const FreeDeliveryMessage_1 = require("../components/product-detail/free-delivery-message/FreeDeliveryMessage");
const ProductCTAContainerV2_1 = require("./product/ProductCTAContainerV2");
const ProductSpecialtyMessageContainer_1 = require("./product/ProductSpecialtyMessageContainer");
const ProductActions_1 = require("../redux/product/actions/ProductActions");
const DeliveryPromiseLoader_1 = require("../components/product-detail/delivery-promise-v2/delivery-promise-loader/DeliveryPromiseLoader");
const DeliveryPromiseV2_1 = require("../components/product-detail/delivery-promise-v2/DeliveryPromiseV2");
const DeliveryPromiseUtil_1 = require("../util/DeliveryPromiseUtil");
const i18nConstants_1 = require("../constants/i18nConstants");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const StoreAPI_1 = require("../api/store/StoreAPI");
const StoreUtil_1 = require("../util/StoreUtil");
const ProductDetailAPI_1 = require("../api/ProductDetailAPI");
const DeliveryPromiseContainerExtended = ({ deliveryPromiseExplanations, thresholdFreeDelivery, freeDeliveryMessage, productId, quantity, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfig)();
    const { defaultRequestParams, servicesEndpoint, businessUnitId, mainWebShop, isShopLanding, checkoutCc: clickAndCollectEnabled, feature_toggles: { delivery_promise_select_size_label }, } = aemConfiguration;
    const isSelectedSizeSoldOut = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetIsSelectedSizeSoldOut)());
    const selectedSku = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedSku)());
    const selectedStoreId = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketClickAndCollectStoreIdState)());
    const deliveryPromiseExtended = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDeliveryPromisesExtended)());
    const sizeCombinationNotExist = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSizeCombinationNotExist)());
    const fulfillment = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillment)());
    const fulfillmentBySize = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillmentBySize)());
    const selectedColorId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedColorId)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const [storeName, setStoreName] = (0, react_1.useState)();
    const [storeStockAvailable, setStoreStockAvailable] = (0, react_1.useState)(false);
    const openCC = () => {
        dispatch(BasketActions_creators_1.basketActions.createCCPopupOpenAction());
        dispatch(AnalyticsActions_creators_1.analyticsActions.showStoreStock());
    };
    const sizeForgotten = () => dispatch(BasketActions_creators_1.basketActions.createSizeForgottenShowAction());
    const showFreeDeliveryMessage = freeDeliveryMessage &&
        delivery_promise_select_size_label &&
        selectedSku &&
        !isSelectedSizeSoldOut &&
        !(fulfillment === null || fulfillment === void 0 ? void 0 : fulfillment.hdExcluded) &&
        !(fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded);
    const fetchProductAvailabilityForCurrentStore = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        const storeAvailabilityData = yield ProductDetailAPI_1.default.fetchProductAvailabilityForCurrentStore(servicesEndpoint, defaultRequestParams, productId, { storeId: selectedStoreId });
        const isStockAvailable = Boolean((_c = (_b = (_a = storeAvailabilityData === null || storeAvailabilityData === void 0 ? void 0 : storeAvailabilityData.colours) === null || _a === void 0 ? void 0 : _a.find(color => color.idColour === selectedColorId)) === null || _b === void 0 ? void 0 : _b.sizes) === null || _c === void 0 ? void 0 : _c.some(size => Number(size.availableStock) > 0));
        setStoreStockAvailable(isStockAvailable);
    });
    (0, react_1.useEffect)(() => {
        if (productId && !selectedSku && selectedStoreId) {
            fetchProductAvailabilityForCurrentStore();
        }
    }, [selectedSku, productId, selectedStoreId]);
    (0, react_1.useEffect)(() => {
        var _a;
        const selectedStoreIdFromLocalStorage = (_a = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY)) === null || _a === void 0 ? void 0 : _a.storeId;
        if (selectedStoreIdFromLocalStorage || selectedStoreId) {
            const getStoreInfo = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
                try {
                    const storeInformation = yield StoreAPI_1.default.getStoreInformation(servicesEndpoint, defaultRequestParams, businessUnitId, Number(selectedStoreIdFromLocalStorage) || Number(selectedStoreId));
                    setStoreName(StoreUtil_1.default.getStoreName(storeInformation, mainWebShop, t));
                }
                catch (_b) { }
            });
            getStoreInfo();
        }
        else {
            setStoreName(undefined);
        }
    }, [selectedStoreId, selectedSku]);
    (0, react_1.useEffect)(() => {
        var _a;
        const selectedStoreIdFromLocalStorage = Number((_a = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY)) === null || _a === void 0 ? void 0 : _a.storeId);
        dispatch(ProductActions_1.productActions.createGetDeliveryPromiseExtended({
            productId,
            sku: selectedSku,
            selectedStoreId: selectedStoreIdFromLocalStorage || Number(selectedStoreId),
        }));
    }, [selectedSku, productId, selectedStoreId, selectedColorId]);
    if ((deliveryPromiseExtended === null || deliveryPromiseExtended === void 0 ? void 0 : deliveryPromiseExtended.loading) || !deliveryPromiseExtended) {
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(DeliveryPromiseLoader_1.DeliveryPromiseLoader, {}) }));
    }
    if (sizeCombinationNotExist) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showFreeDeliveryMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1, marginBottom: 1.5 } }, tablet: { spacing: { marginTop: 1.5, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(FreeDeliveryMessage_1.FreeDeliveryMessage, { freeDeliveryMessage: freeDeliveryMessage, thresholdFreeDelivery: thresholdFreeDelivery, withGridWrapper: true }) })), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        paddingBottom: 2,
                        paddingLeft: 2,
                        paddingRight: 2,
                        paddingTop: isSelectedSizeSoldOut ? 0 : 2,
                        marginTop: 2,
                        marginBottom: 2,
                    },
                    theme: cotopaxi_1.BoxTheme.PRIMARY,
                }, children: [!isSelectedSizeSoldOut && deliveryPromiseExtended && ((0, jsx_runtime_1.jsx)(DeliveryPromiseV2_1.default, { deliveryPromiseExplanation: (0, DeliveryPromiseUtil_1.getDeliveryExplanation)(deliveryPromiseExtended === null || deliveryPromiseExtended === void 0 ? void 0 : deliveryPromiseExtended.hdDeliveryPromise, deliveryPromiseExplanations), deliveryPromiseExtended: deliveryPromiseExtended, selectedSku: selectedSku, storeName: storeName, openCC: openCC, sizeForgotten: sizeForgotten, isSelectedSizeSoldOut: isSelectedSizeSoldOut, fulfillment: fulfillment, storeSourceExcluded: fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded, clickAndCollectEnabled: clickAndCollectEnabled, storeStockAvailable: storeStockAvailable })), !isShopLanding && isSelectedSizeSoldOut && (0, jsx_runtime_1.jsx)(NotifyMeForm_1.NotifyMeForm, { withNewDesign: true }), !isSelectedSizeSoldOut && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ProductCTAContainerV2_1.default, { quantity: quantity }), (0, jsx_runtime_1.jsx)(ProductSpecialtyMessageContainer_1.default, {})] }))] })] }));
};
exports.DeliveryPromiseContainerExtended = DeliveryPromiseContainerExtended;
