"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logoutFromSessionSaga = exports.getSessionSaga = void 0;
const effects_1 = require("redux-saga/effects");
const common_types_1 = require("@as-react/common-types");
const AuthApi_1 = require("../../../api/auth/AuthApi");
const SessionAPI_1 = require("../../../api/SessionAPI");
const CustomerApi_1 = require("../../../api/customer/CustomerApi");
const AuthSocialLoginFacebookApi_1 = require("../../../api/auth/AuthSocialLoginFacebookApi");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const EventEnum_1 = require("../../../enums/EventEnum");
const EventUtil_1 = require("../../../util/EventUtil");
const WishListActions_creators_1 = require("../../wishList/actions/WishListActions.creators");
const SessionActions_creators_1 = require("../actions/SessionActions.creators");
const CookieConsentSelector_1 = require("../../cookieConsent/selectors/CookieConsentSelector");
const AnalyticsActions_creators_1 = require("../../analytics/actions/AnalyticsActions.creators");
const common_utils_1 = require("@as-react/common-utils");
function* customEventSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.GeneralActionTypes.CUSTOM_EVENT, handleCustomEvent);
}
function* handleCustomEvent(action) {
    switch (action.payload.eventType) {
        case EventEnum_1.EventEnum.USER_LOGGED_IN:
            yield (0, effects_1.spawn)(getSession);
            break;
        default:
            break;
    }
}
function* getSessionSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.SessionActionTypes.INIT, getSession);
}
exports.getSessionSaga = getSessionSaga;
function* logoutFromSessionSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.SessionActionTypes.LOGOUT, logoutFromSession);
}
exports.logoutFromSessionSaga = logoutFromSessionSaga;
function* getSession() {
    try {
        const { isShopLanding, shopNumber, servicesEndpoint, defaultRequestParams } = yield (0, effects_1.getContext)('aemConfiguration');
        let requestParams = Object.assign({}, defaultRequestParams);
        if (isShopLanding && shopNumber) {
            requestParams = Object.assign(Object.assign({}, defaultRequestParams), { shopNumber });
        }
        const sessionData = yield (0, effects_1.call)(SessionAPI_1.default.infoRequest, servicesEndpoint, requestParams);
        if (!!sessionData.error && sessionData.error === 'invalid_request') {
            yield (0, effects_1.call)(startAnonymousSession);
            const anonymousSessionData = yield (0, effects_1.call)(SessionAPI_1.default.infoRequest, servicesEndpoint, requestParams);
            yield (0, effects_1.put)(SessionActions_creators_1.default.createSetAction(anonymousSessionData));
        }
        else {
            yield (0, effects_1.put)(SessionActions_creators_1.default.createSetAction(sessionData));
        }
        yield (0, effects_1.fork)(analyticsCustomerIdentification);
    }
    catch (_a) { }
}
function* analyticsCustomerIdentification() {
    const consentMarketing = yield (0, effects_1.select)((0, CookieConsentSelector_1.makeGetHasConsentOnMarketingCookies)());
    const userID = common_utils_1.CookieUtil.get('_UUID') || common_utils_1.CookieUtil.get('customerID');
    if (consentMarketing && userID) {
        const { defaultRequestParams, servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
        try {
            const customerInfo = yield CustomerApi_1.default.getTealiumCustomerData(servicesEndpoint, defaultRequestParams.mainWebshop);
            yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.customerIdentification({
                identity: common_types_1.TealiumCustomerIdentity.IDENTIFIED,
                customerInfo,
            }));
            return;
        }
        catch (_a) { }
    }
    yield (0, effects_1.put)(AnalyticsActions_creators_1.analyticsActions.customerIdentification({ identity: common_types_1.TealiumCustomerIdentity.ANONYMOUS }));
}
function* logoutFromSession() {
    try {
        const { defaultRequestParams, servicesEndpoint, rootWithoutFinalSlash } = yield (0, effects_1.getContext)('aemConfiguration');
        yield (0, effects_1.call)(AuthApi_1.default.logout, servicesEndpoint, defaultRequestParams);
        if (AuthSocialLoginFacebookApi_1.default.isInitialized()) {
            const isAuthenticated = yield (0, effects_1.call)(AuthSocialLoginFacebookApi_1.default.isAuthenticated);
            if (isAuthenticated) {
                yield (0, effects_1.call)(AuthSocialLoginFacebookApi_1.default.logoutWithFacebook);
            }
        }
        EventUtil_1.default.dispatch(EventEnum_1.EventEnum.USER_LOGGED_OUT);
        yield (0, effects_1.fork)(startAnonymousSession);
        yield (0, effects_1.put)(WishListActions_creators_1.wishListActions.logout());
        yield (0, effects_1.take)(ActionEnums_1.WishListActionTypes.LOGGED_OUT);
        const url = rootWithoutFinalSlash;
        if (url.length > 0 && url.indexOf('.html') === -1) {
            // no page extention found.
            window.location.href = `${url}.html`;
        }
        else if (url === '') {
            window.location.href = '/';
        }
        else {
            window.location.href = url;
        }
    }
    catch (_a) { }
}
function* startAnonymousSession() {
    try {
        const { defaultRequestParams, servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
        yield (0, effects_1.call)(AuthApi_1.default.login, servicesEndpoint, defaultRequestParams, undefined, undefined, false);
    }
    catch (_a) { }
}
function* sessionSaga() {
    yield (0, effects_1.all)([customEventSaga(), getSessionSaga(), logoutFromSessionSaga()]);
}
exports.default = sessionSaga;
