"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ImageUtil_1 = require("../../util/ImageUtil");
const MiniPDPContainer_1 = require("../../containers/MiniPDPContainer");
const ProductsAPI_1 = require("../../api/ProductsAPI");
const ProductDetailAPI_1 = require("../../api/ProductDetailAPI");
const common_utils_1 = require("@as-react/common-utils");
const ProductInspiration = ({ title, subTitle, image, buttons, deliveryPromiseExplanations, cdnSettings, }) => {
    const { defaultRequestParams, servicesEndpoint, imageCdnPrefix } = (0, common_components_1.useAemConfig)();
    const [miniPDPProduct, setMiniPDPProduct] = (0, react_1.useState)(null);
    const [activeMarker, setActiveMarker] = (0, react_1.useState)(null);
    const srcset = (0, ImageUtil_1.getPictureSrcset)({ imagePath: image.imagePath, renditions: image.renditions, lastModified: image.lastModified }, imageCdnPrefix, cdnSettings === null || cdnSettings === void 0 ? void 0 : cdnSettings.imageQuality, cdnSettings === null || cdnSettings === void 0 ? void 0 : cdnSettings.requestPassThrough);
    const getRenditionIdFromClickMapKey = (clickMapKey) => clickMapKey.substring(clickMapKey.lastIndexOf('/') + 1);
    const onClickMarker = (marker) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            const hasProductFallback = !!marker.productFallback && !!marker.productFallback.id && !!marker.productFallback.color;
            const fallbackProducts = hasProductFallback
                ? [
                    {
                        productCode: marker.productFallback.id,
                        productColor: marker.productFallback.color,
                    },
                ]
                : null;
            const result = yield ProductsAPI_1.default.getSingleProductData(servicesEndpoint, defaultRequestParams.mainWebshop, defaultRequestParams.anaLang, defaultRequestParams.language, defaultRequestParams.shopId, marker.product.id, marker.product.color, fallbackProducts, true);
            if (result) {
                const product = hasProductFallback ? (0, common_utils_1.fixProductColoursFallback)(result) : result;
                const productPrice = yield ProductDetailAPI_1.default.getProductDetailPriceByProductId(servicesEndpoint, defaultRequestParams.mainWebshop, defaultRequestParams.shopId, product.productId);
                const mergedProduct = (0, common_utils_1.mergeProductPricesToProductLister)(product, productPrice);
                setActiveMarker(marker.markerId);
                setMiniPDPProduct(mergedProduct);
            }
        }
        catch (_a) { }
    });
    const maps = image.clickMaps
        ? Object.keys(image.clickMaps).map(clickMapKey => ({
            renditionId: getRenditionIdFromClickMapKey(clickMapKey),
            points: image.clickMaps[clickMapKey].map(marker => ({
                id: marker.markerId.toString(),
                x: marker.point.x,
                y: marker.point.y,
                onClick: () => onClickMarker(marker),
                isActive: marker.markerId === activeMarker,
            })),
        }))
        : null;
    const mappedButtons = buttons
        ? buttons.map(button => {
            const variantKey = Object.keys(cotopaxi_1.ButtonVariant).find(key => {
                return cotopaxi_1.ButtonVariant[key].toLowerCase() === button.variant.toLowerCase();
            });
            return {
                text: button.label,
                title: button.alt,
                variant: cotopaxi_1.ButtonVariant[variantKey],
                href: button.href,
                target: button.target ? cotopaxi_1.LinkTarget.BLANK : null,
                iconLeft: button.iconLeft
                    ? {
                        name: button.iconLeft,
                    }
                    : undefined,
                iconRight: button.iconRight
                    ? {
                        name: button.iconRight,
                    }
                    : undefined,
            };
        })
        : null;
    const handleMiniPDPClose = () => {
        setActiveMarker(null);
        setMiniPDPProduct(null);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!!title && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, alignment: cotopaxi_1.HeadingAlignment.CENTER, children: title })), !!subTitle && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, align: cotopaxi_1.TextAlign.CENTER, children: subTitle })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: title && subTitle ? { spacing: { marginTop: 3 } } : null, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.InteractiveImage, { image: {
                        src: image.imagePath,
                        srcset: srcset,
                        lazy: image.lazy,
                    }, maps: maps, buttons: mappedButtons }) }), (0, jsx_runtime_1.jsx)(MiniPDPContainer_1.MiniPDPContainer, { displayPackShotImagesFirst: true, productCode: miniPDPProduct === null || miniPDPProduct === void 0 ? void 0 : miniPDPProduct.productCode, colorId: miniPDPProduct === null || miniPDPProduct === void 0 ? void 0 : miniPDPProduct.colourId, deliveryPromiseExplanations: deliveryPromiseExplanations, onClose: handleMiniPDPClose })] }));
};
exports.default = ProductInspiration;
