"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const asNumeral_1 = require("../vendors/asNumeral");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const ProductUtil_1 = require("../util/ProductUtil");
const PriceContainer = ({ priceInformation, dynamicSavingsInformation, }) => {
    var _a;
    const { currency } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const productDiscount = (0, ProductUtil_1.getProductDiscount)(dynamicSavingsInformation.productDiscountAmount, dynamicSavingsInformation.productDiscountPercentage, currency);
    const productPriceTranslations = {
        savings: t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.dynamic.savings`),
        discount: t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.bundles.discount.info`),
    };
    const productPriceProps = ((_a = priceInformation.prices) === null || _a === void 0 ? void 0 : _a.SELL)
        ? Object.assign({ currency, hasDiscount: priceInformation.hasDiscount, productDiscount, translations: productPriceTranslations }, (0, common_utils_1.generateCotopaxiPriceViewModel)(priceInformation, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency)) : null;
    if (!productPriceProps)
        return null;
    return (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductPrice, Object.assign({}, productPriceProps));
};
const makeMapStateToProps = () => {
    const getPriceInformation = (0, ProductSelector_1.makeGetPriceInformation)();
    const getDynamicSavingsInformation = (0, ProductSelector_1.makeGetDynamicSavingsInformation)();
    const mapStateToProps = (state) => ({
        priceInformation: getPriceInformation(state),
        dynamicSavingsInformation: getDynamicSavingsInformation(state),
    });
    return mapStateToProps;
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps)(PriceContainer);
