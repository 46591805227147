"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareAddressByCountry = exports.getAddressValidationSchema = exports.isValidPostCode = exports.isValidAddress = exports.getHouseNumberNLPattern = exports.generateFieldName = exports.emptyAddressFields = exports.emptyDeliveryAddressFields = void 0;
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const Yup = require("yup");
const ArrayUtil_1 = require("../../../util/ArrayUtil");
exports.emptyDeliveryAddressFields = {
    addToProfile: false,
    address: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    city: '',
    country: '',
    county: '',
    defaultAddress: false,
    firstName: '',
    houseNumber: '',
    houseNumberAddition: '',
    isActive: false,
    lastName: '',
    mainAddress: false,
    middleName: '',
    postCode: '',
    state: '',
};
exports.emptyAddressFields = {
    address: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    city: '',
    cityFrench: undefined,
    country: '',
    county: '',
    houseNumber: '',
    houseNumberAddition: '',
    postCode: '',
};
const generateFieldName = (name, prefix) => prefix ? `${prefix}${prefix.endsWith('.') ? name : (0, common_utils_1.capitalizeOnly)(name)}` : name;
exports.generateFieldName = generateFieldName;
const getHouseNumberNLPattern = (value) => new RegExp('^[A-Z]{1}', 'i').test(value) ? common_types_1.DefaultSettings.HOUSE_NUMBER_NL_ALPHA : common_types_1.DefaultSettings.HOUSE_NUMBER_NL;
exports.getHouseNumberNLPattern = getHouseNumberNLPattern;
const isValidAddress = (postCode, houseNumber, countryId) => {
    switch (countryId) {
        case common_types_1.CountryId.NL:
            return (!!postCode &&
                !!houseNumber &&
                new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_NL, 'i').test(postCode) &&
                new RegExp((0, exports.getHouseNumberNLPattern)(houseNumber), 'i').test(houseNumber));
        case common_types_1.CountryId.BE:
            return !!postCode && new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_BE, 'i').test(postCode);
        case common_types_1.CountryId.GB:
        case common_types_1.CountryId.GG:
        case common_types_1.CountryId.JE:
            return !!postCode && new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_UK).test(postCode);
        default:
            return true;
    }
};
exports.isValidAddress = isValidAddress;
const isValidPostCode = (postCode, countryId) => {
    switch (countryId) {
        case common_types_1.CountryId.BE:
            return !!postCode && new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_BE, 'i').test(postCode);
        case common_types_1.CountryId.FR:
        case common_types_1.CountryId.DE:
            return !!postCode && postCode.length === 5;
        default:
            return true;
    }
};
exports.isValidPostCode = isValidPostCode;
const getAddressValidationSchema = ({ country, t, prefix, isOptional }) => {
    var _a;
    const objectName = (_a = prefix === null || prefix === void 0 ? void 0 : prefix.split('.')[0]) !== null && _a !== void 0 ? _a : '';
    const [addressName, houseNumberName, houseNumberAdditionName, postCodeName, cityName, stateName, finderName] = (0, ArrayUtil_1.mapWithAssert)([
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.HOUSE_NUMBER,
        common_types_1.AddressFieldName.HOUSE_NUMBER_ADDITION,
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.STATE,
        common_types_1.AddressFieldName.FINDER,
    ], name => (0, exports.generateFieldName)(name));
    const defaultRequiredMessage = t(common_types_1.ErrorMessage.ACCOUNT_REQUIRED);
    const addIsOptional = (values) => (Object.assign(Object.assign({}, (0, exports.prepareAddressByCountry)(values, '')), { isOptional }));
    const checkOptional = () => ({
        is: (val) => val === true,
        then: (schema) => schema.notRequired().nullable(),
        otherwise: (schema) => schema.required(defaultRequiredMessage),
    });
    const addressSchema = () => {
        switch (country === null || country === void 0 ? void 0 : country.toUpperCase()) {
            case 'NL':
                return Yup.object({
                    [postCodeName]: Yup.string()
                        .when('isOptional', checkOptional())
                        .conditionalMatches(new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_NL, 'i'), t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE_NL)),
                    [houseNumberName]: Yup.string()
                        .when('isOptional', checkOptional())
                        .test('checkNlHouseNumber', t(common_types_1.ErrorMessage.INVALID_HOUSE_NUMBER_NL), houseNumber => {
                        if (!houseNumber)
                            return true;
                        return new RegExp((0, exports.getHouseNumberNLPattern)(houseNumber), 'i').test(houseNumber);
                    }),
                    [addressName]: Yup.string()
                        .when('isOptional', checkOptional())
                        .conditionalMatches(new RegExp(common_types_1.DefaultSettings.STREET_NAME_REGEX), t(common_types_1.ErrorMessage.INVALID_STREET)),
                    [cityName]: Yup.string().when('isOptional', checkOptional()),
                }).transform(addIsOptional);
            case 'DE':
            case 'FR':
                return Yup.object({
                    [addressName]: Yup.string()
                        .when('isOptional', checkOptional())
                        .conditionalMatches(new RegExp(common_types_1.DefaultSettings.STREET_NAME_REGEX), t(common_types_1.ErrorMessage.INVALID_STREET)),
                    [houseNumberName]: Yup.string().when('isOptional', checkOptional()),
                    [postCodeName]: Yup.string()
                        .when('isOptional', checkOptional())
                        .conditionalMatches(new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_DE_FR, 'i'), t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE)),
                    [cityName]: Yup.string().when('isOptional', checkOptional()),
                }).transform(addIsOptional);
            case 'US':
                return Yup.object({
                    [addressName]: Yup.string().when('isOptional', checkOptional()),
                    [postCodeName]: Yup.string().when('isOptional', checkOptional()),
                    [cityName]: Yup.string().when('isOptional', checkOptional()),
                    [stateName]: Yup.string().when('isOptional', checkOptional()),
                }).transform(addIsOptional);
            case 'BE':
            case 'LU':
                return Yup.object({
                    [addressName]: Yup.string()
                        .when('isOptional', checkOptional())
                        .conditionalMatches(new RegExp(common_types_1.DefaultSettings.STREET_BE_LU_REGEXP), t(common_types_1.ErrorMessage.INVALID_STREET_BE_LU)),
                    [houseNumberName]: Yup.string()
                        .when('isOptional', checkOptional())
                        .conditionalMatches(new RegExp(common_types_1.DefaultSettings.HOUSE_NUMBER_BE_LU_REGEXP), t(common_types_1.ErrorMessage.INVALID_HOUSE_NUMBER_BE_LU)),
                    [houseNumberAdditionName]: Yup.string().conditionalMatches(new RegExp(common_types_1.DefaultSettings.HOUSE_NUMBER_ADDITION_BE_LU_REGEXP), t(common_types_1.ErrorMessage.INVALID_HOUSE_NUMBER_ADDITION_BE_LU)),
                    [postCodeName]: Yup.string()
                        .when('isOptional', checkOptional())
                        .conditionalMatches(new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_BE, 'i'), t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE_BE)),
                    [cityName]: Yup.string().when('isOptional', checkOptional()),
                }).transform(addIsOptional);
            case 'GB':
            case 'GG':
            case 'JE':
                return Yup.object({
                    [finderName]: Yup.string()
                        .when([addressName, 'isOptional'], {
                        is: (addressValue, isOptionalValue) => !addressValue && !isOptionalValue,
                        then: () => Yup.string()
                            .required(defaultRequiredMessage)
                            .test('isValidUKAddress', t(common_types_1.ErrorMessage.GENERAL_ADDRESS_BUY), () => false),
                    })
                        .when([addressName, 'isOptional'], {
                        is: (addressValue, isOptionalValue) => !addressValue && isOptionalValue,
                        then: () => Yup.string().test('isValidUKAddress', t(common_types_1.ErrorMessage.GENERAL_ADDRESS_BUY), finderValue => !finderValue),
                    }),
                    [addressName]: Yup.string()
                        .when('isOptional', checkOptional())
                        .conditionalMatches(new RegExp(common_types_1.DefaultSettings.STREET_NAME_REGEX), t(common_types_1.ErrorMessage.INVALID_STREET)),
                    [postCodeName]: Yup.string()
                        .when('isOptional', checkOptional())
                        .conditionalMatches(new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_UK), t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE_UK)),
                    [cityName]: Yup.string().when('isOptional', checkOptional()),
                }).transform(addIsOptional);
            default:
                return Yup.object({
                    [addressName]: Yup.string().when('isOptional', checkOptional()),
                    [postCodeName]: Yup.string().when('isOptional', checkOptional()),
                    [cityName]: Yup.string().when('isOptional', checkOptional()),
                }).transform(addIsOptional);
        }
    };
    return objectName
        ? Yup.object({
            [objectName]: addressSchema(),
        })
        : addressSchema();
};
exports.getAddressValidationSchema = getAddressValidationSchema;
const prepareAddressByCountry = (address, emptyValue) => {
    switch (Number(address.idCountry)) {
        case common_types_1.CountryId.NL:
        case common_types_1.CountryId.BE:
        case common_types_1.CountryId.LU:
        case common_types_1.CountryId.FR:
            return Object.assign(Object.assign(Object.assign({}, address), exports.emptyAddressFields), { postCode: address.postCode || emptyValue, houseNumber: address.houseNumber || emptyValue, houseNumberAddition: address.houseNumberAddition || emptyValue, address: address.address || emptyValue, city: address.city || emptyValue, idCountry: address.idCountry, country: address.country });
        case common_types_1.CountryId.DE:
            return Object.assign(Object.assign(Object.assign({}, address), exports.emptyAddressFields), { postCode: address.postCode || emptyValue, houseNumber: address.houseNumber || emptyValue, address: address.address || emptyValue, city: address.city || emptyValue, idCountry: address.idCountry, country: address.country });
        case common_types_1.CountryId.GB:
        case common_types_1.CountryId.GG:
        case common_types_1.CountryId.JE:
            return Object.assign(Object.assign(Object.assign({}, address), exports.emptyAddressFields), { address: address.address || emptyValue, addressLine2: address.addressLine2 || emptyValue, addressLine3: address.addressLine3 || emptyValue, addressLine4: address.addressLine4 || emptyValue, postCode: address.postCode || emptyValue, city: address.city || emptyValue, county: address.county || emptyValue, idCountry: address.idCountry, country: address.country });
        case common_types_1.CountryId.US:
            return Object.assign(Object.assign(Object.assign({}, address), exports.emptyAddressFields), { address: address.address || emptyValue, addressLine2: address.addressLine2 || emptyValue, postCode: address.postCode || emptyValue, city: address.city || emptyValue, county: address.state || emptyValue, idCountry: address.idCountry, country: address.country });
        default:
            return Object.assign(Object.assign(Object.assign({}, address), exports.emptyAddressFields), { address: address.address || emptyValue, addressLine2: address.addressLine2 || emptyValue, postCode: address.postCode || emptyValue, city: address.city || emptyValue, idCountry: address.idCountry, country: address.country });
    }
};
exports.prepareAddressByCountry = prepareAddressByCountry;
