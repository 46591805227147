"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeProductFromBasketSaga = exports.removeProductFromBasket = void 0;
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
function* removeProductFromBasket(action) {
    try {
        const { defaultRequestParams, servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createUpdateItemsErrorStateAction(null));
        yield (0, effects_1.call)(BasketAPI_1.default.removeProductFromBasket, action.payload.product, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createRemoveSuccessAction(action.payload.product));
    }
    catch (_a) { }
}
exports.removeProductFromBasket = removeProductFromBasket;
function* removeProductFromBasketSaga() {
    yield (0, effects_1.takeEvery)(ActionEnums_1.BasketActionTypes.REMOVE_PRODUCT, removeProductFromBasket);
}
exports.removeProductFromBasketSaga = removeProductFromBasketSaga;
