"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const SliderConstants_1 = require("../../../constants/SliderConstants");
const ScreenWidthConstants_1 = require("../../../constants/ScreenWidthConstants");
const ContentListerUtil_1 = require("../../../util/content-lister/ContentListerUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductRelevantContent = ({ content, collapsible, collapsed, title, types, categories, analyticsBannerId, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.general]);
    const { mainWebShop } = (0, common_components_1.useAemConfig)();
    const contentSize = content.length;
    const isExtended = contentSize < 3;
    const slidesPerView = isExtended ? 2 : 3;
    const contentTileTranslations = {
        readMore: t(`${i18nConstants_1.I18N_NAMESPACES.general}:readMore`),
    };
    const mainBoxParams = {
        mobile: { spacing: { paddingTop: collapsible ? 0 : 2 } },
        tablet: { spacing: { paddingBottom: 5, paddingTop: 0 } },
        dataAttributes: { 'data-analytics-bannerid': analyticsBannerId },
    };
    const innerBoxParams = {
        mobile: {
            spacing: {
                paddingBottom: 3,
                paddingTop: collapsible ? 0 : 2,
            },
        },
        tablet: {
            spacing: {
                paddingBottom: 0,
                paddingTop: 0,
                marginTop: 2,
            },
        },
        mobileBleed: !collapsible,
        tabletBleed: !collapsible,
    };
    const carouselConfig = {
        variant: cotopaxi_1.CarouselVariant.CONTENT_TILE,
        arrowsConfig: {
            color: cotopaxi_1.SliderNavColor.BASE,
            variant: cotopaxi_1.SliderNavVariant.CIRCLE,
        },
        showPagination: true,
        configurationOverrides: {
            preventClicks: true,
            slidesPerView: 'auto',
            spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
            watchOverflow: true,
            shouldSwiperUpdate: true,
            breakpoints: {
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_TABLET]: {
                    slidesPerView,
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.MOBILE,
                },
                [ScreenWidthConstants_1.MIN_SCREEN_WIDTH_DESKTOP]: {
                    slidesPerView,
                    spaceBetween: SliderConstants_1.SLIDE_SPACING.DESKTOP,
                },
            },
        },
    };
    const slides = content.map(c => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { grid: 0, gutter: false, desktop: 12 / contentSize, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { paddingBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ContentTile, { href: c.url, tags: (0, ContentListerUtil_1.getCategoryAndTypeByTags)(mainWebShop, c.tags, types, categories), title: c.title, text: c.description, image: { src: c.image, backgroundImage: true, lazy: true }, extended: isExtended, translations: contentTileTranslations }) }) }, c._id)));
    if (!(content === null || content === void 0 ? void 0 : content.length)) {
        return null;
    }
    if (collapsible) {
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, mainBoxParams, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { id: "product-relevant-content-collapse", title: title, open: !collapsed, query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL], mobileFullwidth: true, tabletFullwidth: true, desktopFullwidth: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, innerBoxParams, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, carouselConfig, { children: slides })) })) }) })));
    }
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, Object.assign({}, mainBoxParams, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SMALL_TITLE, children: title }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, innerBoxParams, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, carouselConfig, { children: slides })) }))] })));
};
exports.default = ProductRelevantContent;
