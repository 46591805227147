"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryHierarchyTree = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const GeneralUtil_1 = require("../../../util/GeneralUtil");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const NavigationUtil_1 = require("../../../util/NavigationUtil");
const UrlUtil_1 = require("../../../util/UrlUtil");
const CategoryHierarchyTreeMobileHelper_1 = require("./CategoryHierarchyTreeMobileHelper");
const useCategoryHierarchyTree_1 = require("../../../hooks/useCategoryHierarchyTree");
const CategoryHierarchyTree = () => {
    const { isInAuthorMode, root, rootWithLanguageSuffix, lang } = (0, common_components_1.useAemConfig)();
    const { categoryIds, categoryHierarchyTree } = (0, useCategoryHierarchyTree_1.useCategoryHierarchyTree)();
    const getUrl = (categoryPath) => (0, UrlUtil_1.concatUrls)(isInAuthorMode ? rootWithLanguageSuffix : root, (0, NavigationUtil_1.getHomepageUrl)(categoryPath));
    const renderHierarchyTreeItem = (0, react_1.useCallback)((item, _, parents) => {
        const translatedProperty = item.categoryTranslatedProperties[lang.toLocaleUpperCase()];
        if ((0, GeneralUtil_1.isEqual)(categoryIds, [...parents.map(parent => parent.categoryId), item.categoryId])) {
            return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { size: cotopaxi_1.LinkSize.SMALL, variant: cotopaxi_1.LinkVariant.SUBTLE, emphasize: true, href: getUrl(translatedProperty.link), children: translatedProperty.description }));
        }
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { size: cotopaxi_1.LinkSize.SMALL, variant: cotopaxi_1.LinkVariant.SUBTLE, href: getUrl(translatedProperty.link), children: translatedProperty.description }));
    }, [categoryHierarchyTree, categoryIds]);
    if (!categoryHierarchyTree) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(CategoryHierarchyTreeMobileHelper_1.CategoryHierarchyTreeMobileHelper, { categoryIds: categoryIds, hierarchyTree: categoryHierarchyTree }), (0, jsx_runtime_1.jsx)(cotopaxi_1.HierarchyTree, { hierarchyTree: categoryHierarchyTree, renderItem: renderHierarchyTreeItem })] }));
};
exports.CategoryHierarchyTree = CategoryHierarchyTree;
