"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCheckoutStepState = exports.CheckoutStepContext = exports.CheckoutStep = void 0;
const react_1 = require("react");
var CheckoutStep;
(function (CheckoutStep) {
    CheckoutStep[CheckoutStep["DETAILS"] = 1] = "DETAILS";
    CheckoutStep[CheckoutStep["DELIVERY"] = 2] = "DELIVERY";
    CheckoutStep[CheckoutStep["PAYMENT"] = 3] = "PAYMENT";
    CheckoutStep[CheckoutStep["CONFIRMATION"] = 4] = "CONFIRMATION";
    CheckoutStep[CheckoutStep["FAST_CHECKOUT"] = 5] = "FAST_CHECKOUT";
})(CheckoutStep || (exports.CheckoutStep = CheckoutStep = {}));
const defaultState = {
    step: CheckoutStep.DETAILS,
};
exports.CheckoutStepContext = (0, react_1.createContext)(defaultState);
const useCheckoutStepState = () => (0, react_1.useContext)(exports.CheckoutStepContext);
exports.useCheckoutStepState = useCheckoutStepState;
