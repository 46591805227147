"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressChangeHandler = void 0;
const tslib_1 = require("tslib");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const formik_1 = require("formik");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const GeneralAPI_1 = require("../../api/general/GeneralAPI");
const BuyFormUtil_1 = require("../../util/BuyFormUtil");
const GeneralUtil_1 = require("../../util/GeneralUtil");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const ArrayUtil_1 = require("../../util/ArrayUtil");
const AddressChangeHandler = ({ countryCode, countryIdFieldName, fieldPrefix = '', vatNumberFieldName = '', setAddresses, }) => {
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const { values, touched, isSubmitting, dirty, setFieldError, setFieldValue, setFieldTouched } = (0, formik_1.useFormikContext)();
    const resetFieldValue = (field) => {
        setFieldValue(field, '');
        setFieldTouched(field, false);
    };
    const [postCodeFieldName, cityFieldName, finderFieldName, houseNumberFieldName, houseNumberAdditionFieldName, addressFieldName, addressLine2FieldName, addressLine3FieldName, addressLine4FieldName, countyFieldName, customTownFieldName, stateFieldName,] = (0, ArrayUtil_1.mapWithAssert)([
        common_types_1.AddressFieldName.POSTCODE,
        common_types_1.AddressFieldName.CITY,
        common_types_1.AddressFieldName.FINDER,
        common_types_1.AddressFieldName.HOUSE_NUMBER,
        common_types_1.AddressFieldName.HOUSE_NUMBER_ADDITION,
        common_types_1.AddressFieldName.ADDRESS,
        common_types_1.AddressFieldName.ADDRESS_LINE_2,
        common_types_1.AddressFieldName.ADDRESS_LINE_3,
        common_types_1.AddressFieldName.ADDRESS_LINE_4,
        common_types_1.AddressFieldName.COUNTY,
        common_types_1.AddressFieldName.CUSTOM_TOWN,
        common_types_1.AddressFieldName.STATE,
    ], name => (0, BuyFormUtil_1.generateFieldName)(name, fieldPrefix));
    const postCode = (0, formik_1.getIn)(values, postCodeFieldName);
    const postCodeTouched = (0, formik_1.getIn)(touched, postCodeFieldName);
    const countryId = (0, formik_1.getIn)(values, countryIdFieldName);
    const countryIdOld = (0, cotopaxi_1.usePrevious)(countryId);
    const city = (0, formik_1.getIn)(values, cityFieldName);
    const houseNumber = (0, formik_1.getIn)(values, houseNumberFieldName);
    const vatNumber = (0, formik_1.getIn)(values, vatNumberFieldName);
    const isMounted = (0, cotopaxi_1.useIsMounted)();
    (0, react_1.useEffect)(() => {
        const resetAddressField = () => {
            resetFieldValue(postCodeFieldName);
            resetFieldValue(cityFieldName);
            resetFieldValue(finderFieldName);
            resetFieldValue(houseNumberFieldName);
            resetFieldValue(houseNumberAdditionFieldName);
            resetFieldValue(addressFieldName);
            resetFieldValue(addressLine2FieldName);
            resetFieldValue(addressLine3FieldName);
            resetFieldValue(addressLine4FieldName);
            resetFieldValue(countyFieldName);
            resetFieldValue(customTownFieldName);
            resetFieldValue(stateFieldName);
        };
        const forceValidationOnCountryChange = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            yield (0, GeneralUtil_1.mimicDelay)(GeneralConstants_1.DELAY.DELAY_0);
            vatNumberFieldName && setFieldTouched(vatNumberFieldName, !!vatNumber);
        });
        if (countryIdOld && String(countryId) !== String(countryIdOld)) {
            resetAddressField();
            forceValidationOnCountryChange();
        }
    }, [countryId]);
    (0, react_1.useEffect)(() => {
        const shouldRun = countryCode && ['BE', 'DE', 'FR', 'LU'].includes(countryCode);
        const validate = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            if (!(0, BuyFormUtil_1.isValidPostCode)(postCode, countryCode)) {
                return;
            }
            try {
                const data = yield GeneralAPI_1.default.getAddresses(servicesEndpoint, defaultRequestParams, parseInt(countryId, 10), postCode);
                if (!isMounted())
                    return;
                const hasCustomCity = !!city && data.every(addressRecord => { var _a; return ((_a = addressRecord.city) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== city.toUpperCase(); });
                if (hasCustomCity) {
                    setAddresses([{ city }, ...data]);
                    setFieldValue(cityFieldName, city);
                    return;
                }
                setAddresses(data);
                if (data.length === 1) {
                    setFieldValue(cityFieldName, data[0].city);
                }
            }
            catch (_a) {
                isMounted() && setFieldError(postCodeFieldName, t('buy.error.no.results'));
            }
        });
        if (!postCodeTouched && city && shouldRun) {
            setAddresses([{ city }]);
            setFieldValue(cityFieldName, city);
        }
        if (postCode && !isSubmitting && shouldRun) {
            validate();
        }
    }, [postCode, postCodeTouched, countryCode, isMounted]);
    (0, react_1.useEffect)(() => {
        let isCurrent = true;
        const setStreetAndCity = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            if (countryCode === 'NL' && (0, BuyFormUtil_1.isValidAddress)(postCode, houseNumber, countryCode) && dirty) {
                try {
                    const data = yield GeneralAPI_1.default.getAddresses(servicesEndpoint, defaultRequestParams, parseInt(countryId, 10), postCode, houseNumber);
                    if ((data === null || data === void 0 ? void 0 : data.length) > 0 && isMounted()) {
                        const firstAddress = data[0];
                        if (firstAddress.address) {
                            setFieldValue(addressFieldName, firstAddress.address);
                        }
                        if (firstAddress.city) {
                            setFieldValue(cityFieldName, firstAddress.city);
                        }
                        if (firstAddress.houseNumberAddition) {
                            setFieldValue(houseNumberAdditionFieldName, firstAddress.houseNumberAddition);
                        }
                    }
                }
                catch (_a) {
                    setFieldError(postCodeFieldName, t('buy.error.no.results'));
                }
            }
        });
        !!isCurrent && (0, GeneralUtil_1.mimicDelay)().then(() => setStreetAndCity());
        return () => {
            isCurrent = false;
        };
    }, [countryCode, postCode, houseNumber, isMounted]);
    return null;
};
exports.AddressChangeHandler = AddressChangeHandler;
