"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WishlistButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const WishListActions_creators_1 = require("../../redux/wishList/actions/WishListActions.creators");
const WishListSelectors_1 = require("../../redux/wishList/selectors/WishListSelectors");
const WishlistButton = ({ productCode, productId, colourId, skuId, buttonProps, onToggle, }) => {
    const { isShopLanding } = (0, common_components_1.useAemConfig)();
    const shouldAnimate = (0, react_1.useRef)(false);
    const dispatch = (0, react_redux_1.useDispatch)();
    const inWishList = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeIsProductInWishList)(productCode, colourId));
    const mergedButtonProps = Object.assign(Object.assign({}, buttonProps), { icon: Object.assign({ name: inWishList ? cotopaxi_1.IconName.HEART : cotopaxi_1.IconName.HEART_EMPTY, size: cotopaxi_1.IconSize.MEDIUM, animation: shouldAnimate.current ? cotopaxi_1.IconAnimation.HEART_PULSE : undefined }, ((buttonProps === null || buttonProps === void 0 ? void 0 : buttonProps.icon) || {})) });
    const handleWishListToggle = () => {
        shouldAnimate.current = !inWishList;
        dispatch(WishListActions_creators_1.wishListActions.toggleProduct({
            colorId: colourId,
            productCode,
            productId,
            sku: skuId,
        }));
        onToggle === null || onToggle === void 0 ? void 0 : onToggle();
    };
    if (isShopLanding) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, Object.assign({ dataQA: "add_to_wishlist", variant: cotopaxi_1.ButtonVariant.INVERSE_ALT, size: cotopaxi_1.ButtonSize.XSMALL, onClick: handleWishListToggle }, mergedButtonProps)));
};
exports.WishlistButton = WishlistButton;
