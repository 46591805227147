"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InStoreFilterShoplanding = void 0;
const tslib_1 = require("tslib");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const StoreAPI_1 = require("../../../api/store/StoreAPI");
const ListerActions_creators_1 = require("../../../redux/lister/actions/ListerActions.creators");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const react_redux_1 = require("react-redux");
const InStoreFilterShoplanding = () => {
    const [shoplandingInfo, setShoplandingInfo] = (0, react_1.useState)();
    const [shopNumber, setShopNumber] = (0, react_1.useState)();
    const [inStoreFilter, setInStoreFilter] = (0, react_1.useState)();
    const { market, servicesApi, lang } = (0, common_components_1.useAemConfig)();
    const dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(() => {
        setShopNumber(LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY));
        setInStoreFilter(LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY));
    }, []);
    (0, react_1.useEffect)(() => {
        if (!shopNumber || inStoreFilter) {
            return;
        }
        const getShoplandingInfo = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            const shoplandingInfoResponse = yield StoreAPI_1.default.getShoplandingInfo(servicesApi, market, shopNumber);
            setShoplandingInfo(shoplandingInfoResponse);
        });
        getShoplandingInfo();
    }, [market, servicesApi, shopNumber, inStoreFilter]);
    (0, react_1.useEffect)(() => {
        if (!shoplandingInfo) {
            return;
        }
        const { storeId, storeTranslatedProperties } = shoplandingInfo;
        const storeName = storeTranslatedProperties[lang.toUpperCase()].description;
        dispatch(ListerActions_creators_1.listerActions.updateInStoreFilter({ storeId, storeName }));
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY, { storeId, storeName });
    }, [shoplandingInfo]);
    return null;
};
exports.InStoreFilterShoplanding = InStoreFilterShoplanding;
