"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListerPaginationContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const UrlActions_creators_1 = require("../../redux/url/actions/UrlActions.creators");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const MetaLinkUtil_1 = require("../../util/MetaLinkUtil");
const ScrollUtil_1 = require("../../util/ScrollUtil");
const DOMElementsConstants_1 = require("../../constants/DOMElementsConstants");
const _ListerPaginationContainer = ({ queryInformation, totalPages, updatePage, }) => {
    const { canonicalUrl } = (0, common_components_1.useAemConfig)();
    const onPageChangeHandler = (newPage) => {
        updatePage(newPage);
        ScrollUtil_1.ScrollUtil.scrollToElement(document.querySelector(DOMElementsConstants_1.LISTER_LAYOUT));
    };
    (0, react_1.useEffect)(() => {
        (0, MetaLinkUtil_1.generateRelatedProductPageLinks)(canonicalUrl, queryInformation.page, queryInformation.size, totalPages, false);
    }, [queryInformation.page, queryInformation.size, totalPages]);
    if (totalPages <= 1)
        return null;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_AROUND, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Pagination, { baseUrl: canonicalUrl, currentPage: queryInformation.page, onPageChange: onPageChangeHandler, totalPages: totalPages }) }));
};
const makeMapStateToProps = () => {
    const getUrlQueryInformationState = (0, UrlSelectors_1.makeGetUrlQueryInformationState)();
    const getTotalPages = (0, ListerSelector_1.makeGetTotalPages)();
    const mapStateToProps = (state) => ({
        queryInformation: getUrlQueryInformationState(state),
        totalPages: getTotalPages(state),
    });
    return mapStateToProps;
};
const mapDispatchToProps = {
    updatePage: UrlActions_creators_1.urlActions.updatePage,
};
const composer = (0, redux_1.compose)((0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps), (0, react_i18next_1.withTranslation)());
exports.ListerPaginationContainer = composer(_ListerPaginationContainer);
