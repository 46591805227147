"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnavailableProductsMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const UnavailableProductsMessage = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const hasUnavailableProducts = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketUnavailableProductsState)());
    if (!hasUnavailableProducts)
        return null;
    return (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "error", text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:basket.hasUnavailableProducts`) });
};
exports.UnavailableProductsMessage = UnavailableProductsMessage;
