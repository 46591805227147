"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickupPointSelector = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const PickupPointSearchForm_1 = require("./PickupPointSearchForm");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const PickupPoint_1 = require("./PickupPoint");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const StoreListLoader_1 = require("../../../product-detail/store-finder-popup/store-list-loader/StoreListLoader");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const DeliverySheet_1 = require("../DeliverySheet");
const PickupPoints = () => {
    const [{ pickupPoint }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { highlightedPickupPoint, selectedPickupPoint } = pickupPoint;
    const { getPickupPointsQuery, locationSearch } = (0, CheckoutDeliveryContext_1.usePickupPointSearchContext)();
    const { data: pickupPointsResponse, isLoading } = getPickupPointsQuery;
    if (isLoading) {
        return (0, jsx_runtime_1.jsx)(StoreListLoader_1.StoreListLoader, {});
    }
    if (!pickupPointsResponse || !locationSearch || pickupPointsResponse.status === common_types_1.HTTPStatusCode.NoContent)
        return null;
    return ((0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "4", children: pickupPointsResponse.data.map(pickupPoint => ((0, jsx_runtime_1.jsx)(PickupPoint_1.PickupPoint, { pickupPoint: pickupPoint, selected: (highlightedPickupPoint === null || highlightedPickupPoint === void 0 ? void 0 : highlightedPickupPoint.id) === pickupPoint.id ||
                (!highlightedPickupPoint && (selectedPickupPoint === null || selectedPickupPoint === void 0 ? void 0 : selectedPickupPoint.id) === pickupPoint.id) }, pickupPoint.id))) }));
};
const PickupPointSelector = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const [{ pickupPoint, activeDeliveryOption, deliveryCountryId, openSelector }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { highlightedPickupPoint, selectedPickupPoint } = pickupPoint;
    const isOpen = openSelector === 'pickup-point';
    const { postPickupPoint } = (0, common_queries_1.checkoutMutations)({ servicesEndpoint, defaultRequestParams });
    const postPickupPointMutation = (0, react_query_1.useMutation)(postPickupPoint);
    const selectPickupPoint = () => {
        if (!highlightedPickupPoint)
            return;
        postPickupPointMutation.mutate({
            countryCode: String(deliveryCountryId),
            provider: activeDeliveryOption.provider,
            pickupPoint: highlightedPickupPoint,
        }, {
            onSuccess: data => {
                dispatch({ type: 'select_pickup_point', payload: highlightedPickupPoint });
                dispatch({ type: 'set_location_information', payload: data.data });
                dispatch({ type: 'highlight_pickup_point', payload: null });
            },
            onError: () => {
                dispatch({ type: 'set_active_delivery_option', payload: undefined });
            },
            onSettled: () => {
                dispatch({ type: 'set_open_selector', payload: undefined });
            },
        });
    };
    const resetDeliveryOption = () => {
        dispatch({ type: 'highlight_pickup_point', payload: null });
        dispatch({ type: 'set_open_selector', payload: undefined });
        if (!selectedPickupPoint) {
            dispatch({ type: 'set_active_delivery_option', payload: undefined });
        }
    };
    const getContent = () => ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "8", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", children: t('checkout:checkout.delivery.pickuppoint.flyout.title') }), (0, jsx_runtime_1.jsx)(PickupPointSearchForm_1.PickupPointSearchForm, {}), (0, jsx_runtime_1.jsx)(PickupPoints, {})] }) }));
    const getCTAContent = () => ((0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, theme: "branded", text: t('checkout:checkout.delivery.pickuppoint.flyout.confirm'), state: !highlightedPickupPoint ? 'disabled' : undefined, onClick: selectPickupPoint, dataTestId: "pickuppoint_submit" }));
    return (0, DeliverySheet_1.DeliverySheet)({
        isOpen,
        onClose: resetDeliveryOption,
        getContent,
        showCTA: !!highlightedPickupPoint,
        getCTAContent,
    });
};
exports.PickupPointSelector = PickupPointSelector;
