"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_types_1 = require("@as-react/common-types");
const BasketSelector_1 = require("../../redux/basket/selectors/BasketSelector");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const ListerActions_creators_1 = require("../../redux/lister/actions/ListerActions.creators");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const UrlActions_creators_1 = require("../../redux/url/actions/UrlActions.creators");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const LocalStorageUtil_1 = require("../../util/LocalStorageUtil");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const StoreFinderPopup_1 = require("../../components/product-detail/store-finder-popup/StoreFinderPopup");
const react_query_1 = require("@tanstack/react-query");
const SearchAPI_1 = require("../../api/search/SearchAPI");
const InStoreFilterPopupContainer = () => {
    var _a;
    const popupState = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketCCPopupState)());
    const urlInfo = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetUrlQueryInformationState)());
    const inStoreFilterState = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetInStoreFilterState)());
    const activeStoreId = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetStoreId)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const { defaultRequestParams, servicesEndpoint, authenticationConfig, isShopLanding } = (0, common_components_1.useAemConfig)();
    const [locationSearchParams, setLocationSearchParams] = (0, react_1.useState)();
    const { getClickAndCollectStores } = (0, common_queries_1.storeQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const isEnabled = !popupState.collapsed &&
        !!((_a = locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.locationSearch) === null || _a === void 0 ? void 0 : _a.location) &&
        locationSearchParams.locationSearch.state !== 'INIT';
    const storesQueryResult = (0, react_query_1.useQueries)({
        queries: [
            getClickAndCollectStores({
                location: ((locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.locationSearch) || {}).location,
                inStore: true,
                allowMultiFascia: false,
                sortBy: (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.sortBy) || 'distance',
                selectedStoreId: (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.locationSearch.type) === common_types_1.LocationSearchType.CUSTOM_SEARCH
                    ? undefined
                    : locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.selectedStoreId,
            }, { enabled: isEnabled }),
            {
                queryKey: ['productCountPerStore', locationSearchParams, urlInfo],
                queryFn: () => SearchAPI_1.default.getProductCountPerStore(servicesEndpoint, authenticationConfig, undefined, urlInfo, isShopLanding, getAppliedFilters()),
                enabled: isEnabled,
            },
        ],
        combine: data => {
            var _a, _b;
            const [storesQueryResult, productCountPerStoreResult] = data;
            if (((_a = storesQueryResult.data) === null || _a === void 0 ? void 0 : _a.length) && ((_b = productCountPerStoreResult.data) === null || _b === void 0 ? void 0 : _b.length)) {
                storesQueryResult.data.forEach(store => {
                    var _a;
                    store.itemsCount = (_a = productCountPerStoreResult.data.find(item => item.storeId === store.storeId)) === null || _a === void 0 ? void 0 : _a.itemCount;
                });
            }
            return storesQueryResult;
        },
    });
    const handleClosePopup = () => dispatch(BasketActions_creators_1.basketActions.createCCPopupCloseAction());
    const updateInStoreFilter = (store) => dispatch(ListerActions_creators_1.listerActions.updateInStoreFilter(store));
    const updateStoreId = (storeId) => dispatch(UrlActions_creators_1.urlActions.updateStoreId(storeId));
    const storeFilterApplied = () => dispatch(AnalyticsActions_creators_1.analyticsActions.storeFilterApplied());
    (0, react_1.useEffect)(() => {
        const inStoreFilter = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY);
        if (!activeStoreId &&
            inStoreFilter &&
            (!inStoreFilterState || (inStoreFilterState === null || inStoreFilterState === void 0 ? void 0 : inStoreFilterState.storeId) !== inStoreFilter.storeId)) {
            updateInStoreFilter(inStoreFilter);
        }
    }, [inStoreFilterState]);
    const getAppliedFilters = () => {
        var _a;
        const appliedFilters = ((_a = urlInfo === null || urlInfo === void 0 ? void 0 : urlInfo.filter) === null || _a === void 0 ? void 0 : _a.length)
            ? urlInfo.filter.map(filter => `${filter.filterOn}:${filter.filterValue}`)
            : [];
        return (urlInfo === null || urlInfo === void 0 ? void 0 : urlInfo.defaultFilter) ? appliedFilters.concat(urlInfo.defaultFilter) : appliedFilters;
    };
    const handleSearch = (searchParams) => {
        setLocationSearchParams(searchParams);
    };
    const handleAddToFilter = (store) => {
        const { storeId, storeName } = store;
        updateStoreId(storeId);
        updateInStoreFilter({ storeId, storeName });
        storeFilterApplied();
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SELECTED_STORE_STORAGE_KEY, { storeId, storeName });
    };
    return ((0, jsx_runtime_1.jsx)(StoreFinderPopup_1.StoreFinderPopup, { isOpen: !popupState.collapsed, urlInfo: urlInfo, type: common_types_1.StoreFinderType.IN_STORE_FILTER, onSearch: handleSearch, storesQueryResult: storesQueryResult, handleClosePopup: handleClosePopup, onStoreSelect: handleAddToFilter }));
};
exports.default = InStoreFilterPopupContainer;
