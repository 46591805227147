"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const CustomerApi_1 = require("../../api/customer/CustomerApi");
const AccountOverviewInfo_1 = require("../../components/account/account-overview-info/AccountOverviewInfo");
const common_utils_1 = require("@as-react/common-utils");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const AccountOverviewInfoContainer = ({ editProfileLink, title, }) => {
    const { defaultRequestParams, servicesEndpoint, lang } = (0, common_components_1.useAemConfig)();
    const isMounted = (0, cotopaxi_1.useIsMounted)();
    const [customerProfile, setCustomerProfile] = (0, react_1.useState)(null);
    const [customerCountry, setCustomerCountry] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        const getCustomerProfile = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            try {
                const profile = yield CustomerApi_1.default.getProfile(servicesEndpoint, defaultRequestParams);
                if (isMounted()) {
                    setCustomerProfile(profile);
                }
            }
            catch (_a) { }
        });
        getCustomerProfile();
    }, [isMounted]);
    (0, react_1.useEffect)(() => {
        const getCountries = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            try {
                let countries = (0, common_utils_1.getCustomWindowObject)(GeneralConstants_1.CUSTOM_OBJECT_COUNTRIES);
                if ((0, common_utils_1.isNullOrUndefined)(countries)) {
                    countries = yield CustomerApi_1.default.getCountries(servicesEndpoint, defaultRequestParams);
                }
                if (isMounted()) {
                    const currentCountry = countries.find(country => country.idCountry === customerProfile.address.idCountry);
                    const countryName = currentCountry.countryDescriptionTranslations[lang.toUpperCase()];
                    setCustomerCountry(countryName);
                }
            }
            catch (_a) { }
        });
        if (customerProfile) {
            getCountries();
        }
    }, [customerProfile, isMounted]);
    if (!customerProfile) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(AccountOverviewInfo_1.AccountOverviewInfo, { country: customerCountry, profile: customerProfile, editProfileLink: editProfileLink, title: title }));
};
exports.default = AccountOverviewInfoContainer;
