"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const BasketAPI_1 = require("../../api/basket/BasketAPI");
const BuyVoucher_1 = require("../../components/buy/payment/BuyVoucher");
const PaidByVoucher_1 = require("../../components/buy/payment/PaidByVoucher");
const PaymentGroups_1 = require("../../components/buy/payment/PaymentGroups");
const ShopPaymentGroups_1 = require("../../components/buy/payment/ShopPaymentGroups");
const i18nConstants_1 = require("../../constants/i18nConstants");
const AnalyticsEnums_1 = require("../../enums/AnalyticsEnums");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const CheckoutActions_creators_1 = require("../../redux/checkout/actions/CheckoutActions.creators");
const CheckoutSelector_1 = require("../../redux/checkout/selectors/CheckoutSelector");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const SaferpayUtil_1 = require("../../util/SaferpayUtil");
const ScriptLoaderUtil_1 = require("../../util/ScriptLoaderUtil");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const ApplePayUtil_1 = require("../../util/ApplePayUtil");
const common_utils_1 = require("@as-react/common-utils");
const BasketSelector_1 = require("../../redux/basket/selectors/BasketSelector");
const useABTest_1 = require("../../hooks/useABTest");
const PaymentGroupCreditCardContainer_1 = require("./PaymentGroupCreditCardContainer");
const BuyPaymentContainer = ({ logo, voucherCodeCollapsed, creditCardSavingLegalMessage, payPalInfoMessage, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const { defaultRequestParams, servicesEndpoint, saferpayConfig, currency, mainWebShop, isShopLanding, imageCdnPrefix, } = (0, common_components_1.useAemConfig)();
    const paymentInfo = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetPaymentInfo)());
    const selectedPaymentOption = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetSelectedPaymentOption)());
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const isPaymentOptionUpdating = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetIsPaymentOptionUpdating)());
    const pricing = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPricingState)());
    const disableAmexPaymentCookie = (0, useABTest_1.useABTest)(common_types_1.ABTestCookie.DISABLE_AMEX_PAYMENT);
    const dispatch = (0, react_redux_1.useDispatch)();
    const [isSavePointCreated, setIsSavePointCreated] = (0, react_1.useState)(false);
    const [payerMessage, setPayerMessage] = (0, react_1.useState)();
    const fetchProducts = () => dispatch(BasketActions_creators_1.basketActions.createGetAction());
    const fetchPaymentInfo = () => dispatch(CheckoutActions_creators_1.default.createGetPaymentInfoAction());
    const onVoucherChanges = () => dispatch(CheckoutActions_creators_1.default.createVoucherChangesAction());
    const onPaymentInitAnalytics = (paymentOption) => dispatch(AnalyticsActions_creators_1.analyticsActions.paymentStep(paymentOption));
    const onVoucherAddSuccessAnalytics = (code) => dispatch(AnalyticsActions_creators_1.analyticsActions.voucherAdded(code));
    const onFormError = (error) => dispatch(AnalyticsActions_creators_1.analyticsActions.formsErrorMessage(error));
    const resetPaymentState = () => dispatch(CheckoutActions_creators_1.default.createResetPaymentStateAction());
    const changePaymentOption = (option, group) => {
        dispatch(CheckoutActions_creators_1.default.createSetPaymentOptionSuccessAction(false));
        dispatch(CheckoutActions_creators_1.default.createChangePaymentOptionAction({ option, group }));
    };
    const onSubmit = () => dispatch(CheckoutActions_creators_1.default.createSavePaymentOptionAction());
    const createSavePoint = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield BasketAPI_1.default.postSavePoint(servicesEndpoint);
            setIsSavePointCreated(true);
        }
        catch (_a) { }
    });
    const onVoucherAddSuccess = (code) => {
        onVoucherAddSuccessAnalytics(code);
        fetchProducts();
        fetchPaymentInfo();
        onVoucherChanges();
    };
    const loadSaferpayScript = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        try {
            yield ScriptLoaderUtil_1.default.loadScript(saferpayConfig.fields.scriptUrl, SaferpayUtil_1.SAFERPAY_SCRIPT_ID, true);
            dispatch(CheckoutActions_creators_1.default.createSetSaferpayFieldsReadyAction(true));
        }
        catch (_b) { }
    });
    const removeAmexPaymentOption = (groups) => {
        const creditCardGroup = groups.find(group => group.name === common_types_1.PaymentGroupName.CREDITCARD);
        if (creditCardGroup) {
            creditCardGroup.paymentOptions = creditCardGroup === null || creditCardGroup === void 0 ? void 0 : creditCardGroup.paymentOptions.filter(option => option.name !== PaymentGroupCreditCardContainer_1.AMEX_METHOD);
        }
        return groups;
    };
    const getPaymentGroups = (paymentGroups) => {
        const groups = paymentGroups.filter(group => [common_types_1.PaymentGroupName.APPLEPAY, common_types_1.PaymentGroupName.APPLEPAY_SAFERPAY].includes(group.name)
            ? ApplePayUtil_1.default.canMakePayments()
            : true);
        if (disableAmexPaymentCookie) {
            return removeAmexPaymentOption(groups);
        }
        return groups;
    };
    const onPaymentGroupsInit = (option, group) => {
        onPaymentInitAnalytics(option || '');
        if (option && group) {
            changePaymentOption(option, group);
        }
    };
    const getVoucherComponent = () => ((0, jsx_runtime_1.jsx)(BuyVoucher_1.BuyVoucher, { validationRegExp: paymentInfo.validateRegex, defaultRequestParams: defaultRequestParams, servicesEndpoint: servicesEndpoint, logo: logo, imageCdnPrefix: imageCdnPrefix, collapsedByDefault: voucherCodeCollapsed, onVoucherAddSuccess: onVoucherAddSuccess, onFormError: onFormError }));
    const getPaymentGroupsComponent = () => ((0, jsx_runtime_1.jsx)(PaymentGroups_1.PaymentGroups, { paymentGroups: getPaymentGroups(paymentInfo.paymentGroups), currency: currency, onPaymentGroupsInit: onPaymentGroupsInit, changePaymentOption: changePaymentOption, chosenPaymentOption: selectedPaymentOption.name, chosenPaymentOptionId: selectedPaymentOption.id, creditCardSavingLegalMessage: creditCardSavingLegalMessage, payPalInfoMessage: payPalInfoMessage, lockPaymentOptions: isPaymentOptionUpdating }));
    (0, react_1.useEffect)(() => {
        const messageQueryParam = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, 'payerMessage');
        setPayerMessage((0, common_utils_1.isEmptyString)(messageQueryParam) ? t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.form.error`) : messageQueryParam);
        loadSaferpayScript();
        return () => {
            resetPaymentState();
        };
    }, []);
    (0, react_1.useEffect)(() => {
        if (!isSavePointCreated && paymentInfo && !selectedPaymentOption.name) {
            createSavePoint();
        }
    }, [paymentInfo]);
    (0, react_1.useEffect)(() => {
        dispatch(AnalyticsActions_creators_1.analyticsActions.checkoutType(isLoggedIn ? AnalyticsEnums_1.CheckoutType.LOGGED_IN : AnalyticsEnums_1.CheckoutType.GUEST));
    }, []);
    if (!paymentInfo) {
        return null;
    }
    const showPendingPaymentMessage = Boolean(pricing.totalPendingPaymentAmount) && !paymentInfo.fullyPaid;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { desktop: { spacing: { paddingTop: 1 } }, children: [(Boolean(payerMessage) || showPendingPaymentMessage) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.MEDIUM, children: [Boolean(payerMessage) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: payerMessage, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE, id: "buy-form__saferpay-feedback" })), showPendingPaymentMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.basket.pending_payment.message`), variant: cotopaxi_1.FeedbackMessageVariant.INFO, dataQA: "pending_payment_message" }))] }) })), isShopLanding ? ((0, jsx_runtime_1.jsx)(ShopPaymentGroups_1.ShopPaymentGroups, { logo: logo, imageCdnPrefix: imageCdnPrefix })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [paymentInfo.paymentGroups && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [mainWebShop === common_types_1.MainWebShop.COTSWOLD ? getPaymentGroupsComponent() : getVoucherComponent(), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: mainWebShop === common_types_1.MainWebShop.COTSWOLD ? getVoucherComponent() : getPaymentGroupsComponent() })] })), paymentInfo.fullyPaid && (0, jsx_runtime_1.jsx)(PaidByVoucher_1.PaidByVoucher, { onSubmit: onSubmit })] }))] }));
};
exports.default = BuyPaymentContainer;
