"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const CustomerApi_1 = require("../api/customer/CustomerApi");
const ChangePassword_1 = require("../components/account/change-password/ChangePassword");
const GeneralConstants_1 = require("../constants/GeneralConstants");
const common_types_1 = require("@as-react/common-types");
const ChangePasswordContainer = ({ title }) => {
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const [errorFeedback, setErrorFeedback] = (0, react_1.useState)();
    const [buttonState, setButtonState] = (0, react_1.useState)(cotopaxi_1.ButtonState.INTERACTIVE);
    const onSubmit = (values_1, _a) => tslib_1.__awaiter(void 0, [values_1, _a], void 0, function* (values, { resetForm, setFieldError }) {
        var _b;
        try {
            yield CustomerApi_1.default.updatePassword(servicesEndpoint, defaultRequestParams, {
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
            });
            setButtonState(cotopaxi_1.ButtonState.FINISHED);
            setErrorFeedback(null);
            resetForm();
            setTimeout(() => setButtonState(cotopaxi_1.ButtonState.INTERACTIVE), GeneralConstants_1.DELAY.DELAY_3000);
        }
        catch (e) {
            if (((_b = e.response) === null || _b === void 0 ? void 0 : _b.status) === common_types_1.HTTPStatusCode.Unauthorized) {
                setFieldError('oldPassword', t('account.password.current.invalid'));
            }
            else {
                setErrorFeedback(t('account.fail'));
            }
        }
    });
    return (0, jsx_runtime_1.jsx)(ChangePassword_1.ChangePassword, { onSubmit: onSubmit, errorFeedback: errorFeedback, buttonState: buttonState, title: title });
};
exports.default = ChangePasswordContainer;
