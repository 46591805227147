"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreLocatorContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const StoreFinderPopup_1 = require("../components/product-detail/store-finder-popup/StoreFinderPopup");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const react_query_1 = require("@tanstack/react-query");
const StoreLocatorContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.store, i18nConstants_1.I18N_NAMESPACES.account]);
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const [isOpen, setIsOpen] = (0, react_1.useState)(false);
    const [locationSearchParams, setLocationSearchParams] = (0, react_1.useState)();
    const { getClickAndCollectStores } = (0, common_queries_1.storeQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const storesQueryResult = (0, react_query_1.useQuery)(getClickAndCollectStores({
        location: ((locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.locationSearch) || {}).location,
        inStore: true,
        allowMultiFascia: true,
        sortBy: (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.sortBy) || 'distance',
        selectedStoreId: (locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.locationSearch.type) === common_types_1.LocationSearchType.CUSTOM_SEARCH
            ? undefined
            : locationSearchParams === null || locationSearchParams === void 0 ? void 0 : locationSearchParams.selectedStoreId,
    }, {
        enabled: isOpen,
    }));
    const handleSearch = (searchParams) => {
        setLocationSearchParams(searchParams);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { children: [t(`${i18nConstants_1.I18N_NAMESPACES.account}:returnItems.storeLocator.button.prefix`), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: () => setIsOpen(true), variant: cotopaxi_1.ButtonVariant.LINK, text: t(`${i18nConstants_1.I18N_NAMESPACES.account}:returnItems.storeLocator.button`) })] }), (0, jsx_runtime_1.jsx)(StoreFinderPopup_1.StoreFinderPopup, { isOpen: isOpen, handleClosePopup: () => setIsOpen(false), type: common_types_1.StoreFinderType.LOCATOR, title: t(`${i18nConstants_1.I18N_NAMESPACES.store}:store.nearbystores.txt`), onSearch: handleSearch, storesQueryResult: storesQueryResult })] }));
};
exports.StoreLocatorContainer = StoreLocatorContainer;
