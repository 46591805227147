"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromotionMessage = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const andes_react_1 = require("@yonderland/andes-react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const react_query_1 = require("@tanstack/react-query");
const BasketActions_creators_1 = require("../../../../redux/basket/actions/BasketActions.creators");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const URLParamUtil_1 = require("../../../../util/URLParamUtil");
const EventUtil_1 = require("../../../../util/EventUtil");
const EventEnum_1 = require("../../../../enums/EventEnum");
const AdobeTargetConstants_1 = require("../../../../constants/AdobeTargetConstants");
const YonderlandMediator_1 = require("../../../../mediator/YonderlandMediator");
const YonderlandMediatorTypes_1 = require("../../../../mediator/types/YonderlandMediatorTypes");
const BasketConstants_1 = require("../../../../constants/BasketConstants");
const ComponentConfigContext_1 = require("../../../../context/ComponentConfigContext");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const PROMOTION_MESSAGE_DISCOUNT_VARIABLE = '{DISCOUNT}';
const PromotionMessage = () => {
    var _a, _b;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const { mainWebShopId, preSolden } = (0, ComponentConfigContext_1.useBasketOverviewConfig)();
    const { currency, defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const [promotionTotalDiscount, setPromotionTotalDiscount] = (0, react_1.useState)(0);
    const basketTotalAmount = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketTotalQuantityState)());
    const promotionProducts = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPromotionProductsState)());
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const isClient = (0, cotopaxi_1.useIsClient)();
    const promotion = (_a = promotionProducts.find(product => !!product.promotion)) === null || _a === void 0 ? void 0 : _a.promotion;
    const recommendedProduct = promotion === null || promotion === void 0 ? void 0 : promotion.recommendedProduct;
    const minimumBasketSize = (_b = promotion === null || promotion === void 0 ? void 0 : promotion.minimumBasketSize) !== null && _b !== void 0 ? _b : 0;
    const addVoucherParam = isClient ? (0, URLParamUtil_1.getQuerystringByName)(window.location.search, BasketConstants_1.ADD_VOUCHER_PARAM_KEY) : false;
    const promotionRequirementsFulfilled = basketTotalAmount >= minimumBasketSize;
    const preSoldenMessage = preSolden === null || preSolden === void 0 ? void 0 : preSolden.presoldenMessage;
    const queryClient = (0, react_query_1.useQueryClient)();
    const { getProductDetailPriceBySku } = (0, common_queries_1.productQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const onAddPromotionCalled = () => {
        (0, URLParamUtil_1.removeQuerystringParameter)(BasketConstants_1.ADD_VOUCHER_PARAM_KEY);
        YonderlandMediator_1.default.publish({
            event: AdobeTargetConstants_1.ADOBE_TARGET_PRESOLDEN,
            group: YonderlandMediatorTypes_1.YonderlandMediatorGroup.BASKET,
            data: {
                origin: 'BasketOverviewPromotionMessage',
                hasPreSoldenProduct: true,
                isPreSoldenFulfilled: true,
                hasMultipleProducts: true,
            },
        });
    };
    (0, react_1.useEffect)(() => {
        EventUtil_1.default.listen(EventEnum_1.EventEnum.BASKET_PROMOTION_CALLED, onAddPromotionCalled);
        return () => {
            EventUtil_1.default.remove(EventEnum_1.EventEnum.BASKET_PROMOTION_CALLED, onAddPromotionCalled);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        addVoucherParam && recommendedProduct && dispatch(BasketActions_creators_1.basketActions.createAddPromotionAction(recommendedProduct));
        if (promotionProducts.length <= 0 || promotionRequirementsFulfilled)
            return;
        const getAllDiscountPrices = (allProducts) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            return yield Promise.all(allProducts.map((product) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
                const productPrice = yield queryClient.fetchQuery(getProductDetailPriceBySku({
                    mainWebShopId,
                    productId: parseInt(product.productId, 10),
                    skuId: product.sku,
                }));
                return productPrice ? productPrice.productDiscountAmount * product.quantity : 0;
            })));
        });
        getAllDiscountPrices(promotionProducts).then(response => {
            setPromotionTotalDiscount(response.reduce((acc, price) => acc + price, 0));
        });
    }, [products, basketTotalAmount, promotionProducts, addVoucherParam]);
    if (addVoucherParam && !recommendedProduct && !promotionRequirementsFulfilled) {
        return (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "error", text: t('checkout.basket.promotion.error.voucher') });
    }
    if (!preSoldenMessage || !recommendedProduct || promotionRequirementsFulfilled)
        return null;
    const linkRegexp = /\[(.*)\]/;
    const linkMatch = new RegExp(linkRegexp).exec(preSoldenMessage);
    const link = linkMatch ? linkMatch[1] : '';
    const messageParts = preSoldenMessage
        .replace(PROMOTION_MESSAGE_DISCOUNT_VARIABLE, promotionTotalDiscount > 0 ? `${currency}${promotionTotalDiscount.toFixed(2)}` : '')
        .split(linkRegexp);
    const addPromotionToBasket = (e) => {
        e.preventDefault();
        dispatch(BasketActions_creators_1.basketActions.createAddPromotionAction(recommendedProduct));
    };
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "informative", text: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: messageParts.map((part, index) => ((0, jsx_runtime_1.jsx)(react_1.Fragment, { children: (part === link && (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { onClick: addPromotionToBasket, text: link })) || part }, `${part}_${index}`))) }) }));
};
exports.PromotionMessage = PromotionMessage;
