"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCustomerDetailsValidationSchema = exports.phoneFieldCountriesOrder = exports.CustomerDetailsFieldName = void 0;
const Yup = require("yup");
const addressFields_utils_1 = require("../address-fields/addressFields.utils");
const ArrayUtil_1 = require("../../../util/ArrayUtil");
const common_types_1 = require("@as-react/common-types");
var CustomerDetailsFieldName;
(function (CustomerDetailsFieldName) {
    CustomerDetailsFieldName["ID_TITLE"] = "idTitle";
    CustomerDetailsFieldName["MIDDLE_NAME"] = "middleName";
    CustomerDetailsFieldName["FIRST_NAME"] = "firstName";
    CustomerDetailsFieldName["LAST_NAME"] = "lastName";
    CustomerDetailsFieldName["ADDRESS"] = "address";
})(CustomerDetailsFieldName || (exports.CustomerDetailsFieldName = CustomerDetailsFieldName = {}));
exports.phoneFieldCountriesOrder = {
    nl: ['NL', 'BE', 'DE', '|'],
    gb: ['GB', 'IE', '|'],
    ie: ['IE', 'GB', '|'],
    lu: ['LU', '|'],
    fr: ['FR', '|'],
    be: ['BE', '|'],
};
const getCustomerDetailsValidationSchema = ({ t, prefix }) => {
    const [idTitle, firstName, lastName, middleName] = (0, ArrayUtil_1.mapWithAssert)([
        CustomerDetailsFieldName.ID_TITLE,
        CustomerDetailsFieldName.FIRST_NAME,
        CustomerDetailsFieldName.LAST_NAME,
        CustomerDetailsFieldName.MIDDLE_NAME,
    ], name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    const defaultRequiredMessage = t(common_types_1.ErrorMessage.ACCOUNT_REQUIRED);
    return Yup.object({
        [idTitle]: Yup.number().required(defaultRequiredMessage),
        [firstName]: Yup.string().required(defaultRequiredMessage),
        [lastName]: Yup.string().required(defaultRequiredMessage),
        [middleName]: Yup.string(),
    });
};
exports.getCustomerDetailsValidationSchema = getCustomerDetailsValidationSchema;
