"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const axios_1 = require("axios");
class ProductsAPI {
    constructor() {
        this.fetchProducts = (urlState, searchEndpoint, defaultListerSort, authenticationConfig, fredhopperSegment, isShopLanding, clearCache, aiSearch) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                url: `${searchEndpoint}${this.generateFilterQueryString(urlState.filter, urlState.defaultFilter)}`,
                method: 'GET',
            };
            axiosConfig.params = Object.assign(Object.assign(Object.assign(Object.assign({}, axiosConfig.params), { anaLang: authenticationConfig.anaLang, fictiveWebShop: authenticationConfig.fictiveWebShop, locale: authenticationConfig.locale, market: authenticationConfig.market, mainWebShop: authenticationConfig.mainWebshop, platform: isShopLanding
                    ? common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.shoplanding
                    : common_types_1.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM_OPTIONS.public_site, query: urlState.q }), urlState), { segment: fredhopperSegment, aiSearch });
            if (urlState.sort === defaultListerSort) {
                delete axiosConfig.params.sort;
            }
            if (clearCache) {
                axiosConfig.params.clearCache = clearCache;
            }
            const defaultFilter = axiosConfig.params.defaultFilter;
            delete axiosConfig.params.filter; // avoid placing the parameter twice
            delete axiosConfig.params.defaultFilter;
            delete axiosConfig.params.q;
            const result = yield axios_1.default.request(axiosConfig);
            if (result.data) {
                return (0, common_utils_1.handleProductSearchObject)(result.data, defaultFilter);
            }
            return null;
        });
        this.getProductVideos = (productId, apiEndpoint, defaultRequestParams) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url: `${apiEndpoint}api/aem/product/video/${productId}`,
                params: defaultRequestParams,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.getLinkedProducts = (productId_1, productColourId_1, linkType_1, mainWebShopId_1, apiEndpoint_1, _a) => tslib_1.__awaiter(this, [productId_1, productColourId_1, linkType_1, mainWebShopId_1, apiEndpoint_1, _a], void 0, function* (productId, productColourId, linkType, mainWebShopId, apiEndpoint, { market, shopId }) {
            const axiosConfig = {
                method: 'GET',
                url: `${apiEndpoint}api/product/links/${market}/${mainWebShopId}/${productId}/${shopId}/${productColourId}`,
                params: { linkType },
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.notifyMeSubscribe = (apiEndpoint_2, _b, productId_2, skuId_1, email_1) => tslib_1.__awaiter(this, [apiEndpoint_2, _b, productId_2, skuId_1, email_1], void 0, function* (apiEndpoint, { mainWebshop, shopId, language }, productId, skuId, email) {
            const axiosConfig = {
                method: 'POST',
                url: `${apiEndpoint}api/notifyme/request`,
                params: { mainWebshop, shopId, language },
                data: { productId, skuId, email },
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.generateFilterQueryString = (urlFilters, defaultFilter) => {
            let filter = '?';
            let filterParams = [];
            if (!(0, common_utils_1.isNullOrUndefined)(defaultFilter) && defaultFilter !== '') {
                filterParams.push(`filter=${defaultFilter}`);
            }
            if (!(0, common_utils_1.isNullOrUndefined)(urlFilters)) {
                const mappedUrlFilters = urlFilters.map((urlFilter) => `filter=${urlFilter.filterOn}:${encodeURIComponent(urlFilter.filterValue)}`);
                filterParams = [...filterParams, ...mappedUrlFilters];
            }
            filter = filter.concat(filterParams.join('&'));
            return filter;
        };
    }
    getProductInformationAPI(productServiceApi, defaultRequestParams, productCode, colorId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const request = {
                method: 'GET',
                params: Object.assign(Object.assign({}, defaultRequestParams), { productCode, colorId }),
                url: `${productServiceApi}api/aem/product/productcode?`,
                withCredentials: true,
            };
            try {
                const result = yield (0, axios_1.default)(request);
                return result.data;
            }
            catch (_a) { }
        });
    }
    getProductComparisonFeaturesAPI(productServiceApi, market, mainWebShopId, productId, language) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const request = {
                method: 'GET',
                url: `${productServiceApi}api/aem/product/features/comparison/${market}/${mainWebShopId}/${productId}?language=${language}`,
                withCredentials: true,
            };
            try {
                const result = yield (0, axios_1.default)(request);
                return result.data;
            }
            catch (_a) {
                return null;
            }
        });
    }
    getAllProductComparisonFeatures(productIds, servicesEndpoint, market, mainWebShopId, language) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const productPromises = productIds.map(productId => this.getProductComparisonFeaturesAPI(servicesEndpoint, market, mainWebShopId, productId, language));
            const result = yield axios_1.default.all(productPromises);
            return result || [];
        });
    }
    getAllProductInformation(products, servicesEndpoint, defaultRequestParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const productPromises = products.map((product) => this.getProductInformationAPI(servicesEndpoint, defaultRequestParams, product.productCode, product.colorId));
            const productsWithInfo = yield axios_1.default.all(productPromises);
            return productsWithInfo.filter(product => product && product.productCode);
        });
    }
    getProductByProductCode(productServiceApi, mainWebShop, productCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const request = {
                method: 'GET',
                url: `${productServiceApi}api/aem/product/${mainWebShop}/${productCode}`,
                withCredentials: true,
            };
            try {
                const result = yield (0, axios_1.default)(request);
                return result.data;
            }
            catch (_a) {
                return null;
            }
        });
    }
    getItemCount(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'GET',
                url,
                withCredentials: true,
            };
            try {
                const result = yield axios_1.default.request(axiosConfig);
                return result.data.itemCount;
            }
            catch (_a) { }
        });
    }
    getSingleProductData(productServiceApi, mainWebShop, anaLang, language, fictiveWebShop, productCode, colorId, fallbackProducts, alwaysReturn) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            let requestUrl = `${productServiceApi}api/aem/product/productcode/fallback/${mainWebShop}/${productCode}${colorId ? '/' + colorId : ''}?anaLang=${anaLang}&language=${language}&fictiveWebShop=${fictiveWebShop}`;
            if (!!fallbackProducts && fallbackProducts.length > 0) {
                fallbackProducts.forEach(fallbackProduct => {
                    const productKey = `${fallbackProduct.productCode}${fallbackProduct.productColor ? `_${fallbackProduct.productColor}` : ''}`;
                    requestUrl = `${requestUrl}&fallbackProducts=${productKey}`;
                });
            }
            if (alwaysReturn) {
                requestUrl = `${requestUrl}&alwaysReturn=true`;
            }
            const request = {
                method: 'GET',
                url: requestUrl,
                withCredentials: true,
            };
            try {
                const result = yield (0, axios_1.default)(request);
                if ((fallbackProducts === null || fallbackProducts === void 0 ? void 0 : fallbackProducts.length) && productCode.toLowerCase() !== ((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.productCode) === null || _b === void 0 ? void 0 : _b.toLowerCase())) {
                    result.data.isFallbackProduct = true;
                }
                return result.data;
            }
            catch (_c) {
                return null;
            }
        });
    }
    getTotalPriceProducts(products_1, apiEndpoint_1, _a, mainWebshopId_1) {
        return tslib_1.__awaiter(this, arguments, void 0, function* (products, apiEndpoint, { market, shopId }, mainWebshopId) {
            const axiosConfig = {
                data: {
                    productSizeIds: products,
                },
                method: 'POST',
                url: `${apiEndpoint}api/product/sum/${market}/${mainWebshopId}/${shopId}`,
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new ProductsAPI();
