"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountProfileDetailsTabPanes = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const ProfileDetails_1 = require("../../components/account/profile-details/ProfileDetails");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const CookieConsentSelector_1 = require("../../redux/cookieConsent/selectors/CookieConsentSelector");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const ConnectedAccounts_1 = require("../../components/connected-accounts/ConnectedAccounts");
const RouterSelector_1 = require("../../redux/router/selectors/RouterSelector");
var AccountProfileDetailsTabPanes;
(function (AccountProfileDetailsTabPanes) {
    AccountProfileDetailsTabPanes[AccountProfileDetailsTabPanes["PROFILE_DETAILS"] = 0] = "PROFILE_DETAILS";
    AccountProfileDetailsTabPanes[AccountProfileDetailsTabPanes["CONNECTED_ACCOUNTS"] = 1] = "CONNECTED_ACCOUNTS";
})(AccountProfileDetailsTabPanes || (exports.AccountProfileDetailsTabPanes = AccountProfileDetailsTabPanes = {}));
const AccountProfileDetailsContainer = props => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const { socialEnabledList } = (0, common_components_1.useAemConfig)();
    const cookieConsentSettingAvailable = (0, react_redux_1.useSelector)((0, CookieConsentSelector_1.makeGetCookieConsentSettingAvailable)());
    const pathname = (0, react_redux_1.useSelector)((0, RouterSelector_1.makeGetPathname)());
    const [hasSocial, setHasSocial] = (0, react_1.useState)(false);
    const [activeTab, setActiveTab] = (0, react_1.useState)(AccountProfileDetailsTabPanes.PROFILE_DETAILS);
    (0, react_1.useEffect)(() => {
        const socialCookieEnabled = cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE ||
            common_utils_1.CookieUtil.isCookieLevelAcceptAll();
        setHasSocial(socialCookieEnabled && socialEnabledList.includes(common_types_1.AemConfigurationThirdpartySocialLoginProvider.FACEBOOK));
    }, []);
    const handleTabsChange = (_, newActiveTab) => {
        history.replaceState({}, window.document.title, pathname);
        setActiveTab(newActiveTab);
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobileBleed: true, mobile: { spacing: { marginBottom: 5 } }, tablet: { spacing: { marginBottom: 6 } }, desktop: { spacing: { marginBottom: 7 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: cotopaxi_1.MediaQuery.DESKTOP, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.EXTRA_TITLE, children: t('profileDetails.heading') }) }) }), hasSocial ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3 } }, desktop: { spacing: { marginBottom: 4 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Tabs, { activeTab: activeTab, onChange: handleTabsChange, hideIconMobile: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Tab, { activeTab: activeTab, index: AccountProfileDetailsTabPanes.PROFILE_DETAILS, iconName: cotopaxi_1.IconName.USER_SOLID, label: t('profileDetails.tab.details') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Tab, { activeTab: activeTab, index: AccountProfileDetailsTabPanes.CONNECTED_ACCOUNTS, iconName: cotopaxi_1.IconName.FACEBOOK_BOX, label: t('profileDetails.tab.accounts') })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.TabPanel, { activePanel: activeTab, index: AccountProfileDetailsTabPanes.PROFILE_DETAILS, children: (0, jsx_runtime_1.jsx)(ProfileDetails_1.ProfileDetails, Object.assign({}, props)) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.TabPanel, { activePanel: activeTab, index: AccountProfileDetailsTabPanes.CONNECTED_ACCOUNTS, children: (0, jsx_runtime_1.jsx)(ConnectedAccounts_1.ConnectedAccounts, {}) })] })) : ((0, jsx_runtime_1.jsx)(ProfileDetails_1.ProfileDetails, Object.assign({}, props)))] }));
};
exports.default = AccountProfileDetailsContainer;
