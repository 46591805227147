"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutLoginContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const common_types_1 = require("@as-react/common-types");
const Icons = require("@yonderland/andes-icons");
const react_1 = require("react");
const react_router_1 = require("react-router");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const AuthActions_creators_1 = require("../../redux/authentication/actions/AuthActions.creators");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const CheckoutActions_creators_1 = require("../../redux/checkout/actions/CheckoutActions.creators");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const i18nConstants_1 = require("../../constants/i18nConstants");
const NavigationUtil_1 = require("../../util/NavigationUtil");
const EmailRecognitionForm_1 = require("../../components/checkout/login/forms/EmailRecognitionForm");
const LoginForm_1 = require("../../components/checkout/login/forms/LoginForm");
const RegistrationForm_1 = require("../../components/checkout/login/forms/RegistrationForm");
const ForgotPasswordForm_1 = require("../../components/checkout/login/forms/ForgotPasswordForm");
const SetPasswordForm_1 = require("../../components/checkout/login/forms/SetPasswordForm");
const PersonalDetails_1 = require("../../components/checkout/personal-details/PersonalDetails");
const CheckoutLayoutContainer_1 = require("./CheckoutLayoutContainer");
const CheckoutStepContext_1 = require("../../context/CheckoutStepContext");
var Steps;
(function (Steps) {
    Steps[Steps["EMAIL_RECOGNITION"] = 0] = "EMAIL_RECOGNITION";
    Steps[Steps["LOGIN"] = 1] = "LOGIN";
    Steps[Steps["REGISTER"] = 2] = "REGISTER";
    Steps[Steps["PASSWORD_FORGOTTEN"] = 3] = "PASSWORD_FORGOTTEN";
    Steps[Steps["PERSONAL_DETAILS"] = 4] = "PERSONAL_DETAILS";
    Steps[Steps["SET_PASSWORD"] = 5] = "SET_PASSWORD";
})(Steps || (Steps = {}));
const LoginLayout = ({ children }) => ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { marginInline: { xs: '5' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { contentAlign: "center", children: (0, jsx_runtime_1.jsx)("div", { style: { maxWidth: '1336px', flex: 1 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid, { columns: 12, gapX: 2, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { xs: 12, md: 8, lg: 6 }, offset: { md: 3, lg: 4 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: { xs: '14', md: '12' }, paddingBlockEnd: "20", children: children }) }) }) }) }) }));
const CheckoutLoginContainer = () => {
    const { isShopLanding, root, isInAuthorMode, basketOverviewUrl } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const [step, setStep] = (0, react_1.useState)(Steps.EMAIL_RECOGNITION);
    const history = (0, react_router_1.useHistory)();
    const dispatch = (0, react_redux_1.useDispatch)();
    // TODO fastcheckout: should be uncommented after the fast checkout redesign is done
    // const fastCheckout = useSelector(makeGetFastCheckoutState());
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const handleBackClick = () => {
        switch (step) {
            case Steps.EMAIL_RECOGNITION:
            case Steps.LOGIN:
                window.location.href = basketOverviewUrl;
                break;
            case Steps.REGISTER:
            case Steps.SET_PASSWORD:
                setStep(Steps.EMAIL_RECOGNITION);
                break;
            case Steps.PASSWORD_FORGOTTEN:
                setStep(Steps.LOGIN);
                break;
            default:
                window.location.href = (0, NavigationUtil_1.getHomepageUrl)(root);
                break;
        }
    };
    const handleEmailRecognitionFinish = (existsResponse) => {
        if (existsResponse &&
            [
                common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA,
                common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA,
                common_types_1.AuthStatusCode.INVALID_ACCOUNT,
            ].includes(existsResponse.accountStatusCode)) {
            setStep(Steps.SET_PASSWORD);
        }
        else if (existsResponse === null || existsResponse === void 0 ? void 0 : existsResponse.hasIdentity) {
            setStep(Steps.LOGIN);
        }
        else {
            setStep(Steps.REGISTER);
        }
    };
    const handleForgotPasswordClick = () => setStep(Steps.PASSWORD_FORGOTTEN);
    const handleRegistrationSubmit = () => setStep(Steps.PERSONAL_DETAILS);
    const handleEditEmailClick = () => setStep(Steps.EMAIL_RECOGNITION);
    (0, react_1.useEffect)(() => {
        dispatch(BasketActions_creators_1.basketActions.createGetAction());
        dispatch(AuthActions_creators_1.authActions.createInitSocialAction());
        dispatch(AnalyticsActions_creators_1.analyticsActions.checkoutStep({ step: 2 }));
        dispatch(CheckoutActions_creators_1.default.createLoginAnalyticsAction());
        dispatch(CheckoutActions_creators_1.default.createSetHasRegistrationAction(false));
    }, []);
    (0, react_1.useEffect)(() => {
        // TODO fastcheckout: should be uncommented after the fast checkout redesign is done
        // if (fastCheckout) {
        //   history.replace(generatePathUrl(root, `buy.html/${RoutePathname.FAST_CHECKOUT}`, isInAuthorMode));
        // } else
        if (loggedIn) {
            history.replace((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.DELIVERY}`, isInAuthorMode));
        }
    }, [
        loggedIn,
        // TODO fastcheckout: should be uncommented after the fast checkout redesign is done
        //fastCheckout
    ]);
    if (isShopLanding) {
        (0, jsx_runtime_1.jsx)(LoginLayout, { children: "shoplanding login" });
    }
    if (step === Steps.PERSONAL_DETAILS) {
        return ((0, jsx_runtime_1.jsx)(CheckoutLayoutContainer_1.CheckoutLayoutContainer, { step: CheckoutStepContext_1.CheckoutStep.DETAILS, children: (0, jsx_runtime_1.jsx)(PersonalDetails_1.PersonalDetails, {}) }));
    }
    return ((0, jsx_runtime_1.jsx)(LoginLayout, { children: (0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "10", children: (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { onClick: handleBackClick, theme: "branded", text: t('checkout.back'), iconLeft: { source: Icons.ArrowLeftIcon, size: 'sm' } }) }), step === Steps.EMAIL_RECOGNITION && (0, jsx_runtime_1.jsx)(EmailRecognitionForm_1.EmailRecognitionForm, { onFinish: handleEmailRecognitionFinish }), step === Steps.LOGIN && ((0, jsx_runtime_1.jsx)(LoginForm_1.LoginForm, { onEditEmailClick: handleEditEmailClick, onForgotPasswordClick: handleForgotPasswordClick })), step === Steps.REGISTER && ((0, jsx_runtime_1.jsx)(RegistrationForm_1.RegistrationForm, { onEditEmailClick: handleEditEmailClick, onSubmit: handleRegistrationSubmit })), step === Steps.PASSWORD_FORGOTTEN && (0, jsx_runtime_1.jsx)(ForgotPasswordForm_1.ForgotPasswordForm, { onBackButtonClick: handleBackClick }), step === Steps.SET_PASSWORD && (0, jsx_runtime_1.jsx)(SetPasswordForm_1.SetPasswordForm, { onEditEmailClick: handleEditEmailClick })] }) }));
};
exports.CheckoutLoginContainer = CheckoutLoginContainer;
