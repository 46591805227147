"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const DpePopupContainer_1 = require("../../../containers/DpePopupContainer");
const DpeApi_1 = require("../../../api/dpe/DpeApi");
const cotopaxi_1 = require("@as/cotopaxi");
const DpeActivator = ({ authFormStep, digitalPartner, logoUrl, displayAs, notAuthLabel, authLabel, isAuthenticated, }) => {
    const { servicesApiPrefixAemServices, DPEPath } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.dpe]);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const [data, setData] = (0, react_1.useState)();
    const getDpeData = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const dpeData = yield DpeApi_1.default.getPartner(servicesApiPrefixAemServices, digitalPartner, DPEPath);
        setData(dpeData);
    });
    const getPopoverTitle = () => {
        switch (authFormStep) {
            case common_types_1.AuthFormStep.REGISTRATION:
                return isAuthenticated ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: data === null || data === void 0 ? void 0 : data.tileData.logo, size: cotopaxi_1.ImageSize.DPE_LOGO_MEDIUM, alt: digitalPartner })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: data === null || data === void 0 ? void 0 : data.authData.logo, size: cotopaxi_1.ImageSize.DPE_LOGO_TITLE, alt: digitalPartner }));
            case common_types_1.AuthFormStep.PASSWORD_FORGOTTEN:
                return t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.login.reset.password`);
            case common_types_1.AuthFormStep.LOGIN:
            case common_types_1.AuthFormStep.EMAIL:
            default:
                return (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: data === null || data === void 0 ? void 0 : data.tileData.logo, size: cotopaxi_1.ImageSize.DPE_LOGO_MEDIUM, alt: digitalPartner });
        }
    };
    const renderLabel = () => {
        switch (displayAs) {
            case common_types_1.DpeDisplayType.LABEL:
                return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.XSMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: logoUrl, alt: digitalPartner, size: cotopaxi_1.ImageSize.DPE_LOGO_SMALL }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: true, size: cotopaxi_1.TextSize.SMALL, children: t(`${i18nConstants_1.I18N_NAMESPACES.dpe}:dpe.member`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { onClick: handlePopoverOpen, variant: cotopaxi_1.ButtonVariant.LINK, text: isAuthenticated ? authLabel : notAuthLabel, size: cotopaxi_1.ButtonSize.SMALL }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: t(`${i18nConstants_1.I18N_NAMESPACES.dpe}:dpe.redeemDiscount`) })] }));
            case common_types_1.DpeDisplayType.BUTTON:
            default:
                return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.BASE, text: isAuthenticated ? authLabel : notAuthLabel, onClick: handlePopoverOpen }));
        }
    };
    (0, react_1.useEffect)(() => {
        getDpeData();
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [renderLabel(), (0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { disableClickOutside: true, type: cotopaxi_1.PopoverType.MODAL, anchorEl: anchorEl, onClose: handlePopoverClose, title: getPopoverTitle(), children: data && ((0, jsx_runtime_1.jsx)(DpePopupContainer_1.default, { data: data, digitalPartner: digitalPartner, onCloseClick: handlePopoverClose, isAuthenticated: isAuthenticated })) })] }));
};
exports.default = DpeActivator;
