"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const ProductTileBasketOverview_1 = require("../../product-tile/basket-overview/ProductTileBasketOverview");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const BasketOverviewItem = ({ product, isDisabled, storeName }) => {
    var _a;
    const errorRef = (0, react_1.useRef)(null);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.navigation, i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.product]);
    const { market, mainWebShop, isShopLanding, feature_toggles: { ecocheques_cart_item_message }, } = (0, common_components_1.useAemConfig)();
    const getMarket = () => (market === 'uk' ? 'uk' : mainWebShop);
    const showEcochequesMessage = ecocheques_cart_item_message && ((_a = product.features) === null || _a === void 0 ? void 0 : _a.includes('ecocheques_accepted'));
    const isDesktop = (0, andes_react_1.useMediaQueryMatchesOnDesktop)();
    (0, react_1.useEffect)(() => {
        if (product.errorState === common_types_1.BasketCartItemError.ADD_FAILED && (errorRef === null || errorRef === void 0 ? void 0 : errorRef.current)) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(errorRef.current, isDesktop);
        }
    }, [product.errorState]);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "3", dataTestId: `basket-product-tile-${product.productId}`, children: [product.errorState === common_types_1.BasketCartItemError.ADD_FAILED && ((0, jsx_runtime_1.jsx)("div", { ref: errorRef, children: (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "error", text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:basket.addProductFailed`) }) })), isDisabled && ((0, jsx_runtime_1.jsx)("div", { ref: errorRef, children: (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "error", tooltip: {
                        hasCloseBtn: false,
                        text: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.${getMarket()}.info`),
                    }, text: storeName
                        ? t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.${getMarket()}.error`, {
                            storeName,
                        })
                        : t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.store.source.excluded.error`, {
                            storeName,
                        }) }) })), (0, jsx_runtime_1.jsx)(ProductTileBasketOverview_1.ProductTileBasketOverview, { product: product }, product.productCode), showEcochequesMessage && ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { hideIcon: true, status: "sustainability", dataTestId: "ecocheque_accepted_message", text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:basket.feature.ecocheques_accepted.message`), tooltip: isShopLanding
                    ? undefined
                    : {
                        text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:basket.feature.ecocheques_accepted.tooltip`),
                        strategy: 'fixed',
                        hasCloseBtn: false,
                    } }))] }));
};
exports.BasketOverviewItem = BasketOverviewItem;
