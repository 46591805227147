"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryStringParams = void 0;
const react_1 = require("react");
function useQueryStringParams(key) {
    const [param, setParam] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        const params = new URLSearchParams(window.location.search);
        setParam(params.get(key) || '');
    }, []);
    return param;
}
exports.useQueryStringParams = useQueryStringParams;
