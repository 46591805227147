"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailRecognitionStep = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const EmailRecognitionForm_1 = require("../forms/EmailRecognitionForm");
const common_components_1 = require("@as-react/common-components");
const CustomerApi_1 = require("../../../../../api/customer/CustomerApi");
const react_redux_1 = require("react-redux");
const AuthActions_creators_1 = require("../../../../../redux/authentication/actions/AuthActions.creators");
const EmailRecognitionStep = ({ onChangeStep, initialEmail, flowType, }) => {
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const [loading, setLoading] = (0, react_1.useState)(false);
    const dispatch = (0, react_redux_1.useDispatch)();
    const handleUnknownEmailSubmit = (email) => {
        flowType === 'without-personal-details'
            ? dispatch(AuthActions_creators_1.authActions.createLoginGuestAction(email))
            : onChangeStep(email, false);
    };
    const handleSubmit = (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ email }) {
        var _b, _c;
        setLoading(true);
        try {
            const { accountStatusCode } = yield CustomerApi_1.default.emailExists(servicesEndpoint, defaultRequestParams, email, false);
            if ([common_types_1.AuthStatusCode.NO_ACCOUNT, common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT].includes(accountStatusCode)) {
                handleUnknownEmailSubmit(email);
            }
            else {
                onChangeStep(email, true);
            }
        }
        catch (err) {
            if (((_c = (_b = err === null || err === void 0 ? void 0 : err.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.accountStatusCode) ===
                common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT) {
                handleUnknownEmailSubmit(email);
            }
        }
    });
    return (0, jsx_runtime_1.jsx)(EmailRecognitionForm_1.EmailRecognitionForm, { onSubmit: handleSubmit, initialEmail: initialEmail, loading: loading });
};
exports.EmailRecognitionStep = EmailRecognitionStep;
