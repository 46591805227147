"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const SaferpayUtil_1 = require("../../util/SaferpayUtil");
const CheckoutSelector_1 = require("../../redux/checkout/selectors/CheckoutSelector");
const BasketSelector_1 = require("../../redux/basket/selectors/BasketSelector");
const BuyAPI_1 = require("../../api/buy/BuyAPI");
const BancontactForm_1 = require("../../components/buy/payment/payment-groups/BancontactForm");
const asNumeral_1 = require("../../vendors/asNumeral");
const EventEnum_1 = require("../../enums/EventEnum");
const EventUtil_1 = require("../../util/EventUtil");
const BuyForm_1 = require("../../components/buy/forms/BuyForm");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const MAX_BASKET_TOTAL_FOR_APP = 500;
const BANCONTACT_PAYMENT_METHODS = ['bancontact'];
const ANALYTICS_PAYMENT_METHOD_QR = 'Bancontact QR Saferpay';
let timers = [];
const PaymentGroupBancontactContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const dispatch = (0, react_redux_1.useDispatch)();
    const { defaultRequestParams, servicesEndpoint, currency, saferpayConfig } = (0, common_components_1.useAemConfig)();
    const { priceTotalBasket } = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketPricingState)());
    const saferpayFieldsReady = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetSaferpayFieldsReady)());
    const isSetPaymentOptionSuccess = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetIsSetPaymentOptionSuccess)());
    const [isTimerRun, setIsTimerRun] = (0, react_1.useState)(false);
    const [hasAppOptions, setHasAppOptions] = (0, react_1.useState)(false);
    const [appOptionsState, setAppOptionsState] = (0, react_1.useState)(common_types_1.SaferpayFieldsBancontactAppOptionsState.LOADING);
    const [inited, setInited] = (0, react_1.useState)(false);
    const [qrCodeImage, setQrCodeImage] = (0, react_1.useState)('');
    const [intentUrl, setIntentUrl] = (0, react_1.useState)('');
    const [validation, setValidation] = (0, react_1.useState)();
    const [isSubmitting, setIsSubmitting] = (0, react_1.useState)(false);
    const [showError, setShowError] = (0, react_1.useState)(false);
    const endTimers = () => {
        timers.forEach(timer => timer.end());
        timers = [];
    };
    const startAppPaymentTimer = (expiration) => {
        const expirationDate = new Date(expiration).getTime();
        const paymentTimer = new common_utils_1.Poller(() => {
            const currentTime = Date.now();
            if (currentTime > expirationDate) {
                return true;
            }
            return null;
        }, GeneralConstants_1.DELAY.DELAY_1000);
        paymentTimer.run().then(() => {
            if (paymentTimer && paymentTimer.active) {
                setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.DISABLED);
            }
        });
        timers.push(paymentTimer);
        setIsTimerRun(true);
    };
    const startPollingForAppPayment = (requestId) => {
        const paymentPoller = new common_utils_1.Poller(() => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            const result = yield BuyAPI_1.default.getSaferpayAltStatus(servicesEndpoint, defaultRequestParams, requestId);
            if ((result === null || result === void 0 ? void 0 : result.status) &&
                (result.status === common_types_1.SaferpayAltStatusResult.CAPTURED || result.status === common_types_1.SaferpayAltStatusResult.FAILURE)) {
                dispatch(AnalyticsActions_creators_1.analyticsActions.paymentRequest({
                    paymentGroup: common_types_1.PaymentGroupName.BANCONTACT_SAFERPAY,
                    paymentMethod: ANALYTICS_PAYMENT_METHOD_QR,
                }));
                return result.redirectUrl;
            }
            return null;
        }), GeneralConstants_1.DELAY.DELAY_3000);
        paymentPoller
            .run()
            .then((redirectUrl) => {
            if (redirectUrl) {
                location.href = redirectUrl;
            }
        })
            .catch(({ message }) => {
            // eslint-disable-next-line no-console
            console.error(`Saferpay-fields polling error ${message}`);
        });
        timers.push(paymentPoller);
        setIsTimerRun(true);
    };
    const checkSaferpayStatusAndInit = () => {
        try {
            window.SaferpayFields.init({
                accessToken: saferpayConfig.fields.accessToken,
                url: saferpayConfig.fields.apiUrl,
                paymentMethods: BANCONTACT_PAYMENT_METHODS,
                placeholders: {
                    cardnumber: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.CARDNUMBER}.placeholder`),
                    holdername: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.HOLDERNAME}.placeholder`),
                    expiration: t(`buy.payment.saferpay.field.${common_types_1.SaferpayFieldTypeEnum.EXPIRATION}.placeholder`),
                    cvc: '',
                },
                style: SaferpayUtil_1.saferpayFieldStyles,
                onError: ({ message }) => {
                    setShowError(true);
                },
                onValidated: (e) => setValidation(prev => (Object.assign(Object.assign({}, prev), { [e.fieldType]: e }))),
            });
        }
        catch (e) {
            setShowError(true);
        }
    };
    const initBancontactPayment = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        endTimers();
        setQrCodeImage('');
        setIntentUrl('');
        setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.LOADING);
        try {
            const result = yield BuyAPI_1.default.postSaferpayAlt(servicesEndpoint, defaultRequestParams);
            endTimers();
            startAppPaymentTimer(result.expiration);
            startPollingForAppPayment(result.requestId);
            setQrCodeImage(result.qrCodeImage);
            setIntentUrl(result.intentUrl);
            setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.ENABLED);
        }
        catch (_a) {
            setAppOptionsState(common_types_1.SaferpayFieldsBancontactAppOptionsState.DISABLED);
        }
    });
    const submitButtonState = () => !isSetPaymentOptionSuccess ? cotopaxi_1.ButtonState.DISABLED : isSubmitting ? cotopaxi_1.ButtonState.LOADING : undefined;
    const onSubmit = () => {
        setIsSubmitting(true);
        setShowError(false);
        dispatch(AnalyticsActions_creators_1.analyticsActions.paymentRequest({
            paymentGroup: common_types_1.PaymentGroupName.BANCONTACT_SAFERPAY,
        }));
        window.SaferpayFields.submit({
            onSuccess: (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ token }) {
                try {
                    const { redirectUrl } = yield BuyAPI_1.default.postSaferpayToken(servicesEndpoint, defaultRequestParams, token, false);
                    location.href = redirectUrl;
                }
                catch (err) {
                    setIsSubmitting(false);
                    setShowError(true);
                }
            }),
            onError: ({ message }) => {
                setIsSubmitting(false);
            },
        });
    };
    const onVoucherChange = () => {
        isSetPaymentOptionSuccess && inited && hasAppOptions && initBancontactPayment();
    };
    (0, react_1.useEffect)(() => {
        if (isSetPaymentOptionSuccess && !inited && hasAppOptions) {
            setInited(true);
            initBancontactPayment();
        }
        else {
            endTimers();
        }
        EventUtil_1.default.listen(EventEnum_1.EventEnum.VOUCHER_CHANGED, onVoucherChange);
        return () => {
            EventUtil_1.default.remove(EventEnum_1.EventEnum.VOUCHER_CHANGED, onVoucherChange);
        };
    }, [isSetPaymentOptionSuccess, inited, hasAppOptions]);
    (0, react_1.useEffect)(() => {
        if (saferpayFieldsReady && isSetPaymentOptionSuccess) {
            checkSaferpayStatusAndInit();
        }
    }, [saferpayFieldsReady, isSetPaymentOptionSuccess]);
    (0, react_1.useEffect)(() => {
        return () => {
            isTimerRun && endTimers();
        };
    }, [isTimerRun]);
    (0, react_1.useEffect)(() => {
        setHasAppOptions(priceTotalBasket <= MAX_BASKET_TOTAL_FOR_APP);
    }, [priceTotalBasket]);
    return ((0, jsx_runtime_1.jsx)(BuyForm_1.BuyForm, { onSubmit: onSubmit, buttonState: submitButtonState(), checkoutStep: common_types_1.RoutePathname.PAYMENT, errorFeedback: showError ? t('buy.payment.form.error') : null, children: (0, jsx_runtime_1.jsx)(BancontactForm_1.default, { appOptionsState: appOptionsState, currency: currency, hasAppOptions: hasAppOptions, intentUrl: intentUrl, paymentMethods: BANCONTACT_PAYMENT_METHODS, priceTotalBasket: asNumeral_1.AsNumeral.getInstance().formatAsCurrency(priceTotalBasket), qrCodeImage: qrCodeImage, validation: validation }) }));
};
exports.default = PaymentGroupBancontactContainer;
