"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavedCreditCardForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_dom_1 = require("react-dom");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../../constants/i18nConstants");
const CreditCardSelectContent_1 = require("./CreditCardSelectContent");
const SavedCreditCardForm = ({ selectedCard, cards, onSubmit, isSubmitDisabled, isSubmitting, hasSubmitError, onSelectedCardChange, onEditClick, newCreditCardFormProps, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.buy]);
    const [isEditEnabled, setIsEditEnabled] = (0, react_1.useState)(false);
    const [isAddNewCardSelected, setIsAddNewCardSelected] = (0, react_1.useState)(false);
    const handleBottomSheetClose = () => {
        setIsEditEnabled(false);
        onSelectedCardChange(null, false);
    };
    const handleCreditCardChange = (card, isAddNewCardSelected) => {
        onSelectedCardChange(card, isAddNewCardSelected);
        setIsAddNewCardSelected(isAddNewCardSelected);
    };
    const handleEditClick = () => {
        setIsEditEnabled(true);
        onEditClick();
    };
    const SubmitButton = () => ((0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { dataTestId: "credit-card-submit", fullWidth: true, state: isSubmitDisabled ? 'disabled' : isSubmitting ? 'loading' : 'interactive', theme: "branded", text: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.payment.submit`), onClick: onSubmit }));
    (0, cotopaxi_1.useLockBodyScroll)(isEditEnabled);
    (0, react_1.useEffect)(() => {
        hasSubmitError && handleBottomSheetClose();
    }, [hasSubmitError]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { padding: "4", backgroundColor: "quiet", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "4", contentAlign: "between", wrap: "nowrap", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: selectedCard.cardNumber }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: selectedCard.cardName }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: selectedCard.brand })] }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t(`${i18nConstants_1.I18N_NAMESPACES.general}:address.card.edit`), onClick: handleEditClick }) })] }) }), (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Breakpoint, { until: "md", children: (0, jsx_runtime_1.jsxs)(andes_react_1.BottomSheet, { isOpen: isEditEnabled, fullHeightOnScroll: true, onClose: handleBottomSheetClose, padding: "none", children: [(0, jsx_runtime_1.jsx)(CreditCardSelectContent_1.CreditCardSelectContent, { selectedCard: selectedCard, cards: cards, onSelectedCardChange: handleCreditCardChange, newCreditCardFormProps: newCreditCardFormProps }), !isAddNewCardSelected && ((0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, { isOpen: true, side: "right", align: "bottom", position: "fixed", animation: "none", zIndex: "modal", hasCloseBtn: false, hasBackdrop: false, fullHeight: false, customWidth: "100%", children: (0, jsx_runtime_1.jsx)(SubmitButton, {}) }))] }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Breakpoint, { from: "md", children: (0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, { isOpen: isEditEnabled, onClose: handleBottomSheetClose, side: "right", align: "top", position: "fixed", customWidth: { width: '50vw', maxWidth: '626px' }, hasCloseBtnSpacing: false, footer: {
                                content: isAddNewCardSelected ? null : (0, jsx_runtime_1.jsx)(SubmitButton, {}),
                                position: 'absolute',
                            }, children: (0, jsx_runtime_1.jsx)(CreditCardSelectContent_1.CreditCardSelectContent, { selectedCard: selectedCard, cards: cards, onSelectedCardChange: handleCreditCardChange, newCreditCardFormProps: newCreditCardFormProps }) }) })] }), document.body)] }));
};
exports.SavedCreditCardForm = SavedCreditCardForm;
