"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.basketOverviewAnalyticsSaga = exports.basketOverviewAnalytics = void 0;
const effects_1 = require("redux-saga/effects");
const BasketAPI_1 = require("../../../api/basket/BasketAPI");
const BasketActions_creators_1 = require("../actions/BasketActions.creators");
const ActionEnums_1 = require("../../../enums/ActionEnums");
function* basketOverviewAnalytics() {
    try {
        const { servicesEndpoint, defaultRequestParams } = yield (0, effects_1.getContext)('aemConfiguration');
        const analyticsAdobeTagManagerData = yield (0, effects_1.call)(BasketAPI_1.default.basketOverviewDtm, servicesEndpoint, defaultRequestParams);
        yield (0, effects_1.put)(BasketActions_creators_1.basketActions.createBasketOverviewAdobeAnalyticsSuccessAction(analyticsAdobeTagManagerData));
    }
    catch (_a) { }
}
exports.basketOverviewAnalytics = basketOverviewAnalytics;
function* basketOverviewAnalyticsSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.BasketActionTypes.BASKET_OVERVIEW_INIT, basketOverviewAnalytics);
}
exports.basketOverviewAnalyticsSaga = basketOverviewAnalyticsSaga;
