"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useValidateCustomerDetails = void 0;
const tslib_1 = require("tslib");
const react_1 = require("react");
const CustomerUtil_1 = require("../../../util/CustomerUtil");
const react_hook_form_1 = require("react-hook-form");
const CustomerDetailsFields_utils_1 = require("../../form/registration-fields/CustomerDetailsFields.utils");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const useValidateCustomerDetails = (info) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const [showCustomerDetailsForm, setShowCustomerDetailsForm] = (0, react_1.useState)(!CustomerUtil_1.default.isLoggedInUser(info));
    const shouldValidate = (0, react_1.useRef)(true);
    const values = (0, react_hook_form_1.useFormContext)().getValues();
    (0, react_1.useEffect)(() => {
        const validateForm = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            if (!Object.keys(values).length) {
                return;
            }
            const isValidCustomerDetails = yield (0, CustomerDetailsFields_utils_1.getCustomerDetailsValidationSchema)({ t }).isValid(Object.assign({}, values));
            setShowCustomerDetailsForm(!isValidCustomerDetails);
            shouldValidate.current = false;
        });
        shouldValidate.current && validateForm();
    }, [values, info.customerDetails]);
    const handleEdit = () => {
        setShowCustomerDetailsForm(true);
        shouldValidate.current = false;
    };
    return {
        showCustomerDetailsForm,
        handleEdit,
    };
};
exports.useValidateCustomerDetails = useValidateCustomerDetails;
