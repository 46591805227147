"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoBasketPopupColorSwatches = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const SwatchUtil_1 = require("../../util/SwatchUtil");
const common_components_1 = require("@as-react/common-components");
const SLIDE_WIDTH = 34;
const SLIDES_TO_SCROLL_CORRECTION = 3;
const PromoBasketPopupColorSwatches = ({ colors, productCode, selectedColorId, setProductColor, }) => {
    const { productImageConfig } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const [swiper, updateSwiper] = (0, react_1.useState)(null);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const selectedProductColor = colors.find((colorVariation) => colorVariation.colorId === selectedColorId);
    const renderSlide = (0, react_1.useCallback)((colour, index) => ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.ImageRadioButton, { id: `promo-basket-popup-${productCode}-${colour.colourId}`, name: `color_swatch_${productCode}`, value: colour.colourId.toString(), checked: colour.colourId === (selectedProductColor === null || selectedProductColor === void 0 ? void 0 : selectedProductColor.colorId), imageUrl: colour.swatch, imageLazyLoad: true, title: colour.color, onChange: () => setProductColor(colour.colourId), size: isMobile ? null : cotopaxi_1.ImageRadioButtonSize.LARGE }) }, `color_swatch_${colour.colourId}_${index}`)), [colors, selectedProductColor, isMobile]);
    (0, react_1.useEffect)(() => {
        if (swiper !== null) {
            swiper.update();
        }
    }, [colors, selectedProductColor, isMobile]);
    const calculateSlidesToScroll = (slidesToScroll, swatches) => swatches && swatches.length - 1 === slidesToScroll ? slidesToScroll : slidesToScroll - SLIDES_TO_SCROLL_CORRECTION;
    const colorSwatches = colors.map((productColor) => (0, SwatchUtil_1.generateSwatchFromProductColorVariation)(productColor, selectedColorId, productCode, productImageConfig.cdnDomains));
    function onSwiperInit() {
        const maxSlides = Math.floor(this.width / SLIDE_WIDTH);
        this.params.slidesPerView = maxSlides;
        this.navigation.update();
        this.update();
    }
    function onSwiperResize() {
        const maxSlides = Math.floor(this.width / SLIDE_WIDTH);
        if (this.params.slidesPerView !== maxSlides) {
            this.params.slidesPerView = maxSlides;
            this.navigation.update();
            this.update();
        }
    }
    const colorSwatchesSlideConfig = {
        variant: isMobile ? cotopaxi_1.CarouselVariant.SWATCHES : cotopaxi_1.CarouselVariant.SWATCHES_LARGE,
        arrowsConfig: {
            variant: isMobile ? cotopaxi_1.SliderNavVariant.SWATCHES : cotopaxi_1.SliderNavVariant.SWATCHES_LARGE,
        },
        showPagination: false,
        configurationOverrides: {
            getSwiper: updateSwiper,
            preventClicks: false,
            watchOverflow: true,
            slidesPerGroup: calculateSlidesToScroll(5, colorSwatches),
            spaceBetween: 0,
            shouldSwiperUpdate: true,
            noSwiping: false,
            allowTouchMove: false,
            on: {
                init: onSwiperInit,
                resize: onSwiperResize,
            },
        },
    };
    if (!colorSwatches.length && !selectedColorId)
        return null;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: { spacing: { marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { gutter: false, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, emphasized: true, children: [t('product:pdp.color'), ":", ' '] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, dataQA: "color_name", children: selectedProductColor === null || selectedProductColor === void 0 ? void 0 : selectedProductColor.translations.description })] }) }), (colorSwatches === null || colorSwatches === void 0 ? void 0 : colorSwatches.length) > 1 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, tablet: { spacing: { marginTop: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Carousel, Object.assign({}, colorSwatchesSlideConfig, { children: colorSwatches.map((image, index) => renderSlide(image, index)) })) }))] }));
};
exports.PromoBasketPopupColorSwatches = PromoBasketPopupColorSwatches;
