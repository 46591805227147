"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerPhoneField = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const FormField_1 = require("../../form/FormField");
const CountryUtil_1 = require("../../../util/CountryUtil");
const common_components_1 = require("@as-react/common-components");
const CustomerDetailsFields_utils_1 = require("../../form/registration-fields/CustomerDetailsFields.utils");
const CustomerPhoneField = ({ isRequired, showSavePhoneNumberCheckbox }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.general]);
    const { fictiveWebShop, defaultCountryCode } = (0, common_components_1.useAemConfig)();
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 12, gapY: 6, fullWidth: true, isQueryContainer: true, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { required: isRequired, name: "mobilePhoneNumber", children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.PhoneField, Object.assign({}, options, { label: t(`${i18nConstants_1.I18N_NAMESPACES.account}:account.details.mobilephone`), optionalText: isRequired ? undefined : t(`${i18nConstants_1.I18N_NAMESPACES.general}:form.field.optional`), countrySelectLabel: t(`${i18nConstants_1.I18N_NAMESPACES.general}:form.field.phone_number.country_select.label`), name: options.name, id: options.name, dataTestId: options.name, error: error, maxLength: 50, defaultCountry: (0, CountryUtil_1.getCountryByFictiveWebShop)(fictiveWebShop), countryOptionsOrder: CustomerDetailsFields_utils_1.phoneFieldCountriesOrder[defaultCountryCode], hint: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.phone.info`) })));
                    } }) }), showSavePhoneNumberCheckbox && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, rowOffset: 2, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "savePhoneNumber", children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]);
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.Checkbox, Object.assign({}, options, { label: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.phone.save`), id: options.name, dataTestId: options.name })));
                    } }) }))] }));
};
exports.CustomerPhoneField = CustomerPhoneField;
