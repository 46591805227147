"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePriceViewModel = void 0;
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const asNumeral_1 = require("../vendors/asNumeral");
const pricingStateTranslationConfig = {
    [common_types_1.PRICE_STATE_FROM]: 'product:product.price.from',
    [common_types_1.PRICE_STATE_NOW_FROM]: 'product:product.price.nowFrom',
    [common_types_1.PRICE_STATE_RRP]: 'product:product.price.rrp',
    [common_types_1.PRICE_STATE_WAS]: 'product:product.price.was',
};
const usePriceViewModel = (priceInformation, fallback) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const formatAsCurrency = asNumeral_1.AsNumeral.getInstance().formatAsCurrency;
    const generatePriceLabel = (priceLabel) => !!priceLabel && pricingStateTranslationConfig[priceLabel]
        ? t(pricingStateTranslationConfig[priceLabel])
        : ' ';
    if (!priceInformation.prices || !priceInformation.prices.SELL) {
        return (fallback !== null && fallback !== void 0 ? fallback : {
            priceActual: {
                formattedPrice: '',
                priceLabel: '',
            },
        });
    }
    const priceActual = {
        formattedPrice: formatAsCurrency(priceInformation.prices.SELL.original),
        priceLabel: generatePriceLabel(priceInformation.prices.SELL.priceState),
    };
    let pricePrevious;
    if (priceInformation.hasDiscount && priceInformation.prices.STD) {
        pricePrevious = {
            formattedPrice: formatAsCurrency(priceInformation.prices.STD.original),
            priceLabel: generatePriceLabel(priceInformation.prices.STD.priceState),
        };
    }
    else if (priceInformation.prices.RRP) {
        pricePrevious = {
            formattedPrice: formatAsCurrency(priceInformation.prices.RRP.original),
            priceLabel: generatePriceLabel(priceInformation.prices.RRP.priceState),
        };
    }
    return {
        priceActual,
        pricePrevious,
    };
};
exports.usePriceViewModel = usePriceViewModel;
