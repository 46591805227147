"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const URLParamUtil_1 = require("../util/URLParamUtil");
const common_components_1 = require("@as-react/common-components");
const CustomerApi_1 = require("../api/customer/CustomerApi");
const cotopaxi_1 = require("@as/cotopaxi");
const ShoppingBudgetBlock_1 = require("../components/shopping-budget/ShoppingBudgetBlock");
const react_redux_1 = require("react-redux");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const EventUtil_1 = require("../util/EventUtil");
const EventEnum_1 = require("../enums/EventEnum");
var ShoppingBudgetStatus;
(function (ShoppingBudgetStatus) {
    ShoppingBudgetStatus["PENDING"] = "pending";
    ShoppingBudgetStatus["VALID"] = "valid";
    ShoppingBudgetStatus["INVALID"] = "invalid";
    ShoppingBudgetStatus["ERROR"] = "error";
})(ShoppingBudgetStatus || (ShoppingBudgetStatus = {}));
const URL_UUID_PARAM_NAME = 'customerUuid';
const URL_VOUCHER_ONE_PARAM_NAME = 'voucherId1';
const URL_VOUCHER_TWO_PARAM_NAME = 'voucherId2';
const ANALYTICS_ERROR_GENERAL = 'error';
const ANALYTICS_ERROR_USED = 'alreadyused';
const ANALYTICS_ERROR_INVALID = 'expired';
const ANALYTICS_ERROR_ACTIVE = 'alreadyactivated';
const ShoppingBudgetContainer = ({ successPage, backgroundImage, isAlreadyActivated, isAlreadyUsed, isExpired, standardError, }) => {
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const [status, setStatus] = (0, react_1.useState)(ShoppingBudgetStatus.PENDING);
    const [uniqueVouchers, setUniqueVouchers] = (0, react_1.useState)([]);
    const setPaybackVouchers = (uuid, vouchers) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        return yield CustomerApi_1.default.postPaybackVouchers(servicesEndpoint, defaultRequestParams, uuid, vouchers);
    });
    const getAnalyticsError = (voucher) => {
        switch (voucher.messageCode) {
            case 'voucher.used':
                return ANALYTICS_ERROR_USED;
            case 'voucher.invalid':
                return ANALYTICS_ERROR_INVALID;
            case 'already.active':
                return ANALYTICS_ERROR_ACTIVE;
            default:
                return ANALYTICS_ERROR_GENERAL;
        }
    };
    const getShoppingBudgetStatus = (voucher) => {
        switch (voucher.messageCode) {
            case 'voucher.used':
                return isAlreadyUsed;
            case 'voucher.invalid':
                return isExpired;
            case 'already.active':
                return isAlreadyActivated;
            default:
                return standardError;
        }
    };
    (0, react_1.useEffect)(() => {
        const uuid = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, URL_UUID_PARAM_NAME);
        const voucher1 = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, URL_VOUCHER_ONE_PARAM_NAME);
        const voucher2 = (0, URLParamUtil_1.getQuerystringByName)(window.location.search, URL_VOUCHER_TWO_PARAM_NAME);
        const vouchers = [voucher1, voucher2].filter(Boolean);
        if (!!uuid && !!vouchers && vouchers.length > 0) {
            const params = window.location.search.substring(0);
            setPaybackVouchers(uuid, vouchers)
                .then(() => {
                setStatus(ShoppingBudgetStatus.VALID);
                EventUtil_1.default.dispatch(EventEnum_1.EventEnum.LATITUDE_USER_LOGGED_IN, { redirectUrl: `${successPage}${params}` });
            })
                .catch(err => {
                const errVouchers = err.response.data.vouchers;
                if (errVouchers) {
                    setStatus(ShoppingBudgetStatus.INVALID);
                    setUniqueVouchers(errVouchers
                        .filter(voucher => !voucher.valid)
                        .filter((value, index, array) => array.findIndex(voucher => voucher.messageCode === value.messageCode) === index));
                    dispatch(AnalyticsActions_creators_1.analyticsActions.paybackError(getAnalyticsError(errVouchers[0])));
                }
                else {
                    setStatus(ShoppingBudgetStatus.ERROR);
                    dispatch(AnalyticsActions_creators_1.analyticsActions.paybackError(ANALYTICS_ERROR_GENERAL));
                }
            });
        }
        else {
            setStatus(ShoppingBudgetStatus.ERROR);
            dispatch(AnalyticsActions_creators_1.analyticsActions.paybackError(ANALYTICS_ERROR_GENERAL));
        }
    }, []);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 }, bleed: { marginInline: 1 } }, tablet: { spacing: { marginTop: 4 } }, desktop: { spacing: { marginTop: 7, marginBottom: 3 } }, children: [status === ShoppingBudgetStatus.PENDING && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Spinner, { size: cotopaxi_1.SpinnerSize.LARGE }) })), status === ShoppingBudgetStatus.ERROR && ((0, jsx_runtime_1.jsx)(ShoppingBudgetBlock_1.ShoppingBudgetBlock, Object.assign({ backgroundImage: backgroundImage }, standardError))), status === ShoppingBudgetStatus.INVALID &&
                uniqueVouchers.map(voucher => ((0, jsx_runtime_1.jsx)(ShoppingBudgetBlock_1.ShoppingBudgetBlock, Object.assign({ backgroundImage: backgroundImage }, getShoppingBudgetStatus(voucher)), voucher.barcode)))] }));
};
exports.default = ShoppingBudgetContainer;
