"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ContentSelectors_1 = require("../../redux/content/selectors/ContentSelectors");
const ContentListerUtil_1 = require("../../util/content-lister/ContentListerUtil");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const ImageUtil_1 = require("../../util/ImageUtil");
const GridTilePlaceholder_1 = require("../../components/lister/grid-tile-placeholder/GridTilePlaceholder");
const EXTENDED_TILE_INDEX = 1;
const EmptyResults = ({ alignCenter }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, desktop: { spacing: { marginTop: 8, marginBottom: 8 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.MEDIUM, valign: alignCenter ? cotopaxi_1.GroupVerticalAlign.CENTER : cotopaxi_1.GroupVerticalAlign.TOP, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: t('content.overview.no.results.heading') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, children: t('content.overview.no.results.tips') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: t('content.overview.no.results.tip1') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: t('content.overview.no.results.tip2') })] }) }));
};
const ContentListerResultContainer = ({ hideTags }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const { mainWebShop, imageCdnPrefix } = (0, common_components_1.useAemConfig)();
    const tiles = (0, react_redux_1.useSelector)((0, ContentSelectors_1.makeGetContentTiles)());
    const types = (0, react_redux_1.useSelector)((0, ContentSelectors_1.makeGetContentTypes)());
    const isLoading = (0, react_redux_1.useSelector)((0, ContentSelectors_1.makeGetLoadingState)());
    const categories = (0, react_redux_1.useSelector)((0, ContentSelectors_1.makeGetContentCategories)());
    if ((tiles === null || tiles === void 0 ? void 0 : tiles.content.length) === 0) {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.MOBILE, cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET], children: (0, jsx_runtime_1.jsx)(EmptyResults, {}) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: cotopaxi_1.MediaQuery.DESKTOP, children: (0, jsx_runtime_1.jsx)(EmptyResults, { alignCenter: true }) })] }));
    }
    const getImageSource = (image, responsiveImage, extended = false) => {
        try {
            return (0, ImageUtil_1.getImageFromCdn)(JSON.parse(responsiveImage), imageCdnPrefix, extended || isMobile ? '1024' : '480');
        }
        catch (_a) {
            return `${imageCdnPrefix}${image}`;
        }
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ProductGrid, { extendedIndex: isLoading ? Infinity : EXTENDED_TILE_INDEX, mobileFullwidth: true, children: isLoading
            ? GeneralConstants_1.PLACEHOLDER_TILES.map((_, index) => (0, jsx_runtime_1.jsx)(GridTilePlaceholder_1.GridTilePlaceholder, {}, `grid-item-${index}`))
            : tiles.content.map((contentTile, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.ExpertAdviceBlock, { href: contentTile.url, tags: !hideTags ? (0, ContentListerUtil_1.getCategoryAndTypeByTags)(mainWebShop, contentTile.tags, types, categories) : undefined, title: contentTile.title, text: contentTile.description, imageSource: getImageSource(contentTile.image, contentTile.responsiveImage, index === EXTENDED_TILE_INDEX), extended: index === EXTENDED_TILE_INDEX }, `content-tile-${contentTile.title}-${contentTile.sortOrder}`))) }));
};
exports.default = ContentListerResultContainer;
