"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandListerFilters = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const BrandListerFilters = ({ filters, onClick }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.brand);
    const { canonicalUrl } = (0, common_components_1.useAemConfig)();
    const getFilterTitle = (key) => {
        if (key === common_types_1.BrandListerGroupedFilterTypes.ALL) {
            return t('brandlister.overview.navigation.all');
        }
        else if (key === common_types_1.BrandListerGroupedFilterTypes.POPULAR) {
            return t('brandlister.overview.navigation.popular');
        }
        return key;
    };
    const getHref = (key) => {
        const url = new URL(canonicalUrl);
        url.searchParams.set('filter', key);
        return url.toString();
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
            spacing: { marginTop: 3, marginBottom: 0.5 },
        }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.BrandFilter, { children: filters.map((filter) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.BrandFilterItem, { title: getFilterTitle(filter.key), filterKey: filter.key, active: filter.active, href: getHref(filter.key), onClick: onClick }, filter.key))) }) }));
};
exports.BrandListerFilters = BrandListerFilters;
