"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplePayMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const EventUtil_1 = require("../../../../util/EventUtil");
const EventEnum_1 = require("../../../../enums/EventEnum");
const ScrollUtil_1 = require("../../../../util/ScrollUtil");
const SessionStorageUtil_1 = require("../../../../util/SessionStorageUtil");
const StorageKeysConstants_1 = require("../../../../constants/StorageKeysConstants");
const APPLE_PAY_PAYMENT_ERROR_ID = 'applepaypaymenteprror-feedbackmessage';
const ApplePayMessage = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const [applePayPaymentError, setApplePayPaymentError] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const applePayPaymentFailed = SessionStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.APPLE_PAY_PAYMENT_FAILED);
        if (applePayPaymentFailed) {
            SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.APPLE_PAY_PAYMENT_FAILED);
            setApplePayPaymentError(applePayPaymentFailed);
        }
        else {
            setApplePayPaymentError(false);
        }
        EventUtil_1.default.listen(EventEnum_1.EventEnum.APPLE_PAY_PAYMENT_FAILED, () => {
            setApplePayPaymentError(true);
        });
    }, []);
    (0, react_1.useEffect)(() => {
        if (applePayPaymentError) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(APPLE_PAY_PAYMENT_ERROR_ID));
        }
    }, [applePayPaymentError]);
    if (!applePayPaymentError)
        return null;
    return ((0, jsx_runtime_1.jsx)("div", { id: APPLE_PAY_PAYMENT_ERROR_ID, children: (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "error", text: t('buy.payment.applepay.error.generic') }) }));
};
exports.ApplePayMessage = ApplePayMessage;
