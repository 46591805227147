"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnrichedMessages = exports.getDynamicMessage = void 0;
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const DYNAMIC_MESSAGES_TEXT_VARIABLE = '[difference]';
const DYNAMIC_MESSAGES_PRICE_CONSTANT = 0.01;
const BASKET_POPUP_MESSAGE_SEPARATOR = '##';
const BASKET_POPUP_MESSAGE_SEPARATOR_LENGTH = 2;
const PRODUCT_CODE_LENGTH = 10;
const getTotalPriceForDynamicMessages = (messages, products, pricing, vouchers) => {
    let vouchersPrice = 0;
    if (messages.ignoreGiftOrEvouchers) {
        products.map(product => {
            if (product.productType === common_types_1.ProductTypes.E_VOUCHER || product.productType === common_types_1.ProductTypes.CUSTOM_PAPER_VOUCHER) {
                vouchersPrice +=
                    messages.calculateBy === common_types_1.DynamicMessagesCalculateTypes.TOTAL
                        ? product.totalPrice
                        : product.totalPreviousPrice;
            }
        });
    }
    let totalPriceForDynamicMessages = messages.calculateBy === common_types_1.DynamicMessagesCalculateTypes.TOTAL
        ? pricing.priceTotalBasket
        : pricing.totalPriceSellBeforeDiscount;
    if (messages.calculateBy === common_types_1.DynamicMessagesCalculateTypes.SUBTOTAL && messages.includeVouchers && vouchers.length) {
        vouchers.map(voucher => {
            totalPriceForDynamicMessages -= voucher.amountUsedForThisBasket;
        });
    }
    if (messages.calculateBy === common_types_1.DynamicMessagesCalculateTypes.TOTAL && !messages.includeVouchers && vouchers.length) {
        vouchers.map(voucher => {
            totalPriceForDynamicMessages += voucher.amountUsedForThisBasket;
        });
    }
    return totalPriceForDynamicMessages - vouchersPrice;
};
const getDynamicMessage = (dynamicMessages, products, pricing, vouchers) => {
    if (!dynamicMessages.messages.length)
        return null;
    const totalPriceForDynamicMessages = getTotalPriceForDynamicMessages(dynamicMessages, products, pricing, vouchers);
    const dynamicMessage = dynamicMessages.messages.find(dynamicMessageItem => totalPriceForDynamicMessages <= dynamicMessageItem.maximumValue &&
        totalPriceForDynamicMessages >= dynamicMessageItem.minimumValue);
    if (!dynamicMessage)
        return null;
    const currentDate = new Date();
    if (dynamicMessage.stopDate &&
        dynamicMessage.startDate &&
        currentDate < new Date(Date.parse(dynamicMessage.stopDate)) &&
        currentDate > new Date(Date.parse(dynamicMessage.startDate))) {
        return dynamicMessage.message.replace(DYNAMIC_MESSAGES_TEXT_VARIABLE, `${(0, common_utils_1.getRoundedPrice)(dynamicMessage.maximumValue - totalPriceForDynamicMessages + DYNAMIC_MESSAGES_PRICE_CONSTANT)}`);
    }
    return null;
};
exports.getDynamicMessage = getDynamicMessage;
const getEnrichedMessages = (basketPopupMessages) => {
    const enrichedMessages = [];
    if (basketPopupMessages.length > 0) {
        basketPopupMessages.forEach((popupMessage) => {
            if (popupMessage.startsWith(BASKET_POPUP_MESSAGE_SEPARATOR)) {
                enrichedMessages.push({
                    message: popupMessage.substring(PRODUCT_CODE_LENGTH + BASKET_POPUP_MESSAGE_SEPARATOR_LENGTH + BASKET_POPUP_MESSAGE_SEPARATOR_LENGTH),
                    productCode: popupMessage.substring(BASKET_POPUP_MESSAGE_SEPARATOR_LENGTH, PRODUCT_CODE_LENGTH + BASKET_POPUP_MESSAGE_SEPARATOR_LENGTH),
                });
            }
            else {
                enrichedMessages.push({
                    message: popupMessage,
                });
            }
        });
    }
    return enrichedMessages;
};
exports.getEnrichedMessages = getEnrichedMessages;
