"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const LimitedStockNotification_1 = require("../components/product-detail/limited-stock-notification/LimitedStockNotification");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const common_utils_1 = require("@as-react/common-utils");
const LimitedStockNotificationContainer = () => {
    const { oneSizeProductCode, market } = (0, common_components_1.useAemConfig)();
    const sizes = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductSizes)());
    const skuAvailability = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSkuAvailability)());
    const sizeCombinationNotExist = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSizeCombinationNotExist)());
    const oneSizeProduct = (0, common_utils_1.isOneSizeProduct)(sizes, oneSizeProductCode);
    return ((0, jsx_runtime_1.jsx)(LimitedStockNotification_1.default, { skuAvailability: skuAvailability, isOneSizeProduct: oneSizeProduct, sizeCombinationNotExist: sizeCombinationNotExist, market: market }));
};
exports.default = LimitedStockNotificationContainer;
