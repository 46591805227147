"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuySteps = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const react_router_1 = require("react-router");
const CheckoutSelector_1 = require("../../../redux/checkout/selectors/CheckoutSelector");
const common_components_1 = require("@as-react/common-components");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const ABTestUtil_1 = require("../../../util/ABTestUtil");
const BuySteps = ({ step }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.buy);
    const hasRegistration = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetHasRegistration)());
    const { root, isInAuthorMode } = (0, common_components_1.useAemConfig)();
    const history = (0, react_router_1.useHistory)();
    const redirectToDelivery = (e) => {
        e.preventDefault();
        history.push((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.DELIVERY}`, isInAuthorMode));
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: step !== common_types_1.RoutePathname.FAST_CHECKOUT && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                spacing: { marginBottom: 3 },
            }, tablet: {
                spacing: { marginBottom: 4 },
            }, desktop: {
                spacing: {
                    marginTop: 1.5,
                    marginBottom: 5,
                },
            }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Steps, { children: [hasRegistration && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Steps.Step, { title: t('buy.progress.details'), current: step === common_types_1.RoutePathname.REGISTRATION, passed: step === common_types_1.RoutePathname.PAYMENT || step === common_types_1.RoutePathname.DELIVERY })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Steps.Step, { title: t('buy.progress.delivery'), link: ABTestUtil_1.default.getABTest('buySteps') === 'v2' &&
                            step === common_types_1.RoutePathname.PAYMENT && {
                            href: `${root}buy.html/${common_types_1.RoutePathname.DELIVERY}`,
                            variant: cotopaxi_1.LinkVariant.SUBTLE_ALT,
                            onClick: redirectToDelivery,
                        }, current: step === common_types_1.RoutePathname.DELIVERY, passed: step === common_types_1.RoutePathname.PAYMENT }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Steps.Step, { title: t('buy.progress.payment'), current: step === common_types_1.RoutePathname.PAYMENT }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Steps.Step, { title: t('buy.progress.confirmation') })] }) })) }));
};
exports.BuySteps = BuySteps;
