"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsedReturnOption = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const asNumeral_1 = require("../../vendors/asNumeral");
const StoreLocatorContainer_1 = require("../../containers/StoreLocatorContainer");
const HtmlInclude_1 = require("../common/html-include/HtmlInclude");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const ImageUtil_1 = require("../../util/ImageUtil");
const UsedReturnOption = ({ logo, title, subtitle, courier, freeText, priceOverride, costPrice, announcementReference, hasLabel, refundInformationMessageType, showMultipleInvoiceMessage, hasMultipleInvoices, showDifferentAnnouncedFreeText, announcedFreeText, hideStoreNotification, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const { servicesEndpoint, lang, currency, mainWebShop, imageCdnPrefix } = (0, common_components_1.useAemConfig)();
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const returnLocation = courier === common_types_1.ReturnLocations.STORE ? 'store' : 'courier';
    const downloadLink = announcementReference
        ? `${servicesEndpoint}api/webreturns/returns/announce/${announcementReference}/downloadLabel${isLoggedIn ? '' : '/guest'}?mainWebshop=${mainWebShop}&language=${lang}`
        : null;
    const getImageUrl = (image) => (0, ImageUtil_1.getImageFromCdn)(image, imageCdnPrefix);
    const refundInformationMessage = t(`returnItems.returnMethod.${returnLocation}.paymentRefund.${refundInformationMessageType === null || refundInformationMessageType === void 0 ? void 0 : refundInformationMessageType.toLowerCase()}`);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 4 } }, mobileBleed: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.CheckList.ItemTile, { title: title, subtitle: courier !== common_types_1.ReturnLocations.STORE ? (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.INHERIT, children: subtitle }) : subtitle, icon: logo ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: getImageUrl(logo), size: cotopaxi_1.ImageSize.CHECK_LIST_ITEM_DEFAULT })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.STORE, size: cotopaxi_1.IconSize.EXTRA_MAJOR })), deliveryPrice: priceOverride
                ? priceOverride
                : costPrice === null || costPrice === 0
                    ? t('returnItems.returnMethod.store.price')
                    : `${currency}${asNumeral_1.AsNumeral.getInstance().formatAsCurrency(costPrice)}`, isFreeDelivery: courier === common_types_1.ReturnLocations.STORE, footer: courier === common_types_1.ReturnLocations.STORE && !hideStoreNotification ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: t('returnItems.returnMethod.store.notice'), variant: cotopaxi_1.FeedbackMessageVariant.INFO, iconName: cotopaxi_1.IconName.STORE, iconSize: cotopaxi_1.IconSize.EXTRA_MAJOR })) : undefined, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutterY: cotopaxi_1.GroupGutter.MEDIUM, column: true, children: [courier === common_types_1.ReturnLocations.STORE && (0, jsx_runtime_1.jsx)(StoreLocatorContainer_1.StoreLocatorContainer, {}), downloadLink && hasLabel && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: downloadLink, download: true, children: t('returnItems.registeredReturns.courierLabel') })), showMultipleInvoiceMessage && hasMultipleInvoices && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { emphasized: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EXCLAMATION_CIRCLE_SOLID, size: cotopaxi_1.IconSize.MINOR, inline: true }), ' ', t('returnItems.returnMethod.multipleInvoiceMessage')] })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: showDifferentAnnouncedFreeText ? announcedFreeText : freeText }) }), refundInformationMessageType && (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: refundInformationMessage })] }) }) }));
};
exports.UsedReturnOption = UsedReturnOption;
