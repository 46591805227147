"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eraseList = exports.eraseProductRecentlyViewedSaga = exports.saveProductToLocalStorage = exports.saveProductRecentlyViewedSaga = exports.loadProductRecentlyViewed = exports.loadProductRecentlyViewedSaga = void 0;
const effects_1 = require("redux-saga/effects");
const common_utils_1 = require("@as-react/common-utils");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const ProductRecentlyViewedActions_creators_1 = require("../actions/ProductRecentlyViewedActions.creators");
const ProductRecentlyViewedAPI_1 = require("../../../api/productRecentlyViewed/ProductRecentlyViewedAPI");
const ProductsAPI_1 = require("../../../api/ProductsAPI");
const ProductDetailAPI_1 = require("../../../api/ProductDetailAPI");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
function* loadProductRecentlyViewedSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductRecentlyViewedActionTypes.GET, loadProductRecentlyViewed);
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductActionTypes.INIT, saveProductToLocalStorage);
}
exports.loadProductRecentlyViewedSaga = loadProductRecentlyViewedSaga;
function* loadProductRecentlyViewed() {
    try {
        let recentlyViewedProducts = ProductRecentlyViewedAPI_1.default.getProductsFromLocalStorage();
        if ((0, common_utils_1.isNullOrUndefined)(recentlyViewedProducts)) {
            recentlyViewedProducts = [];
        }
        const { defaultRequestParams, servicesEndpoint } = yield (0, effects_1.getContext)('aemConfiguration');
        const products = yield ProductsAPI_1.default.getAllProductInformation(recentlyViewedProducts, servicesEndpoint, defaultRequestParams);
        const productsPrices = yield ProductDetailAPI_1.default.getAllProductDetailPriceByProductId(products.map((product) => product.productId), servicesEndpoint, defaultRequestParams);
        const productsWithPrices = products.map((product) => {
            const productPrice = productsPrices.find((price) => product.productId === price.productId);
            return (0, common_utils_1.mergeProductPricesToProductLister)(product, productPrice);
        });
        const productRecentlyViewedFetchAction = ProductRecentlyViewedActions_creators_1.ProductRecentlyViewedActions.fetchProducts(productsWithPrices);
        yield (0, effects_1.put)(productRecentlyViewedFetchAction);
    }
    catch (_a) { }
}
exports.loadProductRecentlyViewed = loadProductRecentlyViewed;
function* saveProductRecentlyViewedSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductRecentlyViewedActionTypes.FETCH, saveProductToLocalStorage);
}
exports.saveProductRecentlyViewedSaga = saveProductRecentlyViewedSaga;
function* saveProductToLocalStorage() {
    try {
        let products = ProductRecentlyViewedAPI_1.default.getProductsFromLocalStorage();
        const productInfo = yield (0, effects_1.select)((0, ProductSelector_1.makeGetProductInfo)());
        if ((0, common_utils_1.isNullOrUndefined)(productInfo) ||
            (0, common_utils_1.isNullOrUndefined)(productInfo.productCode) ||
            (0, common_utils_1.isNullOrUndefined)(productInfo.selectedColorId)) {
            return;
        }
        const product = {
            productCode: productInfo.productCode,
            colorId: productInfo.selectedColorId,
        };
        if (!products) {
            products = [];
        }
        if (products.length === ProductRecentlyViewedAPI_1.default.MAX_PRODUCT_SIZE) {
            products.pop();
        }
        if (products.length === 0) {
            products.push(product);
            LocalStorageUtil_1.default.setItem(ProductRecentlyViewedAPI_1.default.localStorageKey, products);
            ProductRecentlyViewedAPI_1.default.tryToAddProduct(product);
        }
        else if (products.length > 0 && products.length < ProductRecentlyViewedAPI_1.default.MAX_PRODUCT_SIZE) {
            ProductRecentlyViewedAPI_1.default.tryToAddProduct(product);
        }
    }
    catch (_a) { }
}
exports.saveProductToLocalStorage = saveProductToLocalStorage;
function* eraseProductRecentlyViewedSaga() {
    yield (0, effects_1.takeLatest)(ActionEnums_1.ProductRecentlyViewedActionTypes.ERASE, eraseList);
}
exports.eraseProductRecentlyViewedSaga = eraseProductRecentlyViewedSaga;
function* eraseList() {
    LocalStorageUtil_1.default.setItem(ProductRecentlyViewedAPI_1.default.localStorageKey, []);
}
exports.eraseList = eraseList;
function* productRecentlyViewedSaga() {
    yield (0, effects_1.all)([loadProductRecentlyViewedSaga(), saveProductRecentlyViewedSaga(), eraseProductRecentlyViewedSaga()]);
}
exports.default = productRecentlyViewedSaga;
