"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DpePopup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const DpeAuth_1 = require("../dpe-auth/DpeAuth");
const DpeTile_1 = require("../dpe-tile/DpeTile");
const cotopaxi_1 = require("@as/cotopaxi");
const DpePopup = ({ data, digitalPartner, onConfirmationClick, onEnrolmentStepUpdate, isAuthenticated, authFormStep, onFormValidationError, onAuthFormStepUpdate, onLogin, loginState, }) => {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(isAuthenticated || authFormStep === common_types_1.AuthFormStep.EMAIL || authFormStep === common_types_1.AuthFormStep.LOGIN) && ((0, jsx_runtime_1.jsx)(DpeTile_1.default, { isPopup: true, isAuthenticated: isAuthenticated, data: data.tileData, digitalPartner: digitalPartner, onConfirmationClick: onConfirmationClick, onActiveStepChange: onEnrolmentStepUpdate, onFormValidationError: onFormValidationError })), !isAuthenticated && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                    spacing: {
                        paddingTop: (authFormStep === common_types_1.AuthFormStep.LOGIN || authFormStep === common_types_1.AuthFormStep.EMAIL) && 3,
                        paddingBottom: 2,
                        paddingLeft: 2,
                        paddingRight: 2,
                    },
                    theme: authFormStep === common_types_1.AuthFormStep.LOGIN || authFormStep === common_types_1.AuthFormStep.EMAIL
                        ? cotopaxi_1.BoxTheme.PRIMARY
                        : cotopaxi_1.BoxTheme.NONE,
                }, tablet: {
                    spacing: {
                        paddingTop: (authFormStep === common_types_1.AuthFormStep.LOGIN || authFormStep === common_types_1.AuthFormStep.EMAIL) && 3,
                        paddingBottom: 4,
                        paddingLeft: 4,
                        paddingRight: 4,
                    },
                }, children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(authFormStep === common_types_1.AuthFormStep.LOGIN || authFormStep === common_types_1.AuthFormStep.EMAIL) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    paddingBottom: 1,
                                },
                            }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: data.authData.logo, alt: digitalPartner, height: 60 }) })), (0, jsx_runtime_1.jsx)(DpeAuth_1.default, { data: data.authData, step: authFormStep, onStepUpdate: onAuthFormStepUpdate, onLogin: onLogin, loginState: loginState, onValidationError: errorMessage => {
                                onFormValidationError(Object.assign({}, errorMessage));
                            } })] }) }))] }));
};
exports.DpePopup = DpePopup;
