"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDynamicModuleLoaderAnalyticsAdobeTagManager = exports.analyticsAdobeTagManagerMiddleware = void 0;
const AnalyticsUtil_1 = require("./../../util/AnalyticsUtil");
const logger_1 = require("@redux-beacon/logger");
const redux_beacon_1 = require("redux-beacon");
const redux_beacon_adobe_tag_manager_1 = require("@as-react/redux-beacon-adobe-tag-manager");
const common_utils_1 = require("@as-react/common-utils");
const AnalyticsEnums_1 = require("../../enums/AnalyticsEnums");
const common_types_1 = require("@as-react/common-types");
const ActionEnums_1 = require("../../enums/ActionEnums");
exports.analyticsAdobeTagManagerMiddleware = (0, redux_beacon_1.createMiddleware)({
    [ActionEnums_1.ListerActionTypes.LISTER_INIT]: (_action, prevState) => (0, common_utils_1.mergeObjectDeep)({}, (0, AnalyticsUtil_1.generateAdobeAnalyticsProductSearchImpressionEvent)(prevState.url.q) || {}, (0, AnalyticsUtil_1.generateAdobeAnalyticsFilterFacetImpression)(prevState.filters.activeFilters) || {}, (0, AnalyticsUtil_1.generateAdobeAnalyticsFredhopperSegment)(prevState.lister.segment) || {}, (0, AnalyticsUtil_1.generateAdobeAnalyticsSearchResultItemsCount)(Number(prevState.lister.itemCount)) || {}, prevState.lister.isCSR ? (0, AnalyticsUtil_1.generateAdobeAnalyticsSearchPasses)(prevState.lister.searchPasses) : {}),
    [ActionEnums_1.ListerActionTypes.LISTER_TILE_CLICK]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsListerProductClick)(),
    [common_types_1.SET_FILTERS]: (_action, _prevState, nextState) => (0, AnalyticsUtil_1.generateAdobeAnalyticsFilterFacetImpression)(nextState.filters.activeFilters),
    [ActionEnums_1.ListerActionTypes.UPDATE_PRODUCTS_AND_TOTAL_PAGES_AND_ITEM_COUNT]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsSearchResultItemsCount)(action.payload.itemCount),
    [ActionEnums_1.PartialSearchActionTypes.INIT]: (_action, prevState) => (0, common_utils_1.mergeObjectDeep)({}, (0, AnalyticsUtil_1.generateAdobeAnalyticsProductSearchImpressionEvent)(prevState.url.q) || {}, (0, AnalyticsUtil_1.generateAdobeAnalyticsSetPartialSearchResults)() || {}),
    [ActionEnums_1.BasketActionTypes.ADOBE_ANALYTICS_SUCCESS]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsBasketChangeEvent)(action.payload),
    [ActionEnums_1.BasketActionTypes.ADD]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsSetAddToBasketLocation)(action.payload.location),
    [ActionEnums_1.BasketActionTypes.ADOBE_ANALYTICS_TOKEN_SET]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsSetBasketToken)(action.payload),
    [ActionEnums_1.ProductActionTypes.INIT]: (action, _prevState, nextState) => (0, AnalyticsUtil_1.generateAdobeAnalyticsProductDetailInit)(nextState, action.payload),
    [ActionEnums_1.ProductActionTypes.SET_COLOR]: (_action, _prevState, nextState) => (0, AnalyticsUtil_1.generateAdobeAnalyticsProductDetailImpression)(nextState, AnalyticsEnums_1.DirectCallRules.PRODUCT_COLOR_SWITCH),
    [ActionEnums_1.ProductActionTypes.SET_DELIVERY_PROMISE]: (action, _prevState, nextState) => (0, AnalyticsUtil_1.generateAdobeAnalyticsDeliveryPromiseEvent)(action, nextState),
    [ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_START]: (action) => (0, AnalyticsUtil_1.generateDigitalPartnerEnrolmentDigitalDataObject)(action),
    [ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_EMAIL]: (action) => (0, AnalyticsUtil_1.generateDigitalPartnerEnrolmentDigitalDataObject)(action),
    [ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_LOGIN]: (action) => (0, AnalyticsUtil_1.generateDigitalPartnerEnrolmentDigitalDataObject)(action),
    [ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_REGISTER]: (action) => (0, AnalyticsUtil_1.generateDigitalPartnerEnrolmentDigitalDataObject)(action),
    [ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_AUTH_PASSWORD_FORGOTTEN]: (action) => (0, AnalyticsUtil_1.generateDigitalPartnerEnrolmentDigitalDataObject)(action),
    [ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_ELIGIBILITY_CHECK]: (action) => (0, AnalyticsUtil_1.generateDigitalPartnerEnrolmentDigitalDataObject)(action),
    [ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_CONFIRMATION]: (action) => (0, AnalyticsUtil_1.generateDigitalPartnerEnrolmentDigitalDataObject)(action),
    [ActionEnums_1.AnalyticsActionTypes.DPE_ENROLMENT_LEAVE]: (action) => (0, AnalyticsUtil_1.generateDigitalPartnerEnrolmentDigitalDataObject)(action),
    [ActionEnums_1.AnalyticsActionTypes.FORMS_ERROR_MESSAGE]: (action) => (0, AnalyticsUtil_1.generateFormErrorMessageDigitalDataObject)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.BLOCKED_CHECKOUT]: (action) => (0, AnalyticsUtil_1.generateFormErrorMessageDigitalDataObject)(action.payload),
    [ActionEnums_1.WishListActionTypes.ANALYTICS_ADD]: (action, prevState) => (0, AnalyticsUtil_1.generateAdobeAnalyticsWishlistEvent)(action, prevState),
    [ActionEnums_1.WishListActionTypes.ANALYTICS_REMOVE]: (action, prevState) => (0, AnalyticsUtil_1.generateAdobeAnalyticsWishlistEvent)(action, prevState),
    [ActionEnums_1.CompareActionTypes.COMPARE_ADD]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsCompareEvent)(action.type, action.payload),
    [ActionEnums_1.CompareActionTypes.COMPARE_REMOVE]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsCompareEvent)(action.type, action.payload),
    [ActionEnums_1.CompareActionTypes.COMPARE_PAGE_OPEN]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsOpenComparePage)(),
    [ActionEnums_1.CompareActionTypes.COMPARE_POPUP_TOGGLE]: (_, prevState) => (0, AnalyticsUtil_1.generateAdobeAnalyticsToggleComparePopup)(prevState.compare),
    [ActionEnums_1.CompareActionTypes.COMPARE_PAGE_PRODUCT_DISPLAYED_TOGGLE]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsComparePageProductDisplayedToggle)(action.payload),
    [ActionEnums_1.SearchActionTypes.ADOBE_ANALYTICS_SUCCESSFULL_SEARCH_EVENT_PRODUCT]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsSearchEventProduct)(),
    [ActionEnums_1.SearchActionTypes.ADOBE_ANALYTICS_SET_SEARCH_TERM]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsSetSearchTerm)(action.payload),
    [ActionEnums_1.SessionActionTypes.NEWSLETTER_SUBSCRIBE]: (_action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsNewsletterSubscribeEvent)(),
    [ActionEnums_1.SessionActionTypes.SET]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsSessionInitEvent)(action.payload),
    [ActionEnums_1.BasketActionTypes.REMOVE_ADOBE_ANALYTICS_SUCCESS]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsBasketChangeEvent)(action.payload),
    [ActionEnums_1.BasketActionTypes.UPDATE_ADOBE_ANALYTICS_SUCCESS]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsBasketChangeEvent)(action.payload),
    [ActionEnums_1.BasketActionTypes.BASKET_OVERVIEW_ADOBE_ANALYTICS_SUCCESS]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsBasketChangeEvent)(action.payload),
    [ActionEnums_1.CheckoutActionTypes.LOGIN_ANALYTICS]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsCheckout)(AnalyticsEnums_1.PageId.LOGIN, AnalyticsEnums_1.DirectCallRules.CHECKOUT),
    [ActionEnums_1.CheckoutActionTypes.ANALYTICS_SUCCESS]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsBasketChangeEvent)(action.payload),
    [ActionEnums_1.CheckoutActionTypes.ANALYTICS_MERGE_CONFIRMATION_PAGE]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsConfirmationPageEvent)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.CONSENT_ACCEPT_ALL]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsCookieConsentTriggers)(action.payload, AnalyticsEnums_1.DirectCallRules.CONSENT_ACCEPT_ALL),
    [ActionEnums_1.AnalyticsActionTypes.CONSENT_UPDATE_PREFERENCES]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsCookieConsentTriggers)(action.payload, AnalyticsEnums_1.DirectCallRules.CONSENT_UPDATE_PREFERENCES),
    [ActionEnums_1.AnalyticsActionTypes.CONSENT_OPEN_PREFERENCES]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsCookieConsentTriggers)(action.payload, AnalyticsEnums_1.DirectCallRules.CONSENT_OPEN_PREFERENCES),
    [ActionEnums_1.AnalyticsActionTypes.REGISTRATION_STEP]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsRegistrationStep)(),
    [ActionEnums_1.AnalyticsActionTypes.DELIVERY_STEP]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsDeliveryStep)(),
    [ActionEnums_1.AnalyticsActionTypes.PAYMENT_REQUEST]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsPaymentRequest)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.PAYMENT_STEP]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsPaymentStep)(),
    [ActionEnums_1.AnalyticsActionTypes.FAST_CHECKOUT_STEP]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsFastCheckoutStep)(),
    [ActionEnums_1.AnalyticsActionTypes.VOUCHER_ADDED]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsVoucherAdded)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.CONFIRMATION_PAGE]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsConfirmationPage)(),
    [ActionEnums_1.AnalyticsActionTypes.FILTER_WIZARD_OPENED]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsFilterWizardEvent)(AnalyticsEnums_1.EventActions.FILTER_WIZARD_OPENED, AnalyticsEnums_1.DirectCallRules.FILTER_WIZARD_OPENED, action.payload),
    [ActionEnums_1.AnalyticsActionTypes.FILTER_WIZARD_SELECTED]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsFilterWizardEvent)(AnalyticsEnums_1.EventActions.FILTER_WIZARD_SELECTED, AnalyticsEnums_1.DirectCallRules.FILTER_WIZARD_SELECTED, action.payload),
    [ActionEnums_1.AnalyticsActionTypes.CHECKOUT_TYPE]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsCheckoutType)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.COMPARE_PRODUCTS_FEATURES]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsCompareProductsFeatures)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.PRODUCT_SEARCH_PERSONALIZATION]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsProductSearchPersonalization)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.STORE_FILTER_STARTED]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsEventObject)(AnalyticsEnums_1.DirectCallRules.STORE_FILTER_STARTED, AnalyticsEnums_1.EventActions.STORE_FILTER_STARTED),
    [ActionEnums_1.AnalyticsActionTypes.STORE_FILTER_APPLIED]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsEventObject)(AnalyticsEnums_1.DirectCallRules.STORE_FILTER_APPLIED, AnalyticsEnums_1.EventActions.STORE_FILTER_APPLIED),
    [ActionEnums_1.AnalyticsActionTypes.STORE_FILTER_MODIFIED]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsEventObject)(AnalyticsEnums_1.DirectCallRules.STORE_FILTER_MODIFIED, AnalyticsEnums_1.EventActions.STORE_FILTER_MODIFIED),
    [ActionEnums_1.AnalyticsActionTypes.SHOP_THE_LOOK_OPENED]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsShopTheLook)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.LISTER_SORT_CHANGE]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsListerSortChangeEvent)(action.payload),
    [ActionEnums_1.AnalyticsActionTypes.NEWSLETTER_PREFERENCES_SUBSCRIPTION_UPDATE]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsNewsletterPreferencesSubscriptionUpdateEvent)(action.payload.subscriptions, action.payload.unsubscriptions),
    [ActionEnums_1.AnalyticsActionTypes.NEWSLETTER_PREFERENCES_SUBSCRIBE]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsNewsletterPreferencesUpdateEvent)(action.payload, AnalyticsEnums_1.DirectCallRules.NEWSLETTER_OPT_IN),
    [ActionEnums_1.AnalyticsActionTypes.NEWSLETTER_PREFERENCES_UNSUBSCRIBE]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsNewsletterPreferencesUpdateEvent)(action.payload, AnalyticsEnums_1.DirectCallRules.NEWSLETTER_OPT_OUT),
    [ActionEnums_1.AnalyticsActionTypes.NEWSLETTER_PREFERENCES_UPDATE]: (action) => (0, AnalyticsUtil_1.generateAdobeAnalyticsNewsletterPreferencesUpdateEvent)(action.payload, AnalyticsEnums_1.DirectCallRules.NEWSLETTER_PREFERENCES),
    [ActionEnums_1.AnalyticsActionTypes.RETURN_PORTAL_LOGIN]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsReturnPortalLogin)(payload),
    [ActionEnums_1.AnalyticsActionTypes.RETURN_PRODUCT_OVERVIEW]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsReturnPortalOverview)(payload),
    [ActionEnums_1.AnalyticsActionTypes.RETURN_PRODUCTS_CONTINUE_CLICK]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsReturnPortalEvent)(AnalyticsEnums_1.DirectCallRules.RETURN_PRODUCTS_CONTINUE_CLICK, AnalyticsEnums_1.PageId.RETURN_PORTAL_RETURN_PRODUCTS, payload.eventAction),
    [ActionEnums_1.AnalyticsActionTypes.RETURN_PRODUCT_SELECTED]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsReturnPortalEvent)(AnalyticsEnums_1.DirectCallRules.RETURN_PRODUCT_SELECTED, AnalyticsEnums_1.PageId.RETURN_PORTAL_RETURN_PRODUCTS, payload.eventAction),
    [ActionEnums_1.AnalyticsActionTypes.RETURN_PRODUCT_UNSELECTED]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsReturnPortalEvent)(AnalyticsEnums_1.DirectCallRules.RETURN_PRODUCT_UNSELECTED, AnalyticsEnums_1.PageId.RETURN_PORTAL_RETURN_PRODUCTS, payload.eventAction),
    [ActionEnums_1.AnalyticsActionTypes.RETURN_METHOD_OVERVIEW]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsReturnPortalEvent)(AnalyticsEnums_1.DirectCallRules.RETURN_METHOD_OVERVIEW, AnalyticsEnums_1.PageId.RETURN_PORTAL_RETURN_METHOD),
    [ActionEnums_1.AnalyticsActionTypes.RETURN_METHOD_CONFIRM_CLICK]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsReturnPortalEvent)(AnalyticsEnums_1.DirectCallRules.RETURN_METHOD_CONFIRM_CLICK, AnalyticsEnums_1.PageId.RETURN_PORTAL_RETURN_METHOD, payload.eventAction, payload.returnMethod),
    [ActionEnums_1.AnalyticsActionTypes.RETURN_METHOD_SELECTED]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsReturnPortalEvent)(AnalyticsEnums_1.DirectCallRules.RETURN_METHOD_SELECTED, AnalyticsEnums_1.PageId.RETURN_PORTAL_RETURN_METHOD, payload.eventAction, payload.returnMethod),
    [ActionEnums_1.AnalyticsActionTypes.RETURN_ANNOUNCED]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsReturnPortalEvent)(AnalyticsEnums_1.DirectCallRules.RETURN_ANNOUNCED, AnalyticsEnums_1.PageId.RETURN_PORTAL_RETURN_ANNOUNCED, payload.eventAction, payload.returnMethod),
    [ActionEnums_1.AnalyticsActionTypes.SHOW_STORE_STOCK]: (_action, _prevState, nextState) => (0, AnalyticsUtil_1.generateAdobeAnalyticsShowStoreStock)(nextState),
    [ActionEnums_1.AnalyticsActionTypes.PRODUCT_DETAIL_TAB_OPEN]: ({ payload: { tabTitle, pdpTemplateName } }, _prevState, nextState) => (0, AnalyticsUtil_1.generateAdobeAnalyticsProductDetailTabOpen)(tabTitle, pdpTemplateName, nextState),
    [ActionEnums_1.AnalyticsActionTypes.PRODUCT_DETAIL_SIZE_GUIDE_CLICK]: ({ payload }, _prevState, { product }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsProductDetailSizeGuideClick)(product, payload),
    [ActionEnums_1.AnalyticsActionTypes.PRODUCT_DETAIL_ADD_TO_BASKET]: ({ payload }, _prevState, state) => (0, AnalyticsUtil_1.generateAdobeAnalyticsProductDetailAddToBasket)(state, payload),
    [ActionEnums_1.AnalyticsActionTypes.LISTER_RESULTS]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsListerResults)(payload),
    [ActionEnums_1.AnalyticsActionTypes.BUNDLE_DEALS_VIEWED]: ({ payload: colorId }, _prevState, { product }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsBundleDealsViewedObject)(product, colorId),
    [ActionEnums_1.AnalyticsActionTypes.FAQ_QUESTION_EXPAND]: ({ payload: title }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsFaqQuestionExpand)(title),
    [ActionEnums_1.AnalyticsActionTypes.NOTIFY_ME_REQUEST]: (_action, _prevState, { product }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsNotifyMeObject)(product),
    [ActionEnums_1.AnalyticsActionTypes.APPLE_PAY_CLICK]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsEventObject)(AnalyticsEnums_1.DirectCallRules.APPLE_PAY_CLICK, AnalyticsEnums_1.EventActions.APPLE_PAY_CLICK),
    [ActionEnums_1.AnalyticsActionTypes.APPLE_PAY_BUTTON_INITIAL_IN_VIEWPORT]: ({ payload, }) => (0, AnalyticsUtil_1.generateAdobeAnalyticApplePayButtonInitialInViewport)(payload),
    [ActionEnums_1.AnalyticsActionTypes.APPLE_PAY_BUTTON_IMPRESSION]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticApplePayButtonImpression)(payload),
    [ActionEnums_1.AnalyticsActionTypes.ETICKET_SEND_FEEDBACK]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsEventObject)(AnalyticsEnums_1.DirectCallRules.ETICKET_SEND_FEEDBACK, AnalyticsEnums_1.EventActions.ETICKET_SEND_FEEDBACK),
    [ActionEnums_1.AnalyticsActionTypes.PAYBACK_ENRICHMENT_CHANGED]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsEnrichmentChanged)(payload),
    [ActionEnums_1.AnalyticsActionTypes.PAYBACK_ERROR]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsPaybackError)(payload),
    [ActionEnums_1.AnalyticsActionTypes.PRODUCT_GROUP_VIEWED]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsProductGroupViewed)(payload),
    [ActionEnums_1.AnalyticsActionTypes.PRODUCT_GROUP_ITEM_CLICK]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsProductGroupItemClick)(payload),
    [ActionEnums_1.AnalyticsActionTypes.PRODUCT_VARIANT_CLICK]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsProductVariantClick)(),
    [ActionEnums_1.AnalyticsActionTypes.GIFT_VOUCHER_INIT]: (_action, _prevState, { product }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsGiftVoucherInit)(product),
    [ActionEnums_1.AnalyticsActionTypes.BASKET_REMINDER_NOTIFICATION_VIEWED]: () => (0, AnalyticsUtil_1.generateBasketReminderNotificationViewed)(),
    [ActionEnums_1.AnalyticsActionTypes.BASKET_REMINDER_NOTIFICATION_EVENT]: ({ payload }) => (0, AnalyticsUtil_1.generateBasketReminderNotificationEvent)(payload),
    [ActionEnums_1.AnalyticsActionTypes.ADDITIONAL_PRODUCT_SELECTOR_VIEWED]: ({ payload }, _prevState, nextState) => (0, AnalyticsUtil_1.generateAdobeAnalyticsAdditionalProductSelector)(payload, nextState),
    [ActionEnums_1.AnalyticsActionTypes.MOBILE_NAVIGATION_CLICK]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsEventObjectV2)(AnalyticsEnums_1.DirectCallRules.MOBILE_NAVIGATION_CLICK, AnalyticsEnums_1.EventActions.MOBILE_NAVIGATION_CLICK),
    [ActionEnums_1.AnalyticsActionTypes.SIZEBAY_CLICK]: ({ payload }) => (0, AnalyticsUtil_1.generateAdobeAnalyticsEventObjectV2)(AnalyticsEnums_1.DirectCallRules.SIZEBAY_CLICK, payload),
    [ActionEnums_1.AnalyticsActionTypes.CHECKOUT_PERSONAL_DETAILS]: () => (0, AnalyticsUtil_1.generateAdobeAnalyticsCheckout)(AnalyticsEnums_1.PageId.PERSONAL_DETAILS, AnalyticsEnums_1.DirectCallRules.CHECKOUT),
}, (0, redux_beacon_adobe_tag_manager_1.AdobeTagManager)({ enableDebug: !common_utils_1.AppEnvironmentUtil.isProduction() }), !common_utils_1.AppEnvironmentUtil.isProduction() ? { logger: logger_1.default } : undefined);
const createDynamicModuleLoaderAnalyticsAdobeTagManager = () => ({
    id: 'startup-analytics-adobe-tag-manager',
    middlewares: [exports.analyticsAdobeTagManagerMiddleware],
});
exports.createDynamicModuleLoaderAnalyticsAdobeTagManager = createDynamicModuleLoaderAnalyticsAdobeTagManager;
