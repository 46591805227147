"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliverySheet = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const react_dom_1 = require("react-dom");
const ZendeskWidgetUtil_1 = require("../../../util/ZendeskWidgetUtil");
const react_1 = require("react");
const SIDEBAR_WIDTH = '512px';
const DeliverySheet = ({ isOpen, onClose, getContent, showCTA, getCTAContent }) => {
    (0, cotopaxi_1.useLockBodyScroll)(isOpen);
    (0, react_1.useEffect)(() => {
        if (isOpen) {
            ZendeskWidgetUtil_1.default.updateZIndex(1);
        }
        return () => {
            ZendeskWidgetUtil_1.default.resetZIndex();
        };
    }, [isOpen]);
    return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Breakpoint, { until: "sm", children: (0, jsx_runtime_1.jsxs)(andes_react_1.BottomSheet, { isOpen: isOpen, fullHeightOnScroll: true, onClose: onClose, padding: "none", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "6", paddingBlockEnd: "20", children: getContent() }), showCTA && ((0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, { isOpen: true, side: "right", align: "bottom", position: "fixed", animation: "none", zIndex: "modal", hasCloseBtn: false, hasBackdrop: false, fullHeight: false, customWidth: "100%", children: getCTAContent() }))] }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Breakpoint, { from: "sm", children: (0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, { isOpen: isOpen, onClose: onClose, side: "right", align: "top", position: "fixed", customWidth: SIDEBAR_WIDTH, hasCloseBtnSpacing: false, footer: {
                        content: showCTA && getCTAContent(),
                        position: 'absolute',
                    }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "6", children: getContent() }) }) })] }), document.body);
};
exports.DeliverySheet = DeliverySheet;
