"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const redux_1 = require("redux");
const Rental_1 = require("../components/rental/Rental");
const AuthActions_creators_1 = require("../redux/authentication/actions/AuthActions.creators");
const initialState = {
    ready: false,
};
const jQuery = '$';
class RentalContainer extends react_1.PureComponent {
    constructor(props) {
        super(props);
        this.componentDidMount = () => {
            // wait for jQuery datepicker
            this.waitForScript();
        };
        this.waitForScript = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const scriptPoll = new common_utils_1.Poller(() => (window && window[jQuery] && window[jQuery]().datepicker ? true : false), 500);
            yield scriptPoll.run();
            this.setReady();
        });
        this.setReady = () => {
            this.setState({
                ready: true,
            });
        };
        this.state = initialState;
    }
    render() {
        const { ready } = this.state;
        return ((0, jsx_runtime_1.jsx)(common_components_1.AemConfigurationContext.Consumer, { children: configuration => {
                const { defaultRequestParams, servicesEndpoint } = configuration;
                if (ready) {
                    return ((0, jsx_runtime_1.jsx)(Rental_1.Rental, { apiPrefix: servicesEndpoint, defaultRequestParams: defaultRequestParams, onLogout: this.props.onLogout }));
                }
                return null;
            } }));
    }
}
const mapDispatchToProps = dispatch => (0, redux_1.bindActionCreators)({
    onLogout: AuthActions_creators_1.authActions.logout,
}, dispatch);
const makeMapStateToProps = () => {
    const mapStateToProps = state => ({});
    return mapStateToProps;
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(RentalContainer);
