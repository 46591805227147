"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentRequestGroupCreditCardContainer = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const PaymentGroupCreditCard_1 = require("../../../components/buy/payment/payment-groups/PaymentGroupCreditCard");
const common_components_1 = require("@as-react/common-components");
const PaymentRequestAPI_1 = require("../../../api/buy/PaymentRequestAPI");
const PaymentRequestGroupCreditCardContainer = ({ paymentRequestId, group, saferpayReady, }) => {
    const { defaultRequestParams, servicesEndpoint } = (0, common_components_1.useAemConfig)();
    const handleSubmitNewCard = (saferpayFieldsToken) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const { redirectUrl } = yield PaymentRequestAPI_1.default.postSaferpayToken(servicesEndpoint, defaultRequestParams, paymentRequestId, saferpayFieldsToken);
        location.href = redirectUrl;
    });
    return ((0, jsx_runtime_1.jsx)(PaymentGroupCreditCard_1.PaymentGroupCreditCard, { group: group, onSubmitNewCard: handleSubmitNewCard, isHostedTokenizationSuccess: true, saferpayFieldsReady: saferpayReady }));
};
exports.PaymentRequestGroupCreditCardContainer = PaymentRequestGroupCreditCardContainer;
