"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutStepper = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const CheckoutContext_1 = require("../../context/CheckoutContext");
const CheckoutStepContext_1 = require("../../context/CheckoutStepContext");
const CheckoutStepper = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const [{ login }] = (0, CheckoutContext_1.useCheckoutState)();
    const { step } = (0, CheckoutStepContext_1.useCheckoutStepState)();
    if (step === CheckoutStepContext_1.CheckoutStep.FAST_CHECKOUT)
        return null;
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "10", children: (0, jsx_runtime_1.jsxs)(andes_react_1.Stepper, { children: [(step === CheckoutStepContext_1.CheckoutStep.DETAILS || login.registrationPassed) && ((0, jsx_runtime_1.jsx)(andes_react_1.Stepper.Step, { title: t('checkout.progress.details'), current: step === CheckoutStepContext_1.CheckoutStep.DETAILS, passed: step > CheckoutStepContext_1.CheckoutStep.DETAILS })), (0, jsx_runtime_1.jsx)(andes_react_1.Stepper.Step, { title: t('checkout.progress.delivery'), current: step === CheckoutStepContext_1.CheckoutStep.DELIVERY, passed: step > CheckoutStepContext_1.CheckoutStep.DELIVERY }), (0, jsx_runtime_1.jsx)(andes_react_1.Stepper.Step, { title: t('checkout.progress.payment'), current: step === CheckoutStepContext_1.CheckoutStep.PAYMENT, passed: step > CheckoutStepContext_1.CheckoutStep.PAYMENT }), (0, jsx_runtime_1.jsx)(andes_react_1.Stepper.Step, { title: t('checkout.progress.confirmation'), current: step === CheckoutStepContext_1.CheckoutStep.CONFIRMATION })] }) }));
};
exports.CheckoutStepper = CheckoutStepper;
