"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFieldsNLWithCard = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const FormField_1 = require("../../FormField");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const common_types_1 = require("@as-react/common-types");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const addressFields_utils_1 = require("../addressFields.utils");
const PostcodeHouseNumberWatcher_1 = require("../watchers/PostcodeHouseNumberWatcher");
const react_1 = require("react");
const AddressCard_1 = require("../AddressCard");
const react_hook_form_1 = require("react-hook-form");
const AddressFieldsNLWithCard = ({ prefix, isRequired = true, hasInitialAddress = false, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.general);
    const [showAdditionalFields, setShowAdditionalFields] = (0, react_1.useState)(hasInitialAddress);
    const { formState } = (0, react_hook_form_1.useFormContext)();
    const [houseNumberAdditionName, addressName, cityName] = (0, ArrayUtil_1.mapWithAssert)([common_types_1.AddressFieldName.HOUSE_NUMBER_ADDITION, common_types_1.AddressFieldName.ADDRESS, common_types_1.AddressFieldName.CITY], name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    const [postCodeSearchName, houseNumberSearchName] = (0, ArrayUtil_1.mapWithAssert)([common_types_1.AddressFieldName.POSTCODE, common_types_1.AddressFieldName.HOUSE_NUMBER], name => (0, addressFields_utils_1.generateFieldName)(name, 'addressSearch.'));
    (0, react_1.useEffect)(() => {
        const hasInvalidFields = [addressName, cityName].some(field => Boolean((0, react_hook_form_1.get)(formState.errors, field)));
        hasInvalidFields && setShowAdditionalFields(true);
    }, [formState.isSubmitting]);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { children: [(0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { fullWidth: true, columns: 12, gapY: 6, isQueryContainer: true, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: postCodeSearchName, required: isRequired, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: options.name, name: options.name, maxLength: 50, required: isRequired, label: t('address.postCode'), optionalText: isRequired ? undefined : t('form.field.optional'), placeholder: t('placeholder.postal.code.nl'), dataTestId: "postcode" })));
                            } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 6, ['@lg']: 3 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: houseNumberSearchName, required: isRequired, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: options.name, maxLength: 20, required: isRequired, label: t('address.houseNumber'), optionalText: isRequired ? undefined : t('form.field.optional'), placeholder: t('placeholder.house.number.nl'), dataTestId: "houseNumber" })));
                            } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 6, ['@lg']: 3 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: houseNumberAdditionName, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: options.name, maxLength: 20, optionalText: t('form.field.optional'), label: t('address.houseNumberAddition'), placeholder: t('placeholder.house.number.addition.nl'), dataTestId: "houseNumberAddition" })));
                            } }) })] }), showAdditionalFields && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: { xs: '3', lg: '4' }, children: (0, jsx_runtime_1.jsx)(AddressCard_1.AddressCard, { country: "NL", prefix: prefix, onSaveClick: isEmpty => isEmpty && setShowAdditionalFields(false), addressFields: [addressName, cityName], children: (0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "6", children: (0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { fullWidth: true, columns: 12, gapY: 6, isQueryContainer: true, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: addressName, required: isRequired, children: (_a) => {
                                            var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                            return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: options.name, maxLength: 500, required: isRequired, optionalText: isRequired ? undefined : t('form.field.optional'), label: t('address.street'), dataTestId: "address" })));
                                        } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@lg']: 6 }, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: cityName, required: isRequired, children: (_a) => {
                                            var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                            return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: options.name, maxLength: 50, required: isRequired, label: t('address.city'), optionalText: isRequired ? undefined : t('form.field.optional'), dataTestId: "town_city" })));
                                        } }) })] }) }) }) })), (0, jsx_runtime_1.jsx)(PostcodeHouseNumberWatcher_1.PostcodeHouseNumberWatcher, { prefix: prefix, countryId: common_types_1.CountryId.NL, onAddressFound: () => setShowAdditionalFields(true), postalCodeSearchFieldName: postCodeSearchName, houseNumberSearchFieldName: houseNumberSearchName })] }));
};
exports.AddressFieldsNLWithCard = AddressFieldsNLWithCard;
