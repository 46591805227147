"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const StickyBar_1 = require("../components/product-detail/sticky-bar/StickyBar");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const WishListSelectors_1 = require("../redux/wishList/selectors/WishListSelectors");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const WishListActions_creators_1 = require("../redux/wishList/actions/WishListActions.creators");
const ProductActions_1 = require("../redux/product/actions/ProductActions");
const ProductUtil_1 = require("../util/ProductUtil");
const ImageUtil_1 = require("../util/ImageUtil");
const i18nConstants_1 = require("../constants/i18nConstants");
const asNumeral_1 = require("../vendors/asNumeral");
const ProductDetailV2Container_1 = require("./product/ProductDetailV2Container");
const positionMobile = 1364;
const positionDesktop = 960;
const StickyBarContainer = ({ isHidden, setHidden }) => {
    var _a, _b, _c, _d;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const dispatch = (0, react_redux_1.useDispatch)();
    const { selectedAdditionalProducts } = (0, ProductDetailV2Container_1.useProductDetailContext)();
    const handleAddToBasket = (quantity, location) => dispatch(selectedAdditionalProducts.length > 0
        ? BasketActions_creators_1.basketActions.createAddAdditionalProductsAction(selectedAdditionalProducts, quantity, location)
        : BasketActions_creators_1.basketActions.createAddAction(quantity, location, common_types_1.PDPVersion.V2));
    const handleToggleWishList = (product) => dispatch(WishListActions_creators_1.wishListActions.toggleProduct(product));
    const handleOpenCC = () => dispatch(BasketActions_creators_1.basketActions.createCCPopupOpenAction());
    const handleSizeForgotten = (location) => dispatch(BasketActions_creators_1.basketActions.createSizeForgottenShowAction(location));
    const handleSelectSize = ({ sku }) => dispatch(ProductActions_1.productActions.createSelectSizeAction({ sku }));
    const handleSetBasketError = (error) => dispatch(BasketActions_creators_1.basketActions.createErrorAction(error));
    const { currency, oneSizeProductCode, isShopLanding, checkoutCc: clickAndCollectEnabled, productImageConfig, } = (0, common_components_1.useAemConfig)();
    const selectedSku = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedSku)());
    const productColorIsInWishList = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetProductColorIsInWishList)());
    const selectedColorId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedColorId)());
    const { productCode, productId } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)());
    const sizes = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductSizes)());
    const basketValidationState = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketValidationState)());
    const sizeRecommendationData = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSizeRecommendation)());
    const priceInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetPriceInformation)());
    const { productDiscountAmount, productDiscountPercentage } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDynamicSavingsInformation)());
    const previewInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetPreviewInformation)());
    const detailInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDetailInformation)());
    const { ccExcluded, hdExcluded } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillment)());
    const fulfillmentBySize = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillmentBySize)());
    const isSelectedSizeSoldOut = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetIsSelectedSizeSoldOut)());
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isClient = (0, cotopaxi_1.useIsClient)();
    const oneSizeProduct = (0, common_utils_1.isOneSizeProduct)(sizes, oneSizeProductCode);
    const positionHidden = isMobile ? positionMobile : positionDesktop;
    const imageUrl = ((_a = previewInformation.images[0]) === null || _a === void 0 ? void 0 : _a.picture)
        ? (0, ImageUtil_1.getImageSourceSetByProductCode)(productCode, (_b = previewInformation.images[0]) === null || _b === void 0 ? void 0 : _b.picture, productImageConfig)
        : undefined;
    const handleSetHidden = (positionTop) => {
        if (positionTop > positionHidden) {
            setHidden(false);
        }
        if (positionTop < positionHidden) {
            setHidden(true);
        }
    };
    (0, cotopaxi_1.useScrollPosition)(({ currPos }) => {
        const positionTop = Math.abs(currPos.y);
        handleSetHidden(positionTop);
    }, [positionHidden]);
    (0, react_1.useEffect)(() => {
        const { scrollY, pageYOffset } = window;
        const positionTop = scrollY || pageYOffset;
        handleSetHidden(positionTop);
    }, [positionHidden]);
    const wishListProduct = {
        colorId: selectedColorId,
        productCode: productCode,
        productId: Number(productId),
        sku: selectedSku,
    };
    const productPriceTranslations = {
        savings: t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.dynamic.savings`),
        discount: t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.bundles.discount.info`),
    };
    const productDiscount = (0, ProductUtil_1.getProductDiscount)(productDiscountAmount, productDiscountPercentage, currency);
    const productPriceProps = ((_c = priceInformation.prices) === null || _c === void 0 ? void 0 : _c.SELL)
        ? Object.assign({ currency, hasDiscount: priceInformation.hasDiscount, productDiscount, translations: productPriceTranslations }, (0, common_utils_1.generateCotopaxiPriceViewModel)(priceInformation, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency)) : null;
    if ((ccExcluded && hdExcluded) || !isClient) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(StickyBar_1.default, { isHidden: isHidden, isMobile: isMobile, selectedSku: selectedSku, productColorIsInWishList: productColorIsInWishList, wishListProduct: wishListProduct, isOneSizeProduct: oneSizeProduct, sizes: sizes, basketValidationState: basketValidationState, sizeRecommendation: sizeRecommendationData.colorBased, shopLandingEnabledFlag: isShopLanding, clickAndCollectEnabled: clickAndCollectEnabled, hdExcluded: hdExcluded, addToBasket: handleAddToBasket, openCC: handleOpenCC, sizeForgotten: handleSizeForgotten, toggleWishList: handleToggleWishList, selectSize: handleSelectSize, setBasketError: handleSetBasketError, productPriceProps: productPriceProps, imageUrl: (_d = imageUrl === null || imageUrl === void 0 ? void 0 : imageUrl[common_types_1.ProductImageSizesPortraitEnum.thumb]) !== null && _d !== void 0 ? _d : '', detailInformation: detailInformation, storeSourceExcluded: fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded, isSelectedSizeSoldOut: isSelectedSizeSoldOut }));
};
exports.default = StickyBarContainer;
