"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailRecognitionForm = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const react_hook_form_1 = require("react-hook-form");
const FormField_1 = require("../../../form/FormField");
const EmailValidationWrapper_1 = require("../../../form/validators/EmailValidationWrapper");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const QueryClientUtil_1 = require("../../../../util/QueryClientUtil");
const CheckoutContext_1 = require("../../../../context/CheckoutContext");
const EmailRecognitionForm = ({ onFinish }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const { defaultRequestParams, servicesEndpoint, mainWebShop } = (0, common_components_1.useAemConfig)();
    const [state, dispatch] = (0, CheckoutContext_1.useCheckoutState)();
    const { email } = state.login;
    const formProps = (0, react_hook_form_1.useForm)({ defaultValues: { email }, mode: 'onTouched' });
    const { getEmailExists } = (0, common_queries_1.customerQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const onSubmit = (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ email }) {
        var _b, _c, _d;
        let response;
        try {
            response = yield QueryClientUtil_1.default.fetchQuery(getEmailExists({ email }));
            onFinish(response);
        }
        catch (error) {
            if (((_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.accountStatusCode) ===
                common_types_1.AuthStatusCode.UNKNOWN_ACCOUNT) {
                response = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data;
                onFinish(response);
            }
        }
        dispatch({ type: 'update_login_data', payload: { email, accountStatusCode: response === null || response === void 0 ? void 0 : response.accountStatusCode } });
    });
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(onSubmit), name: "email-recognition-form", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", color: "emphasised", children: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.email_recognition.title`) }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "3", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t([
                                        `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.email_recognition.intro.${mainWebShop}`,
                                        `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.email_recognition.intro`,
                                    ]) }) })] }), (0, jsx_runtime_1.jsx)(EmailValidationWrapper_1.EmailValidationWrapper, { emailFieldKey: "email", errorMessages: { invalidEmail: t(common_types_1.ErrorMessage.INVALID_EMAIL) }, children: ({ validate, renderError }) => ((0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "email", validate: validate, renderError: renderError, children: ({ fieldProps, error }) => ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { label: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.email_recognition.email.label`), name: "email", id: "email", autoComplete: "email", error: error }))) })) }), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { theme: "branded", type: "submit", state: formProps.formState.isSubmitting ? 'loading' : 'interactive', text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.email_recognition.action`), fullWidth: true })] }) }) })));
};
exports.EmailRecognitionForm = EmailRecognitionForm;
