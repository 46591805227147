"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const DpeVitality_1 = require("../dpe-vitality/DpeVitality");
const DpeValidator = ({ digitalPartner, onValidationError, onValidationSuccess, }) => {
    const renderPartner = () => {
        switch (digitalPartner) {
            case common_types_1.DpePartner.VITALITY:
                return (0, jsx_runtime_1.jsx)(DpeVitality_1.default, { onValidationSuccess: onValidationSuccess, onValidationError: onValidationError });
            default:
                return null;
        }
    };
    return renderPartner();
};
exports.default = DpeValidator;
