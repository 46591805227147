"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailOurPlanetContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const ProductSustainabilityFeatures_1 = require("../../components/product-detail/product-sustainability/ProductSustainabilityFeatures");
const ProductSustainabilityProductPlacementCopy_1 = require("../../components/product-detail/product-sustainability/ProductSustainabilityProductPlacementCopy");
const useGlossaryHighlightInPopover_1 = require("../../hooks/useGlossaryHighlightInPopover");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductUtil_1 = require("../../util/ProductUtil");
const ProductSustainabilityTabCTA_1 = require("../../components/product-detail/product-sustainability/ProductSustainabilityTabCTA");
const ProductDetailOurPlanetContainer = ({ ourPlanetFeatures, ppcImageUrl, ppcAltText, ppcDescription, inPopover, isPopoverOpen, readMoreText, readMoreUrl, }) => {
    const { lang, feature_toggles } = (0, common_components_1.useAemConfig)();
    const categories = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductTechnicalCategories)());
    const isOurPlanet = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductOurPlanet)());
    const { our_planet } = feature_toggles;
    const showOurPlanet = our_planet && isOurPlanet;
    const scrollContainer = (0, react_1.useRef)(null);
    const includedCategoriesIDs = ourPlanetFeatures.map(({ id }) => Number(id));
    const filteredCategories = categories
        .map(ProductUtil_1.filterProductCategoryEmptyFeatures)
        .map(emptyProductCategories => (0, ProductUtil_1.filterProductCategoryFalseBooleanFeatures)(emptyProductCategories, lang))
        .filter(category => category.features.length && includedCategoriesIDs.includes(category.categoryId))
        .map(category => (Object.assign(Object.assign({}, category), { description: ourPlanetFeatures.find(feat => Number(feat.id) === category.categoryId).description })));
    (0, useGlossaryHighlightInPopover_1.useGlossaryHighlightInPopover)(scrollContainer, isPopoverOpen);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.ConditionalWrapper, { condition: inPopover, wrapper: children => ((0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: cotopaxi_1.ScrollableListVariant.SHADOW, fullHeight: true, scrollContainerRef: scrollContainer, children: children })), children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: inPopover ? 3 : 2 } }, children: [showOurPlanet && ((0, jsx_runtime_1.jsx)(ProductSustainabilityProductPlacementCopy_1.ProductSustainabilityProductPlacementCopy, { imageUrl: ppcImageUrl, altText: ppcAltText, description: ppcDescription })), filteredCategories.map((category, index) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    marginTop: !showOurPlanet && index === 0 ? 0 : 3,
                                    marginLeft: inPopover ? 2 : 0,
                                    marginRight: inPopover ? 2 : 0,
                                },
                            }, tablet: { spacing: { marginLeft: inPopover ? 4 : 0 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H3, size: cotopaxi_1.HeadingSize.S, children: category.categoryName }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: category.description }) })] }), (0, jsx_runtime_1.jsx)(ProductSustainabilityFeatures_1.ProductSustainabilityFeatures, { features: category.features, contentSpacing: {
                                firstColumn: {
                                    mobile: {
                                        spacing: {
                                            paddingTop: 1.5,
                                            paddingBottom: 1.5,
                                            paddingLeft: 2,
                                            paddingRight: 1,
                                        },
                                    },
                                    tablet: { spacing: { paddingLeft: inPopover ? 4 : 0, paddingRight: 2 } },
                                },
                                secondColumn: {
                                    mobile: {
                                        spacing: {
                                            paddingTop: 1.5,
                                            paddingBottom: 1.5,
                                            paddingLeft: 1,
                                            paddingRight: 2,
                                        },
                                    },
                                    tablet: { spacing: { paddingLeft: 2, paddingRight: inPopover ? 4 : 0 } },
                                },
                            } })] }, category.categoryCode))), !inPopover && showOurPlanet && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, children: (0, jsx_runtime_1.jsx)(ProductSustainabilityTabCTA_1.ProductSustainabilityTabCTA, { url: readMoreUrl, text: readMoreText }) }))] }) }));
};
exports.ProductDetailOurPlanetContainer = ProductDetailOurPlanetContainer;
