"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketOverviewDynamicMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const BasketOverviewMessagesUtil_1 = require("../../util/BasketOverviewMessagesUtil");
const BasketOverviewDynamicMessage = ({ dynamicMessages, messageBoxSpacing, pricing, products, vouchers, }) => {
    if (!dynamicMessages)
        return null;
    const dynamicMessage = (0, BasketOverviewMessagesUtil_1.getDynamicMessage)(dynamicMessages, products, pricing, vouchers);
    if (!dynamicMessage)
        return null;
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, messageBoxSpacing, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { isTransparent: true, variant: cotopaxi_1.FeedbackMessageVariant.INFO, iconSize: cotopaxi_1.IconSize.MEDIUM, text: dynamicMessage }) })));
};
exports.BasketOverviewDynamicMessage = BasketOverviewDynamicMessage;
